import { USER_LOGOUT } from "./UserReducer";

const initState = () => {
  return {
    creating: false,
    creationError: true
  };
};

export const LOCATION_CREATING = "LOCATION_CREATING";
export const LOCATION_CREATE_DONE = "LOCATION_CREATE_DONE";
export const LOCATION_CREATE_ERROR = "LOCATION_CREATE_ERROR";

const locationReducer = (state = initState(), action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return { ...initState };
    case LOCATION_CREATING:
      return { ...state, creating: true, creationError: null };
    case LOCATION_CREATE_DONE:
      return { ...state, creating: false };
    default:
      return state;
  }
};

export default locationReducer;
