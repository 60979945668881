import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

import OperatingSystem from "../../../components/OperatingSystems/OperatingSystem";
import {
  androidTV,
  iOS,
  android,
  firefox,
  chrome,
  safari,
  ps,
  xbox,
  oculus,
  tesla
} from "assets/img/works-with";

import styles from "../../../assets/jss/material-kit-react/components/operatingSystemsStyle";

const useStyles = makeStyles(styles);

const WorksWith = ({ condensed = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const worksWithItems = [
    {
      name: "Android TV",
      icon: androidTV,
      os: false
    },
    {
      name: "iOS",
      icon: iOS,
      os: false
    },
    {
      name: "Android",
      icon: android,
      os: false
    },
    {
      name: "Firefox",
      icon: firefox,
      os: false
    },
    {
      name: "Chrome",
      icon: chrome,
      os: false
    },
    {
      name: "Safari",
      icon: safari,
      os: false
    },
    {
      name: "Playstation",
      icon: ps,
      os: false
    },
    {
      name: "Xbox",
      icon: xbox,
      os: false
    },
    {
      name: "Oculus",
      icon: oculus,
      os: false
    },
    {
      name: "Tesla",
      icon: tesla,
      os: false
    }
  ];

  //Android TV, Apple TV, iOS, Android, Firefox, Chrome, Safari
  return (
    <Container className={classes.osContainer}>
      <Typography variant="h5" component="h3" className={classes.osHeader}>
        {t("works_with")}
      </Typography>
      <div className={classes.iconsContainer}>
        {worksWithItems.map((item, index) => (
          <OperatingSystem key={index} data={item} condensed={condensed} />
        ))}
      </div>
    </Container>
  );
};

export default WorksWith;
