// cloudtweaks special plan

const initState = {
  loading: false,
  cloudTweaksPlans: null
};

export const CLOUDTWEAKS_PLAN_FETCH = "CLOUDTWEAKS_PLAN_FETCH";
export const CLOUDTWEAKS_PLAN_FETCH_DONE = "CLOUDTWEAKS_PLAN_FETCH_DONE";

const CloudTweaksPlanReducer = (state = initState, action) => {
  switch (action.type) {
    case CLOUDTWEAKS_PLAN_FETCH:
      return { ...state, loading: true };
    case CLOUDTWEAKS_PLAN_FETCH_DONE:
      return {
        ...state,
        loading: false,
        cloudTweaksPlans: action.cloudTweaksPlans
      };
    default:
      return state;
  }
};

export default CloudTweaksPlanReducer;
