import React, { useState, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { getMode, getCookie } from "@karpeleslab/klbfw";
import Header from "./Header";
import Footer from "components/Footer/Footer.js";
import routes from "../../router/routeConfig";
import LoginRequired from "../Route/LoginRequired";
import ScrollToTop from "../Route/ScrollToTop";
import Toasts from "components/common/feedback/Toasts";
import { theme } from "./Theme";
import { ThemeProvider } from "@material-ui/styles";
import ErrorBoundary from "./ErrorBoundary";

export default () => {
  const mode = getMode();
  const location = useLocation();
  const pricingTestCookie = getCookie("pricing_test");
  const [lastPath, setLastPath] = useState("");

  // AB TESTING ON PRICING PAGE
  useEffect(() => {
    ABTesting();
  }, [location]); //eslint-disable-line

  const ABTesting = () => {
    if (location.pathname !== lastPath) {
      if (
        location.pathname === "/pricing" &&
        typeof window !== "undefined" &&
        window.gtag
      ) {
        window.gtag("event", "page_view", {
          page_location: window.location,
          page_path: "/pricing",
          page_title:
            pricingTestCookie === "pricing_alt"
              ? "Pricing, version B"
              : "Pricing, version A"
        });
      }

      setLastPath(location.pathname);
    }
  };

  const buildRoute = (idx, route) => {
    if ("mode" in route) {
      if (Array.isArray(route.mode)) {
        if (!route.mode.includes(mode)) return null;
      } else if (mode !== route.mode) return null;
    }

    const C = "components" in route ? route.components : route.component;
    const Component = "components" in route ? C : <C />;

    delete route.component;

    if ("loginRequired" in route && route.loginRequired === true) {
      return (
        <Route key={idx} {...route}>
          <LoginRequired>
            <ErrorBoundary>{Component}</ErrorBoundary>
          </LoginRequired>
        </Route>
      );
    }
    return (
      <Route key={idx} {...route}>
        <ErrorBoundary>{Component}</ErrorBoundary>
      </Route>
    );
  };

  const buildRoutes = () => {
    return (
      <Switch location={location}>
        {routes().map((route, idx) => {
          switch (route.type) {
            case "route":
            default:
              return buildRoute(idx, { ...route });
          }
        })}
      </Switch>
    );
  };

  const addLayout =
    mode !== "ssr-open" && mode !== "client-open" && mode !== "ssr-mail";
  if (!addLayout)
    return <ThemeProvider theme={theme}>{buildRoutes()}</ThemeProvider>;

  return (
    <ThemeProvider theme={theme}>
      <div>
        <ScrollToTop />
        <Toasts />
        <Header />
        <div style={{ width: "100vw", height: "105px" }} />
        {buildRoutes()}
        <Footer />)
      </div>
    </ThemeProvider>
  );
};
