import React from "react";
import TableCell from "@material-ui/core/TableCell";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/landingPage20210323";

const useStyles = makeStyles(styles);

export default function TableRows({ row, pageName }) {
  const classes = useStyles();
  let rows;

  if (!row) return null;

  switch (pageName) {
    case "vms":
      rows = (
        <>
          <TableCell className={classes.tableCell} align="center">
            {row.l}
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            {row.d}
          </TableCell>
        </>
      );
      break;
    case "ide":
      rows = (
        <>
          <TableCell className={classes.tableCell} align="center">
            {row.cs}
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            {row.ca}
          </TableCell>
        </>
      );
      break;
    case "grade":
      rows = (
        <>
          <TableCell className={classes.tableCell} align="center">
            {row.mo}
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            {row.gd}
          </TableCell>
        </>
      );
      break;
    case "editing":
      rows = (
        <>
          <TableCell className={classes.tableCell} align="center">
            {row.mve}
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            {row.z}
          </TableCell>
        </>
      );
      break;
    case "rif":
    case "byod":
    case "computers":
    case "simplified":
    case "quickbooks":
      rows = (
        <>
          <TableCell className={classes.tableCell} align="center">
            {row.aw}
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            {row.oh}
          </TableCell>
        </>
      );
      break;
    case "irc":
      rows = (
        <>
          <TableCell className={classes.tableCell} align="center">
            {row.irc}
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            {row.m}
          </TableCell>
        </>
      );
      break;
    default:
      rows = (
        <TableCell className={classes.tableCell} align="center">
          {row.m}
        </TableCell>
      );
  }

  return rows;
}
