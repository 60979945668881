import React from "react";
import { useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/homepage20210414.style.js";
// import style from "assets/scss/modules/homepage20210414.module.scss";

const useStyles = makeStyles(styles);

export default function HPCard({ data, ...etc }) {
  const classes = useStyles();
  const history = useHistory();
  const { header, desc, image, imageAlt, link } = data;

  let redirectTo,
    cursorClass,
    onLoadImage = image;
  if (link) {
    redirectTo = () => history.push(link);
    cursorClass = classes.cursorPointer;
  }

  return (
    <Grid item xs={3} {...etc} onClick={redirectTo}>
      <Card className={`${classes.cardParent} ${cursorClass}`}>
        <CardMedia alt={imageAlt} image={onLoadImage} component="img" />
        <CardContent>
          <Typography
            variant="h4"
            component="h3"
            className={classes.cardHeader}
          >
            {header}
          </Typography>
          <Typography variant="body1" className={classes.cardDesc}>
            {desc}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
