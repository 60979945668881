import { rest } from "@karpeleslab/klbfw";
import {
  catchRedirectException,
  handleError
} from "../../components/utils/apiErrorHandler";
import {
  PLAN_SELECT,
  PLAN_UNIT_SELECT,
  PLANS_FETCH,
  PLANS_FETCH_DONE
} from "../reducers/PlanReducer";

export const fetchPlans = () => {
  return (dispatch, getState) => {
    dispatch({ type: PLANS_FETCH });

    return rest("Catalog/Product:search", "POST", {
      query: { "Description.Type": "shells_plan" },
      sort: "Basic.Priority:asc"
    })
      .then(catchRedirectException)
      .then(data => {
        dispatch({ type: PLANS_FETCH_DONE, plans: data.data.data });
        return data.data.data;
      })
      .catch(err => {
        handleError(getState, dispatch, err);
      });
  };
};

export const selectPlan = plan => {
  return dispatch => {
    dispatch({ type: PLAN_SELECT, plan });
  };
};

export const selectUnit = unit => {
  return dispatch => {
    dispatch({ type: PLAN_UNIT_SELECT, nbUnitSelected: unit });
  };
};
