import React, { useCallback, useEffect } from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";
import { connect } from "react-redux";
import { cmsFetch } from "../../../store/actions/CmsAction";
import DisplayArticle, { IMG_VARIATIONS } from "../../cms/DisplayArticle";
import { useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Container from "@material-ui/core/Container";

// component
import NotFound from "components/NotFound/NotFound";

// style
import styles from "assets/jss/material-kit-react/views/page.js";
import Redirect from "../../Route/Redirect";
import { getLocale } from "@karpeleslab/klbfw";
const defaultLng = getLocale();

const useStyles = makeStyles(styles);

const Page = ({
  loadingEntry,
  error,
  entry,
  type,
  slugRedirect,
  fetchPage,
  fetchNews,
  fetchFaq,
  fetchTutorial,
  fetchBlog
}) => {
  const { slug } = useParams();
  const classes = useStyles();
  const { t } = useTranslation();
  const fetch = useCallback(
    s => {
      let handler;
      switch (type) {
        case "page":
          handler = fetchPage;
          break;
        case "faq":
          handler = fetchFaq;
          break;
        case "blog":
          handler = fetchBlog;
          break;
        case "tutorial":
          handler = fetchTutorial;
          break;
        case "news":
        default:
          handler = fetchNews;
          break;
      }
      handler(s).catch(() => {});
    },
    [fetchPage, fetchNews, fetchBlog, fetchFaq, fetchTutorial, type]
  );

  useEffect(() => {
    fetch(decodeURIComponent(slug));
  }, [fetch, slug]);

  const buildArticle = type => {
    let content;

    if (type === "progress") {
      content = (
        <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={1}>
            <Box minHeight={410}>
              <CircularProgress />
            </Box>
          </Grid>
        </Grid>
      );
    } else {
      content = <DisplayArticle article={entry} type={type} />;
    }

    return (
      <div
        className={`${classes.genericPageWrapper} ${
          type === "page" && defaultLng === "ja-JP" ? classes.japanesePage : ""
        }`}
      >
        {slugRedirect && <Redirect target={`/${type}/${slugRedirect}`} />}
        {!slugRedirect && <Helmet title={t("helmet_shells_page")} />}
        {!slugRedirect && (
          <div className={classNames(classes.main, classes.mainRaised)}>
            <Container maxWidth="xl">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {content}
                </Grid>
              </Grid>
            </Container>
          </div>
        )}
      </div>
    );
  };
  if (entry && !error) return buildArticle(type);
  else if (loadingEntry) return buildArticle("progress");
  else return <NotFound />;
};

const mapStateToProps = state => {
  return {
    entry: state.cms.entry,
    slugRedirect: state.cms.slugRedirect,
    loadingEntry: state.cms.loadingEntry,
    error: state.cms.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPage: slug => dispatch(fetchPage(slug)),
    fetchBlog: slug => dispatch(fetchBlog(slug)),
    fetchNews: slug => dispatch(fetchNews(slug)),
    fetchFaq: slug => dispatch(fetchFaq(slug)),
    fetchTutorial: slug => dispatch(fetchTutorial(slug))
  };
};

const fetchPage = slug => {
  return cmsFetch(slug, "ctcm-4clvyc-vfdz-ag3n-fnqy-43aooblu", IMG_VARIATIONS);
};

const fetchBlog = slug => {
  return cmsFetch(slug, "ctcm-k7dgki-7f2r-hjxh-763c-ruhqttfu", IMG_VARIATIONS);
};

const fetchNews = slug => {
  return cmsFetch(slug, "ctcm-mzm3ms-ulvr-bmpg-v3h5-n3karqa4", IMG_VARIATIONS);
};

const fetchFaq = slug => {
  return cmsFetch(slug, "ctcm-zswkyx-lpfv-dfxn-5irv-i7eiyqau", IMG_VARIATIONS);
};

const fetchTutorial = slug => {
  return cmsFetch(slug, "ctcm-arhcjm-avpn-f7zl-wqnv-iuqzzlwq", IMG_VARIATIONS);
};

Page.serverFetchPage = (match, store) =>
  store.dispatch(fetchPage(match.params.slug));
Page.serverFetchBlog = (match, store) =>
  store.dispatch(fetchBlog(match.params.slug));
Page.serverFetchNews = (match, store) =>
  store.dispatch(fetchNews(match.params.slug));
Page.serverFetchFaq = (match, store) =>
  store.dispatch(fetchFaq(match.params.slug));
Page.serverFetchTutorial = (match, store) =>
  store.dispatch(fetchTutorial(match.params.slug));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Page);
