import React from "react";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";

import SectionOneGridItem from "./SectionOneGridItem";
import { WrapperContainer } from "components/HOC";
import { daasImage1, daasImage2, daasImage3 } from "assets/img/daas";

export default function SectionOne() {
  const { t } = useTranslation();

  const sectionOneData = [
    {
      imageSrc: daasImage1,
      headerText: t("daas_section_one_header"),
      bodyText: t("daas_section_one_description")
    },
    {
      imageSrc: daasImage2,
      headerText: t("whyshells_h3"),
      bodyText: t("whyshells_p3")
    },
    {
      imageSrc: daasImage3,
      headerText: t("whyshells_h6"),
      bodyText: t("whyshells_p6")
    }
  ];

  return (
    <WrapperContainer addMaxWidth={false}>
      <Grid container spacing={3} justify="center">
        {sectionOneData.map((data, i) => (
          <SectionOneGridItem data={data} key={i} />
        ))}
      </Grid>
    </WrapperContainer>
  );
}
