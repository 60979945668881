import React from "react";
import { Get } from "@karpeleslab/klbfw";
import { Trans, useTranslation } from "react-i18next";
import moment from "moment";

import EmailLayout from "./../../../components/Email/EmailLayout";
import Text from "./../../../components/Email/EmailText";
import Button from "./../../../components/Email/EmailButton";

const Html = () => {
  const { t } = useTranslation();
  const userName = Get("_user").Profile.Display_Name;
  const shellName = Get("_shell").Label;
  const productId = Get("_shell").Catalog_Product__;
  const shellId = Get("_shell").Shell__;
  const renewLink = `https://www.shells.com/cart?product=${productId},shell=${shellId}`;
  const now = moment();
  const new_date = Get("_shell").Expires
    ? moment(parseInt(Get("_shell").Expires.unixms))
    : "N/A";
  const days = new_date !== "N/A" ? new_date.diff(now, "days") : "N/A";

  return (
    <EmailLayout>
      <table
        border="0"
        cellSpacing="0"
        cellPadding="0"
        width="100%"
        style={{ width: "100%" }}
      >
        <tbody>
          <tr>
            <td>
              <Text size="medium">
                <Trans i18nKey="mail_shell_expires_content">
                  {{ shellName }}
                  {{ userName }}
                  {{ days }}
                  <br />
                  <a href={process.env.REACT_APP_SHELL_CONSOLE_URL}>sample</a>
                  <a href={renewLink}>sample</a>
                  <a href="mailto:support@shells.com">sample</a>
                </Trans>
              </Text>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td align="center">
              <Text size="medium">{t("contact_us_desc")}</Text>
            </td>
          </tr>
          <tr>
            <td align="center">
              <Button href="https://www.shells.com/contact">
                {t("contact_us")}
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </EmailLayout>
  );
};

export default Html;
