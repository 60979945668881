import React from "react";
import { useTranslation } from "react-i18next";
import Helmet from "components/instances/instance/Helmet/Helmet";

import Box from "@material-ui/core/Box";

import { Hero, SectionOne, SectionTwo } from "components/Daas";
import PlansCarousel from "components/PlansCarousel/PlansCarousel";
import DealPricingText from "views/PricingPlans/DealPricingText";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/daas";

const useStyles = makeStyles(styles);

export default function Daas() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Helmet title={t("daas_title")} />
      <Hero />
      <SectionOne />
      <SectionTwo />
      <Box pb={18} className={classes.carouselBox}>
        <DealPricingText os="daas" />
        <PlansCarousel />
      </Box>
    </>
  );
}
