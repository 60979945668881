import {
  mainGreenColor,
  blackColor,
  whiteColor,
  orangeColor
} from "assets/jss/material-kit-react";
import {
  heroDesktop,
  heroTablet,
  map,
  mapTablet,
  oneDeviceDesktop,
  oneDeviceTablet
} from "assets/img/homepage20210414";

const styles = theme => ({
  // HPHeader
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.6)"
  },
  heroHeader: {
    fontFamily: "Open Sans",
    fontWeight: "900",
    color: whiteColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "42.5984px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "52px"
    }
  },
  heroHeaderContainer: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
      margin: "0 auto"
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "750px",
      margin: "0 auto"
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "1140px",
      margin: "0 auto"
    }
  },
  subHeader: {
    fontSize: "22px",
    fontFamily: "Roboto, sans-serif",
    color: whiteColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  subHeaderContainer: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "525px",
      margin: "0 auto"
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "710px",
      margin: "0 auto"
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "1140px",
      margin: "0 auto"
    }
  },
  watchVideoButton: {
    borderRadius: "35px",
    fontSize: "22px",
    textAlign: "center",
    "& .MuiButton-label": {
      textTransform: "none",
      color: whiteColor
    },
    [theme.breakpoints.down("xs")]: {
      width: "240px",
      padding: "6px 14px",
      marginTop: "24px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "12px"
    },
    [theme.breakpoints.up("md")]: {
      width: "200px"
    }
  },
  beautifulJoinButton: {
    backgroundColor: "linear-gradient(45deg, #5ba331 0%, #75cb42 100%)",
    borderRadius: "35px",
    color: "#fff",
    textAlign: "center",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover, &:focus": {
      boxShadow: "0 0 0 2px #5ba331 inset",
      background: "#fff",
      color: "#5ba331"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      width: "240px",
      padding: "6px 14px"
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
      width: "250px",
      padding: "6px 14px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "22px"
    }
  },
  mainFeaturedPost: {
    position: "relative",
    padding: "60px 15px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${heroDesktop})`
    },
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${heroTablet})`
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${heroDesktop})`,
      padding: "150px 15px"
    }
  },
  mainFeaturedPostContent: {
    textAlign: "center",
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6)
    }
  },
  featured: {
    width: "150px",
    [theme.breakpoints.down("sm")]: {
      width: "124px"
    }
  },

  // HPCardsRow, HPCard
  featuresHeader: {
    fontFamily: "Open Sans",
    fontSize: "32px",
    fontWeight: "900",
    textAlign: "center",
    color: blackColor
  },
  cardHeader: {
    fontFamily: "Open Sans",
    color: blackColor,
    fontWeight: "900",
    fontSize: "24px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  cardDesc: {
    fontSize: "16px",
    color: blackColor
  },
  // Moved these over to homepage20210414.module.scss for multiple animation triggers on card
  // Not doing this anymore but might in the future so left uncommented
  // cardImage: {
  //   width: "200px",
  //   marginBottom: "20px",
  //   transform: "translateY(5px)",
  //   "-webkit-transform": "translateY(5px)",
  //   transition: "transform 0.4s",
  //   "-webkit-transition": "-webkit-transform 0.4s"
  // },
  cardParent: {
    height: "100%",
    width: "100%",
    textAlign: "center",
    transform: "translateY(5px)",
    "-webkit-transform": "translateY(5px)",
    transition: "transform 0.4s",
    "-webkit-transition": "-webkit-transform 0.4s",
    [theme.breakpoints.up("md")]: {
      minHeight: "360px"
    },
    "&:hover": {
      transform: "translateY(-5px)",
      "-webkit-transform": "translateY(-5px)",
      transition: "transform 0.4s",
      "-webkit-transition": "-webkit-transform 0.4s",
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.3)"
    }
  },
  cardsWrapper: {
    position: "relative",
    width: "315px",
    height: "400px"
  },
  cardsContainer: {
    position: "absolute",
    width: "1280px",
    top: 0,
    left: "50%",
    marginLeft: "0",
    transform: "translateX(-50%)",
    transition: "margin-left .75s cubic-bezier(.17,.67,.16,.99)",
    "@media (max-width: 1280px)": {
      left: 0,
      transform: "unset"
    }
  },
  cardDots: {
    position: "relative",
    borderRadius: "16px",
    backgroundColor: "#888",
    transform: "scale(.5)",
    overflow: "hidden",
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: "-100%",
      width: "100%",
      height: "100%",
      backgroundColor: mainGreenColor,
      borderRadius: "16px",
      transform: "translateX(200%)",
      transition:
        "transform .25s cubic-bezier(.17,.67,.16,.99) .1s, left .1s cubic-bezier(.17,.67,.16,.99) .3s"
    },
    "&.active::before": {
      left: 0,
      transform: "translateX(0)",
      transition: "left .25s cubic-bezier(.17,.67,.16,.99) .1s"
    }
  },
  cardItem: {
    "@media (max-width: 1280px)": {
      opacity: ".75",
      transform: "scale(.9)",
      transition:
        "opacity .5s linear, transform .5s cubic-bezier(.17,.67,.16,.99)",
      "&.active": {
        opacity: 1,
        transform: "scale(1)"
      }
    }
  },

  // HPTopEnd
  oneDeviceFeaturedPost: {
    position: "relative",
    color: theme.palette.common.white,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: 0,
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${oneDeviceDesktop})`,
      padding: "60px 15px"
    },
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${oneDeviceTablet})`,
      padding: "60px 15px"
    },
    [theme.breakpoints.up("md")]: {
      padding: "100px 15px"
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${oneDeviceDesktop})`,
      padding: "275px 15px"
    }
  },
  oneDeviceImage1: {
    width: "300px",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  fontOrange: {
    color: `${orangeColor} !important`
  },
  tinyHeader: {
    fontSize: "16px",
    fontWeight: "bold"
  },

  // HPServers
  serversFeaturedPost: {
    position: "relative",
    color: theme.palette.common.white,
    padding: "225px 15px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${map})`,
      padding: "60px 15px"
    },
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${mapTablet})`,
      padding: "60px 15px"
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${map})`,
      padding: "225px 15px"
    }
  },

  // HPCommunity
  communityWrapper: {
    padding: "60px 15px"
  },
  communityImage: {
    [theme.breakpoints.down("xs")]: {
      width: "64px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "64px"
    },
    [theme.breakpoints.up("md")]: {
      width: "100px"
    }
  },

  // Misc
  sectionHeroHeader: {
    fontFamily: "Open Sans",
    fontWeight: "900",
    color: whiteColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "40px"
    }
  },
  textContainer: {
    maxWidth: "1140px",
    margin: "0 auto"
  },
  alignCenter: {
    textAlign: "center"
  },
  marginBottom: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "25px"
    }
  },
  hideOverflow: {
    overflowX: "hidden"
  },
  cursorPointer: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  blackFont: {
    color: `${blackColor} !important`
  }
});

export default styles;
