import React from "react";
import { useHistory } from "react-router-dom";

import styles from "../../assets/scss/modules/countdown.module.scss";

export default function StaticCard({ digit }) {
  const history = useHistory();

  if (!digit) {
    history.push("/pricing?special=manjaro");
    return null;
  } else {
    return (
      <div className={styles["card-wrapper"]}>
        <div className={styles["card"]}>
          {digit
            .toString()
            .split("")
            .map((number, index) => (
              <div
                className={styles["number-group"]}
                key={`itemgroup-${index}`}
                style={{ transform: `translate3d(0, -${number * 10}%, 0)` }}
              >
                {[...new Array(10).keys()].map(i => (
                  <div key={`item-${i}`} className={styles["number"]}>
                    {i}
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    );
  }
}
