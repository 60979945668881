import React from "react";

import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/virtualBox";

const useStyles = makeStyles(styles);

export default function VirtualBoxListItem({ item, index }) {
  const classes = useStyles();
  let listItemClass, checkIconClass, textClass;

  if (index % 2 === 0) {
    listItemClass = classes.listItem;
    checkIconClass = `fas fa-check ${classes.checkIconWhite}`;
    textClass = `${classes.listItemText} ${classes.listItemWhite}`;
  } else {
    listItemClass = null;
    checkIconClass = `fas fa-check ${classes.checkIcon}`;
    textClass = classes.listItemText;
  }

  return (
    <ListItem
      alignItems="center"
      className={listItemClass}
      style={{ padding: "15px" }}
    >
      <ListItemAvatar>
        <Icon className={checkIconClass} />
      </ListItemAvatar>
      <Typography variant="body1" component="p" className={textClass}>
        {item}
      </Typography>
    </ListItem>
  );
}
