import {
  whiteColor,
  mainGreenColor,
  secondaryGreenColor
} from "assets/jss/material-kit-react";
import { daasBG } from "assets/img/daas";

const styles = theme => ({
  mainFeaturedPost: {
    position: "relative",
    color: theme.palette.common.white,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: 0,
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${daasBG})`
    },
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${daasBG})`
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${daasBG})`
    }
  },
  mainHeader: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: "900",
    color: whiteColor,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "42.5984px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "52px"
    }
  },
  subHeader: {
    fontSize: "22px",
    fontFamily: "Open Sans, Roboto",
    color: whiteColor,
    lineHeight: 1.4,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: 1.4
    }
  },
  daasDevicesImage: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: "45px"
    }
  },
  daasTextContainer: {
    maxWidth: "550px",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "320px"
    }
  },
  headers: {
    fontWeight: "900",
    fontFamily: "Open Sans, sans-serif",
    marginBottom: "25px",
    color: "#042E0F",
    lineHeight: 1.5,
    fontSize: "24px"
  },
  description: {
    fontSize: "16px",
    lineHeight: "1.6",
    fontFamily: "Roboto, sans-serif",
    textAlign: "center",
    color: "#042E0F",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  addPadding: {
    [theme.breakpoints.down("xs")]: {
      padding: "60px 15px"
    },
    [theme.breakpoints.up("sm")]: {
      padding: "60px 45px"
    },
    [theme.breakpoints.up("md")]: {
      padding: "100px 45px"
    }
  },
  gridItem: {
    maxWidth: "550px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "0px !important"
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "45px"
    }
  },
  descriptionContainer: {
    maxWidth: "400px",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      padding: "0 15px 0 15px"
    }
  },
  affiliatesImage: {
    [theme.breakpoints.down("xs")]: {
      width: "300px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "290px"
    },
    [theme.breakpoints.up("lg")]: {
      width: "325px"
    }
  },
  addMarginBottom: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "45px"
    }
  },
  carouselBox: {
    overflowX: "hidden",
    [theme.breakpoints.down("xs")]: {
      marginTop: "45px"
    }
  },
  signUpButton: {
    display: "block",
    margin: "40px auto 40px 0",
    backgroundColor: mainGreenColor,
    borderRadius: "35px",
    color: whiteColor,
    fontSize: "22px",
    textAlign: "center",
    padding: "6px 24px",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover, &:focus": {
      boxShadow: `0 0 0 2px ${secondaryGreenColor} inset`,
      background: whiteColor,
      color: secondaryGreenColor
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "auto",
      marginRight: "auto"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "250px",
      padding: "6px 14px"
    },
    [theme.breakpoints.up("md")]: {
      width: "375px"
    }
  }
});

export default styles;
