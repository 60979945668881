import React, { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import { CSSTransition, SwitchTransition } from "react-transition-group";
import Fab from "@material-ui/core/Fab";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ShareIcon from "@material-ui/icons/Share";

import { makeStyles } from "@material-ui/core/styles";

const useShareStyle = makeStyles(theme => ({
  button: {
    position: "fixed",
    bottom: "24px",
    right: "24px",
    color: "#fff"
  },

  icon: {
    padding: "5px 10px 5px 10px"
  },

  menu: {
    [theme.breakpoints.down("md")]: {
      backgroundColor: "#fff",
      position: "fixed",
      bottom: "84px",
      right: "24px",
      borderRadius: "4px",
      boxShadow:
        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)"
    }
  }
}));

const SharePane = ({ article }) => {
  const getCurrentUrl = () =>
    typeof window === "undefined" ? "" : window.location.href;
  const SHARE_PARAMS = {
    facebook: {
      url: "https://www.facebook.com/sharer.php?u=",
      title: "Share On Facebook",
      params: "",
      text: article.Title,
      sharedUrl: getCurrentUrl(),
      generateText: () => {
        return "";
      },
      generateSharedUrl: (sharedUrl = SHARE_PARAMS.facebook.sharedUrl) => {
        return sharedUrl;
      }
    },
    twitter: {
      url: "https://twitter.com/intent/tweet?",
      title: "Share On Twitter",
      params: "",
      text: article.Title,
      sharedUrl: getCurrentUrl(),
      hashTags: article.keywords,
      suggestedAccounts: [],
      generateText: (text = SHARE_PARAMS.twitter.text) => {
        if (text) {
          return `text=${text.split(" ").join("%20")}`;
        } else return false;
      },
      generateSharedUrl: (sharedUrl = SHARE_PARAMS.twitter.sharedUrl) => {
        return `%0D%0A${sharedUrl}`;
      }
    },
    email: {
      url: "mailto:?",
      title: "Share via Email",
      params: "",
      text: article.Title,
      sharedUrl: getCurrentUrl(),
      sendTo: "",
      generateText: (text = SHARE_PARAMS.email.text) => {
        return `subject=${text}`;
      },
      generateSharedUrl: (sharedUrl = SHARE_PARAMS.email.sharedUrl) => {
        return `&body=${sharedUrl}`;
      }
    }
  };

  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const classes = useShareStyle();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const buildModal = (platform, w = 400, h = 500, resizable = "no") => {
    const queryUrl = platform.url;
    const queryText = platform.generateText();
    const sharedUrl = platform.generateSharedUrl();
    const query = `${queryUrl}${queryText}${sharedUrl}`;
    const options = `width=${w},height=${h},resizable=${resizable}`;

    window.open(query, platform.title, options).focus();
  };
  const handleShare = key => buildModal(SHARE_PARAMS[key]);

  return (
    <>
      <SwitchTransition mode="in-out">
        <CSSTransition
          key={open || matches ? "open" : "closed"}
          addEndListener={(node, done) =>
            node.addEventListener("transitionend", done, false)
          }
          classNames="u-transition--vertical"
        >
          {open || matches ? (
            <List className={classes.menu}>
              <ListItem
                button
                dense
                id="facebook"
                onClick={() => {
                  handleShare("facebook");
                }}
              >
                <ListItemIcon>
                  <i
                    className={`fab fa-lg fa-facebook ${classes.icon}`}
                    aria-hidden="true"
                    style={{ color: "#3B5998" }}
                  />
                </ListItemIcon>
                <ListItemText primary={t("share_facebook")} />
              </ListItem>
              <ListItem
                button
                dense
                id="twitter"
                onClick={() => {
                  handleShare("twitter");
                }}
              >
                <ListItemIcon>
                  <i
                    className={`fab fa-lg fa-twitter ${classes.icon}`}
                    aria-hidden="true"
                    style={{ color: "#00ACEE" }}
                  />
                </ListItemIcon>
                <ListItemText primary={t("share_twitter")} />
              </ListItem>
              <ListItem
                button
                dense
                id="email"
                onClick={() => {
                  handleShare("email");
                }}
              >
                <ListItemIcon>
                  <i
                    className={`fa fa-lg fa-envelope ${classes.icon}`}
                    aria-hidden="true"
                    style={{ color: "#C0C0C0" }}
                  />
                </ListItemIcon>
                <ListItemText primary={t("share_email")} />
              </ListItem>
            </List>
          ) : (
            <span></span>
          )}
        </CSSTransition>
      </SwitchTransition>
      <Hidden lgUp>
        <Fab
          color="primary"
          className={classes.button}
          onClick={() => setOpen(!open)}
        >
          <ShareIcon />
        </Fab>
      </Hidden>
    </>
  );
};

export default SharePane;
