import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation, Trans } from "react-i18next";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/lfa";

import { mmaTeam, andImage } from "assets/img/lfa";

const useStyles = makeStyles(styles);

export default function SectionOne() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper
      className={`${classes.sectionOneMain} ${classes.bgLightBlue} ${classes.s1NoOverflow}`}
      square
      elevation={0}
    >
      <div className={classes.s1TextContainer}>
        <div className={classes.s1Text1div}>
          <Typography
            variant="h5"
            className={`${classes.fontOrange} ${classes.tinyHeader}`}
            gutterBottom
          >
            {t("lfa_p10")}
          </Typography>
          <Typography variant="h3" className={classes.sectionOneHeader}>
            {t("lfa_h3_a")}
          </Typography>
          <img src={andImage} alt="And" className={classes.andImage} />
          <Typography variant="h5" className={classes.sectionOneHeader}>
            {t("lfa_h3_b")}
          </Typography>
        </div>

        <div className={classes.text2Bg}>
          <Typography
            variant="h5"
            component="h3"
            className={classes.s1Description}
          >
            <Trans
              i18nKey="lfa_p3_1"
              components={{
                bold: <strong />,
                green: <span className={classes.greenFont} />
              }}
            />
          </Typography>
          <Typography
            variant="h5"
            component="h3"
            className={`${classes.s1Description} ${classes.marginTop}`}
          >
            {t("lfa_p3_2")}
          </Typography>
        </div>

        <Box mt={6}>
          <Button
            variant="contained"
            disableElevation
            className={`${classes.signUpButton} ${classes.btnCentered}`}
            component={Link}
            to="/pricing"
          >
            {t("whyshells_button")}
          </Button>
        </Box>
      </div>
      <img src={mmaTeam} alt="LFA Fighters" className={classes.allFighters} />
    </Paper>
  );
}
