import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import BitpayPaymentWrapper from "./Sections/BitpayPaymentWrapper";
import OnFilePaymentWrapper from "./Sections/OnFilePaymentWrapper";
import Button from "../../components/CustomButtons/ShellLoadingButton";
import { Grid } from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import WalletPaymentWrapper from "./Sections/WalletPaymentWrapper";
import PaypalWrapper from "../../components/payment/PaypalWrapper";
import Stripe from "../../components/common/inputs/Stripe";

const TabPanel = props => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};
const a11yProps = index => ({
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`
});

const PaymentTabs = ({
  methods,
  methodsOrder,
  submitStripeHandler,
  submitFreeHandler,
  continueEnabled,
  order
}) => {
  const { t } = useTranslation();
  const [value, _setValue] = useState("stripe");
  const [stripe, setStripe] = useState(null);
  const [stripeElements, setStripeElements] = useState(null);

  // list of supported (in this code) methods
  const supported = {
    Wallet: true,
    Bitpay: true,
    Free: true,
    OnFile: true,
    Stripe: true,
    Paypal: true
  };

  useEffect(() => {
    // Be sure to select stripe tab if we are being redirected after a stripe payment
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (clientSecret) {
      _setValue("stripe");
      return;
    }

    const hash = window.location.hash.replace("#", "");
    if (hash && methodsOrder.find(m => m.toLowerCase() === hash))
      _setValue(hash);
    else _setValue(methodsOrder[0].toLowerCase());
  }, []); // eslint-disable-line

  useEffect(() => {
    // This is in case we are being redirected to this page, we need to confirm the payment
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    if (!stripe) {
      return;
    }

    submitStripeHandler(stripe, stripeElements, clientSecret);

    // eslint-disable-next-line
  }, [stripe]);

  const setValue = v => {
    _setValue(v);
  };

  const buildPayment = method => {
    let paymentComponent = null;
    switch (method.method) {
      case "Wallet":
        paymentComponent = (
          <>
            <WalletPaymentWrapper
              method={method}
              submitHandler={submitFreeHandler}
            />
          </>
        );
        break;
      case "Bitpay":
        paymentComponent = <BitpayPaymentWrapper method={method} />;
        break;
      case "Free":
        paymentComponent = (
          <GridContainer spacing={3}>
            <GridItem xs={12}>
              <p>{t("free_order_text")}</p>
            </GridItem>
            <GridItem xs={12}>
              <Grid container spacing={3} justify="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      submitFreeHandler();
                    }}
                    disabled={order.orderProcess}
                    loading={order.orderProcess}
                  >
                    {t("free_order_btn")}
                  </Button>
                </Grid>
              </Grid>
            </GridItem>
          </GridContainer>
        );
        break;
      case "OnFile":
        paymentComponent = (
          <>
            <OnFilePaymentWrapper
              method={method}
              submitHandler={submitFreeHandler}
            />
          </>
        );
        break;
      case "Stripe":
        paymentComponent = (
          <GridContainer spacing={3}>
            <GridItem xs={12}>
              <div
                style={{
                  margin: "auto",
                  border: "solid 1px #B0E170",
                  padding: "12px"
                }}
              >
                <Stripe
                  disabled={order.orderProcess}
                  setStripe={setStripe}
                  setStripeElements={setStripeElements}
                  settings={method.fields}
                />
              </div>
            </GridItem>
            <GridItem xs={12}>
              <Grid container spacing={3} justify="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      submitStripeHandler(stripe, stripeElements);
                    }}
                    disabled={!continueEnabled || order.orderProcess}
                    loading={order.orderProcess}
                  >
                    {t("btn_purchase_cart")}
                  </Button>
                </Grid>
              </Grid>
            </GridItem>
          </GridContainer>
        );
        break;
      case "Paypal":
        paymentComponent = (
          <PaypalWrapper
            fields={method.fields}
            orderId={order.order.data.order.Order__}
            session={method.session}
          />
        );
        break;
      default:
        break;
    }
    return <div>{paymentComponent}</div>;
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={(e, v) => setValue(v)}
        indicatorColor="primary"
      >
        {methodsOrder.map((m, i) => {
          if (!supported[m]) return null;
          return (
            <Tab
              value={m.toLowerCase()}
              key={m.toLowerCase()}
              label={t(`payment_method_${m.toLowerCase()}`)}
              {...a11yProps(i)}
            />
          );
        })}
      </Tabs>
      {methodsOrder.map(m => {
        if (!supported[m]) return null;
        return (
          <TabPanel value={value} index={m.toLowerCase()} key={m.toLowerCase()}>
            {buildPayment(methods[m])}
          </TabPanel>
        );
      })}
    </>
  );
};

export default PaymentTabs;
