import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import qs from "querystring";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import Grid from "@material-ui/core/Grid";
import OAuthDialog from "./../../components/OAuthDialog/OAuthDialog";
import Paper from "@material-ui/core/Paper";

import { oauthGrant, oauthGrantScan } from "./../../store/actions/OAuthAction";
import Typography from "@material-ui/core/Typography";

const OAuthGrant = ({
  grantError,
  grantResult,
  grantScan,
  grantScanError,
  loadingGrant,
  loadingGrantScan,
  oauthGrant,
  oauthGrantScan,
  user
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [status, setStatus] = useState("loading");

  const grantPermissions = scopes => {
    const query = qs.parse(location.search.replace("?", ""));
    const approved_scope = [];
    for (const s in scopes) {
      if (scopes[s].checked) approved_scope.push(s);
    }
    oauthGrant({
      approved_scope,
      group: user.User_Group__,
      ...query
    });
  };

  const reject = () => {
    const query = qs.parse(location.search.replace("?", ""));
    let url = query.redirect_uri;
    let glue = "?";
    if (query.state) {
      url += glue + query.state;
      glue = "&";
    }
    url += `${glue}error=${encodeURIComponent(
      "User cancelled the authorization."
    )}`;
    window.location.assign(url);
  };

  const getContent = () => {
    switch (status) {
      case "done":
        return (
          <Grid item>
            <Typography align="center" variant="h5" component="p">
              {t("oauth_success_title")}
            </Typography>
            <Box py={4}>
              <Typography align="center">
                {t("oauth_success_description")}
              </Typography>
            </Box>
            <Button component="a" href={grantResult.redirect_url}>
              {t("oauth_click_if_no_redirect")}
            </Button>
          </Grid>
        );
      case "error":
        return (
          <Grid item>
            <Typography align="center" variant="h5" component="p">
              {t("oauth_error_title")}
            </Typography>
            <Box py={4}>
              <Typography align="center">
                {t("oauth_error_description")}
              </Typography>
              <Typography align="center">
                {t("oauth_error_description")}
              </Typography>
            </Box>
          </Grid>
        );
      case "loaded":
        return (
          <OAuthDialog
            app={grantScan.data.OAuth2_App}
            scopes={grantScan.data.Scope}
            onCancel={reject}
            onConfirm={grantPermissions}
          />
        );
      case "loading":
      default:
        return (
          <Grid item>
            <CircularProgress size={120} />
          </Grid>
        );
    }
  };

  useEffect(() => {
    if (!grantScan && !grantScanError && !loadingGrantScan)
      oauthGrantScan(qs.parse(location.search.replace("?", "")));
  }, []); // eslint-disable-line

  useEffect(() => {
    console.log(grantError);
    if (grantError) setStatus("error");
  }, [grantError]);

  useEffect(() => {
    if (grantResult) setStatus("done");

    if (
      typeof window !== "undefined" &&
      grantResult &&
      grantResult.result === "redirect" &&
      grantResult.redirect_url
    ) {
      setTimeout(() => {
        window.location.assign(grantResult.redirect_url);
      }, 2000);
    }
  }, [grantResult]);

  useEffect(() => {
    if (loadingGrant || loadingGrantScan) setStatus("loading");
  }, [loadingGrant, loadingGrantScan]);

  useEffect(() => {
    if (grantScan) setStatus("loaded");
  }, [grantScan]);

  return (
    <Grid container justify="center">
      <Grid item xs={10} sm={6} md={4}>
        <Box py={6}>
          <Paper elevation={3}>
            <Box p={4}>
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={4}
              >
                <SwitchTransition mode="out-in">
                  <CSSTransition
                    key={status}
                    addEndListener={(node, done) =>
                      node.addEventListener("transitionend", done, false)
                    }
                    classNames="u-transition--lateral"
                  >
                    {getContent()}
                  </CSSTransition>
                </SwitchTransition>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    grantError: state.oauth.grantError,
    grantResult: state.oauth.grantResult,
    grantScan: state.oauth.grantScan,
    grantScanError: state.oauth.grantScanError,
    loadingGrant: state.oauth.loadingGrant,
    loadingGrantScan: state.oauth.loadingGrantScan,
    user: state.user.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    oauthGrant: params => dispatch(oauthGrant(params)),
    oauthGrantScan: params => dispatch(oauthGrantScan(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OAuthGrant);
