import React, { useState } from "react";
import Button from "../../../components/CustomButtons/ShellButton";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Alert from "@material-ui/lab/Alert";
import DialogActions from "@material-ui/core/DialogActions";

const ChangePaymentMethodButton = ({
  disabled,
  loading,
  handleChangePaymentMethod
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button
        color="default"
        variant="contained"
        disabled={disabled}
        loading={loading}
        onClick={() => setShowDialog(true)}
      >
        {t("change_payment_method")}
      </Button>
      <Dialog
        open={showDialog}
        aria-labelledby={t("change_payment_method")}
        aria-describedby={t("change_payment_method")}
      >
        <DialogTitle id="change-payment-method-title">
          {t("change_payment_method")}
        </DialogTitle>
        <DialogContent>
          <Alert severity="warning">{t("change_payment_method_desc")}</Alert>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            onClick={() => setShowDialog(false)}
            disabled={loading || disabled}
          >
            {t("close_btn")}
          </Button>
          <Button
            onClick={handleChangePaymentMethod}
            color="primary"
            type="submit"
            variant="contained"
            loading={loading}
            disabled={loading || disabled}
          >
            {t("ok_btn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangePaymentMethodButton;
