export const manjaroLogo = require("./Shells_Manjaro_Icon.svg");
export const manjaroDevices = require("./manjaro_overlay-fs8.png");
export const bgWithLogo = require("./Manjaro_wallpaper.png");
export const bgWithoutLogo = require("./Manjaro_wallpaper_no_logo.png");

export const installAnythingImage = require("./Install_Anything.svg");
export const availabilityImage = require("./Availability.svg");
export const greatCommunityImage = require("./Great_Community.svg");

export const oneClickImage = require("./One_Click_One_Solution.svg");
export const chooseImage = require("./Choose_Install_Finished.svg");
export const artistsImage = require("./Artists_and_Home_Users.svg");
export const changeImage = require("./Change_Everything_You_Need.svg");
export const controlPCimage = require("./Control_Your_PC.svg");

export const kritaLogo = require("./Krita_Logo.png");
export const kdenLiveLogo = require("./KDenLive_Logo.png");
export const ardourLogo = require("./Ardour_Logo.png");
export const libreLogo = require("./LibreOffice_Logo.png");
export const thunderbirdLogo = require("./Thunderbird_Logo.png");
export const firefoxLogo = require("./Firefox_Logo.png");
