import React, { useState } from "react";
import { rest } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Helmet from "components/instances/instance/Helmet/Helmet";
import Script from "react-load-script";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import "./Network.scss";
import style from "assets/scss/modules/lpLayout.module.scss";

const Network = () => {
  const { t } = useTranslation();
  const [locations, setLocations] = useState([]);

  const initGlobe = async () => {
    if (typeof window === "undefined" || !window.document) return;

    window.Cesium.Ion.defaultAccessToken =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1MGI0OTkxZC01NTNlLTRkZTktYTJlOC0yN2MzNzY0ZDI3NDEiLCJpZCI6Mzk2MDYsImlhdCI6MTYwNzY4MTg0N30.UuSnNrKVwa8O-lemqCUUu9uz4Jh9ujcCITQ2MyF14jE";
    // Initialize the Cesium Viewer in the HTML element with the `cesiumContainer` ID.
    const viewer = new window.Cesium.Viewer("cesiumContainer", {
      terrainProvider: window.Cesium.createWorldTerrain(),
      baseLayerPicker: false,
      timeline: false,
      homeButton: false,
      infoBox: false,
      sceneModePicker: false,
      navigationHelpButton: false,
      navigationInstructionsInitiallyVisible: false,
      animation: false
    });
    // Add Cesium OSM Buildings, a global 3D buildings layer.
    viewer.scene.primitives.add(window.Cesium.createOsmBuildings());

    const locs = [];
    const { data } = await rest("Shell/Datacenter:list");
    setLocations(data);
    data.forEach(el => {
      locs.push({
        name: `${el.Location} [${el.Name}]`,
        position: window.Cesium.Cartesian3.fromDegrees(
          el.Longitude,
          el.Latitude,
          50000
        ),
        lat: el.Latitude,
        lng: el.Longitude,
        point: {
          pixelSize: 15,
          color:
            parseInt(el.Available) >= 3000
              ? window.Cesium.Color.GREEN
              : parseInt(el.Available) >= 1000
              ? window.Cesium.Color.ORANGE
              : window.Cesium.Color.RED,
          outlineColor: window.Cesium.Color.WHITE,
          outlineWidth: 2
        },
        label: {
          text: `${el.Location} [${el.Name}]`, // `${el.Location} [${el.Name}] \n${t("available_units")}: ${el.Available}`,
          font: "14pt monospace",
          style: window.Cesium.LabelStyle.FILL_AND_OUTLINE,
          outlineWidth: 2,
          verticalOrigin: window.Cesium.VerticalOrigin.BOTTOM,
          pixelOffset: new window.Cesium.Cartesian2(0, -9),
          fillColor: window.Cesium.Color.YELLOW
        }
      });
    });
    let curIdx = 0;
    viewer.camera.flyTo({
      destination: window.Cesium.Cartesian3.fromDegrees(
        locs[curIdx].lng,
        locs[curIdx].lat,
        15000000
      ),
      orientation: {
        // heading : Cesium.Math.toRadians(0.0),
        pitch: window.Cesium.Math.toRadians(-90.0)
      }
    });
    locs.forEach(l => viewer.entities.add(l));
    viewer.zoomTo(viewer.entities);
    setInterval(() => {
      if (curIdx >= locs.length - 1) curIdx = 0;
      else curIdx++;

      viewer.camera.flyTo({
        destination: window.Cesium.Cartesian3.fromDegrees(
          locs[curIdx].lng,
          locs[curIdx].lat,
          15000000
        ),
        orientation: {
          // heading : Cesium.Math.toRadians(0.0),
          pitch: window.Cesium.Math.toRadians(-90.0)
        }
      });
    }, 5000);
  };

  return (
    <Container maxWidth="lg">
      <Helmet title={t("worldwide_network")} />
      <Script
        url="https://cesium.com/downloads/cesiumjs/releases/1.76/Build/Cesium/Cesium.js"
        onLoad={initGlobe}
      />
      <div
        className={`${style["hero"]} ${style["lp-wrapper"]} ${
          style["lp-wrapper-top"]
        }`}
      >
        <h1 className={style["hero-title"]}>{t("worldwide_network")}</h1>
        <p className={style["hero-text"]}>{t("network_desc")}</p>
      </div>
      <div id="cesiumContainer" />
      <Box py={4}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("network_table_name")}</TableCell>
                <TableCell>{t("network_table_location")}</TableCell>
                {/* <TableCell align="right">{t("available_units")}</TableCell> */}
                <TableCell align="right">
                  {t("network_table_distance")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {locations.map(row => (
                <TableRow key={row.Name}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: "bold" }}
                  >
                    {row.Name}
                  </TableCell>
                  <TableCell>{row.Location}</TableCell>
                  {/* <TableCell align="right">
                    {row.Available >= 3000
                      ? "✅"
                      : row.Available >= 1000
                      ? "⚡"
                      : "⚠️"}
                    &nbsp;
                    {row.Available}
                  </TableCell> */}
                  <TableCell align="right">
                    {Math.round(row.Distance / 1000)}km
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default Network;
