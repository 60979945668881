import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import ToggleOrder from "../../../components/Plans/ToggleOrder";

import { removeItem } from "../../../store/actions/CartAction";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/orderPage.js";

const useStyles = makeStyles(styles);

const Cart = ({
  selected,
  vat,
  nbUnit,
  nbSelected,
  plans,
  cart,
  removeItem
}) => {
  const customPlan = plans.find(p => p["Description.AuthorCode"] === "custom");
  const { t } = useTranslation();
  const classes = useStyles();
  const [totalValue, setTotalValue] = useState(0);
  const [shellPrice, setShellPrice] = useState(0);
  const [vatTotal, setVatTotal] = useState(0);

  useEffect(() => {
    if (selected) {
      if (selected.Price) {
        setTotalValue(selected.Price.value);
      }

      // for Windows plans
      if (selected["Price.Price"]) {
        setTotalValue(selected["Price.Price"].value_disp);
      }
    }
  }, [selected]); // eslint-disable-line

  useEffect(() => {
    if (
      cart &&
      cart.cart &&
      cart.cart.total &&
      cart.cartItem &&
      cart.cartItem.length > 0
    )
      setTotalValue(cart.cart.total.value);
  }, [cart]); // eslint-disable-line

  useEffect(() => {
    let tmp = parseFloat(totalValue);
    if (
      selected &&
      customPlan &&
      selected.Catalog_Product__ === customPlan["Catalog_Product__"]
    ) {
      cart.cartItem.forEach(item => removeItem(item.key));
      tmp *= nbUnit;
    } else {
      tmp = Number(totalValue);
    }

    setShellPrice(tmp);
    setVatTotal(tmp * ((vat ? vat.Rate : 0) / 100));
  }, [totalValue, customPlan, vat, nbUnit]); // eslint-disable-line

  if (!selected) {
    return (
      <GridContainer alignItems="center" direction="column">
        <GridItem>
          <Typography align="center" className={classes.cartColor}>
            {t("no_product_selected")}
          </Typography>
        </GridItem>
      </GridContainer>
    );
  }

  let itemsInCart;

  // Showing windows license fee if user is buying from Windows pricing page or Slickdeals pricing page
  cart.cartItem.length === 2 &&
  (cart.cartItem[0].meta.shell_os === "windows-server-2019-dc-desktop" ||
    cart.cartItem[0].data["Description.Type"] === "slickdeals_plans")
    ? (itemsInCart = (
        <>
          <GridItem xs={9}>{cart.cartItem[0].data["Basic.Name"]}</GridItem>
          <GridItem xs={3}>{"$" + cart.cartItem[0].price.value_disp}</GridItem>
          <GridItem xs={9}>{cart.cartItem[1].data["Basic.Name"]}</GridItem>
          <GridItem xs={3}>{"$" + cart.cartItem[1].price.value_disp}</GridItem>
        </>
      ))
    : (itemsInCart = (
        <>
          <GridItem xs={9}>{selected["Basic.Name"]}</GridItem>
          <GridItem xs={3}>{"$" + shellPrice.toFixed(2)}</GridItem>
        </>
      ));

  return (
    <GridContainer
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
    >
      <GridItem xs={12}>
        <GridContainer className={classes.cartColor}>
          {itemsInCart}

          {customPlan &&
            selected.Catalog_Product__ === customPlan["Catalog_Product__"] && (
              <>
                <GridItem xs={9}>{t("cart_number_units_selected")}</GridItem>

                <GridItem xs={3}>{nbSelected}</GridItem>
              </>
            )}

          <GridItem xs={9}>
            <span>
              {t("order_tax")} <span id="vat">{vat && vat.Rate} %</span>{" "}
            </span>
          </GridItem>
          <GridItem xs={3}>{vat && "$" + vatTotal.toFixed(2)}</GridItem>

          <GridItem xs={12}>
            <hr className={classes.cartColor} />
          </GridItem>

          <GridItem xs={9} className={classes.cartNumbers}>
            <span>{t("order_total")}</span>
          </GridItem>
          <GridItem xs={3} className={classes.cartNumbers}>
            {vat &&
              "$" + (shellPrice + shellPrice * (vat.Rate / 100)).toFixed(2)}
          </GridItem>
          <GridItem xs={12} className={classes.cartText}>
            {t("order_yearly_monthly")}
            {selected && selected["Description.Type"] === "shells_plan" ? (
              <Hidden smUp>
                <ToggleOrder variant="toggle" />
              </Hidden>
            ) : (
              ""
            )}
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

const mapDispatchToProps = dispatch => ({
  removeItem: key => dispatch(removeItem(key))
});

const mapStateToProps = state => {
  return {
    selected: state.plans.selected,
    vat: state.order.vat,
    nbUnit: state.plans.nbUnitSelected,
    nbSelected: state.plans.nbUnitSelected,
    plans: state.plans.plans,
    cart: state.cart
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cart);
