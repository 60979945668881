import { whiteColor, mainGreenColor } from "assets/jss/material-kit-react";
import { linuxMintBG1 } from "assets/img/linux-mint";

const styles = theme => ({
  mainFeaturedPost: {
    position: "relative",
    color: theme.palette.common.white,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: 0,
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${linuxMintBG1})`
    },
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${linuxMintBG1})`
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${linuxMintBG1})`
    }
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.4)"
  },
  mainHeader: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: "900",
    color: whiteColor,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "42.5984px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "52px"
    }
  },
  subHeader: {
    fontSize: "22px",
    fontFamily: "Open Sans, Roboto",
    color: whiteColor,
    lineHeight: 1.4,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: 1.4
    }
  },
  morePadding: {
    [theme.breakpoints.down("xs")]: {
      padding: "150px 15px 60px 15px"
    },
    [theme.breakpoints.up("sm")]: {
      padding: "150px 45px"
    },
    [theme.breakpoints.up("lg")]: {
      padding: "150px 25px"
    }
  },
  linuxMintImage: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "45px"
    }
  },
  mainRibbon: {
    position: "absolute",
    top: "30px",
    left: "0",
    width: "100%",
    backgroundColor: mainGreenColor,
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center",
    padding: "12px",
    fontSize: "24px",
    transition: "background-color .15s linear",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px"
    },
    "&:hover": {
      backgroundColor: "#5ba331",
      color: "#fff"
    },
    "& > span": {
      textDecoration: "underline"
    }
  }
  // headerTextContainer: {
  //   maxWidth: "750px",
  //   margin: "0 auto",
  //   [theme.breakpoints.up("sm")]: {
  //     maxWidth: "550px"
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     maxWidth: "750px"
  //   }
  // },
  // subTextContainer: {
  //   maxWidth: "750px",
  //   margin: "0 auto"
  // }
  // whiteOutline: {
  //   padding: "15px",
  //   borderRadius: "16px",
  //   backdropFilter: "blur(4px)",
  //   backgroundColor: "rgba(255, 255, 255, 0.2)"
  // }
});

export default styles;
