import React from "react";
import { useTranslation } from "react-i18next";
import { HashLink as Link } from "react-router-hash-link";

import { WrapperContainer } from "components/HOC";
import FadeIn from "./FadeIn";

import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/material-kit-react/views/whyShells";

const useStyles = makeStyles(styles);

export default function ImageAndText({ details }) {
  const {
    imageLeft,
    addButton,
    header_a,
    header_b,
    description,
    image,
    addlWclass,
    fontColor,
    addlFontColor,
    textContainerClass,
    lineClass,
    addlLineClass,
    newHP = false
  } = details;
  const { t } = useTranslation();
  const classes = useStyles();

  let view, button;

  if (addButton) {
    button = (
      <Button
        variant="contained"
        disableElevation
        className={`${classes.signUpButton} ${classes.extraButtonStyle}`}
        component={Link}
        to="/pricing"
      >
        {t("whyshells_button")}
      </Button>
    );
  } else {
    button = null;
  }

  if (imageLeft) {
    view = (
      <WrapperContainer addMaxWidth={false} addlWclass={addlWclass}>
        <div className={classes.contentContainer}>
          <Hidden smDown>
            <div className={classes.leftImage02}>
              <FadeIn direction="right">
                <img src={image} alt="" />
              </FadeIn>
            </div>
          </Hidden>
          <div className={textContainerClass}>
            <div className={classes.headerContainer2}>
              <Typography
                variant="h3"
                className={`${classes.headerNextLine} ${fontColor}`}
              >
                {header_a}
              </Typography>
              <Typography
                variant="h3"
                className={`${classes.headers} ${fontColor}`}
              >
                {header_b}
              </Typography>
            </div>
            <div className={lineClass} />
            <Hidden mdUp>
              <div
                className={
                  newHP ? classes.newHPleftImage02 : classes.leftImage02
                }
              >
                <FadeIn direction={"right"}>
                  <img src={image} alt="" />
                </FadeIn>
              </div>
            </Hidden>
            <div
              className={`${classes.descriptionContainer} ${classes.marginTop}`}
            >
              <Typography variant="body1" className={`${classes.description2}`}>
                {description}
              </Typography>
            </div>
            {button}
          </div>
        </div>
      </WrapperContainer>
    );
  } else {
    view = (
      <WrapperContainer addMaxWidth={true} addlWclass={addlWclass}>
        <div className={classes.contentContainer}>
          <div className={textContainerClass}>
            <div className={classes.headerContainer2}>
              <Typography
                variant="h1"
                className={
                  newHP
                    ? `${classes.headerNextLine} ${addlFontColor}`
                    : `${classes.headerNextLine} ${fontColor}`
                }
              >
                {header_a}
              </Typography>
              <Typography
                variant="h1"
                className={
                  newHP
                    ? `${classes.headers} ${addlFontColor}`
                    : `${classes.headers} ${fontColor}`
                }
              >
                {header_b}
              </Typography>
            </div>
            <div className={newHP ? addlLineClass : lineClass} />
            <Hidden mdUp>
              <div
                className={
                  newHP
                    ? classes.newHPhorizontalImage03
                    : classes.horizontalImage03
                }
              >
                <FadeIn>
                  <img src={image} alt="" />
                </FadeIn>
              </div>
            </Hidden>
            <div
              className={`${classes.descriptionContainer} ${classes.marginTop}`}
            >
              <Typography
                variant="body1"
                className={`${classes.description2} ${fontColor}`}
              >
                {description}
              </Typography>
            </div>
          </div>
          <Hidden smDown>
            <div
              className={
                newHP
                  ? classes.newHPhorizontalImage03
                  : classes.horizontalImage03
              }
            >
              <FadeIn direction="left">
                <img src={image} alt="" />
              </FadeIn>
            </div>
          </Hidden>
        </div>
      </WrapperContainer>
    );
  }

  return view;
}
