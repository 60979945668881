import React from "react";
import { useTranslation } from "react-i18next";
import { HashLink as Link } from "react-router-hash-link";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";

import { WrapperContainer } from "components/HOC";
import { daasImage3, daasImage2, daasImage1 } from "assets/img/daas";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/virtualBox";

const useStyles = makeStyles(styles);

export default function VirtualBoxHero({ page }) {
  const { t } = useTranslation();
  const classes = useStyles();

  let heroText, imageSrc, imageAlt;

  switch (page) {
    case "virtualBox":
      heroText = "virtual_box_hero";
      imageAlt = "Shells / VirtualBox";
      imageSrc = daasImage3;
      break;
    case "vmware":
      heroText = "vmware_hero";
      imageAlt = "Shells / Vmware";
      imageSrc = daasImage1;
      break;
    case "windowsLinux":
      heroText = "windows_linux_hero";
      imageAlt = "Shells / Windows Subsystem Linux";
      imageSrc = daasImage2;
      break;
    default:
      break;
  }

  return (
    <Paper elevation={0} className={classes.mainFeaturedPost}>
      <WrapperContainer addMaxWidth={false} addlWclass={classes.morePadding}>
        <Grid container alignItems="center" justify="center" spacing={2}>
          <Hidden smUp>
            <Grid item xs={12} sm={6}>
              <img
                src={imageSrc}
                alt={imageAlt}
                className={classes.lttDevicesImage}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6}>
            <div className={classes.headerTextContainer}>
              <Typography
                variant="h1"
                component="h2"
                className={classes.mainHeader}
              >
                {t(heroText)}
              </Typography>

              <Button
                variant="contained"
                disableElevation
                className={classes.signUpButton}
                component={Link}
                to="#pricing"
              >
                {t("virtual_box_text")}
              </Button>
            </div>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={6}>
              <img src={imageSrc} alt={imageAlt} style={{ width: "100%" }} />
            </Grid>
          </Hidden>
        </Grid>
      </WrapperContainer>
    </Paper>
  );
}
