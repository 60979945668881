import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

// style
import styles from "../../assets/scss/modules/cart.module.scss";

// component
import Loading from "../../components/common/feedback/loading/Loading";

const CartOrder = props => {
  const { order } = props;
  const { t } = useTranslation();

  const buildItem = () => {
    return order.order.data.order.Items.map((item, idx) => {
      return (
        <dl key={idx}>
          <div>
            <dt>{t("cart_name")}</dt>
            <dd>{item.Catalog_Product["Basic.Name"]}</dd>
          </div>
          <div>
            <dt>{t("cart_quantity")}</dt>
            <dd>{item.Quantity}</dd>
          </div>
          <div>
            <dt>{t("cart_price")}</dt>
            <dd>{item.Price_Vat.display}</dd>
          </div>
        </dl>
      );
    });
  };

  const buildAllPrice = () => {
    return (
      <dl className={styles["cart-total"]}>
        <div>
          <dt>{t("cart_subtotal")}</dt>
          <dd>{order.order.data.order.Total.display}</dd>
        </div>
        <div>
          <dt>{t("cart_tax")}</dt>
          <dd>{order.order.data.order.Vat_Amount.display}</dd>
        </div>
        <div>
          <dt>{t("cart_total")}</dt>
          <dd>{order.order.data.order.Total_Vat.display}</dd>
        </div>
      </dl>
    );
  };

  const buildCart = () => {
    if (order.order === null) {
      return <Loading />;
    } else {
      return (
        <>
          <div className={styles["cart-item"]}>{buildItem()}</div>
          {buildAllPrice()}
        </>
      );
    }
  };

  return <div className={styles["cart-wrapper"]}>{buildCart()}</div>;
};

const mapDispatchToProps = () => {
  return {};
};

const mapStateToProps = state => {
  return {
    order: state.defaultOrder
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartOrder);
