import React from "react";

import {
  androidTV,
  iOS,
  firefox,
  chrome,
  safari,
  playstation,
  xbox,
  oculus,
  tesla
} from "assets/img/lfa";

import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import styles from "assets/jss/material-kit-react/views/lfa";

const useStyles = makeStyles(styles);

export default function OS() {
  const classes = useStyles();
  const osIcons = [
    { img: androidTV, title: "Android TV" },
    { img: iOS, title: "iOS" },
    { img: firefox, title: "Firefox" },
    { img: chrome, title: "Chrome" },
    { img: safari, title: "Safari" },
    { img: playstation, title: "Playstation" },
    { img: xbox, title: "Xbox" },
    { img: oculus, title: "Oculus" },
    { img: tesla, title: "Tesla" }
  ];

  return (
    <div className={classes.osContainer}>
      {osIcons.map((os, index) => {
        const { img, title } = os;

        return (
          <div className={classes.osIcon} key={index}>
            <Tooltip title={title} placement="top">
              <img src={img} index={index} alt="" />
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
}
