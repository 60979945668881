import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
// core components
import { useTranslation } from "react-i18next";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import typostyles from "assets/jss/material-kit-react/components/typographyStyle.js";

import makeStyles from "@material-ui/core/styles/makeStyles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/ShellButton.js";
import cart from "assets/img/cart.png";

const useStyles = makeStyles(styles);
const useTypoStyles = makeStyles(typostyles);

const OrderCompleted = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const typoClasses = useTypoStyles();

  return (
    <div className={classes.genericPageWrapper}>
      <Helmet title={t("helmet_shells_contact")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classNames(classes.container)}>
          <GridContainer
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <GridItem xs={12} style={{ textAlign: "center" }}>
              <div className={typoClasses.titleContainer}>
                <h2 className={typoClasses.titleSection}>
                  {" "}
                  {t("order_completed_title")}{" "}
                </h2>
              </div>
            </GridItem>

            <GridItem xs={12} style={{ textAlign: "center" }}>
              <div>
                <img src={cart} alt="cart" width={"120px"} />
              </div>
            </GridItem>

            <GridItem xs={12} style={{ textAlign: "center" }}>
              <p>{t("order_completed_item_p")}</p>
            </GridItem>
            <GridItem xs={12} sm={8} md={6} style={{ textAlign: "center" }}>
              <GridContainer justify="center">
                <GridItem xs={12} md={6}>
                  <Button
                    color="primary"
                    variant="outlined"
                    component="a"
                    href={process.env.REACT_APP_SHELL_CONSOLE_URL}
                  >
                    {t("order_completed_account")}
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
};

export default OrderCompleted;
