import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Button from "../../../components/CustomButtons/ShellLoadingButton";
import { connect } from "react-redux";

const OnFilePaymentWrapper = ({ method, submitHandler, processing }) => {
  const [selected, setSelected] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    setSelected(
      method.fields.user_billing.values.length > 0
        ? method.fields.user_billing.values[0].User_Billing__
        : null
    );
  }, [method, setSelected]);

  const handleCheckout = () => {
    submitHandler("OnFile", { user_billing: selected });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          label={t("user_billing_selector")}
          helperText={t("user_billing_selector_helperText")}
          select
          native
          variant="outlined"
          fullWidth
          value={selected}
          onChange={e => setSelected(e.target.value)}
          disabled={processing}
          SelectProps={{
            native: true
          }}
          inputProps={{
            id: "billing-select"
          }}
        >
          <option />
          {method.fields.user_billing.values.map(billing => (
            <option key={billing.User_Billing__} value={billing.User_Billing__}>
              {t("user_billing_selector_value", {
                name: billing.Methods[0].Name,
                exp: billing.Methods[0].Expiration
              })}
            </option>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3} justify="center">
          <Grid item>
            <Button
              onClick={handleCheckout}
              variant="contained"
              color="primary"
              disabled={!selected || processing}
              loading={processing}
            >
              {t("onfile_checkout_btn")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    processing: state.defaultOrder.orderProcess
  };
};

export default connect(
  mapStateToProps,
  null
)(OnFilePaymentWrapper);
