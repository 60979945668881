import React from "react";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/pricingPlans";

const useStyles = makeStyles(styles);

export default function Disclaimer({ transKey }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box pb={3} className={classes.disclaimerContainer}>
      <Typography align="center" className={classes.disclaimerText}>
        {t(transKey)}
      </Typography>
    </Box>
  );
}
