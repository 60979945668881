import React from "react";
import { Get } from "@karpeleslab/klbfw";
import moment from "moment";
import { useTranslation } from "react-i18next";

import EmailLayout from "./../../../components/Email/EmailLayout";
import Text from "./../../../components/Email/EmailText";
import Button from "./../../../components/Email/EmailButton";
import InfoBox from "./../../../components/Email/EmailInfoBox";

const Html = () => {
  const { t } = useTranslation();
  return (
    <EmailLayout>
      <table
        border="0"
        cellSpacing="0"
        cellPadding="0"
        width="100%"
        style={{ width: "100%" }}
      >
        <tbody>
          <tr>
            <td>
              <Text size="medium">{t("login_notification_text")}</Text>
            </td>
          </tr>
          <tr>
            <td>
              <InfoBox>
                <Text align="center" variant="highlight" size="large">
                  {t("login_notification_title")}
                </Text>
                <Text align="center" size="medium">
                  {t("login_notification_name", {
                    username: Get("_user").Email
                  })}
                </Text>
                <Text align="center" size="medium">
                  {t("login_notification_date", {
                    year: moment().year(),
                    month: moment().month(),
                    day: moment().day(),
                    time: moment().format("HH:mm:ss")
                  })}
                </Text>
              </InfoBox>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td align="center">
              <Text size="medium">{t("contact_us_desc")}</Text>
            </td>
          </tr>
          <tr>
            <td align="center">
              <Button href="https://www.shells.com/contact">
                {t("contact_us")}
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </EmailLayout>
  );
};

export default Html;
