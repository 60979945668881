import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import { error as errorToast } from "../../store/actions/ToastAction";

import { orderProcess } from "../../store/actions/DefaultOrderAction";

const PaypalWrapper = ({ fields, orderId, orderProcess, session }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (typeof window === "undefined" || scriptLoaded) return;

    loadDynamicScript(fields._javascript, () => setScriptLoaded(true));
  }, []); //eslint-disable-line

  useEffect(() => {
    if (typeof window === "undefined" || !window.paypal) return;

    window.paypal
      .Buttons({
        createOrder(data, actions) {
          return actions.order.create(fields.order_id.attributes.options);
        },
        onApprove(data) {
          setProcessing(true);
          return orderProcess("Paypal", session, orderId, {
            order_id: data.orderID
          }).then(() => {
            history.push("/order_completed_item");
          });
        },
        onCancel() {
          errorToast("paypal_payment_canceled");
        },
        onError() {
          errorToast("paypal_payment_error");
        },
        fundingSource: window.paypal.FUNDING.PAYPAL,
        style: {
          color: "gold",
          shape: "rect",
          label: "paypal"
        }
      })
      .render("#paypal-button-container");
  }, [scriptLoaded]); // eslint-disable-line

  if (!fields._javascript) return;

  const loadDynamicScript = (url, callback) => {
    const existingScript = document.getElementById("script-paypal");

    if (!existingScript) {
      const script = document.createElement("script");

      script.onload = () => {
        if (callback) callback();
      };

      script.src = url;
      script.id = "script-paypal";
      document.body.appendChild(script);
    }

    if (existingScript && callback) setTimeout(callback, 500);
  };

  return scriptLoaded || processing ? (
    <div id="paypal-button-container" />
  ) : (
    <CircularProgress />
  );
};

const mapDispatchToProps = dispatch => {
  return {
    orderProcess: (method, session, orderId, params) =>
      dispatch(orderProcess(method, session, null, orderId, params))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(PaypalWrapper);
