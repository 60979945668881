import {
  defaultFont,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor
} from "assets/jss/material-kit-react.js";

const headerStyle = theme => ({
  "@global": {
    body: {
      padding: "0px !important"
    }
  },
  appBar: {
    display: "flex",
    justifyContent: "space-between",
    border: "0",
    borderRadius: "3px",
    color: "#555",
    width: "100%",
    backgroundColor: "#fbfcfe",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
    transition: "all 150ms ease 0s",
    alignItems: "center",
    flexFlow: "row nowrap",
    position: "relative",
    zIndex: "unset"
  },
  absolute: {
    position: "absolute",
    zIndex: "1100"
  },
  fixed: {
    position: "fixed",
    zIndex: "1100"
  },
  container: {
    minHeight: "50px",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap",
    width: "100%",
    maxWidth: "1400px",
    padding: "0 15px",
    margin: "0 auto"
  },
  title: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    letterSpacing: "unset",
    "&:hover,&:focus": {
      color: "inherit",
      background: "transparent"
    }
  },
  appResponsive: {
    margin: "20px 10px",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexFlow: "column",
      justifyContent: "flex-start",
      height: "calc(100% - 50px)"
    }
  },
  primary: {
    backgroundColor: primaryColor,
    color: "#fbfcfe",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46)"
  },
  info: {
    backgroundColor: infoColor,
    color: "#fbfcfe",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46)"
  },
  success: {
    backgroundColor: successColor,
    color: "#fbfcfe",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46)"
  },
  warning: {
    backgroundColor: warningColor,
    color: "#fbfcfe",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46)"
  },
  danger: {
    backgroundColor: dangerColor,
    color: "#fbfcfe",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)"
  },
  rose: {
    backgroundColor: roseColor,
    color: "#fbfcfe",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46)"
  },
  transparent: {
    boxShadow: "none",
    paddingTop: "5px",
    color: "#fbfcfe",
    "& $imgWhite": {
      display: "initial"
    }
  },
  dark: {
    color: "#fbfcfe",
    backgroundColor: "#212121 !important",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)"
  },
  white: {
    border: "0",
    padding: "0.625rem 0",
    marginBottom: "20px",
    color: "#555",
    backgroundColor: "#fbfcfe !important",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
    "& $imgBlack": {
      display: "initial"
    }
  },
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: 260,
    boxShadow:
      "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",
    left: "auto",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
  },
  beautifulButton: {
    padding: "6px 24px",
    borderRadius: "35px",
    color: "#fff",
    fontSize: "20px",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover,&:focus": {
      background: "#5ba331",
      color: "#fff"
    }
  },
  signUpButton: {
    background: "#989898",
    "&:hover": {
      background: "#B8B8B8"
    }
  },
  margin5: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "7px"
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "7px"
    },
    [theme.breakpoints.up("md")]: {
      margin: "5px"
    }
  },
  flexifiedMenuItems: {
    justifySelf: "stretch",
    flex: ".75",
    justifyContent: "stretch"
  },
  imgWhite: {
    width: "210px",
    height: "84px",
    [theme.breakpoints.down("sm")]: {
      width: "125px"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100px"
    }
  },
  navLink: {
    color: "#555",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "20px",
    borderRadius: "3px",
    lineHeight: "20px",
    textDecoration: "none",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "#555",
      background: "rgba(200, 200, 200, 0.2)"
    },
    "& > .MuiButton-label": {
      textTransform: "none"
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)"
    }
  },
  logoutBtn: {
    [theme.breakpoints.down("sm")]: {
      margin: "8px 0 0 15px"
    }
  },
  list: {
    ...defaultFont,
    fontSize: "20px",
    margin: 0,
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "inherit",
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column"
    }
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0 6px",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:after": {
        width: "calc(100% - 30px)",
        display: "block",
        height: "1px",
        marginLeft: "15px",
        backgroundColor: "#e5e5e5"
      }
    }
  },
  menuItemContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      margin: "15px 0 0 30px",
      flexDirection: "column"
    }
  },
  mobileLogo: {
    width: "125px",
    margin: "0 auto",
    marginBottom: "20px"
  }
});

export default headerStyle;
