import React from "react";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/lfa";

import { WrapperContainer } from "components/HOC";
import { devices } from "assets/img/lfa";

const useStyles = makeStyles(styles);

export default function SectionThree() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <WrapperContainer addMaxWidth={false} addlWclass={classes.bgLightBlue}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={8}>
          <img
            src={devices}
            alt="Devices with Shells"
            className={classes.devices}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography
            variant="h5"
            component="h3"
            className={`${classes.headers} ${classes.greenFont}`}
          >
            {t("lfa_h6")}
          </Typography>
          <div className={classes.s3GreenLine} />
          <div className={classes.s3BodyContainer}>
            <Typography variant="body1" className={classes.description}>
              {t("lfa_p6")}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </WrapperContainer>
  );
}
