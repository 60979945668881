import React from "react";
import { useTranslation } from "react-i18next";

import { WrapperContainer } from "components/HOC";
import ListItem from "./ListItem";

import Grid from "@material-ui/core/Grid";

export default function SectionOne() {
  const { t } = useTranslation();

  const sectionOneList = [
    {
      header: t("minecraft_sectionone_header_one"),
      body: t("minecraft_sectionone_body_one"),
      href_1:
        "https://www.shells.com/l/en-US/tutorial/0-All-You-Need-to-Know-About-Minecraft-Mods-in-2021",
      href_1_text: t("minecraft_link_one")
    },
    {
      header: t("minecraft_sectionone_header_two"),
      body: t("minecraft_sectionone_body_two"),
      href_1:
        "https://www.shells.com/l/en-US/tutorial/A-Guide-to-Installing-Minecraft-Server-on-Windows-0",
      href_1_text: t("minecraft_link_two"),
      href_2:
        "https://www.shells.com/l/en-US/tutorial/0-A-Guide-to-Installing-a-Minecraft-Server-on-Linux-Ubuntu",
      href_2_text: t("minecraft_link_three")
    }
  ];

  return (
    <WrapperContainer addMaxWidth={true}>
      <Grid container spacing={6}>
        {sectionOneList.map((data, index) => (
          <ListItem data={data} key={index} />
        ))}
      </Grid>
    </WrapperContainer>
  );
}
