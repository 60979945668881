import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getCookie } from "@karpeleslab/klbfw";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import { fetchPlans, selectPlan } from "store/actions/PlanAction";
import { addItem, cartFetch } from "store/actions/CartAction";

import PageModal from "components/PageModal/PageModal";
import Section from "views/LandingPage/Other/Section";
import PlansCarouselItem from "./PlansCarouselItem";
import Loading from "components/common/feedback/loading/Loading";

import { findPlan } from "../Plans/util";
import { isMobileTablet } from "../utils/mobileTablet";
import sendGtagEvent from "../utils/sendGtagEvent";

import usePlansCarouselStyles from "./PlansCarousel.style";

const defaultShowOnly = ["lite", "basic", "plus", "pro"];
const defaultOptions = {
  lite: { disclaimer: null, usageLimit: null },
  basic: { disclaimer: "as_low_as", usageLimit: "unlimited_use" },
  plus: { disclaimer: "as_low_as", usageLimit: "unlimited_use" },
  pro: { disclaimer: "as_low_as", usageLimit: "unlimited_use" }
};

const HPPlans = ({
  fetchPlans,
  cartFetch,
  fetchedPlans,
  carouselPlans,
  addItem,
  cartItems,
  selected,
  showOnly = defaultShowOnly,
  options = defaultOptions,
  loading,
  selectPlan
}) => {
  const classes = usePlansCarouselStyles();
  const isNarrow = useMediaQuery("(max-width:1024px)");
  const [isMobile] = useState(isMobileTablet());
  const [currentActiveIdx, setCurrentActiveIdx] = useState(1);
  const [plansToShow, setPlansToShow] = useState([]);
  const plansWrapperRef = useRef(null);
  const [touchPos, setTouchPos] = useState({ x: 0, y: 0 });
  const [touchTimestamp, setTouchTimestamp] = useState(Date.now());
  const history = useHistory();
  const pricingTestCookie = getCookie("pricing_test");
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    fetchPlans();
    cartFetch();
  }, [fetchPlans, cartFetch]);

  useEffect(() => {
    if (carouselPlans) {
      const finalPlans = [];

      let carouselPlansCopy = { ...carouselPlans };

      showOnly.forEach(planType => {
        if (carouselPlansCopy[planType]) {
          carouselPlansCopy[planType] = {
            ...carouselPlansCopy[planType],
            ...options[planType]
          };

          finalPlans.push(carouselPlansCopy[planType]);
        }
      });

      setPlansToShow([...finalPlans]);
    }
  }, [carouselPlans, showOnly, options]);

  useEffect(() => {
    if (!isMobile || !isNarrow || !plansWrapperRef || !plansWrapperRef.current)
      return;

    let style = `width: ${plansToShow.length * 292}px;`;
    if (isMobile || isNarrow) {
      style += `-webkit-transform: translate3d(-${(currentActiveIdx + 0.5) *
        ((1 / plansToShow.length) * 100)}%, 0, 0);
      -moz-transform: translate3d(-${(currentActiveIdx + 0.5) *
        ((1 / plansToShow.length) * 100)}%, 0, 0);
        -ms-transform: translate3d(-${(currentActiveIdx + 0.5) *
          ((1 / plansToShow.length) * 100)}%, 0, 0);
          -o-transform: translate3d(-${(currentActiveIdx + 0.5) *
            ((1 / plansToShow.length) * 100)}%, 0, 0);
      transform: translate3d(-${(currentActiveIdx + 0.5) *
        ((1 / plansToShow.length) * 100)}%, 0, 0);`;
    }

    plansWrapperRef.current.setAttribute("style", style);
  }, [currentActiveIdx, isMobile, isNarrow, plansToShow.length, loading]); // eslint-disable-line

  useEffect(() => {
    if (
      (!isMobile && !isNarrow) ||
      !plansWrapperRef ||
      !plansWrapperRef.current
    )
      return;

    plansWrapperRef.current.setAttribute(
      "style",
      `width: ${plansToShow.length * 292}px;
       -webkit-transform: translate3d(-${(currentActiveIdx + 0.5) *
         ((1 / plansToShow.length) * 100)}%, 0, 0);
       -moz-transform: translate3d(-${(currentActiveIdx + 0.5) *
         ((1 / plansToShow.length) * 100)}%, 0, 0);
       -ms-transform: translate3d(-${(currentActiveIdx + 0.5) *
         ((1 / plansToShow.length) * 100)}%, 0, 0);
       -o-transform: translate3d(-${(currentActiveIdx + 0.5) *
         ((1 / plansToShow.length) * 100)}%, 0, 0);
       transform: translate3d(-${(currentActiveIdx + 0.5) *
         ((1 / plansToShow.length) * 100)}%, 0, 0);`
    );
  }, [currentActiveIdx, isMobile, isNarrow, plansToShow.length]); //eslint-disable-line

  useEffect(() => {
    if (!plansWrapperRef || !plansWrapperRef.current || isMobile || isNarrow) {
      return;
    } else {
      plansWrapperRef.current.setAttribute(
        "style",
        `width: ${plansToShow.length * 292}px;`
      );
    }
  }, [isMobile, isNarrow, plansToShow.length]); // eslint-disable-line

  useEffect(() => {
    if (firstLoad) return setFirstLoad(false);

    if (selected && cartItems.length < 1) history.push("/join");
  }, [selected]); // eslint-disable-line

  // With use of Modal for specs
  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const handleOpen = e => {
    e.stopPropagation();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const onTouchEnd = (e, plan = null) => {
    const end = Date.now();
    const { x, y } = touchPos;
    if (!isMobile || !plansWrapperRef || !plansWrapperRef.current) return;

    if (end - touchTimestamp < 100 && plan && !!e.target.closest(".active"))
      return onPlanSelected(plan);

    if (
      e.changedTouches[0].clientX < x &&
      (Math.abs(e.changedTouches[0].clientY - y) < 50 ||
        Math.abs(y - e.changedTouches[0].clientY) < 50)
    ) {
      // swipe right
      setCurrentActiveIdx(
        currentActiveIdx < plansToShow.length - 1 ? currentActiveIdx + 1 : 0
      );
    }
    // swipe left
    else if (
      e.changedTouches[0].clientX > x &&
      (Math.abs(e.changedTouches[0].clientY - y) < 50 ||
        Math.abs(y - e.changedTouches[0].clientY) < 50)
    ) {
      setCurrentActiveIdx(
        (currentActiveIdx > 0 ? currentActiveIdx : plansToShow.length) - 1
      );
    }
  };

  const onPlanSelected = plan => {
    const foundPlan = findPlan(plan, fetchedPlans);
    if (foundPlan) {
      selectPlan(foundPlan);
      addItem(foundPlan["Catalog_Product__"]);

      const whichVersion =
        pricingTestCookie === "pricing_alt" ? "without_lite" : "regular";
      const label =
        pricingTestCookie === "pricing_alt"
          ? "B (without Lite plan)"
          : "A (with Lite plan included)";

      sendGtagEvent(
        `select_plan_${whichVersion}__${foundPlan["Description.AuthorCode"]}_${
          foundPlan["Basic.ServiceLifetime"]
        }`,
        {
          event_category: "ab_testing_pricing",
          event_label: `Visitor selected plan ${
            foundPlan["Description.AuthorCode"]
          } (${
            foundPlan["Description.AuthorCode"]
          }) on Pricing version ${label}.`
        }
      );
    }
  };

  const setDuration = (index, duration) => {
    const tmp = [...plansToShow];
    tmp[index].duration = duration;
    onPlanSelected(tmp[index]);
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <Section
        key="pricingversiona"
        anchor="pricing"
        maxWidth={showOnly.length > 3 ? "lg" : "md"}
      >
        <Box py={4} className={classes.outer}>
          <PageModal
            handleClose={handleClose}
            open={open}
            modalText={modalText}
          />
          {!isMobile && (
            <>
              <IconButton
                className={[classes.arrow, classes.arrowLeft].join(" ")}
                onClick={() =>
                  setCurrentActiveIdx(
                    (currentActiveIdx > 0
                      ? currentActiveIdx
                      : plansToShow.length) - 1
                  )
                }
              >
                <ArrowBackIosIcon className={classes.arrowBack} />
              </IconButton>
              <IconButton
                className={[classes.arrow, classes.arrowRight].join(" ")}
                onClick={() =>
                  setCurrentActiveIdx(
                    currentActiveIdx < plansToShow.length - 1
                      ? currentActiveIdx + 1
                      : 0
                  )
                }
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </>
          )}
          <Box className={classes.inner}>
            <Grid
              container
              spacing={2}
              justify="center"
              alignItems="center"
              className={classes.container}
              ref={plansWrapperRef}
              onTouchStart={e => {
                if (isMobile) {
                  setTouchPos({
                    x: e.touches[0].clientX,
                    y: e.touches[0].clientY
                  });
                }
              }}
              onTouchEnd={e => {
                if (isMobile) {
                  onTouchEnd(e);
                }
              }}
            >
              {plansToShow.map((p, i) => {
                const data = {
                  p,
                  i,
                  setDuration,
                  handleOpen,
                  setModalText,
                  onTouchEnd,
                  setCurrentActiveIdx,
                  currentActiveIdx,
                  isMobile,
                  setTouchTimestamp,
                  plansToShow
                };

                return <PlansCarouselItem data={data} key={i} />;
              })}
            </Grid>
          </Box>
        </Box>
      </Section>
    );
  }
};

const mapStateToProps = state => {
  return {
    fetchedPlans: state.plans.plans,
    carouselPlans: state.plans.carouselPlans,
    cartItems: state.cart.cartItem,
    loading: state.plans.loading,
    selected: state.plans.selected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectPlan: plan => dispatch(selectPlan(plan)),
    fetchPlans: () => dispatch(fetchPlans()),
    addItem: plan => dispatch(addItem(plan)),
    cartFetch: () => dispatch(cartFetch())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HPPlans);
