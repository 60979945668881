import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchPlans, selectPlan } from "store/actions/PlanAction";

import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ToggleOrder from "./ToggleOrder";
import OrderPlan from "./OrderPlan";
import BuildSpec from "./BuildSpec";

import { makeStyles } from "@material-ui/core/styles";

// style
import styles from "assets/jss/material-kit-react/views/orderPage.js";
import style from "../../assets/scss/modules/order.module.scss";

const useStyles = makeStyles(styles);

const Plans = ({ fetchPlans, selectPlan, selected, loading, plans }) => {
  const [filteredPlans, setFilteredPlans] = useState([]);
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  useEffect(() => {
    if (selected === null || selected === void 0) {
      const basicYearly = plans.find(
        p =>
          p["Description.AuthorCode"] === "basic" &&
          p["Basic.ServiceLifetime"] === "1y"
      );
      selectPlan(basicYearly);
    }
  }, [selectPlan, selected, plans]);

  useEffect(() => {
    let servicePeriod;
    if (!selected) {
      return;
    }

    servicePeriod = selected["Basic.ServiceLifetime"];

    setFilteredPlans(
      plans.filter(p => p["Basic.ServiceLifetime"] === servicePeriod)
    );
  }, [selected, plans]);

  if (loading) {
    return (
      <GridContainer direction="column" justify="center" alignItems="center">
        <GridItem xs={2}>
          <CircularProgress />
        </GridItem>
      </GridContainer>
    );
  }

  return (
    <GridContainer
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      spacing={3}
    >
      <GridItem xs={12} sm={6} className={`${classes.orderPeriodGridItem}`}>
        <Grid className={style["order-period-wrapper"]} container spacing={0}>
          <ToggleOrder variant="toggle" />
          <ToggleOrder variant="tab" />
        </Grid>
        {filteredPlans.map((plan, i) => (
          <OrderPlan key={i} plan={plan} />
        ))}
      </GridItem>

      <GridItem xs={12} sm={6} className={`${style["spec-wrapper"]}`}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.specsGridContainer}
        >
          <p className={classes.specsHeader}>
            {t("selected_plan_vm_custom_following_specs")}
          </p>

          <BuildSpec />
        </Grid>
      </GridItem>
    </GridContainer>
  );
};

const mapStateToProps = state => {
  return {
    selected: state.plans.selected,
    plans: state.plans.plans,
    loading: state.plans.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPlans: () => dispatch(fetchPlans()),
    selectPlan: plan => dispatch(selectPlan(plan))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Plans);
