import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Helmet from "components/instances/instance/Helmet/Helmet";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components

import styles from "assets/jss/material-kit-react/views/orderPage.js";
import { useTranslation } from "react-i18next";

// Sections for this page
import Box from "@material-ui/core/Box";
import Button from "./../../components/CustomButtons/ShellButton";
import ChoosePlan from "./Sections/ChoosePlan";

import PaymentMethod from "./Sections/PaymentMethod";

import { cartFetch } from "store/actions/CartAction";
import { selectPlan } from "store/actions/PlanAction";

const useStyles = makeStyles(styles);

const OrderPage = ({ cartItems, cartFetch, selected, selectPlan, loading }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [plan, setPlan] = useState({});
  const [showPlanSelection, setShowPlanSelection] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    cartFetch();
  }, []); // eslint-disable-line

  // if no plan was selected already, we must display the "choose plan" section.
  // else, we hide it for simplicity
  useEffect(() => {
    if (!loading && !selected && firstLoad) {
      setShowPlanSelection(true);
      setFirstLoad(false);
    } else if (
      !loading &&
      selected &&
      selected["Description.AuthorCode"] === "custom"
    ) {
      setShowPlanSelection(true);
      setFirstLoad(false);
    }
  }, [selected, loading]); // eslint-disable-line

  useEffect(() => {
    if (cartItems.length >= 1 && !selected) selectPlan(cartItems[0].data);
  }, [cartItems]); // eslint-disable-line

  return (
    <div className={classes.genericPageWrapper}>
      <Helmet title={t("helmet_shells_order")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {showPlanSelection ? (
            <ChoosePlan onPlanSelected={setPlan} />
          ) : (
            <Box pt={4}>
              <Button component={Link} variant="outlined" to="/pricing">
                {t("continue_browsing")}
              </Button>
            </Box>
          )}
          <PaymentMethod plan={plan} />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    cartFetch: () => dispatch(cartFetch()),
    selectPlan: plan => dispatch(selectPlan(plan))
  };
};

const mapStateToProps = state => ({
  cartItems: state.cart.cartItem,
  loading: state.plans.loading,
  selected: state.plans.selected
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderPage);
