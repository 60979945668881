export const image1_mobile = require("./Header_Image_002_Mobile.png");
export const image1_tablet = require("./Header_Image_002_Tablet.png");
export const image1_desktop = require("./Header_Image_002.png");
export const image2 = require("./Compose.svg");
export const image3 = require("./Easy_Encoding.svg");
export const image4 = require("./Infinitely_Portable.svg");
export const image5 = require("./Up_To_Date_And_Always_On.svg");
export const image6 = require("./Auto_Save.svg");
export const image7 = require("./Virus_Protection.svg");
export const image8 = require("./Anytime_Backups.svg");
export const image9 = require("./Powerful_Processors.svg");
export const image10 = require("./Fast_And_Reliable.svg");
export const image11 = require("./Professional_Grade_Software.svg");
export const image12_mobile = require("./Header_Image_001_Mobile.png");
export const image12_tablet = require("./Header_Image_001_Tablet.png");
export const image12_desktop = require("./Header_Image_001.png");
