import React from "react";
import classNames from "classnames";

// components

// style
import style from "assets/scss/modules/lpLayout.module.scss";

export default function Text(props) {
  const { type, img, title, text, children } = props;

  const buildChild = () => {
    if (children === void 0) return null;

    return children;
  };

  return (
    <div
      className={classNames(`${style["lp-content"]} ${style["lp-wrapper"]}`, {
        [style["lp-content-left"]]: type === "left" || type === void 0,
        [style["lp-content-right"]]: type === "right"
      })}
    >
      <div className={style["lp-content-img"]}>
        <img src={img} alt="" />
      </div>
      <div className={style["lp-content-group"]}>
        <h2 className={style["lp-content-group-title"]}>{title}</h2>
        <p className={style["lp-content-group-text"]}>{text}</p>
        {buildChild()}
      </div>
    </div>
  );
}
