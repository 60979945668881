import React from "react";
import { useTranslation } from "react-i18next";
import { WrapperContainer } from "components/HOC";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/os";

const useStyles = makeStyles(styles);

export default function OSHeader() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <WrapperContainer addMaxWidth={true}>
      <div className={classes.heroHeaderContainer}>
        <Typography variant="h1" component="h2" className={classes.heroHeader}>
          {t("os_hero_header")}
        </Typography>
      </div>
      <Box py={4} className={classes.heroTextContainer}>
        <Typography variant="h3" className={classes.subHeader}>
          {t("os_hero_text")}
        </Typography>
      </Box>
    </WrapperContainer>
  );
}
