import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Get } from "@karpeleslab/klbfw";

const Text = () => {
  const { t } = useTranslation();
  return (
    <textarea
      id="mail-text"
      defaultValue={
        t("login_notification_text") +
        "\n\r" +
        t("login_notification_title") +
        "\n\r" +
        t("login_notification_name", { username: Get("_user").Email }) +
        "\n\r" +
        t("login_notification_date", {
          year: moment().year(),
          month: moment().month(),
          day: moment().day(),
          time: moment().format("HH:mm:ss")
        }) +
        "\n\r" +
        t("user_password_mail_regards") +
        "\n\r" +
        t("user_password_email_footer") +
        "\n\r"
      }
    />
  );
};

export default Text;
