import React from "react";
import { useTranslation, Trans } from "react-i18next";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/mxLinux";

import { WrapperContainer } from "components/HOC";
import { mxLinuxDevices, logos } from "assets/img/mx-linux";

const useStyles = makeStyles(styles);

export default function MXLinuxPricingHero() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper className={classes.mainFeaturedPost}>
      <div className={classes.overlay} />
      <a href="#pricing" className={classes.mainRibbon}>
        <Trans
          i18nKey="lp_linuxquestionsorg_ribbon"
          components={{ underline: <span /> }}
        />
      </a>
      <WrapperContainer addMaxWidth={false} addlWclass={classes.morePadding}>
        <Grid container alignItems="center" justify="center" spacing={1}>
          <Hidden smUp>
            <Grid item xs={12} sm={6} style={{ position: "relative" }}>
              <img
                src={mxLinuxDevices}
                alt="MX Linux Devices with Shells"
                className={classes.mxLinuxImage}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={5} md={5}>
            <div className={classes.heroTextContainer}>
              <img
                src={logos}
                alt="Shells and MX Linux"
                className={classes.shellsMxLogo}
              />
              <Typography
                variant="h1"
                component="h2"
                className={classes.mainHeader}
              >
                {t("mx_linux_hero")}
              </Typography>
              <Box pt={6}>
                <Typography
                  variant="h1"
                  component="h2"
                  className={classes.subHeader}
                >
                  {t("mx_linux_subtext")}
                </Typography>
              </Box>
            </div>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={7} md={7} style={{ position: "relative" }}>
              <img
                src={mxLinuxDevices}
                alt="MX Linux Devices with Shells"
                style={{ width: "100%" }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </WrapperContainer>
    </Paper>
  );
}
