export const headerImageMobile = require("./Header_Image_Mobile.png");
export const headerImageTablet = require("./Header_Image_Tablet.png");
export const headerImageDesktop = require("./Header_Image.png");
export const oneOfThree = require("./1of3.png");
export const linuxGraph = require("./Linux_Graph.png");
export const linuxOpenSource = require("./Linux_Open_Source.png");
export const linuxSecure = require("./Linux_Secure.png");
export const headerBottomMobile = require("./HeaderBottom_Mobile.png");
export const headerBottomTablet = require("./HeaderBottom_Tablet.png");
export const headerBottomDesktop = require("./HeaderBottom_Desktop.png");
export const patchingSecurity = require("./Patching_Security_Holes.svg");
export const linuxKernels = require("./Linux_Kernels_In_The_Open.svg");
