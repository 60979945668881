import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/homepage20210414.style.js";

const useStyles = makeStyles(styles);

export default function HPServers() {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

  return (
    <Box pt={6} pb={isMobile ? 0 : 6}>
      <Paper elevation={0} className={classes.serversFeaturedPost}>
        <Container maxWidth="lg" className={classes.alignCenter}>
          <Box className={classes.textContainer}>
            <Typography
              component="h1"
              variant="h2"
              className={`${classes.sectionHeroHeader} ${classes.blackFont}`}
              align="center"
            >
              {t("servers_header")}
            </Typography>
          </Box>
          <Box py={6} className={classes.textContainer}>
            <Typography
              component="h2"
              variant="body1"
              className={`${classes.subHeader} ${classes.blackFont}`}
              align="center"
            >
              {t("servers_desc")}
            </Typography>
          </Box>
          <Button
            color="primary"
            variant="contained"
            className={classes.beautifulJoinButton}
            component={Link}
            to={"/pricing"}
          >
            {t("servers_get_button")}
          </Button>
        </Container>
      </Paper>
    </Box>
  );
}
