import React from "react";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/os";

const useStyles = makeStyles(styles);

export default function ShellsOS({ os }) {
  const classes = useStyles();
  const { image, name, types } = os;

  return (
    <Grid item xs={12} sm={6} className={classes.shellsOSParent}>
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={3}
        className={classes.shellsOSGridContainer}
      >
        <Hidden smUp>
          <Grid item xs={12} sm={6} className={classes.mobileGridItem}>
            <img src={image} alt={name} className={classes.osImage} />
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={7} md={6}>
          <Typography variant="h3" className={classes.osHeader}>
            {name}
          </Typography>
          <ul className={classes.typesList}>
            {types.map((type, i) => (
              <li key={i} className={classes.listItem}>
                {type}
              </li>
            ))}
          </ul>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={12} sm={5} md={6}>
            <img src={image} alt={name} className={classes.osImage} />
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  );
}
