import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
// core components
import { useTranslation } from "react-i18next";
import styles from "assets/jss/material-kit-react/views/landingPage.js";

import FormContact from "./FormContact";
import makeStyles from "@material-ui/core/styles/makeStyles";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

import { Hidden } from "@material-ui/core";

import "./../../assets/scss/cms.scss";

const useStyles = makeStyles(styles);

export default function Contact() {
  const { t } = useTranslation();
  const classes = useStyles();

  const contactInfo = (
    <div>
      <b>{t("write_to_us")}:</b>
      <br />
      5900 Wilshire Blvd.
      <br />
      Unit 2110
      <br />
      Los Angeles, CA 90036
      <br />
      <br />
      <b>{t("email_us")}:</b>
      <br />
      support@support.shells.com
      <br />
      <br />
      <b>{t("call_us")}:</b>
      <br />
      +1 833 MY SHELL
      <br />
    </div>
  );

  return (
    <div className={classes.genericPageWrapper}>
      <Helmet title={t("helmet_shells_contact")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classNames(classes.container)}>
          <div className="banner--wrapper" style={{ marginBottom: "48px" }}>
            <div className="title--wrapper">
              <h2
                className={classes.title}
                style={{
                  padding: "5px 0 5px 0",
                  textAlign: "center",
                  textTransform: "unset"
                }}
              >
                {t("contact_title")}
              </h2>
            </div>
          </div>

          <GridContainer direction="row" alignItems="center">
            <Hidden smDown>
              <GridItem
                item
                container
                xs={12}
                md={3}
                justify="center"
                align="left"
              >
                {contactInfo}
              </GridItem>
            </Hidden>
            <Hidden mdUp>
              <GridItem xs={12} md={3} justify="center" align="center">
                {contactInfo}
              </GridItem>
            </Hidden>

            <GridItem xs={12} md={9}>
              <GridContainer spacing={3}>
                <GridItem>
                  <FormContact />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
