import makeStyles from "@material-ui/core/styles/makeStyles";

const usePlansCarouselStyles = makeStyles(theme => ({
  arrow: {
    position: "absolute",
    top: "50%",
    transform: "translateY(50%)",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  arrowLeft: {
    right: "120%"
  },
  arrowRight: {
    left: "120%"
  },
  container: {
    position: "absolute",
    top: "0",
    left: "50%",
    width: "100%",
    margin: 0,
    transform: "translate3d(-50%, 0, 0)",
    transition: "transform .25s cubic-bezier(.17,.67,.16,.99)"
  },
  inner: {
    position: "relative",
    width: "100%",
    height: "380px",
    // overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-32px",
      width: "calc(100% + 64px)"
    }
  },
  outer: {
    position: "relative",
    width: "100%"
  },
  plan: {
    transition: "transform .25s cubic-bezier(.17,.67,.16,.99)",
    "&:not(.active)": {
      opacity: ".66",
      transform: "scale(.9)"
    },
    "&:hover": {
      cursor: "pointer"
    }
  },
  planInner: {
    border: "4px solid #72BA3E",
    backgroundColor: "#fff",
    height: "510px",
    [theme.breakpoints.down("sm")]: {
      height: "530px"
    }
  },
  price: {
    fontWeight: "bold",
    fontSize: "4rem",
    letterSpacing: "-.1rem",
    "& > sup": {
      position: "relative",
      marginLeft: "4px",
      fontSize: "2rem",
      borderBottom: "3px solid #72BA3E",
      borderStyle: "inset",
      borderTop: "0 solid transparent",
      borderLeft: "0 solid transparent",
      borderRight: "0 solid transparent"
    }
  },
  title: {
    backgroundColor: "#72BA3E",
    color: "#fff",
    boxShadow: "0 0 0 2px #72BA3E",
    padding: "6px 0"
  },
  greenText: {
    color: "#72BA3E"
  },
  italic: {
    fontStyle: "italic"
  },
  monthText: {
    fontWeight: "bold",
    fontSize: "2rem",
    color: "#72BA3E",
    marginTop: "-25px"
  },
  specTitle: {
    "&:hover": {
      textDecoration: "underline"
    }
  },
  descriptionContainerClass: {
    maxWidth: "140px",
    margin: "0 auto"
  },
  durationDropdown: {
    width: "165px",
    backgroundColor: "#72ba3e",
    color: "#fff"
  },
  arrowBack: {
    "-webkit-transform": "translateX(3px)",
    "-moz-transform": "translateX(3px)",
    "-ms-transform": "translateX(3px)",
    "-o-transform": "translateX(3px)",
    transform: "translateX(3px)"
  },
  discountPrice: {
    fontWeight: "bold",
    fontSize: ".8em"
  },
  linethrough: {
    fontWeight: "normal",
    padding: "0 3px",
    marginRight: "6px",
    color: "red",
    backgroundColor: "rgba(255, 0, 0, .1)",
    textDecoration: "line-through"
  }
}));

export default usePlansCarouselStyles;
