import React from "react";
import { useTranslation } from "react-i18next";
import { WrapperContainer } from "components/HOC";
import { shellsOSList } from "./ShellsOSList";
import ShellsOS from "./ShellsOS";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/os";

const useStyles = makeStyles(styles);

export default function SectionOne() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <WrapperContainer addMaxWidth={true} addlWclass={classes.wrapperClass}>
      <Box pb={6}>
        <Typography variant="h3" className={classes.mainHeader}>
          {t("os_offer")}
        </Typography>
      </Box>

      <Grid container spacing={3} justify="center" alignItems="center">
        {shellsOSList.map((os, i) => (
          <ShellsOS os={os} key={i} />
        ))}
      </Grid>
    </WrapperContainer>
  );
}
