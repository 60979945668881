import {
  image1_mobile,
  image1_tablet,
  image1_desktop,
  image12_mobile,
  image12_tablet,
  image12_desktop
} from "assets/img/cloudmusic";
import {
  mainGreenColor,
  secondaryGreenColor,
  darkGreenColor,
  darkWhiteColor,
  whiteColor,
  lightGrayColor,
  blackColor
} from "assets/jss/material-kit-react";

const styles = theme => ({
  body: {
    backgroundColor: lightGrayColor,
    boxSizing: "border-box"
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    padding: "60px 15px"
  },
  secondWrapper: {
    margin: "60px 0px"
  },
  container: {
    maxWidth: "1140px",
    margin: "0 auto",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0 15px 0 15px"
    },
    [theme.breakpoints.down("lg")]: {
      padding: "0 20px 0 20px"
    }
  },
  mainHeader: {
    fontSize: "52px",
    fontWeight: "bold",
    fontFamily: "Open Sans, sans-serif",
    marginBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    }
  },
  bottomHeader: {
    fontWeight: "bold",
    fontFamily: "Open Sans, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    }
  },
  headerLinkClass: {
    color: mainGreenColor,
    "&:hover": {
      color: mainGreenColor,
      textDecoration: "underline"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    }
  },
  bottomSubHeader: {
    lineHeight: 1.6,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "22px"
    }
  },
  linkClass: {
    lineHeight: 1.6,
    color: mainGreenColor,
    "&:hover": {
      color: mainGreenColor,
      textDecoration: "underline"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "20px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "24px"
    }
  },
  subHeader: {
    fontFamily: "Open Sans, sans-serif",
    marginBottom: "25px",
    lineHeight: 1.5,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "22px"
    }
  },
  headers: {
    fontWeight: 900,
    fontFamily: "Open Sans, sans-serif",
    marginBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "26px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "28px"
    }
  },
  cardHeader: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 900,
    fontSize: "24px"
  },
  description: {
    fontSize: "20px",
    lineHeight: "1.6",
    fontFamily: "Roboto, sans-serif",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      maxWidth: "80%"
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "80%"
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "220px"
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "45%"
    }
  },
  blackFont: {
    color: blackColor
  },
  imageOne: {
    marginBottom: "40px",
    [theme.breakpoints.up("xs")]: {
      width: "260px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "300px"
    },
    [theme.breakpoints.up("md")]: {
      width: "450px"
    },
    [theme.breakpoints.up("lg")]: {
      width: "500px"
    }
  },
  cardImage: {
    margin: "0 auto",
    padding: "15px 0 15px 0",
    [theme.breakpoints.up("xs")]: {
      width: "200px"
    }
  },
  mainFeaturedPost: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${image1_mobile})`
    },
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${image1_tablet})`
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${image1_desktop})`
    }
  },
  secondFeaturedPost: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${image12_mobile})`
    },
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${image12_tablet})`
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${image12_desktop})`
    }
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.6)"
  },
  mainFeaturedPostContent: {
    textAlign: "left",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      padding: "24px 0px 24px 0px"
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6)
    }
  },
  sectionOnePostContent: {
    textAlign: "left",
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6)
    }
  },
  cardText: {
    fontSize: "16px",
    color: blackColor
  },
  backgroundLight: {
    backgroundColor: lightGrayColor
  },
  backgroundDark: {
    backgroundColor: darkGreenColor,
    color: darkWhiteColor
  },
  lineOne: {
    border: `2px solid ${whiteColor}`,
    margin: "0 auto",
    width: "75px",
    marginTop: "45px",
    marginBottom: "45px"
  },
  lineTwo: {
    border: "2px solid #3c4858",
    margin: "0 auto",
    width: "75px",
    marginTop: "45px",
    marginBottom: "45px"
  },
  gridItem: {
    textAlign: "center",
    padding: "60px"
  },
  cardContentOne: {
    maxWidth: "425px",
    margin: "0 auto",
    [theme.breakpoints.up("sm")]: {
      minHeight: "242px"
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "170px"
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "170px"
    }
  },
  cardContentTwo: {
    [theme.breakpoints.up("sm")]: {
      minHeight: "180px"
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "178px"
    }
  },
  youtubeContainer: {
    textAlign: "center"
  },
  signUpButton: {
    width: "315px",
    margin: "0px auto 40px auto",
    padding: "6px 24px",
    backgroundColor: mainGreenColor,
    color: whiteColor,
    display: "block",
    borderRadius: "35px",
    fontSize: "22px",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover": {
      boxShadow: `0 0 0 2px ${secondaryGreenColor} inset`,
      background: whiteColor,
      color: secondaryGreenColor
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "250px"
    }
  },
  gridCard: {
    height: "100%",
    backgroundColor: lightGrayColor,
    maxWidth: "425px",
    margin: "0 auto"
  }
});

export default styles;
