import { container, title } from "assets/jss/material-kit-react.js";

import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";

const typographyStyle = theme => ({
  section: {
    padding: "70px 0"
  },
  container,
  space50: {
    height: "50px",
    display: "block"
  },
  title: {
    ...title,
    marginTop: "10px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
    width: "100%"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  marginLeft: {
    marginLeft: "auto !important"
  },
  paginationContainer: {
    marginTop: "25px"
  },
  searchContainer: {
    width: "100%",
    textAlign: "center",
    marginTop: "40px"
  },
  searchInput: {
    [theme.breakpoints.down("sm")]: {
      width: "160px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "200px"
    }
  },
  searchButton: {
    backgroundColor: "#72BA3E",
    color: "#fff",
    marginLeft: "20px",
    "&:hover": {
      backgroundColor: "#5ba331",
      color: "#fbfcfe"
    }
  },
  ...imagesStyles
});

export default typographyStyle;
