import React from "react";
import { Get } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";

const Text = () => {
  const { t } = useTranslation();

  return (
    <textarea
      id="mail-text"
      defaultValue={
        Get("_order").Billing_User_Location.Last_Name +
        " " +
        Get("_order").Billing_User_Location.First_Name +
        "\n\r" +
        t("mail_order_desc_txt")
      }
    />
  );
};

export default Text;
