import { rest } from "@karpeleslab/klbfw";
import {
  LOCATION_CREATE_DONE,
  LOCATION_CREATE_ERROR,
  LOCATION_CREATING
} from "../reducers/LocationReducer";
import {
  catchRedirectException,
  handleError
} from "../../components/utils/apiErrorHandler";

export const create = (firstName, lastName, zip, country) => {
  return (dispatch, getState) => {
    dispatch({ type: LOCATION_CREATING });
    return rest("User/@/Location", "POST", {
      First_Name: firstName,
      Last_Name: lastName,
      Zip: zip,
      Country__: country
    })
      .then(catchRedirectException)
      .then(data => {
        dispatch({ type: LOCATION_CREATE_DONE });
        return data.data;
      })
      .catch(err => {
        dispatch({ type: LOCATION_CREATE_ERROR, error: err.message });
        handleError(getState, dispatch, err);
      });
  };
};
