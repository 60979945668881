import React from "react";

import { WrapperContainer } from "components/HOC";
import FadeIn from "./FadeIn";

import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/material-kit-react/views/whyShells";

const useStyles = makeStyles(styles);

export default function SectionTwo({ details }) {
  const {
    headerOne,
    descriptionOne,
    imageOne,
    headerTwo,
    descriptionTwo,
    imageTwo,
    addlWclass,
    extraClass = false,
    newHP = false
  } = details;
  const classes = useStyles();

  return (
    <>
      <WrapperContainer
        addMaxWidth={true}
        addlWclass={extraClass ? extraClass : addlWclass}
      >
        <div className={classes.contentContainer}>
          <div className={classes.textContainer1}>
            <Hidden mdUp>
              <div
                className={
                  newHP ? classes.newHPhorizontalImage : classes.horizontalImage
                }
              >
                <FadeIn>
                  <img src={imageOne} alt="" />
                </FadeIn>
              </div>
            </Hidden>
            <div
              className={
                newHP ? classes.headerContainer2 : classes.headerContainer
              }
            >
              <Typography
                variant="h3"
                className={`${classes.headers} ${classes.fontOrange}`}
              >
                {headerOne}
              </Typography>
            </div>
            {newHP && <div className={classes.orangeLine} />}
            <div className={classes.descriptionContainer}>
              <Typography
                variant="body1"
                className={
                  newHP
                    ? `${classes.description2} ${classes.blackFont}`
                    : `${classes.description2} ${classes.whiteFont}`
                }
              >
                {descriptionOne}
              </Typography>
            </div>
          </div>
          <Hidden smDown>
            <div
              className={
                newHP ? classes.newHPhorizontalImage : classes.horizontalImage
              }
            >
              <FadeIn>
                <img src={imageOne} alt="" />
              </FadeIn>
            </div>
          </Hidden>
        </div>
      </WrapperContainer>
      <WrapperContainer addMaxWidth={false} addlWclass={addlWclass}>
        <div className={classes.contentContainer}>
          <Hidden smDown>
            <div className={newHP ? classes.newHPleftImage : classes.leftImage}>
              <FadeIn direction="right">
                <img src={imageTwo} alt="" />
              </FadeIn>
            </div>
          </Hidden>
          <div className={classes.textContainer1}>
            <Hidden mdUp>
              <div
                className={newHP ? classes.newHPleftImage : classes.leftImage}
              >
                <FadeIn direction="right">
                  <img src={imageTwo} alt="" />
                </FadeIn>
              </div>
            </Hidden>
            <div
              className={
                newHP ? classes.headerContainer2 : classes.headerContainer
              }
            >
              <Typography
                variant="h3"
                className={`${classes.headers} ${classes.fontOrange}`}
              >
                {headerTwo}
              </Typography>
            </div>
            {newHP && <div className={classes.orangeLine} />}
            <div className={classes.descriptionContainer}>
              <Typography
                variant="body1"
                className={`${classes.description2} ${classes.whiteFont}`}
              >
                {descriptionTwo}
              </Typography>
            </div>
          </div>
        </div>
      </WrapperContainer>
    </>
  );
}
