import React from "react";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { WrapperContainer } from "components/HOC";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/lfa";

const useStyles = makeStyles(styles);

export default function SectionTwo() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <WrapperContainer addMaxWidth={false} addlWclass={classes.s2Padding}>
      <Grid container className={classes.bgLightBlue}>
        <Grid
          item
          xs={12}
          sm={6}
          className={`${classes.bgDarkBlue} ${classes.gridItem}`}
        >
          <div className={classes.headerContainer}>
            <Typography
              variant="h5"
              component="h3"
              align="center"
              className={`${classes.headers} ${classes.s2Header} ${classes.whiteFont}`}
            >
              {t("lfa_h4")}
            </Typography>
          </div>
          <div className={classes.whiteLine} />
          <div className={classes.bodyContainer}>
            <Typography
              variant="body1"
              className={`${classes.description} ${classes.whiteFont}`}
              align="center"
            >
              {t("lfa_p4")}
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={`${classes.gridItem} ${classes.bgLightBlue}`}
        >
          <div className={classes.headerContainer}>
            <Typography
              variant="h5"
              component="h3"
              align="center"
              className={`${classes.headers} ${classes.s2Header} ${classes.greenFont}`}
            >
              {t("lfa_h5")}
            </Typography>
          </div>
          <div className={classes.greenLine} />
          <div className={classes.bodyContainer}>
            <Typography
              variant="body1"
              className={classes.description}
              align="center"
            >
              {t("lfa_p5")}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </WrapperContainer>
  );
}
