import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

// core components
import styles from "assets/jss/material-kit-react/views/orderPage.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { getVat } from "store/actions/OrderAction";

import Billing from "./Billing";
import Box from "@material-ui/core/Box";
import Cart from "./Cart";

const useStylesG = makeStyles(styles);
const useStyles = makeStyles(theme => ({
  bordered: {
    border: "2px solid #afe170"
  },
  root: {
    color: theme.palette.text.primary
  }
}));

const PaymentMethod = ({ getVat, plan }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const classesG = useStylesG();

  useEffect(() => {
    getVat();
  }, [getVat]);

  return (
    <GridContainer
      className={classes.section}
      direction="row"
      justify="flex-start"
    >
      <GridItem xs={12} sm={6}>
        <GridContainer style={{ margin: 0 }}>
          <GridContainer justify="flex-start">
            <Grid item xs={12}>
              <div className={classesG.titleContainer}>
                <h2 className={classesG.titleSection}>
                  {" "}
                  {t("order_select_payment")}{" "}
                </h2>
              </div>
            </Grid>
          </GridContainer>

          <GridItem xs={12}>
            <Box border={2} p={2} style={{ padding: "10px" }}>
              <Billing plan={plan} />
            </Box>
          </GridItem>
        </GridContainer>
      </GridItem>

      <GridItem xs={12} sm={6}>
        <GridContainer style={{ margin: 0 }}>
          <GridContainer justify="flex-start">
            <Grid item xs={12}>
              <div className={classesG.titleContainer}>
                <h2 className={classesG.titleSection}>
                  {" "}
                  {t("order_summary")}{" "}
                </h2>
              </div>
            </Grid>
          </GridContainer>

          <GridItem xs={12}>
            <Box
              border={2}
              borderColor="#B0E170"
              p={2}
              style={{ background: "#FBFCFE" }}
            >
              <Cart />
            </Box>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user.user,
    selected: state.plans.selected,
    vat: state.order.vat,
    nbUnit: state.plans.nbUnitSelected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getVat: country => dispatch(getVat(country))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentMethod);
