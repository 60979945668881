import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/components/carouselStyle";

const useStyles = makeStyles(styles);

export default function CarouselItem({ data }) {
  const { name, purpose, image } = data;
  const classes = useStyles();

  return (
    <Paper square elevation={0} className={classes.carouselItem}>
      <img src={image} className={classes.carouselImage} alt="Carousel Item" />
      <Typography
        gutterBottom
        variant="h5"
        component="h3"
        className={classes.carouselHeader}
      >
        {name}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        component="p"
        className={classes.carouselBody}
      >
        {purpose}
      </Typography>
    </Paper>
  );
}
