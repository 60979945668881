import React from "react";
import { useTranslation } from "react-i18next";

// components
import ComputerIcon from "@material-ui/icons/Computer";
import Icon from "@material-ui/core/Icon";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import GridCard from "../Other/GridCard";
import { WrapperContainer } from "components/HOC";
import ComparisonTable from "../Other/ComparisonTable20210323";

// img
import img1 from "assets/img/lp/img_rif_001.png";
import img2 from "assets/img/lp/img_rif_002.png";
import img3 from "assets/img/lp/img_rif_003.png";
import img4 from "assets/img/lp/img_rif_004.png";
import img5 from "assets/img/lp/img_rif_005.png";
import shellsLogo from "assets/img/shellslogo-black.png";

// style
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/landingPage20210323";

const useStyles = makeStyles(styles);

export default function Rif() {
  const { t } = useTranslation();
  const classes = useStyles();

  const rifList = [
    {
      title: t("lp_rif_content1_title"),
      text: t("lp_rif_content1_text"),
      image: img1
    },
    {
      title: t("lp_rif_content2_title"),
      text: t("lp_rif_content2_text"),
      image: img2
    },
    {
      title: t("lp_rif_content3_title"),
      text: t("lp_rif_content3_text"),
      image: img3
    },
    {
      title: t("lp_rif_content4_title"),
      text: t("lp_rif_content4_text"),
      image: img4
    },
    {
      title: t("lp_rif_content5_title"),
      text: t("lp_rif_content5_text"),
      image: img5
    }
  ];

  const tableHead = [
    "", // eslint-disable-next-line
    <img src={shellsLogo} alt="Shells Logo" className={classes.shellsIcon} />, // eslint-disable-next-line
    <Icon className={`fab fa-aws ${classes.icon}`} />, // eslint-disable-next-line
    <div className={classes.iconDiv}>
      <ComputerIcon className={classes.icon} />
      {t("lp_rif_comparison_th3")}
    </div>
  ];

  const tableBody = [
    {
      title: t("lp_rif_comparison_list1"),
      s: <Icon className={`fas fa-check ${classes.checkIcon}`} />,
      aw: <Icon className={`fas fa-check ${classes.checkIcon}`} />,
      oh: <Icon className={`fas fa-check ${classes.checkIcon}`} />
    },
    {
      title: t("lp_rif_comparison_list2"),
      s: <Icon className={`fas fa-check ${classes.checkIcon}`} />,
      aw: <Icon className={`fas fa-check ${classes.checkIcon}`} />,
      oh: <Icon className={`fas fa-times ${classes.xIcon}`} />
    },
    {
      title: t("lp_rif_comparison_list3"),
      s: <Icon className={`fas fa-check ${classes.checkIcon}`} />,
      aw: <Icon className={`fas fa-check ${classes.checkIcon}`} />,
      oh: <Icon className={`fas fa-times ${classes.xIcon}`} />
    },
    {
      title: t("lp_rif_comparison_list4"),
      s: <Icon className={`fas fa-check ${classes.checkIcon}`} />,
      aw: <Icon className={`fas fa-times ${classes.xIcon}`} />,
      oh: <Icon className={`fas fa-times ${classes.xIcon}`} />
    },
    {
      title: t("lp_rif_comparison_list5"),
      s: <Icon className={`fas fa-check ${classes.checkIcon}`} />,
      aw: <Icon className={`fas fa-times ${classes.xIcon}`} />,
      oh: <Icon className={`fas fa-times ${classes.xIcon}`} />
    },
    {
      title: t("lp_rif_comparison_list6"),
      s: <Icon className={`fas fa-check ${classes.checkIcon}`} />,
      aw: <Icon className={`fas fa-check ${classes.checkIcon}`} />,
      oh: <Icon className={`fas fa-times ${classes.xIcon}`} />
    },
    {
      title: t("lp_rif_comparison_list7"),
      s: <Icon className={`fas fa-check ${classes.checkIcon}`} />,
      aw: <Icon className={`fas fa-times ${classes.xIcon}`} />,
      oh: <Icon className={`fas fa-times ${classes.xIcon}`} />
    }
  ];

  return (
    <>
      <WrapperContainer addMaxWidth={false}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center" justify="center">
            {rifList.map((data, index) => {
              return <GridCard data={data} key={index} />;
            })}
          </Grid>

          <ComparisonTable tableHead={tableHead} tableBody={tableBody} />
        </Container>
      </WrapperContainer>
    </>
  );
}
