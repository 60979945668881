import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { rest } from "@karpeleslab/klbfw";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Trans, useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Countries from "../../../components/Countries/Countries";
import { useLocation } from "react-router-dom";
import qs from "querystring";

import Button from "components/CustomButtons/ShellButton.js";
import { getLocale, getCookie } from "@karpeleslab/klbfw";
import {
  setComplete,
  setProcessing
} from "../../../store/actions/StripePaymentAction";
import {
  create as createBilling,
  fetch
} from "../../../store/actions/UserBillingActions";
import { create as createLocation } from "../../../store/actions/LocationAction";
import { createShell, selectShell } from "../../../store/actions/ShellAction";
import { error as errorToast } from "../../../store/actions/ToastAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import PaymentMethodDisplay from "../../../components/payment/PaymentMethodDisplay";
import { useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { registerAndLog } from "../../../store/actions/UserActions";
import ChangePaymentMethodButton from "./ChangePaymentMethodButton";
import { addItem, removeItem } from "./../../../store/actions/CartAction";
import uuid from "./../../../components/utils/uuid";
import { isCustomPlan } from "components/Plans/util";
import FlowLogin from "components/login/Login";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import sendGtagEvent from "./../../../components/utils/sendGtagEvent";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/orderPage.js";
import { Typography } from "@material-ui/core";
import Stripe from "../../../components/common/inputs/Stripe";

const useStyles = makeStyles(styles);
const currentLinuxDistros = [
  "debian-buster-gnome-desktop",
  "debian-buster-ssh-server",
  "fedora-33-server",
  "fedora-33-workstation-product",
  "manjaro-desktop",
  "manjaro-gnome-desktop",
  "manjaro-kde-desktop",
  "ubuntu-focal-kde-neon-desktop",
  "ubuntu-focal-kubuntu-desktop",
  "ubuntu-focal-lubuntu-desktop",
  "ubuntu-focal-openssh-server",
  "ubuntu-focal-ubuntu-desktop",
  "ubuntu-focal-xubuntu-desktop",
  "ubuntu-focal-rescue",
  "windows-server-2019-dc-desktop",
  "debian-buster-mx-linux-desktop"
];

const Billing = props => {
  const history = useHistory();
  const { nbUnit, selected } = props;
  const { processing } = props;
  const pricingTestCookie = getCookie("pricing_test");
  const {
    createBilling,
    createLocation,
    setProcessing,
    createShell,
    fetchBilling,
    selectShell,
    addItem,
    removeItem
  } = props;
  const {
    registering,
    loading,
    loadingMethod,
    selectedMethod,
    selectedBilling,
    user
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const [shellName, setShellName] = useState("My Shell");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState(
    getLocale()
      .split("-")[1]
      .toLowerCase()
  );

  const [continueEnabled, setContinueEnabled] = useState(false);
  const [complete, setComplete] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [preloadLoginFlow, setPreloadLoginFlow] = useState(false);

  const [stripeIntent, setStripeIntent] = useState(null);
  const [stripeElements, setStripeElements] = useState(null);
  const [stripe, setStripe] = useState(null);
  const [stripeStatus, setStripeStatus] = useState({});

  const onStripeChange = e => {
    setStripeStatus(e);
  };

  // First load the existing billing, and reset potential previous payment
  useEffect(() => {
    setProcessing(false);
    setComplete(false);
    if (user && !processing) fetchBilling();
    // eslint-disable-next-line
  }, [fetchBilling, setProcessing, setComplete, user]);

  // Checks to enable disable to payment button
  // If we have a billing, we no need to check the creation form
  useEffect(() => {
    if (!selected) {
      setContinueEnabled(false);
      return;
    }

    // If we have a billing we only need to check a country is present (no creation form)
    if (selectedBilling) {
      if (!shellName.trim()) setContinueEnabled(false);
      else setContinueEnabled(true);
      return;
    }

    if (!stripeStatus.complete) {
      setContinueEnabled(false);
      return;
    }

    if (
      !shellName.trim() ||
      !firstname.trim() ||
      !lastname.trim() ||
      !zip.trim() ||
      !country.trim()
    ) {
      setContinueEnabled(false);
      return;
    }

    if (!user) {
      setContinueEnabled(false);
      return;
    }

    setContinueEnabled(true);
  }, [
    selected,
    firstname,
    lastname,
    zip,
    country,
    setContinueEnabled,
    shellName,
    stripeStatus,
    selectedBilling,
    user
  ]);

  const location = useLocation();
  const [operatingSystem, setOperatingSystem] = useState(null);
  const [nonce, setNonce] = useState(null);

  useEffect(() => {
    setNonce(uuid());
    const query = qs.parse(location.search.replace("?", ""));

    if (query.session) setOpenLoginModal(true);

    if (
      selected &&
      selected["Meta.shell_os"] &&
      currentLinuxDistros.includes(selected["Meta.shell_os"])
    ) {
      setOperatingSystem(selected["Meta.shell_os"]);
    } else {
      if (!query.os || !currentLinuxDistros.includes(query.os)) return;

      setOperatingSystem(query.os);
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    if (!openLoginModal) return;

    setPreloadLoginFlow(true);
    rest("Catalog/Cart/@", "GET").then(({ data }) => {
      // if we're returning from login
      if (
        qs.parse(location.search.replace("?", "")).session &&
        data &&
        data.products &&
        data.products.length > 0 &&
        data.products[0].meta &&
        data.products[0].meta.shell_label
      ) {
        setShellName(data.products[0].meta.shell_label);
        setPreloadLoginFlow(false);
      } else {
        // if we're going to login
        let tmp = null;
        if (data && data.products && data.products.length > 0) {
          data.products.forEach(async (p, index) => {
            if (index < 1) tmp = JSON.parse(JSON.stringify(p));
            if (
              !p["Basic.Decorator"] ||
              p["Basic.Decorator"] !== "ShellLicense"
            )
              await removeItem(p.key);
          });
        }
        if (!tmp) return;

        let productId = tmp.data.Catalog_Product__;
        if (
          tmp.data &&
          tmp.meta &&
          isCustomPlan(tmp.data) &&
          tmp.meta.shell_size
        )
          productId += `,shell_size=${tmp.meta.shell_size}`;

        if (tmp && tmp.meta && tmp.meta.shell_os)
          productId += `,shell_os=${tmp.meta.shell_os}`;

        if (shellName) productId += `,shell_label=${shellName}`;

        addItem(productId).then(() => setPreloadLoginFlow(false));
      }
    });
  }, [openLoginModal]); // eslint-disable-line

  const handleChangePaymentMethod = async () => {
    const { data } = await rest("Catalog/Cart/@", "GET");
    if (data && data.products && data.products.length > 0) {
      data.products.forEach(async p => {
        await removeItem(p.key);
      });
    }
    let productId = selected.Catalog_Product__;
    if (selected && isCustomPlan(selected))
      productId += `,shell_size=${nbUnit}`;

    if (selected && selected["Meta.shell_os"])
      productId += `,shell_os=${selected["Meta.shell_os"]}`;

    if (selected && shellName) productId += `,shell_label=${shellName}`;

    await addItem(productId);

    if (user) history.push("/cart");
  };

  const gaEvent = () => {
    // if regular plan, sending a custom event for the A/B testing on the pricing page
    if (selected && selected["Description.Type"] === "shells_plan") {
      const fromWhichVersion =
        pricingTestCookie === "pricing_alt" ? "without_lite" : "regular";
      const labelDetails =
        pricingTestCookie === "pricing_alt"
          ? "they were shown the Pricing version B (without Lite plan) page."
          : "they were shown the Pricing version A (with Lite plan included) page.";
      sendGtagEvent(
        `checkout_plan_${fromWhichVersion}__${
          selected["Description.AuthorCode"]
        }_${selected["Basic.ServiceLifetime"]}`,
        {
          event_category: "ab_testing_pricing",
          event_label: `Visitor completed the checkout process for ${
            selected["Description.AuthorCode"]
          } plan (${selected["Basic.ServiceLifetime"]}); ${labelDetails}`
        }
      );
    }
  };

  const handlePay = () => {
    setProcessing(true);
    let size = null;
    if (selected && isCustomPlan(selected)) size = nbUnit;

    createShell(
      selectedBilling.User_Billing__,
      selected.Catalog_Product__,
      shellName,
      size,
      nonce,
      operatingSystem
    )
      .then(shell => selectShell(shell))
      .then(() => {
        setComplete(true);
        gaEvent();
      })
      .then(() => history.push("/order_completed"))
      .catch(error => {
        setProcessing(false);
        errorToast(error.message);
      });
  };

  const createBillingAndPay = async () => {
    setProcessing(true);

    if (!stripe || !stripeElements) return; // stripe not loaded yet

    setProcessing(true);

    const result = await stripe.confirmSetup({
      elements: stripeElements,
      redirect: "if_required",
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: `${firstname} ${lastname}`,
            email: user?.Email,
            address: {
              country: country,
              postal_code: zip,
              state: "",
              city: "",
              line1: "",
              line2: ""
            }
          }
        }
      }
    });

    if (result.error) {
      if (
        result.error.type === "card_error" ||
        result.error.type === "validation_error"
      ) {
        errorToast(result.error.message);
      } else {
        errorToast(t("unexpected_error"));
      }

      setProcessing(false);
      return;
    }

    const intent = stripeIntent.stripe_intent;

    return createLocation(firstname, lastname, zip, country)
      .then(location => {
        return createBilling(location.User_Location__, intent);
      })
      .then(billing => {
        let size = null;
        if (isCustomPlan(selected)) size = nbUnit;

        return createShell(
          billing.User_Billing__,
          selected.Catalog_Product__,
          shellName,
          size,
          nonce,
          operatingSystem
        );
      })
      .then(shell => selectShell(shell))
      .then(() => {
        setComplete(true);
        gaEvent();
      })
      .then(() => history.push("/order_completed"))
      .catch(error => {
        setProcessing(false);
        errorToast(error.message);
      });
  };

  const handleCreationAndPay = () => {
    if (user) {
      createBillingAndPay();
      return;
    }

    setProcessing(true);
    createBillingAndPay();
  };

  // If we are loading the existing billing and method display the loader
  // Nothing to load if there is no connected user
  if (user && (loading || loadingMethod)) {
    return <CircularProgress color="primary" />;
  }

  // If the payment is complete dispaly the message
  if (complete) {
    return (
      <GridContainer spacing={3}>
        <GridItem xs={12}>
          <p>{t("shellcreated_success")}</p>
          <Link component="a" to={process.env.REACT_APP_SHELL_CONSOLE_URL}>
            {t("order_my_account")}
          </Link>
        </GridItem>
      </GridContainer>
    );
  }

  // If we have a billing, we don't need to display the creation form
  // We only need the country

  return (
    <GridContainer spacing={3} className={classes.billingColor}>
      <GridItem xs={12} style={{ padding: "unset" }}>
        <div className={classes.billingContainer}>
          <GridItem xs={12}>
            <TextField
              id="outlined-basic"
              label={t("shellname_lbl")}
              required
              variant="outlined"
              fullWidth
              helperText={t("order_choose_label")}
              value={shellName}
              onChange={e => setShellName(e.target.value)}
              disabled={processing || registering}
            />
          </GridItem>

          {!user ? (
            <>
              <Box py={4} px={2}>
                <Grid container justify="center">
                  <Typography>
                    {t("create_account_or_login_description")}
                  </Typography>
                  <Box pt={4}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setOpenLoginModal(true)}
                    >
                      {t("create_account_or_login_title")}
                    </Button>
                  </Box>
                </Grid>
              </Box>
              <Dialog
                open={openLoginModal}
                onClose={() => setOpenLoginModal(false)}
                classes={{
                  paper: classes.userFlowDialog
                }}
              >
                <Box py={4} px={2}>
                  {preloadLoginFlow ? (
                    <CircularProgress />
                  ) : (
                    <FlowLogin onLoggedIn={() => setOpenLoginModal(false)} />
                  )}
                </Box>
              </Dialog>
            </>
          ) : (
            <>
              <Fragment>
                {selectedBilling && selectedMethod && (
                  <>
                    <GridItem xs={12} className={classes.selectedGridItem}>
                      <PaymentMethodDisplay method={selectedMethod} />
                    </GridItem>
                  </>
                )}
                {!selectedBilling && (
                  <Fragment>
                    <GridItem xs={12}>
                      <hr />
                    </GridItem>
                    <GridContainer
                      spacing={1}
                      className={classes.billingMargin}
                    >
                      <GridItem xs={12} md={6}>
                        <TextField
                          id="outlined-basic"
                          required
                          label={t("firstname_lbl")}
                          variant="outlined"
                          fullWidth
                          value={firstname}
                          onChange={e => setFirstname(e.target.value)}
                          disabled={processing || registering}
                        />
                      </GridItem>
                      <GridItem xs={12} md={6}>
                        <TextField
                          id="outlined-basic"
                          required
                          label={t("lastname_lbl")}
                          variant="outlined"
                          fullWidth
                          value={lastname}
                          onChange={e => setLastname(e.target.value)}
                          disabled={processing || registering}
                        />
                      </GridItem>
                      <GridItem xs={12} md={6}>
                        <TextField
                          id="outlined-basic"
                          required
                          label={t("zip_lbl")}
                          variant="outlined"
                          fullWidth
                          value={zip}
                          onChange={e => setZip(e.target.value)}
                          disabled={processing || registering}
                        />
                      </GridItem>

                      <GridItem xs={12} md={6}>
                        <Countries
                          inputProps={{ fullWidth: true, required: true }}
                          value={country}
                          onChange={e => setCountry(e.target.value)}
                          disabled={processing || registering}
                          geoLookup
                        />
                      </GridItem>
                    </GridContainer>
                  </Fragment>
                )}

                <GridItem xs={12} className={classes.selectedGridItem}>
                  <Trans i18nKey="order_edit_billing_link">
                    sample
                    <Link
                      component="a"
                      to={process.env.REACT_APP_SHELL_CONSOLE_URL}
                    >
                      sample
                    </Link>
                  </Trans>
                </GridItem>
              </Fragment>
              <GridItem xs={12}>
                <GridContainer
                  alignItems="center"
                  direction="column"
                  spacing={3}
                >
                  {!selectedBilling && (
                    <GridItem xs={12}>
                      <div className={classes.stripeContainer}>
                        <Stripe
                          setIntent={setStripeIntent}
                          onChange={onStripeChange}
                          setStripeElements={setStripeElements}
                          setStripe={setStripe}
                          disabled={processing}
                        />
                      </div>
                    </GridItem>
                  )}
                  <Grid
                    item
                    className={classes.orderButtonGridItem}
                    container
                    justify="center"
                  >
                    {!processing && !registering && (
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={!continueEnabled}
                        onClick={
                          selectedBilling ? handlePay : handleCreationAndPay
                        }
                      >
                        {t("order_btn")}
                      </Button>
                    )}
                    {(processing || registering) && <CircularProgress />}
                  </Grid>
                </GridContainer>
              </GridItem>
            </>
          )}
        </div>
        <GridItem xs={12}>
          <GridContainer alignItems="center" direction="column" spacing={3}>
            <p className={classes.serviceTermsText}>{t("terms_service")}</p>
            <Grid item>
              <ChangePaymentMethodButton
                handleChangePaymentMethod={handleChangePaymentMethod}
                loading={registering}
                disabled={!user ? true : false}
              />
            </Grid>
          </GridContainer>
        </GridItem>
      </GridItem>
    </GridContainer>
  );
};

const mapStateToProps = state => {
  return {
    registering: state.user.registering,
    user: state.user.user,
    selected: state.plans.selected,
    processing: state.stripe.processing,
    complete: state.stripe.complete,
    nbUnit: state.plans.nbUnitSelected,
    loading: state.userBilling.loading,
    loadingMethod: state.userBilling.loadingMethod,
    selectedMethod: state.userBilling.selectedMethod,
    selectedBilling: state.userBilling.selected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createBilling: (locationId, stripeIntent) =>
      dispatch(createBilling(locationId, stripeIntent, "Shells", true)),
    createLocation: (
      firstName,
      lastName,
      zip,
      province,
      city,
      address,
      country
    ) =>
      dispatch(
        createLocation(
          firstName,
          lastName,
          zip,
          province,
          city,
          address,
          country
        )
      ),
    setProcessing: processing => dispatch(setProcessing(processing)),
    setComplete: () => dispatch(setComplete()),
    createShell: (
      billingId,
      product,
      label,
      size,
      nonce,
      operatingSystemCode = null
    ) =>
      dispatch(
        createShell(billingId, product, label, size, nonce, operatingSystemCode)
      ),
    fetchBilling: () => dispatch(fetch()),
    selectShell: shell => dispatch(selectShell(shell)),
    registerAndLog: (email, password, tosAccept, displayName = null) =>
      dispatch(registerAndLog(email, password, tosAccept, displayName)),
    addItem: productId => dispatch(addItem(productId)),
    removeItem: key => dispatch(removeItem(key))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Billing);
