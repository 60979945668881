import React from "react";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import Button from "components/CustomButtons/ShellButton.js";

const BackPage = props => {
  const { t } = useTranslation();
  const goBack = () => {
    props.history.goBack();
  };

  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "24px" }}
    >
      <Button variant="contained" onClick={() => goBack()}>
        {t("back_previous_page_btn")}
      </Button>
    </div>
  );
};

export default withRouter(BackPage);
