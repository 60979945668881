import React from "react";
import { getCookie, setCookie } from "@karpeleslab/klbfw";
import Login from "views/LoginPage/LoginPage";
import Order from "views/OrderPage/OrderPage";
import { updateUser } from "store/actions/UserActions";
import Page from "../views/Page/Page";
import List from "../views/List/List";
import PageComponent from "../components/pages/Page/Page";
import NotFound404 from "views/Special/NotFound404";
import Download from "../views/Download/Download";
//import Team from "../views/Team/Team";
import LFA from "../views/LFA/LFA";
import WhyShells from "../views/WhyShells/WhyShells";
import CloudMusic from "../views/CloudMusic/CloudMusic";
import Security from "../views/Security/Security";
import Manjaro from "../views/Manjaro/Manjaro";
import Debian from "views/Debian/Debian";
import OperatingSystems from "views/OperatingSystems/OperatingSystems";
import UbuntuPricingPage from "views/Ubuntu/UbuntuPricingPage";
import Minecraft from "views/Minecraft/Minecraft";
import VsCode from "views/VsCode/VsCode";
import Daas from "views/Daas/Daas";
//import Faq from "views/Faq/Faq";
//import FaqComponent  from "../views/Faq/Sections/Faq"
import Contact from "views/Contact/Contact";
import EdTech from "views/EdTech/EdTech";
import mailRoutes from "./mailRoutes";
import PasswordForgotten from "views/PasswordForgotten/PasswordForgotten";
import PasswordReset from "views/PasswordReset/PasswordReset";
import Cart from "views/Cart/Cart";
import CartRedirect from "views/Cart/CartRedirect";
import OrderDefault from "views/OrderPage/Order";
import Giveaway from "views/Giveaway/Giveaway";

import OrderCompleted from "views/OrderCompleted/OrderCompleted";
import OrderCompletedItem from "views/OrderCompleted/OrderCompletedItem";
import AlternativeLayout from "../views/LandingPage/Alternative/AlternativeLayout";
import CmsList from "../components/cms/List";

import Html from "./../mails/Login/Notification/Html";
import ConsoleRedirect from "../views/Special/ConsoleRedirect";
import Network from "./../views/Network/Network";
import { Redirect } from "react-router-dom";
import LinuxCloudDesktop from "./../views/LinuxCloudDesktop/LinuxCloudDesktop";
import LinuxYouWant from "./../views/LinuxCloudDesktop/LinuxYouWant";
import Homepage20210414 from "./../views/Homepage20210414/Homepage20210414";
import PricingPlans from "./../views/PricingPlans/PricingPlans";
import TechRadarLandingPage from "./../views/TechRadarLandingPage/TechRadarLandingPage";
import LinuxQuestionsOrgLandingPage from "./../views/LinuxQuestionsOrgLandingPage/LinuxQuestionsOrgLandingPage";
import CloudTweaksLandingPage from "./../views/CloudTweaksLandingPage/CloudTweaksLandingPage";
import RemoteToolsLandingPage from "./../views/RemoteTools/RemoteToolsLandingPage";
import StreetPromote from "views/StreetPromote/StreetPromote";
import VirtualBox from "views/VirtualBox/VirtualBox";
import VMWare from "views/VMWare/VMWare";
import WindowsSubsystem from "views/WindowsSubsystem/WindowsSubsystem";
import BrowserInTheCloud from "views/BrowserInTheCloud/BrowserInTheCloud";
import OAuthGrant from "views/OAuthGrant/OAuthGrant";
import OAuthReturn from "views/OAuthReturn/OAuthReturn";
import RedeemCode from "views/RedeemCode/RedeemCode";
import { i18nPromise } from "i18n";

// views/ManjaroLandingPage/ManjaroLandingPageT;his set of promises will waited prior running all loadData promises.
export const prerequisites = store => {
  return [
    i18nPromise,
    store.dispatch(updateUser()).catch(() => {}) // ignore error
  ];
};

export default (cookies = {}) => { // eslint-disable-line
  // A/B Testing on pricing plans page
  const pricingTest = ["pricing", "pricing_alt"];
  if (!getCookie("pricing_test")) {
    setCookie(
      "pricing_test",
      pricingTest[Math.floor(Math.random() * pricingTest.length)],
      30
    );
  }

  // for A/B Testing on pricing plans page
  const pricingCookie = getCookie("pricing_test");

  return [ // eslint-disable-line
    {
      path: "/",
      component: Homepage20210414,
      exact: true,
      type: "route"
    },
    {
      path: "/order_completed",
      component: OrderCompleted,
      exact: true,
      type: "route"
    },
    {
      path: "/login",
      component: Login,
      exact: true,
      type: "route"
    },
    {
      path: "/login.html",
      component: Login,
      exact: true,
      type: "route"
    },
    {
      path: "/join",
      component: Order,
      exact: true,
      type: "route",
      loginRequired: false
    },
    {
      path: "/order",
      components: <Redirect to="/join" />,
      exact: true,
      type: "route",
      loginRequired: false
    },
    {
      path: "/order/:orderId",
      component: OrderDefault,
      exact: true,
      type: "route",
      loginRequired: false
    },
    {
      path: "/blog",
      exact: true,
      components: <List type="blog" />,
      type: "route",
      loadData: [CmsList.serverFetchBlogs]
    },
    {
      path: "/news",
      exact: true,
      components: <List type="news" />,
      type: "route",
      loadData: [CmsList.serverFetchNews]
    },
    {
      path: "/pricing",
      exact: true,
      components:
        pricingCookie === "pricing_alt" ? (
          <PricingPlans withLitePlan={true} />
        ) : (
          <PricingPlans withLitePlan={false} />
        ),
      type: "route"
    },
    {
      path: "/tutorial",
      exact: true,
      components: <List type="tutorial" />,
      type: "route",
      loadData: [CmsList.serverFetchTutorials]
    },
    {
      path: "/order_completed_item",
      component: OrderCompletedItem,
      exact: true,
      type: "route"
    },
    {
      path: "/page/:slug",
      components: <Page type="page" />,
      loadData: [PageComponent.serverFetchPage],
      type: "route"
    },
    {
      path: "/faq/:slug",
      components: <Page type="faq" />,
      loadData: [PageComponent.serverFetchFaq],
      type: "route"
    },
    {
      path: "/news/:slug",
      components: <Page type="news" />,
      loadData: [PageComponent.serverFetchNews],
      type: "route"
    },
    {
      path: "/blog/:slug",
      components: <Page type="blog" />,
      loadData: [PageComponent.serverFetchBlog],
      type: "route"
    },
    {
      path: "/tutorial/:slug",
      components: <Page type="tutorial" />,
      loadData: [PageComponent.serverFetchTutorial],
      type: "route"
    },
    /*{
          path:"/faq",
          component:Faq,
          exact:true,
          loadData: [FaqComponent.serverFetch],
          type:"route",
      },*/
    {
      path: "/contact",
      component: Contact,
      exact: true,
      type: "route"
    },
    {
      path: "/forget-password",
      component: PasswordForgotten,
      exact: true,
      type: "route"
    },
    {
      path: "/reset-password",
      component: PasswordReset,
      exact: true,
      type: "route"
    },
    {
      path: "/cart",
      component: Cart,
      exact: true,
      type: "route"
    },
    {
      path: "/cart-redirect",
      component: CartRedirect,
      exact: true,
      type: "route"
    },
    {
      path: "/network",
      component: Network,
      exact: true,
      type: "route"
    },
    {
      path: "/lp/:pageName",
      component: AlternativeLayout,
      exact: true,
      type: "route"
    },
    {
      path: "/test/email",
      component: Html,
      exact: true,
      type: "route"
    },
    {
      path: "/account",
      component: ConsoleRedirect,
      exact: true,
      type: "route"
    },
    ...mailRoutes,
    {
      path: "/download",
      component: Download,
      exact: true,
      type: "route",
      loadData: [Download.serverfetch]
    },
    /*
    {
      path: "/team",
      component: Team,
      exact: true,
      type: "route"
    },
    */
    {
      path: "/whyshells",
      component: WhyShells,
      exact: true,
      type: "route"
    },
    {
      path: "/lfa",
      component: LFA,
      exact: true,
      type: "route"
    },
    {
      path: "/edtech",
      component: EdTech,
      exact: true,
      type: "route"
    },
    {
      path: "/linux-cloud-desktop",
      component: LinuxCloudDesktop,
      exact: true,
      type: "route"
    },
    {
      path: "/linux-you-want",
      component: LinuxYouWant,
      exact: true,
      type: "route"
    },
    {
      path: "/cloudmusic",
      component: CloudMusic,
      exact: true,
      type: "route"
    },
    {
      path: "/security",
      component: Security,
      exact: true,
      type: "route"
    },
    {
      path: "/manjaro",
      component: Manjaro,
      exact: true,
      type: "route"
    },
    {
      path: "/techradar-exclusive",
      component: TechRadarLandingPage,
      exact: true,
      type: "route"
    },
    {
      path: "/cloudtweaks-exclusive",
      component: CloudTweaksLandingPage,
      exact: true,
      type: "route",
      loadData: [CloudTweaksLandingPage.serverFetchPlans]
    },
    {
      path: "/linux-questions-org-exclusive",
      component: LinuxQuestionsOrgLandingPage,
      exact: true,
      type: "route"
    },
    {
      path: "/remote-tools-exclusive",
      component: RemoteToolsLandingPage,
      exact: true,
      type: "route"
    },
    {
      path: "/debian",
      component: Debian,
      exact: true,
      type: "route"
    },
    {
      path: "/ubuntu-hippo",
      component: UbuntuPricingPage,
      exact: true,
      type: "route"
    },
    {
      path: "/promo",
      component: StreetPromote,
      exact: true,
      type: "route"
    },
    {
      path: "/minecraft",
      component: Minecraft,
      exact: true,
      type: "route"
    },
    {
      path: "/vscode",
      component: VsCode,
      exact: true,
      type: "route"
    },
    {
      path: "/browser-in-the-cloud",
      component: BrowserInTheCloud,
      exact: true,
      type: "route"
    },
    {
      path: "/DaaS",
      component: Daas,
      exact: true,
      type: "route"
    },
    {
      path: "/virtual-box",
      component: VirtualBox,
      exact: true,
      type: "route"
    },
    {
      path: "/vmware",
      component: VMWare,
      exact: true,
      type: "route"
    },
    {
      path: "/windows-subsystem",
      component: WindowsSubsystem,
      exact: true,
      type: "route"
    },
    {
      path: "/july09giveaway",
      components: <Giveaway social="twitter" />,
      exact: true,
      type: "route"
    },
    // {
    //   path: "/giveaway-on-reddit",
    //   components: <Giveaway social="reddit" />,
    //   exact: true,
    //   type: "route"
    // },
    {
      path: "/os",
      component: OperatingSystems,
      exact: true,
      type: "route"
    },
    {
      path: "/oauth2_grant",
      component: OAuthGrant,
      exact: true,
      type: "route"
    },
    {
      path: "/oauth2_return",
      component: OAuthReturn,
      exact: true,
      type: "route"
    },
    {
      path: "/redeem",
      component: RedeemCode,
      exact: true,
      type: "route"
    },
    {
      path: "*",
      component: NotFound404,
      exact: false,
      type: "route"
    }
  ];
};
