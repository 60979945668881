import React from "react";
import { useTranslation } from "react-i18next";
import Helmet from "components/instances/instance/Helmet/Helmet";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/whyShells";

import {
  students,
  coders,
  workers,
  business
} from "assets/img/homepage20210414";
import { SectionTwo, ImageAndText } from "components/WhyShells";
import {
  device1,
  device2,
  device3,
  device4
} from "assets/img/homepage20210414";

import HPHeader from "./HPHeader";
import HPCardsRow from "./HPCardsRow";
import HPTopEnd from "./HPTopEnd";
import HPServers from "./HPServers";

const useStyles = makeStyles(styles);

const Homepage20210414 = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const users = [
    {
      header: t("users_one_header"),
      desc: t("users_one_desc"),
      link: "/edtech",
      image: students,
      imageAlt: t("users_one_header")
    },
    {
      header: t("users_two_header"),
      desc: t("users_two_desc"),
      link: "/lp/ide",
      image: coders,
      imageAlt: t("users_two_header")
    },
    {
      header: t("users_three_header"),
      desc: t("users_three_desc"),
      link: "",
      image: workers,
      imageAlt: t("users_three_header")
    },
    {
      header: t("users_four_header"),
      desc: t("users_four_desc"),
      link: "/lp/rif",
      image: business,
      imageAlt: t("users_four_header")
    }
  ];

  const detailsOne = {
    headerOne: t("whyshells_h3"),
    descriptionOne: t("whyshells_p3"),
    imageOne: device1,
    headerTwo: t("whyshells_h4"),
    descriptionTwo: t("whyshells_p4"),
    imageTwo: device4,
    addlWclass: `${classes.blackBG} ${classes.overflowHidden}`,
    extraClass: `${classes.lightBlueBG} ${classes.overflowHidden}`,
    newHP: true
  };
  const detailsTwo = {
    header_a: t("whyshells_h5a"),
    header_b: t("whyshells_h5b"),
    description: t("whyshells_p5"),
    image: device2,
    addlWclass: classes.lightBlueBG,
    imageLeft: true,
    addButton: true,
    fontColor: classes.fontOrange,
    textContainerClass: classes.textContainer3,
    lineClass: classes.orangeLine,
    newHP: true
  };
  const detailsThree = {
    header_a: t("whyshells_h6a"),
    header_b: t("whyshells_h6b"),
    description: t("whyshells_p6"),
    image: device3,
    imageLeft: false,
    addButton: false,
    addlWclass: `${classes.blackBG} ${classes.overflowHidden}`,
    fontColor: classes.whiteFont,
    addlFontColor: classes.fontOrange,
    textContainerClass: classes.textContainer1,
    lineClass: classes.whiteLine,
    addlLineClass: classes.orangeLine,
    newHP: true
  };

  return (
    <>
      <Helmet title={t("helmet_shells_home")}>
        <meta name="keywords" content={t("helmet_shells_home_keyword")} />
        <meta name="description" content={t("helmet_shells_home_desc")} />
      </Helmet>

      {/* Hero section */}
      <HPHeader />

      {/* See what Shells can do for you */}
      <HPCardsRow title={t("users_title")} cards={users} />

      {/* The future is us */}
      <HPTopEnd />

      {/* Work on your projects from any device */}
      <SectionTwo details={detailsOne} />

      {/* Stay secure */}
      <ImageAndText details={detailsTwo} />

      {/* Upgrades are easy */}
      <ImageAndText details={detailsThree} />

      {/* Our past experiences */}
      <HPServers />
    </>
  );
};

export default Homepage20210414;
