import React from "react";
import { useTranslation } from "react-i18next";

// components
import Icon from "@material-ui/core/Icon";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import GridCard from "../Other/GridCard";
import { WrapperContainer } from "components/HOC";
import ComparisonTable from "../Other/ComparisonTable20210323";

// img
import img1 from "assets/img/lp/img_vms_001.png";
import img2 from "assets/img/lp/img_vms_002.png";
import img3 from "assets/img/lp/img_vms_003.png";
import img4 from "assets/img/lp/img_vms_004.png";
import img5 from "assets/img/lp/img_vms_005.png";
import img6 from "assets/img/lp/img_vms_006.png";
import shellsLogo from "assets/img/shellslogo-black.png";

// style
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/landingPage20210323";

const useStyles = makeStyles(styles);

export default function Vms() {
  const { t } = useTranslation();
  const classes = useStyles();

  const vmsList = [
    {
      title: t("lp_vms_content1_title_v2"),
      text: t("lp_vms_content1_text"),
      image: img1
    },
    {
      title: t("lp_vms_content2_title"),
      text: t("lp_vms_content2_text"),
      image: img2
    },
    {
      title: t("lp_vms_content3_title_v2"),
      text: t("lp_vms_content3_text"),
      image: img3
    },
    {
      title: t("lp_vms_content4_title"),
      text: t("lp_vms_content4_text"),
      image: img4
    },
    {
      title: t("lp_vms_content5_title"),
      text: t("lp_vms_content5_text"),
      image: img5
    },
    {
      title: t("lp_vms_content6_title"),
      text: t("lp_vms_content6_text_v2"), // comment out 3 and 5 from list!
      text2: t("lp_vms_content6_text2"),
      image: img6
    }
  ];

  const tableHead = [
    "",
    // eslint-disable-next-line
    <img src={shellsLogo} alt="Shells Logo" className={classes.shellsIcon} />,
    // eslint-disable-next-line
    <div className={classes.iconDiv}>
      <Icon className={`fab fa-linode ${classes.icon}`} />
      {t("lp_vms_comparison_th2")}
    </div>,
    // eslint-disable-next-line
    <div className={classes.iconDiv}>
      <Icon className={`fab fa-digital-ocean ${classes.icon}`} />
      {t("lp_vms_comparison_th3")}
    </div>
  ];

  const tableBody = [
    {
      title: t("lp_vms_comparison_list1"),
      s: <Icon className={`fas fa-check ${classes.checkIcon}`} />,
      l: <Icon className={`fas fa-times ${classes.xIcon}`} />,
      d: <Icon className={`fas fa-times ${classes.xIcon}`} />
    },
    {
      title: t("lp_vms_comparison_list2"),
      s: <Icon className={`fas fa-check ${classes.checkIcon}`} />,
      l: <Icon className={`fas fa-check ${classes.checkIcon}`} />,
      d: <Icon className={`fas fa-times ${classes.xIcon}`} />
    },
    {
      title: t("lp_vms_comparison_list3"),
      s: <Icon className={`fas fa-check ${classes.checkIcon}`} />,
      l: <Icon className={`fas fa-check ${classes.checkIcon}`} />,
      d: <Icon className={`fas fa-check ${classes.checkIcon}`} />
    },
    {
      title: t("lp_vms_comparison_list4"),
      s: <Icon className={`fas fa-check ${classes.checkIcon}`} />,
      l: <Icon className={`fas fa-times ${classes.xIcon}`} />,
      d: <Icon className={`fas fa-times ${classes.xIcon}`} />
    },
    {
      title: t("lp_vms_comparison_list5"),
      s: <Icon className={`fas fa-check ${classes.checkIcon}`} />,
      l: <Icon className={`fas fa-check ${classes.checkIcon}`} />,
      d: <Icon className={`fas fa-times ${classes.xIcon}`} />
    },
    {
      title: t("lp_vms_comparison_list6"),
      s: (
        <a
          href="https://browser.geekbench.com/v5/cpu/3715063"
          target="_newsupportwindow"
          rel="noopener noreferrer"
          className={classes.geekBenchScore}
        >
          605
        </a>
      ),
      l: (
        <a
          href="https://browser.geekbench.com/v5/cpu/3715833"
          target="_newsupportwindow"
          rel="noopener noreferrer"
          className={classes.geekBenchScore}
        >
          561
        </a>
      ),
      d: (
        <a
          href="https://browser.geekbench.com/v5/cpu/3715945"
          target="_newsupportwindow"
          rel="noopener noreferrer"
          className={classes.geekBenchScore}
        >
          406
        </a>
      )
    },
    {
      title: t("lp_vms_comparison_list7"),
      s: (
        <a
          href="https://browser.geekbench.com/v5/cpu/3715063"
          target="_newsupportwindow"
          rel="noopener noreferrer"
          className={classes.geekBenchScore}
        >
          2519
        </a>
      ),
      l: (
        <a
          href="https://browser.geekbench.com/v5/cpu/3715833"
          target="_newsupportwindow"
          rel="noopener noreferrer"
          className={classes.geekBenchScore}
        >
          1868
        </a>
      ),
      d: (
        <a
          href="https://browser.geekbench.com/v5/cpu/3715945"
          target="_newsupportwindow"
          rel="noopener noreferrer"
          className={classes.geekBenchScore}
        >
          1007
        </a>
      )
    }
  ];

  return (
    <>
      <WrapperContainer addMaxWidth={false}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center" justify="center">
            {vmsList.map((data, index) => {
              return <GridCard data={data} key={index} />;
            })}
          </Grid>

          <ComparisonTable tableHead={tableHead} tableBody={tableBody} />
        </Container>
      </WrapperContainer>
    </>
  );
}
