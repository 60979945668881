import { Get, rest } from "@karpeleslab/klbfw";

import {
  SYNC_CMS,
  SYNC_CMS_DONE,
  SYNC_CMS_ERROR,
  SYNC_CMS_LIST,
  SYNC_CMS_LIST_DONE,
  SYNC_CMS_LIST_ERROR
} from "../reducers/CmsReducer";
import { catchRedirectException } from "../../components/utils/apiErrorHandler";

export const cmsFetch = (slug, cms, imgVariation = null) => {
  return dispatch => {
    dispatch({ type: SYNC_CMS });
    const params = {
      slug: slug,
      image_variation: imgVariation
    };
    if (Get("_preview")) {
      params["_preview"] = Get("_preview");
    }
    return rest("Content/Cms/" + cms + ":loadSlug", "GET", params)
      .then(catchRedirectException)
      .then(data => {
        dispatch({
          type: SYNC_CMS_DONE,
          entry: data.data.content_cms_entry_data,
          slugRedirect: data.slug_redirect ?? null
        });
        return data;
      })
      .catch(data => {
        dispatch({ type: SYNC_CMS_ERROR, error: data.message });
        throw data;
      });
  };
};

export const list = (cms, paging, filters = {}, imgVariation = null) => {
  return (dispatch, getState) => {
    dispatch({ type: SYNC_CMS_LIST });

    paging = { ...getState().cms.paging, ...paging, results_per_page: 12 };

    const query = {
      ...paging,
      sort: "published:desc",
      image_variation: imgVariation
    };

    if (Object.entries(filters).length) query.query = filters;

    return rest("Content/Cms/" + cms + ":search", "GET", query)
      .then(catchRedirectException)
      .then(data => {
        dispatch({
          type: SYNC_CMS_LIST_DONE,
          list: data.data.data,
          paging: data.paging
        });
      })
      .catch(data => {
        dispatch({ type: SYNC_CMS_LIST_ERROR, error: data.message });
        throw data;
      });
  };
};
