import {
  mainGreenColor,
  secondaryGreenColor,
  whiteColor
} from "assets/jss/material-kit-react";

const styles = theme => ({
  streetSignUpButton: {
    margin: "35px 0 15px 0",
    padding: "6px 24px",
    backgroundColor: mainGreenColor,
    color: whiteColor,
    display: "block",
    borderRadius: "35px",
    fontSize: "22px",
    textAlign: "center",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover": {
      boxShadow: `0 0 0 2px ${secondaryGreenColor} inset`,
      background: whiteColor,
      color: secondaryGreenColor
    },
    [theme.breakpoints.down("sm")]: {
      width: "200px",
      margin: "0 auto",
      marginBottom: "15px",
      fontSize: "16px",
      padding: "6px 14px"
    },
    [theme.breakpoints.up("md")]: {
      width: "315px"
    }
  }
});

export default styles;
