import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/edTech";

import { WrapperContainer } from "components/HOC";
import { crossPlat } from "assets/img/edtech";

const useStyles = makeStyles(styles);

export default function SectionThree() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <WrapperContainer addMaxWidth={false}>
      <Container maxWidth="xl">
        <Grid container alignItems="center" justify="center">
          <Grid xs={12} sm={6} className={classes.headerContainer2}>
            <img src={crossPlat} alt="" className={classes.connectionImage} />
          </Grid>
          <Grid xs={12} sm={6}>
            <div className={classes.s3TextButtonContainer}>
              <div className={classes.headerContainer2}>
                <Typography
                  variant="h3"
                  className={`${classes.sectionOneHeader} ${classes.blackText}`}
                >
                  {t("edtech_h7")}
                </Typography>
                <div className={classes.greenLine_s1_b} />
              </div>
              <Typography
                variant="body1"
                className={`${classes.sectionThreeBody} ${classes.blackText}`}
              >
                {t("edtech_p7")}
              </Typography>
              <Box mt={4}>
                <Button
                  variant="contained"
                  disableElevation
                  className={`${classes.signUpButton}`}
                  component={Link}
                  to="/pricing"
                >
                  {t("whyshells_button")}
                </Button>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Container>
    </WrapperContainer>
  );
}
