import { USER_LOGOUT } from "./UserReducer";

const initState = {
  order: [],
  registering: false,
  error: null
};

export const ORDER_CREATED = "ORDER_CREATED";
export const ORDER_CREATED_DONE = "ORDER_CREATED_DONE";
export const ORDER_CREATED_ERROR = "ORDER_CREATED_ERROR";

export const USER_BILLING_DONE = "USER_BILLING_DONE";
export const USER_BILLING_ERROR = "USER_BILLING_ERROR";

export const USER_LOCATION_DONE = "USER_LOCATION_DONE";
export const USER_LOCATION_ERROE = "USER_LOCATION_ERROE";

const cartReducer = (state = initState, action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return { ...initState };
    case ORDER_CREATED:
      return { ...state, registering: true, order: [], error: null };
    case ORDER_CREATED_DONE:
      return { ...state, registering: false, order: action.order, error: null };
    case USER_BILLING_DONE:
      return { ...state, order: [], error: null };
    case USER_LOCATION_DONE:
      return { ...state, order: [], error: null };
    case ORDER_CREATED_ERROR:
      return { ...state, registering: false, order: [], error: action.error };
    case USER_BILLING_ERROR:
      return { ...state, registering: false, order: [], error: action.error };
    case USER_LOCATION_ERROE:
      return { ...state, registering: false, order: [], error: action.error };
    default:
      return state;
  }
};

export default cartReducer;
