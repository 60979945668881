import React from "react";
import { useTranslation } from "react-i18next";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import { WrapperContainer } from "components/HOC";
import GridPaper from "./GridPaper";

import {
  oneClickImage,
  chooseImage,
  artistsImage,
  controlPCimage
} from "assets/img/manjaro";

import styles from "assets/jss/material-kit-react/views/manjaro";

const useStyles = makeStyles(styles);

export default function SectionTwo() {
  const { t } = useTranslation();
  const classes = useStyles();

  const paperData = [
    { image: oneClickImage, header: t("manjaro_h9"), body: t("manjaro_p7") },
    { image: chooseImage, header: t("manjaro_h10"), body: t("manjaro_p8") },
    { image: artistsImage, header: t("manjaro_h12"), body: t("manjaro_p10") },
    { image: controlPCimage, header: t("manjaro_h13"), body: t("manjaro_p11") }
  ];

  return (
    <WrapperContainer addMaxWidth={false} addlWclass={classes.powerSection}>
      <div>
        <Typography
          variant="h1"
          component="h2"
          className={`${classes.mainHeader} ${classes.colorGreen}`}
        >
          {t("manjaro_h8")}
        </Typography>
        <div className={classes.headerGreenLine}></div>
      </div>
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          {paperData.map((data, index) => (
            <GridPaper data={data} key={index} />
          ))}
        </Grid>
      </Container>
    </WrapperContainer>
  );
}
