import {
  defaultFont,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  title
} from "assets/jss/material-kit-react.js";

const typographyStyle = {
  defaultFontStyle: {
    ...defaultFont,
    fontSize: "14px"
  },
  defaultHeaderMargins: {
    marginTop: "20px",
    marginBottom: "10px"
  },
  quote: {
    padding: "10px 20px",
    margin: "0 0 20px",
    fontSize: "17.5px",
    borderLeft: "5px solid #eee"
  },
  quoteText: {
    margin: "0 0 10px",
    fontStyle: "italic"
  },
  quoteAuthor: {
    display: "block",
    fontSize: "80%",
    lineHeight: "1.42857143",
    color: "#777"
  },
  mutedText: {
    color: "#777"
  },
  primaryText: {
    color: primaryColor
  },
  titleContainer: {
    maxWidth: "330px",
    margin: "auto"
  },
  titleSection: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center",
    borderBottom: "solid 2px #72BA3E",
    fontSize: "32px"
  },
  titleUnderline: {
    marginBottom: "1rem",
    textDecoration: "none",
    textAlign: "center",
    borderBottom: "solid 2px #72BA3E"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "left",
    fontSize: "18px"
  },
  subtitle: {
    fontWeight: "bold",
    color: primaryColor,
    fontSize: "24px"
  },
  desc: {
    color: primaryColor
  },
  infoText: {
    color: infoColor
  },
  successText: {
    color: successColor
  },
  warningText: {
    color: warningColor
  },
  dangerText: {
    color: dangerColor
  },
  blueText: {
    color: "#0034ff",
    fontWeight: "bold",
    textTransform: "uppercase",
    fontSize: "18px"
  },
  smallText: {
    fontSize: "65%",
    fontWeight: "400",
    lineHeight: "1",
    color: "#777"
  },
  titleFeature: {
    color: "#72BA3E",
    fontWeight: "bold",
    textTransform: "uppercase",
    fontSize: "14px",
    margin: 0
  },
  feature: {
    color: "#3C4858",
    textAlign: "left"
  },
  ibmSectionTitle: {
    padding: "32px 0 24px 0",
    fontFamily: "IBM Plex Sans",
    fontSize: "52px",
    fontWeight: "bold",
    color: "#020202"
  }
};

export default typographyStyle;
