import { mainGreenColor, blackColor } from "assets/jss/material-kit-react";

const styles = theme => ({
  container: {
    maxWidth: "1015px",
    [theme.breakpoints.down("xl")]: {
      maxWidth: "910px"
    }
  },
  heroTitle: {
    fontFamily: "Open Sans, 'Roboto Black', Helvetica, Arial, sans-serif",
    fontWeight: 900,
    color: blackColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "42.5984px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "52px"
    }
  },
  heroCatchphrase: {
    fontSize: "22px",
    fontFamily: "Open Sans, Roboto",
    lineHeight: 1.4,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  heroDescription: {
    fontSize: "22px",
    fontFamily: "Open Sans, Roboto",
    color: blackColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: 1.4
    }
  },
  heroBox: {
    maxWidth: "475px",
    margin: "0 auto"
  },
  descriptionBox: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px",
      margin: "0 auto"
    }
  },
  plansFirstTitle: {
    fontFamily: "'Roboto Black', Helvetica, Arial, sans-serif",
    fontSize: "52px",
    fontWeight: "bold",
    [theme.breakpoints.down("xl")]: {
      fontSize: "42px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "34px"
    }
  },
  annotation: {
    fontSize: "36px",
    [theme.breakpoints.down("xl")]: {
      fontSize: "26px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "27px"
    }
  },
  planBlock: {
    border: `8px solid ${mainGreenColor}`,
    backgroundColor: "#fff",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, .15)"
  },
  planTitle: {
    backgroundColor: mainGreenColor,
    color: "#fff",
    fontSize: "48px",
    padding: "6px 12px",
    boxShadow: `0 0 0 8px ${mainGreenColor}`,
    [theme.breakpoints.down("xl")]: {
      fontSize: "38px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "36px"
    }
  },
  planTitleAlt: {
    backgroundColor: "#fff",
    color: mainGreenColor,
    fontSize: "48px",
    padding: "6px 12px",
    boxShadow: `0 0 0 8px ${mainGreenColor}`,
    [theme.breakpoints.down("xl")]: {
      fontSize: "38px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "36px"
    }
  },
  planDescription: {
    fontSize: "28px",
    lineHeight: "32px",
    [theme.breakpoints.down("xl")]: {
      fontSize: "22px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "21px"
    }
  },
  planPrice: {
    fontFamily: "'Roboto Black', Helvetica, Arial, sans-serif",
    fontSize: "96px",
    fontWeight: "bold",
    lineHeight: "112px",
    "& > span": {
      display: "inline-block",
      fontSize: "52px",
      transform: "translateY(-32px)",
      [theme.breakpoints.down("xl")]: {
        fontSize: "42px"
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "39px"
      },
      "&::before": {
        content: "''",
        position: "absolute",
        top: "72%",
        left: "0",
        width: "100%",
        height: "6px",
        backgroundColor: mainGreenColor,
        borderRadius: "3px"
      }
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "86px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "72px"
    }
  },
  planPerMonth: {
    fontSize: "32px",
    fontWeight: "bold",
    transform: "translateY(-32px)",
    [theme.breakpoints.down("xl")]: {
      fontSize: "22px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px"
    }
  },

  planButton: {
    fontSize: "26px",
    padding: "6px 20px",
    borderRadius: "40px",
    transform: "translateY(-24px)",
    [theme.breakpoints.down("xl")]: {
      fontSize: "16px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "19.5px"
    }
  },
  planButtonLabel: {
    textTransform: "none"
  },
  overflowHidden: {
    overflowX: "hidden"
  },
  regularPricingParagraph: {
    textAlign: "center",
    color: blackColor,
    fontSize: "22px",
    fontFamily: "Roboto, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: 1.4
    }
  },
  buildYourShellParagraph: {
    textAlign: "center",
    color: blackColor,
    fontSize: "24px",
    fontWeight: "bold",
    fontFamily: "Roboto, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: 1.4
    }
  },
  greenLink: {
    color: mainGreenColor,
    "&:hover": {
      textDecoration: "underline",
      color: mainGreenColor
    }
  },

  dealTextContainer: {
    maxWidth: "550px",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
      maxWidth: "320px"
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "36px"
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "64px"
    }
  },
  regularPlansRedirectContainer: {
    paddingBottom: "50px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "10px",
      paddingRight: "10px"
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "15px"
    }
  },
  addLineHeight: {
    lineHeight: 1.4
  },

  // For VS Code page
  vsCodeTextContainer: {
    maxWidth: "400px",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "10px",
      paddingRight: "10px"
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "36px",
      maxWidth: "320px"
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "64px"
    }
  },

  // for browser in the cloud page
  cloudTextContainer: {
    maxWidth: "400px",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "10px",
      paddingRight: "10px"
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "36px",
      maxWidth: "320px"
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "64px"
    }
  },

  // For DaaS page
  daasTextContainer: {
    maxWidth: "550px",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
      maxWidth: "320px"
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "36px"
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "64px"
    }
  },
  daasPricingHeader: {
    fontWeight: "900",
    fontFamily: "Open Sans, sans-serif",
    marginBottom: "25px",
    color: "#042E0F",
    lineHeight: 1.5,
    fontSize: "24px"
  },
  virtualBoxTextContainer: {
    maxWidth: "400px",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "10px",
      paddingRight: "10px"
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "36px",
      maxWidth: "320px"
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "64px"
    }
  },

  // For Disclaimer component
  disclaimerText: {
    fontSize: "12px",
    color: blackColor
  },
  disclaimerContainer: {
    maxWidth: "600px",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      padding: "0 15px 24px 15px"
    }
  },

  // For Slickdeals
  slickdealsTextContainer: {
    maxWidth: "650px",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
      maxWidth: "350px"
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "36px"
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "64px"
    }
  }
});

export default styles;
