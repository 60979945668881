import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import Badge from "@material-ui/core/Badge";
import CartModal from "./../CartModal/CartModal";
import IconButton from "@material-ui/core/IconButton";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

const StyledBadge = withStyles(theme => ({
  badge: {
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    color: "#fff"
  }
}))(Badge);

const CartButton = ({ cart }) => {
  const [open, setOpen] = useState(false);
  const [initCheck, setInitCheck] = useState(true);
  const [initCartSize, setInitCartSize] = useState(0);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!cart || cart.cartLoading) return;

    if (
      !initCheck &&
      initCartSize !== cart.cartItem.length &&
      cart.cartItem &&
      cart.cartItem.length > 0 &&
      pathname !== "/cart"
    )
      setOpen(true);

    if (cart.cartItem.length > initCartSize)
      setInitCartSize(cart.cartItem.length);

    setInitCheck(false);
  }, [cart]); // eslint-disable-line

  return (
    <>
      <IconButton aria-label="cart" onClick={() => setOpen(true)}>
        <StyledBadge
          badgeContent={cart.cartItem.length}
          color="primary"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
        >
          <ShoppingCartIcon />
        </StyledBadge>
      </IconButton>
      <CartModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

const mapStateToProps = state => ({ cart: state.cart });

export default connect(
  mapStateToProps,
  null
)(CartButton);
