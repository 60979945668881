import React from "react";
import { useTranslation, Trans } from "react-i18next";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/linuxMint";

import { WrapperContainer } from "components/HOC";
import { linuxMintDevices } from "assets/img/linux-mint";

const useStyles = makeStyles(styles);

export default function LinuxMintPricingMainHeader() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper className={classes.mainFeaturedPost}>
      <div className={classes.overlay} />
      <a href="#pricing" className={classes.mainRibbon}>
        <Trans
          i18nKey="lp_linuxquestionsorg_ribbon"
          components={{ underline: <span /> }}
        />
      </a>
      <WrapperContainer addMaxWidth={false} addlWclass={classes.morePadding}>
        <Grid container alignItems="center" justify="center" spacing={1}>
          <Hidden smUp>
            <Grid item xs={12} sm={6} style={{ position: "relative" }}>
              <img
                src={linuxMintDevices}
                alt="Linux Mint Devices"
                className={classes.linuxMintImage}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={5} md={5}>
            <div
              style={{
                maxWidth: "550px",
                margin: "0 auto",
                position: "relative"
              }}
            >
              <Typography
                variant="h1"
                component="h2"
                className={classes.mainHeader}
              >
                {t("linux_mint_pricing_title")}
              </Typography>
              <Box pt={6}>
                <Typography
                  variant="h1"
                  component="h2"
                  className={classes.subHeader}
                >
                  {t("linux_mint_subtitle")}
                </Typography>
              </Box>
            </div>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={7} md={7} style={{ position: "relative" }}>
              <img
                src={linuxMintDevices}
                alt="Linux Mint devices"
                style={{ width: "100%" }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </WrapperContainer>
    </Paper>
  );
}
