import React, {
  useEffect
  // useState
} from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import NewCartList from "./../Cart/NewCartList";
import ShellsButton from "./../CustomButtons/ShellButton";
import Typography from "@material-ui/core/Typography";

// actions
import { selectPlan } from "store/actions/PlanAction";
import { cartFetch } from "store/actions/CartAction";

const useStyles = makeStyles(theme => ({
  dialog: {
    width: "100%"
  },
  closeBtn: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1)
  }
}));

const CartModal = ({ cart, cartFetch, open, onClose, selectPlan }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const selectAndJoin = () => {
    let url;
    let cartItem = cart.cartItem[0];

    if (!cart || !cartItem || cartItem.length < 1) return;

    selectPlan(cartItem.data);
    onClose();

    //  if its a Shells plan, take user to Join page, otherwise take them to the Cart page
    if (
      cartItem.meta.shell_size &&
      (cartItem.data["Description.Type"].includes("plan") ||
        cartItem.data["Description.Type"] === "shells_b1m_g1mf")
    ) {
      if (!cartItem.meta.shell_os) {
        url = `/join`;
      } else {
        url = `/join?os=${cartItem.meta.shell_os}`;
      }
    } else {
      url = `/cart`;
    }

    history.push(url);
  };

  // fetching the cart of the current user's session
  useEffect(() => {
    cartFetch();
  }, [cartFetch]);

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      onBackdropClick={onClose}
      onClose={onClose}
      classes={{
        paper: classes.dialog
      }}
      disableScrollLock
    >
      <DialogTitle disableTypography>
        <Typography component="p" variant="h4">
          {t("cart_title")}
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        className={classes.closeBtn}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box p={matches ? 2 : 8}>
          <Grid container spacing={4} justify="center" alignItems="center">
            {!cart ? <CircularProgress /> : <NewCartList remove={true} />}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("continue_browsing")}</Button>
        <ShellsButton
          variant="contained"
          color="primary"
          disabled={!cart || !cart.cartItem || cart.cartItem.length < 1}
          onClick={() => {
            // When a user adds more than 1 item to their cart, clicking this button will take them to the cart page
            // So when a user tries to purchase a single Windows plan, 2 items are added to the cart
            // But we want them to be taken to the Join page
            if (
              (cart.cartItem.length === 2 &&
                cart.cartItem[0].meta["shell_os"] ===
                  "windows-server-2019-dc-desktop") ||
              cart.cartItem.length < 2
            ) {
              selectAndJoin();
            } else {
              onClose();
              history.push("/cart");
            }
          }}
        >
          {t("proceed_to_checkout")}
        </ShellsButton>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = dispatch => ({
  cartFetch: () => dispatch(cartFetch()),
  selectPlan: plan => dispatch(selectPlan(plan))
});

const mapStateToProps = state => ({ cart: state.cart });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartModal);
