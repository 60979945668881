import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import { fetchPlans } from "./../../store/actions/PlanAction";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    height: "48px",
    padding: "6px 24px",
    backgroundColor: "#72ba3e",
    borderRadius: "35px",
    color: "#fff",
    fontSize: "20px",
    overflow: "hidden",
    cursor: "pointer",
    transition:
      "height .5s cubic-bezier(.17,.67,.16,.99), transform .5s cubic-bezier(.17,.67,.16,.99), background-color .15s linear",
    "&:not(.open):hover": {
      backgroundColor: "#5ba331"
    },
    "&.open": {
      height: "96px",
      transform: "translate3d(0, -33%, 0)"
    },
    "&.open > :first-child": {
      transform: "translate3d(0, -100%, 0)",
      opacity: "0"
    },
    "&.open > :last-child": {
      top: "0",
      opacity: "1"
    },
    "&::before": {
      content: "''",
      position: "absolute",
      top: "calc(50% - 1px)",
      left: "50%",
      width: "0",
      height: "0",
      borderBottom: "1px solid #fff",
      opacity: "0",
      zIndex: "2"
    },
    "&.open::before": {
      width: "100%",
      opacity: "1",
      left: "0",
      transition:
        "left .5s cubic-bezier(.17,.67,.16,.99), width .5s cubic-bezier(.17,.67,.16,.99), opacity .25s linear"
    },
    "@media (max-width: 960px)": {
      fontSize: "16px"
    }
  },
  label: {
    padding: "6px 24px",
    fontWeight: "bold",
    transition:
      "transform .25s cubic-bezier(.17,.67,.16,.99), opacity .25s linear"
  },
  options: {
    position: "absolute",
    top: "100%",
    left: "0",
    width: "100%",
    opacity: "0",
    transition: "top .25s cubic-bezier(.17,.67,.16,.99), opacity .25s linear"
  },
  option: {
    display: "flex",
    width: "100%",
    padding: "12px 24px",
    justifyContent: "space-between",
    alignItems: "center",
    "& > *": {
      height: "100%"
    },
    "&:hover": {
      backgroundColor: "#5ba331"
    }
  },
  strike: {
    textDecoration: "line-through"
  }
}));

const CloudTweaksPlansButton = ({
  basicPlan,
  fetchPlans,
  plans,
  proPlan,
  onChanged
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  if (typeof window !== "undefined" && window.document) {
    // clickaway
    document.addEventListener("mouseup", () => {
      setOpen(false);
    });
  }

  const getRegularPlan = planType => {
    if (!plans || !plans.plans) return {};

    return plans.plans.find(
      p =>
        p["Basic.ServiceLifetime"] === "1y" &&
        p["Description.AuthorCode"] === planType
    );
  };

  useEffect(() => {
    fetchPlans();
  }, []); // eslint-disable-line

  return (
    <div
      className={`${classes.root} ${open ? "open" : ""}`}
      onClick={() => setOpen(!open)}
    >
      <div className={classes.label}>{t("lp_techradar_button")}</div>
      <div className={classes.options}>
        <div className={classes.option} onClick={() => onChanged(basicPlan)}>
          <div>
            <strong>{t("lp_techradar_shells_basic")}</strong>
          </div>
          {basicPlan && basicPlan["Price.Monthly"] && (
            <div>
              {getRegularPlan("basic") && (
                <i className={classes.strike}>
                  ({getRegularPlan("basic")["Price.Monthly"].display_short})
                </i>
              )}
              &nbsp;
              {basicPlan["Price.Monthly"].display_short}/mo
            </div>
          )}
        </div>
        <div className={classes.option} onClick={() => onChanged(proPlan)}>
          <div>
            <strong>{t("lp_techradar_shells_pro")}</strong>
          </div>
          {proPlan && proPlan["Price.Monthly"] && (
            <div>
              {getRegularPlan("pro") && (
                <i className={classes.strike}>
                  ({getRegularPlan("pro")["Price.Monthly"].display_short})
                </i>
              )}
              &nbsp;
              {proPlan["Price.Monthly"].display_short}/mo
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  fetchPlans: () => dispatch(fetchPlans())
});

const mapStateToProps = state => ({
  plans: state.plans // to be UNCOMMENTED after Elliot's refactor
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CloudTweaksPlansButton);
