import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  fadeIn: {
    opacity: 0,
    [theme.breakpoints.down("xs")]: {
      transition:
        "opacity .5s linear, transform .9s cubic-bezier(.17,.67,.16,.99)",
      "&.left": {
        transform: "translate3d(15px, 0, 0)",
        "webkit-transform": "translate3d(15px, 0, 0)"
      },
      "&.right": {
        transform: "translate3d(-15px, 0, 0)",
        "webkit-transform": "translate3d(-15px, 0, 0)"
      },
      "&.active": {
        opacity: 1,
        transform: "unset"
      }
    },
    [theme.breakpoints.up("sm")]: {
      transition:
        "opacity .5s linear, transform .9s cubic-bezier(.17,.67,.16,.99)",
      "&.left": {
        transform: "translate3d(75px, 0, 0)",
        "webkit-transform": "translate3d(75px, 0, 0)"
      },
      "&.right": {
        transform: "translate3d(-75px, 0, 0)",
        "webkit-transform": "translate3d(-75px, 0, 0)"
      },
      "&.active": {
        opacity: 1,
        transform: "unset"
      }
    },
    [theme.breakpoints.up("md")]: {
      transition:
        "opacity .5s linear, transform .9s cubic-bezier(.17,.67,.16,.99)",
      "&.left": {
        transform: "translate3d(100px, 0, 0)",
        "webkit-transform": "translate3d(100px, 0, 0)"
      },
      "&.right": {
        transform: "translate3d(-100px, 0, 0)",
        "webkit-transform": "translate3d(-100px, 0, 0)"
      },
      "&.active": {
        opacity: 1,
        transform: "unset"
      }
    }
  }
}));

export default function FadeIn({ direction = "left", children, ...etc }) {
  const ref = useRef(true);
  const classes = useStyles();

  useEffect(() => {
    if (!ref || !ref.current) return;

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          entry.target.classList.toggle("active", entry.isIntersecting);
        });
      },
      { threshold: [0, 1] }
    );

    observer.observe(ref.current);
  }, [ref]);

  return (
    <div ref={ref} className={[classes.fadeIn, direction].join(" ")} {...etc}>
      {children}
    </div>
  );
}
