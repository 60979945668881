import React from "react";
import { Get } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";

import EmailLayout from "./../../components/Email/EmailLayout";
import Text from "./../../components/Email/EmailText";
import InfoBox from "./../../components/Email/EmailInfoBox";

const Html = () => {
  const { t } = useTranslation();

  return (
    <EmailLayout>
      <table
        border="0"
        cellSpacing="0"
        cellPadding="0"
        width="100%"
        style={{ width: "100%" }}
      >
        <tbody>
          <tr>
            <td>
              <Text size="large" variant="highlight" align="center">
                {t("two_step_title_text")}
              </Text>
            </td>
          </tr>
          <tr>
            <td>
              <InfoBox>
                <Text align="center" size="large">
                  {Get("_code")}
                </Text>
              </InfoBox>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td align="center">
              <Text size="medium">{t("code_instructions")}</Text>
            </td>
          </tr>
        </tbody>
      </table>
    </EmailLayout>
  );
};

export default Html;
