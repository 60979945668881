import React from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import Button from "../../../components/CustomButtons/ShellLoadingButton";
import { connect } from "react-redux";
import Alert from "@material-ui/lab/Alert";

const WalletPaymentWrapper = ({ method, submitHandler, processing }) => {
  const { t } = useTranslation();

  const handleCheckout = () => {
    submitHandler("Wallet");
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Alert severity="info">
          {t("wallet_balance", {
            balance: method.fields.Wallet.Balance.display
          })}
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3} justify="center">
          <Grid item>
            <Button
              onClick={handleCheckout}
              variant="contained"
              color="primary"
              disabled={processing}
              loading={processing}
            >
              {t("wallet_checkout_btn")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    processing: state.defaultOrder.orderProcess
  };
};

export default connect(
  mapStateToProps,
  null
)(WalletPaymentWrapper);
