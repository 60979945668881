import { blackColor } from "assets/jss/material-kit-react";

const carouselStyle = theme => ({
  carousel: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative"
  },
  carouselItem: {
    width: "400px",
    [theme.breakpoints.down("sm")]: {
      width: "245px"
    }
  },
  leftArrow: {
    position: "absolute",
    top: "50%",
    left: "40px",
    zIndex: "10",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      left: "-5px"
    },
    [theme.breakpoints.up("sm")]: {
      left: "-35px"
    }
  },
  rightArrow: {
    position: "absolute",
    top: "50%",
    right: "32px",
    zIndex: "10",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      right: "-10px"
    },
    [theme.breakpoints.up("sm")]: {
      right: "-40px"
    }
  },
  slide: {
    opacity: 0,
    transitionDuration: "2s ease"
  },
  active: {
    opacity: 1,
    transitionDuration: "2s",
    transform: "scale(1.08)"
  },
  carouselHeader: {
    fontFamily: "Open Sans, sans-serif",
    color: blackColor,
    fontWeight: "900",
    fontSize: "24px",
    marginBottom: "15px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  carouselBody: {
    fontSize: "16px",
    color: blackColor,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  carouselImage: {
    margin: "0 auto",
    padding: "15px 0 15px 0",
    [theme.breakpoints.down("sm")]: {
      width: "150px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "200px"
    }
  }
});

export default carouselStyle;
