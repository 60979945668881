import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/material-kit-react/views/edTech";

const useStyles = makeStyles(styles);

export default function Header({ details }) {
  const { header, description, image } = details;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box pb={4} className={classes.centeredText}>
      <Paper className={classes.mainFeaturedPost}>
        <div className={classes.overlay} />
        <div className={classes.mainFeaturedPostContent}>
          <Grid container justify="center" alignItems="center" spacing={2}>
            <Hidden smUp>
              <Grid item xs={12} sm={6}>
                <img src={image} alt="" className={classes.heroImage} />
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={6}>
              <div className={classes.headerContainer_a}>
                <Typography
                  component="h1"
                  variant="h2"
                  className={classes.heroHeader}
                >
                  <p>{header}</p>
                </Typography>
                <Box pt={4} className={classes.subHeaderContainer}>
                  <Typography
                    component="h2"
                    variant="body1"
                    className={classes.subHeader}
                  >
                    {description}
                  </Typography>
                </Box>

                <Box pt={4} className={classes.inputAndBtn}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.beautifulJoinButton}
                    component={Link}
                    to={"/pricing"}
                  >
                    {t("whyshells_button")}
                  </Button>
                  <Box pt={1}>
                    <Typography
                      component="h2"
                      variant="body1"
                      className={classes.moneyBackText}
                    >
                      {t("lp_subheader_7day")}
                    </Typography>
                  </Box>
                </Box>
              </div>
            </Grid>
            <Hidden xsDown>
              <Grid item xs={12} sm={6}>
                <img src={image} alt="" className={classes.heroImage} />
              </Grid>
            </Hidden>
          </Grid>
        </div>
      </Paper>
    </Box>
  );
}
