import {
  blackColor,
  secondaryGreenColor,
  whiteColor
} from "assets/jss/material-kit-react";

const styles = theme => ({
  heroHeader: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: "900",
    color: blackColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "42.5984px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "52px"
    }
  },
  subHeader: {
    fontSize: "22px",
    fontFamily: "Open Sans, sans-serif",
    color: blackColor,
    lineHeight: 1.4,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  heroHeaderContainer: {
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "375px"
    }
  },
  heroTextContainer: {
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "300px"
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "550px"
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "650px"
    }
  },
  mainHeader: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: "900",
    textAlign: "center",
    color: blackColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "32px"
    }
  },
  osHeader: {
    fontFamily: "Open Sans, sans-serif",
    color: blackColor,
    fontWeight: "900",
    fontSize: "24px",
    marginBottom: "15px"
  },
  osData: {
    fontSize: "16px",
    color: blackColor
  },
  beautifulJoinButton: {
    backgroundColor: `linear-gradient(45deg, ${secondaryGreenColor} 0%, #75cb42 100%)`,
    borderRadius: "35px",
    color: whiteColor,
    fontSize: "22px",
    textAlign: "center",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover, &:focus": {
      boxShadow: `0 0 0 2px ${secondaryGreenColor} inset`,
      background: whiteColor,
      color: secondaryGreenColor
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "250px",
      padding: "6px 14px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "460px"
    },
    [theme.breakpoints.up("md")]: {
      width: "560px"
    }
  },
  osImage: {
    width: "100px",
    [theme.breakpoints.down("xs")]: {
      width: "75px"
    }
  },
  typesList: {
    listStyle: "none",
    textAlign: "center",
    padding: "0px"
  },
  shellsOSParent: {
    marginBottom: "25px"
  },
  mobileGridItem: {
    padding: "24px 24px 8px 24px !important"
  },
  wrapperClass: {
    [theme.breakpoints.down("sm")]: {
      padding: "25px 15px !important"
    },
    [theme.breakpoints.up("lg")]: {
      padding: "60px 15px"
    }
  },
  listItem: {
    marginBottom: "3px"
  },
  shellsOSGridContainer: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "400px",
      margin: "0 auto"
    }
  },
  moneyBackText: {
    fontSize: "22px",
    fontFamily: "Open Sans",
    color: blackColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  wrapperClassS2: {
    [theme.breakpoints.down("sm")]: {
      padding: "25px 15px 60px 15px !important"
    },
    [theme.breakpoints.up("lg")]: {
      padding: "60px 15px"
    }
  }
});

export default styles;
