import React from "react";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import ShellsSpec from "./ShellsSpec";
import ShellsButton from "../CustomButtons/ShellButton";

import useSpecialPlansCarouselStyles from "./SpecialPlansCarousel.style";

export default function SpecialPlansCarouselItem({ data }) {
  const { t } = useTranslation();
  const classes = useSpecialPlansCarouselStyles();
  const {
    p,
    i,
    handleOpen,
    setModalText,
    currentActiveIdx,
    setCurrentActiveIdx,
    setTouchTimestamp,
    onTouchEnd,
    onPlanSelected,
    isMobile,
    plansToShow
  } = data;

  return (
    <Grid
      key={`plan-${i}`}
      item
      xs={12 / plansToShow.length}
      className={[
        classes.plan,
        currentActiveIdx === i ? "active" : "",
        "js-plan"
      ].join(" ")}
      onTouchStart={() => {
        if (isMobile) {
          setTouchTimestamp(Date.now());
        }
      }}
      onTouchEnd={e => {
        if (isMobile) {
          onTouchEnd(e, p.plan);
        }
      }}
      onMouseEnter={() => {
        if (!isMobile) {
          setTouchTimestamp(Date.now());
        }
      }}
      onMouseLeave={e => {
        if (!isMobile) {
          onTouchEnd(e, p.plan);
        }
      }}
    >
      <Box
        className={classes.planInner}
        onMouseEnter={() => setCurrentActiveIdx(i)}
      >
        <Grid
          container
          spacing={2}
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item style={{ width: "100%" }}>
            <Typography
              variant="h5"
              component="h4"
              align="center"
              paragraph
              className={classes.title}
            >
              {p["Basic.Family_Name"]}
            </Typography>
            <Box px={1}>
              {p.disclaimer ? (
                <Typography
                  align="center"
                  className={`${classes.greenText} ${classes.italic}`}
                >
                  {t(p.disclaimer.key, { number: p.disclaimer.number })}
                </Typography>
              ) : (
                <Box mb={5} />
              )}
              <Typography className={classes.price} align="center">
                $
                {
                  Number(
                    p["Price.Monthly"]
                      ? p["Price.Monthly"].value_disp
                      : p["Price.Price"].value_disp
                  )
                    .toString()
                    .split(".")[0]
                }
                <sup>
                  {
                    Number(
                      p["Price.Monthly"]
                        ? p["Price.Monthly"].value_disp
                        : p["Price.Price"].value_disp
                    )
                      .toFixed(2)
                      .toString()
                      .split(".")[1]
                  }
                </sup>
              </Typography>
              <Typography align="center" className={classes.monthText}>
                {`/${t("new_hp_month")}`}
              </Typography>
              {p.discount && (
                <Typography
                  component="div"
                  align="center"
                  className={classes.discountPrice}
                >
                  {p.discount}
                </Typography>
              )}
              <Box
                py={3}
                className={
                  p["Description.AuthorCode"] === "pro"
                    ? classes.proDescriptionContainerClass
                    : classes.descriptionContainerClass
                }
              >
                <Typography align="center" className={classes.italic}>
                  {p["Description.Short"]}
                </Typography>
              </Box>
              <Box pb={1}>
                <ShellsSpec
                  specName={
                    Math.ceil(p["Shell.Size"] * 0.25) > 1
                      ? t("comparison_table_title_processors")
                      : t("comparison_table_title_processor")
                  }
                  specCount={Math.ceil(p["Shell.Size"] * 0.25)}
                  specModalText={t("comparison_table_modal_processor")}
                  handleOpen={handleOpen}
                  setModalText={setModalText}
                />
                <ShellsSpec
                  specName={t("comparison_table_title_storage")}
                  specCount={Math.ceil(p["Shell.Size"] * 10) + " GB"}
                  specModalText={t("comparison_table_modal_storage")}
                  handleOpen={handleOpen}
                  setModalText={setModalText}
                />
                <ShellsSpec
                  specName={t("comparison_table_title_memory")}
                  specCount={Math.floor((p["Shell.Size"] * 512) / 1000) + " GB"}
                  specModalText={t("comparison_table_modal_memory")}
                  handleOpen={handleOpen}
                  setModalText={setModalText}
                />
              </Box>
              <Typography
                align="center"
                className={`${classes.greenText} ${classes.italic}`}
              >
                {!p["Shell.Timer_Allowance"]
                  ? t(p.usageLimit)
                  : `${p["Shell.Timer_Allowance"]} ${t(
                      "remaining_time_seconds"
                    )}`}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <ShellsButton
              variant="contained"
              color="primary"
              onClick={() => onPlanSelected(p["Description.AuthorCode"])}
            >
              {t("select_this_plan")}
            </ShellsButton>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
