const initState = {
  loading: false,
  operatingSystems: []
};

export const OS_FETCH = "OS_FETCH";
export const OS_FETCH_DONE = "OS_FETCH_DONE";

const OperatingSystemsReducer = (state = initState, action) => {
  switch (action.type) {
    case OS_FETCH:
      return { ...state, loading: true, operatingSystems: [] };
    case OS_FETCH_DONE:
      return {
        ...state,
        loading: false,
        operatingSystems: action.operatingSystems
      };
    default:
      return state;
  }
};

export default OperatingSystemsReducer;
