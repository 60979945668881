import {
  SOFTWARE_FETCH_SHELL,
  SOFTWARE_FETCH_SHELL_DONE
} from "../reducers/SoftwareReducer";
import {
  catchRedirectException,
  handleError
} from "../../components/utils/apiErrorHandler";
import { rest } from "@karpeleslab/klbfw";

export const fetchShellDownloads = () => {
  return (dispatch, getState) => {
    dispatch({ type: SOFTWARE_FETCH_SHELL });
    return rest("Catalog/Software/@shells")
      .then(catchRedirectException)
      .then(d => {
        dispatch({ type: SOFTWARE_FETCH_SHELL_DONE, shellDownload: d.data });

        return d.data;
      })
      .catch(err => {
        handleError(getState, dispatch, err);
      });
  };
};
