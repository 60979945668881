import {
  headerImageMobile,
  headerImageTablet,
  headerImageDesktop,
  headerBottomMobile,
  headerBottomTablet,
  headerBottomDesktop
} from "assets/img/security";

import {
  mainGreenColor,
  whiteColor,
  lightBlueColor,
  darkBlueColor
} from "assets/jss/material-kit-react";

const styles = theme => ({
  body: {
    boxSizing: "border-box"
  },
  mainFeaturedPost: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${headerImageMobile})`
    },
    [theme.breakpoints.down("lg")]: {
      backgroundImage: `url(${headerImageTablet})`
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${headerImageDesktop})`
    }
  },
  bottomFeaturedPost: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${headerBottomMobile})`
    },
    [theme.breakpoints.down("lg")]: {
      backgroundImage: `url(${headerBottomTablet})`
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${headerBottomDesktop})`
    }
  },
  mainFeaturedPostContent: {
    textAlign: "center",
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6)
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0
    }
  },
  overlayTop: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.6)"
  },
  overlayBottom: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.1)"
  },
  lightBlueBackground: {
    backgroundColor: lightBlueColor
  },
  darkBlueBackground: {
    background: darkBlueColor
  },
  mainHeaderTop: {
    fontSize: "52px",
    fontWeight: "bold",
    fontFamily: "Open Sans, IBM Plex Sans",
    color: whiteColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
      marginBottom: "0px"
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "10px"
    }
  },
  mainHeader: {
    fontSize: "52px",
    fontWeight: "bold",
    fontFamily: "Open Sans, IBM Plex Sans",
    marginBottom: "25px",
    color: whiteColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    }
  },
  subHeader: {
    fontFamily: "Roboto, IBM Plex Sans",
    fontWeight: "bold",
    marginBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "26px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "28px"
    }
  },
  bottomSubHeader: {
    fontFamily: "Roboto, IBM Plex Sans",
    marginBottom: "25px",
    lineHeight: 1.45,
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "26px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "28px"
    }
  },
  description: {
    fontFamily: "Roboto, IBM Plex Sans",
    fontSize: "20px",
    lineHeight: 2
  },
  bottomDescription: {
    fontFamily: "Roboto, IBM Plex Sans",
    fontSize: "20px",
    lineHeight: 1.25,
    marginBottom: "25px"
  },
  header: {
    fontSize: "30px",
    marginBottom: "45px",
    "&.MuiTypography-h1": {
      fontFamily: "Open Sans",
      fontWeight: "bold"
    }
  },
  cardHeader: {
    fontSize: "30px",
    marginBottom: "15px",
    "&.MuiTypography-h1": {
      fontFamily: "Open Sans",
      fontWeight: "bold"
    }
  },
  bottomHeader: {
    fontFamily: "Open Sans, IBM Plex Sans",
    fontWeight: "bold",
    fontSize: "35px",
    marginBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px"
    }
  },
  textContainer: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "left"
    }
  },
  sideBySide: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  imageOne: {
    [theme.breakpoints.down("sm")]: {
      width: "250px",
      height: "250px",
      margin: "0 auto"
    },
    [theme.breakpoints.up("sm")]: {
      width: "300px",
      height: "300px",
      margin: "0 auto"
    },
    [theme.breakpoints.up("md")]: {
      width: "400px",
      height: "400px",
      margin: "0 auto"
    }
  },
  mRightmBottom: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "25px"
    },
    [theme.breakpoints.up("md")]: {
      marginRight: "25px"
    }
  },
  marginLeft: {
    marginLeft: "25px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0
    }
  },
  marginTop: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "-60px"
    }
  },
  marginBottom: {
    marginBottom: "25px"
  },
  cardImage: {
    margin: "0 auto",
    padding: "15px",
    [theme.breakpoints.up("xs")]: {
      width: "200px"
    }
  },
  cardText: {
    fontSize: "20px"
  },
  cardTextContainer: {
    [theme.breakpoints.down("sm")]: {
      minHeight: 0
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "190px"
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "170px"
    }
  },
  gridCard: {
    backgroundColor: lightBlueColor
  },
  greenLine: {
    border: `2px solid ${mainGreenColor}`,
    margin: "45px auto 45px auto",
    [theme.breakpoints.down("sm")]: {
      width: "250px"
    },
    [theme.breakpoints.up("md")]: {
      width: "500px"
    },
    [theme.breakpoints.up("lg")]: {
      width: "700px"
    }
  },
  greenLineTop: {
    border: `2px solid ${mainGreenColor}`,
    margin: "0 0 45px 0"
  },
  greenLineBottom: {
    border: `2px solid ${mainGreenColor}`,
    margin: "45px 0 0 0"
  },
  flexContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    }
  },
  flexEnd: {
    alignSelf: "flex-end"
  },
  boldAndGreen: {
    color: mainGreenColor,
    fontWeight: "bold"
  },
  communityLink: {
    color: mainGreenColor,
    fontWeight: "bold",
    "&:hover,&:visited": {
      color: mainGreenColor,
      textDecoration: "underline"
    },
    "&:focus": {
      textDecoration: "underline"
    }
  },
  green: {
    color: mainGreenColor
  },
  whiteFont: {
    color: whiteColor
  },
  imageText: {
    fontFamily: "Roboto, IBM Plex Sans",
    fontSize: "20px",
    lineHeight: 2
  },
  whiteOutline: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    padding: "25px 25px 0 25px",
    borderRadius: "16px",
    border: "2px solid #FFFFFF66",
    backdropFilter: "blur(4px)",
    opacity: 1,
    [theme.breakpoints.down("sm")]: {
      padding: "25px 20px 0 20px"
    }
  }
});

export default styles;
