import React from "react";
import { useTranslation, Trans } from "react-i18next";

import { img1 } from "assets/img/whyshells";
import SharedLinuxPage from "../SharedLinuxPage/SharedLinuxPage";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/streetPromote";

const useStyles = makeStyles(styles);

export default function StreetPromote() {
  const { t } = useTranslation();
  const classes = useStyles();

  const headerDetails = {
    header: (
      <Trans
        i18nKey="lp_street_promote_hero"
        components={{
          newLine: <br />
        }}
      />
    ),
    image: img1,
    show7Day: true
  };

  return (
    <>
      <SharedLinuxPage
        title="Shells Virtual Desktop"
        headerDetails={headerDetails}
        showNormalPlans={true}
        buttonText={t("lp_street_promote_button")}
        buttonUrl="#pricing"
        specialButtonClass={classes.streetSignUpButton}
      />
    </>
  );
}
