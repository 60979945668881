import React from "react";
import { useTranslation } from "react-i18next";
import { HashLink as Link } from "react-router-hash-link";

import { WrapperContainer } from "components/HOC";
import GridItem from "./GridItem";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/whyShells";

const useStyles = makeStyles(styles);

export default function SectionSix({ details }) {
  const { header, addlWclass, card_a, card_b, card_c } = details;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <WrapperContainer addMaxWidth={false} addlWclass={addlWclass}>
      <div className={classes.sectionSixHeaderContainer}>
        <Typography variant="h1" className={classes.sectionOneHeader}>
          {header}
        </Typography>
      </div>
      <Grid container spacing={3} justify="center" alignItems="center">
        <GridItem details={card_a} />
        <GridItem details={card_b} />
        <GridItem details={card_c} />
      </Grid>

      <Button
        variant="contained"
        disableElevation
        className={`${classes.signUpButton} ${classes.moreButtonStyle}`}
        component={Link}
        to="/pricing"
      >
        {t("whyshells_button")}
      </Button>
    </WrapperContainer>
  );
}
