export const img2 = require("./Multi_Device.svg");
export const bgMobile = require("./hero-background2-414.svg");
export const bgTablet = require("./hero-background2-1024.svg");
export const bgDesktop = require("./hero-background.png");
export const headerImage = require("./Shells_All_Devices_Education.png");
export const cloudImage = require("./Powered_By_Cloud.svg");
export const budgetImage = require("./budget.svg");
export const safeImage = require("./safe.svg");
export const blurBg = require("./hero-bg-blur.png");
export const crossPlat = require("./Shells_Happy_Shells_Guy.png");
export const virusResistant = require("./virus_resistant.png");
export const autoSave = require("./auto_save.png");
export const professional = require("./professional.png");
export const desktop = require("./Shells_Great_Community.png");
