import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

import TableRows from "./TableRows";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/landingPage20210323";

const useStyles = makeStyles(styles);

export default function ComparisonTable({ tableHead, tableBody }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { pageName } = useParams();

  return (
    <>
      <Box my={4}>
        <Typography
          gutterBottom
          variant="h5"
          component="h3"
          className={classes.sectionHeader}
          align="center"
        >
          {t("lp_vms_comparison_title")}
        </Typography>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className={classes.posRelative}>
              {tableHead.map((header, i) => (
                <TableCell
                  key={i}
                  className={
                    i < 1
                      ? `${classes.stickyHeader} ${classes.tableHeader}`
                      : classes.tableHeader
                  }
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {tableBody.map((row, i) => (
              <TableRow key={i}>
                <TableCell
                  component="th"
                  scope="row"
                  className={`${classes.tableCellTitle} ${classes.sticky}`}
                >
                  {row.title}
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  {row.s}
                </TableCell>
                <TableRows row={row} pageName={pageName} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
