import React from "react";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/giveaway";

const useStyles = makeStyles(styles);

export default function RedditContestRules() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
        <div className={classes.descriptionContainer}>
          <div className={classes.marginBottom}>
            <Typography
              variant="body1"
              className={`${classes.description} ${classes.linkTitle}`}
            >
              {t("giveaway_reddit_box1_item1")}
            </Typography>
            <a
              href="mailto:support@support.shells.com"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              mailto:support@shells.com
            </a>
          </div>
          <Typography variant="body1" className={classes.description}>
            {t("giveaway_reddit_box1_item2")}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {t("giveaway_reddit_box1_item3")}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
        <div className={classes.descriptionContainer}>
          <Typography
            variant="body1"
            className={`${classes.description} ${classes.marginBottom}`}
          >
            {t("giveaway_reddit_box2_item1")}
          </Typography>
          <Typography
            variant="body1"
            className={`${classes.description} ${classes.smallMarginBottom}`}
          >
            {t("giveaway_reddit_box2_item2")}
          </Typography>
          <Typography
            variant="body1"
            className={`${classes.description} ${classes.smallMarginBottom}`}
          >
            {t("giveaway_reddit_box2_item3")}
          </Typography>
          <Typography
            variant="body1"
            className={`${classes.description} ${classes.smallMarginBottom}`}
          >
            {t("giveaway_reddit_box2_item4")}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
        <div className={classes.descriptionContainer}>
          <Typography
            variant="body1"
            className={`${classes.description} ${classes.smallMarginBottom}`}
          >
            {t("giveaway_reddit_box3_item1")}
          </Typography>
          <Typography
            variant="body1"
            className={`${classes.description} ${classes.smallMarginBottom}`}
          >
            {t("giveaway_reddit_box3_item2")}
          </Typography>
          <Typography
            variant="body1"
            className={`${classes.description} ${classes.smallMarginBottom}`}
          >
            {t("giveaway_reddit_box3_item3")}
          </Typography>
        </div>
      </Grid>
    </>
  );
}
