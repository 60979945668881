import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/debian";

import { WrapperContainer } from "components/HOC";
import { arrowLeft, arrowRight, community } from "assets/img/debian";

const useStyles = makeStyles(styles);

export default function SectionThree() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <WrapperContainer addMaxWidth={true}>
      <div className={classes.s3Container}>
        <Grid container spacing={1} alignItems="center" justify="center">
          <Hidden xsDown>
            <Grid item sm={3} md={3}>
              <img
                src={arrowLeft}
                alt="left arrow"
                className={classes.arrowImage}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6} md={6}>
            <img
              src={community}
              alt="community"
              className={classes.sectionOneImage}
            />
          </Grid>
          <Hidden xsDown>
            <Grid item sm={3} md={3}>
              <img
                src={arrowRight}
                alt="right arrow"
                className={classes.arrowImage}
              />
            </Grid>
          </Hidden>
        </Grid>

        <Typography
          variant="h4"
          component="h3"
          className={classes.sectionOneHeader}
        >
          {t("debian_s3_title")}
        </Typography>
        <div className={classes.greenLine} />
        <Typography
          variant="body1"
          className={`${classes.sectionOneBody} ${classes.alignLeft}`}
        >
          {t("debian_s3_body")}
        </Typography>

        <Box pt={4}>
          <Button
            color="primary"
            variant="contained"
            className={classes.sectionThreeJoinButton}
            component={Link}
            to={"/pricing"}
          >
            {t("lp_get_shell_button")}
          </Button>
        </Box>
      </div>
    </WrapperContainer>
  );
}
