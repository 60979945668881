import { mainGreenColor, blackColor } from "assets/jss/material-kit-react";
import {
  bgImageDesktop,
  bgImageTablet,
  bgImageMobile
} from "assets/img/homepage20210312";

const styles = theme => ({
  imageSectionOne: {
    width: "200px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px"
    }
  },
  sectionOneHeader: {
    fontFamily: "Open Sans",
    color: blackColor,
    fontWeight: "900",
    fontSize: "24px",
    marginBottom: "15px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  s1ImageContainer: {
    minHeight: "225px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  sectionThreeStepsHeader: {
    fontWeight: "bold",
    fontSize: "35px",
    marginBottom: "15px",
    color: blackColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    }
  },
  descriptionContainer: {
    [theme.breakpoints.down("sm")]: {
      minHeight: 0
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "220px"
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "315px"
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "260px"
    }
  },
  descriptionSubContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start"
  },
  dash: {
    marginRight: "5px"
  },
  sectionOneBody: {
    fontSize: "16px",
    color: blackColor
  },
  learnMoreLink: {
    fontSize: "16px",
    color: mainGreenColor,
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: mainGreenColor,
      textDecoration: "underline"
    }
  },
  heroHeader: {
    fontFamily: "Open Sans",
    fontWeight: "900",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "42.5984px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "52px"
    }
  },
  heroImage: {
    [theme.breakpoints.down("sm")]: {
      width: "235px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "300px"
    },
    [theme.breakpoints.up("md")]: {
      width: "400px"
    },
    [theme.breakpoints.up("lg")]: {
      width: "650px"
    },
    "@media (min-width: 1280px) and (max-width: 1365px)": {
      width: "575px"
    }
  },
  inputAndBtn: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  mainHeader: {
    fontFamily: "Open Sans",
    fontSize: "32px",
    fontWeight: "900",
    textAlign: "center",
    color: blackColor
  },
  sectionThreeHeader: {
    fontSize: "20px",
    color: mainGreenColor,
    fontWeight: "bold",
    margin: "15px 0 15px 0"
  },
  sectionThreeBody: {
    fontSize: "20px",
    color: blackColor
  },
  gridImage: {
    width: "275px",
    [theme.breakpoints.down("sm")]: {
      width: "200px"
    }
  },
  gridItemSectionThree: {
    textAlign: "center"
  },
  sectionThreeHeaderContainer: {
    maxWidth: "250px",
    margin: "0 auto"
  },
  greenFont: {
    color: mainGreenColor
  },
  greenLine: {
    color: mainGreenColor,
    width: "100%",
    border: `4px solid ${mainGreenColor}`,
    borderRadius: "5px",
    marginBottom: "20px"
  },
  textLink: {
    color: mainGreenColor,
    fontWeight: "bold",
    "&:hover, &:focus": {
      textDecoration: "underline",
      color: mainGreenColor
    }
  },
  marginBottom: {
    marginBottom: "40px"
  },
  watchVideoButton: {
    borderRadius: "35px",
    fontSize: "22px",
    textAlign: "center",
    "& .MuiButton-label": {
      textTransform: "none",
      color: "#fff"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "240px",
      padding: "6px 14px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "24px"
    },
    [theme.breakpoints.up("md")]: {
      width: "375px"
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "12px",
      width: "200px"
    }
  },
  beautifulJoinButton: {
    width: "375px",
    backgroundColor: "linear-gradient(45deg, #5ba331 0%, #75cb42 100%)",
    borderRadius: "35px",
    color: "#fff",
    fontSize: "22px",
    textAlign: "center",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover, &:focus": {
      boxShadow: "0 0 0 2px #5ba331 inset",
      background: "#fff",
      color: "#5ba331"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "240px",
      padding: "6px 14px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      width: "375px"
    }
  },
  sectionOneJoinButton: {
    width: "300px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "6px 24px",
    border: `3px solid ${mainGreenColor}`,
    backgroundColor: "#ffffff",
    borderRadius: "35px",
    color: mainGreenColor,
    fontSize: "22px",
    textAlign: "center",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover, &:focus": {
      background: mainGreenColor,
      color: "#ffffff"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      width: "250px"
    }
  },
  reviewsJoinButton: {
    width: "300px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "6px 24px",
    border: `3px solid ${mainGreenColor}`,
    backgroundColor: mainGreenColor,
    borderRadius: "35px",
    color: "#ffffff",
    fontSize: "22px",
    textAlign: "center",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover, &:focus": {
      background: "#ffffff",
      color: mainGreenColor
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      width: "250px"
    }
  },
  centeredText: {
    textAlign: "center",
    overflow: "hidden"
  },
  mainFeaturedPost: {
    position: "relative",
    color: theme.palette.common.white,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    padding: "60px 15px",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${bgImageMobile})`
    },
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${bgImageTablet})`
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${bgImageDesktop})`
    }
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.1)",
    backdropFilter: "blur(4px)"
  },
  mainFeaturedPostContent: {
    textAlign: "center",
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6)
    }
  },
  subHeader: {
    fontSize: "22px",
    fontFamily: "Open Sans",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  moneyBackText: {
    fontSize: "22px",
    fontFamily: "Open Sans",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  subHeaderContainer: {
    maxWidth: "550px",
    lineHeight: 5
  },
  downloadContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center"
    }
  },
  downloadImage: {
    width: "181px",
    height: "55px",
    margin: "0 15px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "15px"
    }
  },
  finalDownloadImage: {
    width: "181px",
    height: "55px",
    margin: "0 15px"
  }
});

export default styles;
