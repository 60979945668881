import React from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/daas";

const useStyles = makeStyles(styles);

export default function SectionOneGridItem({ data }) {
  const classes = useStyles();
  const { imageSrc, headerText, bodyText } = data;

  return (
    <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
      <img src={imageSrc} alt="Desktops" className={classes.affiliatesImage} />
      <div className={classes.daasTextContainer}>
        <Typography variant="h3" className={classes.headers} align="center">
          {headerText}
        </Typography>
      </div>
      <div className={classes.descriptionContainer}>
        <Typography variant="body1" className={classes.description}>
          {bodyText}
        </Typography>
      </div>
    </Grid>
  );
}
