import styledComponents from "styled-components";
import { styled } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const Div = styledComponents.div``;
const Form = styledComponents.form``;
const H3 = styledComponents.h3``;

export const UserInfoWrapper = styled(Div)(() => {
  return {
    textAlign: "center"
  };
});

export const LoginWrapper = styled(Div)(style => {
  return {
    marginTop: style.theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  };
});

export const OAuth2Wrapper = styled(Div)(style => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > *": {
      margin: style.theme.spacing(1)
    }
  };
});

export const OauthBtn = styled(Div)(style => {
  return {
    borderRadius: "50%",
    width: style.theme.spacing(7),
    height: style.theme.spacing(7),
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "scroll",
    backgroundSize: "50%",
    overflow: "hidden",
    textIndent: "-1000px",
    cursor: style.button ? "pointer" : "inherit",
    backgroundColor: style.backgroundColor,
    backgroundImage: `url(${style.logo})`,
    "&:hover": {
      opacity: style.button ? 0.4 : 1
    },
    "&.twitter": {
      backgroundSize: "60%"
    },
    "&.line": {
      backgroundSize: "70%"
    },
    "&.facebook": {
      backgroundSize: "30%"
    }
  };
});

export const LoginForm = styled(Form)(() => {
  return {
    width: "90%",
    maxWidth: "600px",
    margin: "0 auto"
  };
});

export const LoginButton = styled(Button)(style => {
  return {
    margin: style.theme.spacing(3, 0, 2)
  };
});

export const FormTitle = styled(H3)(() => {
  return {
    fontFamily: "IBM Plex Sans",
    fontSize: "30px",
    fontWeight: "Bold"
  };
});
