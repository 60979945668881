import React, { useEffect, useRef, useState } from "react";

import HPCard from "./HPCard";
import { isMobileTablet } from "components/utils/mobileTablet";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/homepage20210414.style.js";

const useStyles = makeStyles(styles);

export default function HPCardsRow({ title, cards }) {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width: 1280px)");
  const [isMobile] = useState(isMobileTablet());
  const [itvl, setItvl] = useState(null);
  const [activeCardIdx, setActiveCardIdx] = useState(0);
  const [touchPos, setTouchPos] = useState({ x: 0, y: 0 });
  const sliderWrapperRef = useRef(null);

  const slide = () => {
    if (matches) {
      setItvl(
        setTimeout(() => {
          if (matches)
            setActiveCardIdx(i => (i + 1 >= cards.length ? 0 : i + 1));
        }, 5000)
      );
    }
  };

  const onTouchEnd = e => {
    // const end = Date.now();
    const { x, y } = touchPos;
    if (!matches || !sliderWrapperRef || !sliderWrapperRef.current) return;

    if (
      e.changedTouches[0].clientX < x &&
      (Math.abs(e.changedTouches[0].clientY - y) < 50 ||
        Math.abs(y - e.changedTouches[0].clientY) < 50)
    ) {
      // swipe right
      setActiveCardIdx(
        activeCardIdx < cards.length - 1 ? activeCardIdx + 1 : 0
      );
    }
    // swipe left
    else if (
      e.changedTouches[0].clientX > x &&
      (Math.abs(e.changedTouches[0].clientY - y) < 50 ||
        Math.abs(y - e.changedTouches[0].clientY) < 50)
    ) {
      setActiveCardIdx((activeCardIdx > 0 ? activeCardIdx : cards.length) - 1);
    }
  };

  useEffect(() => {
    clearTimeout(itvl);
    if (sliderWrapperRef && sliderWrapperRef.current)
      sliderWrapperRef.current.setAttribute("style", "");

    slide();
  }, [matches]); // eslint-disable-line

  useEffect(() => {
    clearTimeout(itvl);
    if (!sliderWrapperRef || !sliderWrapperRef.current) return;

    slide();
    sliderWrapperRef.current.setAttribute(
      "style",
      `margin-left: -${(sliderWrapperRef.current.offsetWidth / cards.length) *
        activeCardIdx}px`
    );
  }, [activeCardIdx]); //eslint-disable-line

  return (
    <Box py={8} className={classes.hideOverflow}>
      <Box pb={5} px={2}>
        <Typography
          component="h2"
          variant="h3"
          align="center"
          className={classes.featuresHeader}
        >
          {title}
        </Typography>
      </Box>

      <Container className={classes.cardsWrapper}>
        <Container ref={sliderWrapperRef} className={classes.cardsContainer}>
          <Grid container spacing={3} justify="center" alignItems="stretch">
            {cards.map((card, i) => (
              <HPCard
                data={card}
                key={i}
                className={[
                  classes.cardItem,
                  i === activeCardIdx ? "active" : ""
                ].join(" ")}
                onClick={() => {
                  if (!isMobile) setActiveCardIdx(i);
                }}
                onTouchStart={e => {
                  if (isMobile) {
                    setTouchPos({
                      x: e.touches[0].clientX,
                      y: e.touches[0].clientY
                    });
                  }
                }}
                onTouchEnd={e => {
                  if (isMobile) onTouchEnd(e);
                }}
              />
            ))}
          </Grid>
        </Container>
      </Container>
      {matches && (
        <Container maxWidth="lg">
          <Box pt={4}>
            <Grid container justify="center" alignItems="center" spacing={4}>
              {cards.map((c, i) => (
                <Grid
                  item
                  key={`dot-${i}`}
                  className={[
                    classes.cardDots,
                    i === activeCardIdx ? "active" : ""
                  ].join(" ")}
                  onClick={() => {
                    if (!isMobile) setActiveCardIdx(i);
                  }}
                  onTouchEnd={() => {
                    if (isMobile) setActiveCardIdx(i);
                  }}
                />
              ))}
            </Grid>
          </Box>
        </Container>
      )}
    </Box>
  );
}
