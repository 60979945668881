import { whiteColor, secondaryGreenColor } from "assets/jss/material-kit-react";
import { bgImageDesktop, bgImageTablet, bgImageMobile } from "assets/img/lp";

const styles = theme => ({
  mainFeaturedPost: {
    position: "relative",
    color: theme.palette.common.white,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    padding: "60px 15px",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${bgImageMobile})`
    },
    [theme.breakpoints.down("lg")]: {
      backgroundImage: `url(${bgImageTablet})`
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${bgImageDesktop})`
    }
  },
  mainFeaturedPostContent: {
    textAlign: "center",
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6)
    }
  },
  smallBusinessPostContent: {
    textAlign: "center",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1)
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1)
    },
    [theme.breakpoints.up("md")]: {
      padding: "10px"
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(6)
    }
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backdropFilter: "blur(4px)"
  },
  heroImage: {
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "300px"
    },
    [theme.breakpoints.up("md")]: {
      width: "400px"
    },
    [theme.breakpoints.up("lg")]: {
      width: "650px"
    },
    "@media (min-width: 1280px) and (max-width: 1365px)": {
      width: "575px"
    }
  },
  heroHeader: {
    fontFamily: "Open Sans",
    fontWeight: "900",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "42.5984px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "52px"
    }
  },
  gridItemContainer: {
    maxWidth: "750px",
    margin: "0 auto"
  },
  smallBizGridItemContainer: {
    maxWidth: "650px",
    margin: "0 auto"
  },
  subHeaderContainer: {
    lineHeight: 5
  },
  subHeader: {
    fontSize: "22px",
    fontFamily: "Open Sans",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  beautifulJoinButton: {
    width: "375px",
    backgroundColor: `linear-gradient(45deg, ${secondaryGreenColor} 0%, #75cb42 100%)`,
    borderRadius: "35px",
    color: whiteColor,
    fontSize: "22px",
    textAlign: "center",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover, &:focus": {
      boxShadow: `0 0 0 2px ${secondaryGreenColor} inset`,
      background: whiteColor,
      color: secondaryGreenColor
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "240px",
      padding: "6px 14px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "275px"
    },
    [theme.breakpoints.up("md")]: {
      width: "375px"
    }
  },
  smallBusinessJoinButton: {
    backgroundColor: `linear-gradient(45deg, ${secondaryGreenColor} 0%, #75cb42 100%)`,
    borderRadius: "35px",
    color: whiteColor,
    fontSize: "22px",
    textAlign: "center",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover, &:focus": {
      boxShadow: `0 0 0 2px ${secondaryGreenColor} inset`,
      background: whiteColor,
      color: secondaryGreenColor
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "270px",
      padding: "6px 14px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "370px"
    },
    [theme.breakpoints.up("md")]: {
      width: "470px"
    },
    [theme.breakpoints.up("lg")]: {
      width: "70%"
    }
  },
  moneyBackText: {
    fontSize: "22px",
    fontFamily: "Open Sans",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  }
});

export default styles;
