const initState = () => {
  return {
    user: null,
    passwordReseting: false,
    passwordResetDone: false,
    passwordResetError: null,
    forgetPasswordSent: false,
    forgetPasswordEmail: null,
    // Password Management
    changingInfo: false,
    changingInfoError: null,

    registering: false,
    login: false
  };
};

export const USER_CHANGE = "USER_CHANGE";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_FORGET_PWD = "USER_FORGET_PWD";
export const USER_UPDATE = "USER_UPDATE";

export const USER_INFO_CHANGE_ERROR = "USER_INFO_CHANGE_ERROR";

export const USER_PASSWORD_RESETING = "USER_PASSWORD_RESETING";
export const USER_PASSWORD_RESET_DONE = "USER_PASSWORD_RESET_DONE";
export const USER_PASSWORD_RESET_ERROR = "USER_PASSWORD_RESET_ERROR";

export const USER_REGISTER = "USER_REGISTER";
export const USER_REGISTER_DONE = "USER_REGISTER_DONE";

const userReducer = (state = initState(), action) => {
  switch (action.type) {
    case USER_REGISTER:
      return { ...state, registering: true };
    case USER_REGISTER_DONE:
      return { ...state, registering: false, user: action.user };
    case USER_UPDATE:
      return { ...state, user: action.data };
    case USER_LOGOUT:
      return { ...state, user: null };
    case USER_CHANGE:
      return { ...state, user: action.user };
    case USER_FORGET_PWD:
      return {
        ...state,
        forgetPasswordSent: true,
        forgetPasswordEmail: action.email
      };
    case USER_INFO_CHANGE_ERROR:
      return { ...state, changingInfoError: action.error, changingInfo: false };
    case USER_PASSWORD_RESETING:
      return {
        ...state,
        passwordReseting: true,
        passwordResetDone: false,
        passwordResetError: null
      };
    case USER_PASSWORD_RESET_DONE:
      return {
        ...state,
        passwordReseting: false,
        passwordResetDone: true,
        passwordResetError: null
      };
    case USER_PASSWORD_RESET_ERROR:
      return {
        ...state,
        passwordReseting: false,
        passwordResetDone: false,
        passwordResetError: action.error
      };
    default:
      return state;
  }
};

export default userReducer;
