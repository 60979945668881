// cloudtweaks special plan

const initState = {
  loading: false,
  specialPlans: null
};

export const SPECIAL_PLANS_FETCH = "SPECIAL_PLAN_FETCH";
export const SPECIAL_PLANS_FETCH_DONE = "SPECIAL_PLAN_FETCH_DONE";

const SpecialPlansReducer = (state = initState, action) => {
  switch (action.type) {
    case SPECIAL_PLANS_FETCH:
      return { ...state, loading: true };
    case SPECIAL_PLANS_FETCH_DONE:
      return {
        ...state,
        loading: false,
        specialPlans: action.specialPlans
      };
    default:
      return state;
  }
};

export default SpecialPlansReducer;
