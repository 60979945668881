import React from "react";
import Html from "./Html";
import Text from "./Text";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";

const Mail = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>{t("mail_two_step_subject")}</title>
        <meta name="mail-from-name" content="Shells" />
        <meta name="mail-from" content="noreply@shells.com" />
      </Helmet>
      <Html />
      <Text />
    </div>
  );
};

export default Mail;
