import imgLight from "assets/img/plans/lp-plan-01.png";
import imgLite from "assets/img/plans/lp-plan-01.png";
import imgBasic from "assets/img/plans/lp-plan-02.png";
import imgPro from "assets/img/plans/lp-plan-04.png";
import imgCustom from "assets/img/plans/lp-plan-05.png";

export const getShellImage = plan => {
  switch (plan["Description.AuthorCode"]) {
    case "lite":
      return imgLite;
    case "basic":
      return imgBasic;
    case "pro":
      return imgPro;
    case "custom":
      return imgCustom;
    default:
      return imgLight;
  }
};

export const isCustomPlan = plan => {
  return (
    plan["Description.AuthorCode"] === "custom" ||
    !("Shell.Size" in plan) ||
    parseInt(plan["Shell.Size"]) <= 0
  );
};

// used in PlansCarousel.js
export const findPlan = (plan, plans) => {
  if (!plans || !plan) return "";

  return plans.find(
    p =>
      p["Basic.ServiceLifetime"] === plan.duration &&
      p["Description.AuthorCode"] === plan["Description.AuthorCode"]
  );
};
