import { rest } from "@karpeleslab/klbfw";
import {
  catchRedirectException,
  handleError
} from "../../components/utils/apiErrorHandler";
import { fetchSpecialPlans } from "./SpecialPlansAction";

import {
  WINDOWS_PLANS_FETCH,
  WINDOWS_PLANS_FETCH_SUCCESS
} from "../reducers/WindowsReducer";

export const fetchWindowsPlans = () => {
  return (dispatch, getState) => {
    dispatch({ type: WINDOWS_PLANS_FETCH });
    dispatch(fetchSpecialPlans("manjaro_plan"))
      .then(data => {
        const requests = {};

        data.forEach(
          plan =>
            (requests[plan["Description.AuthorCode"]] =
              plan.Catalog_Product__ +
              ",shell_os=windows-server-2019-dc-desktop")
        );

        return requests;
      })
      .then(requests =>
        rest("Catalog/Cart:simulateMany", "POST", {
          requests
        })
      )
      .then(catchRedirectException)
      .then(({ data }) => {
        if (!data || !data.carts) {
          throw new Error("Can't find Windows Plans.");
        }

        Object.entries(data.carts).forEach(([, value]) => {
          value["Price.Price"] = value["total"];
          value["Basic.Name"] = value.products[0].data["Basic.Name"];
          value["Catalog_Product__"] = value.products[0].id;
          value["Meta.shell_os"] = value.products[0].meta.shell_os;
        });

        dispatch({
          type: WINDOWS_PLANS_FETCH_SUCCESS,
          windowsPlans: data.carts
        });
      })
      .catch(e => handleError(getState, dispatch, e));
  };
};
