import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import ShellsButton from "./../../components/CustomButtons/ShellButton";
import { Header } from "components/WhyShells";
import Discount from "views/PricingPlans/Discount";
import Helmet from "components/instances/instance/Helmet/Helmet";
import Box from "@material-ui/core/Box";
import SpecialPlansCarousel from "../../components/SpecialPlansCarousel/SpecialPlansCarousel";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

import styles from "../../assets/jss/material-kit-react/views/whyShells";
import { img1 } from "assets/img/whyshells";

const useStyles = makeStyles(styles);

const LinuxQuestionsOrgLandingPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const detailsOne = {
    header: t("lp_linuxquestionsorg_title"),
    description: t("lp_linuxquestionsorg_subtext"),
    image: img1,
    imageClass: `${classes.lpImage} ${classes.marginTop}`,
    addlWclass: `${classes.overflowHidden} ${classes.lqWrapper}`
  };

  return (
    <>
      <Helmet title="Shells Virtual Desktop - LinuxQuestions.org" />

      <Header
        details={detailsOne}
        largerTextBlock={true}
        prioritizeButton={true}
        heroRibbon={true}
        specialButton={
          <ShellsButton
            color="primary"
            variant="contained"
            component="a"
            href="#pricing-plans"
            className={classes.signUpButton}
          >
            {t("lp_linuxquestionsorg_checkitout")}
          </ShellsButton>
        }
      />
      <div id="pricing-plans" style={{ marginTop: "-62px" }} />
      <Box pt={6} pb={16} style={{ overflowX: "hidden" }}>
        <div className={classes.dealTextContainer}>
          <Typography
            align="center"
            variant="h3"
            component="h5"
            className={`${classes.heroDescription} ${classes.addLineHeight}`}
          >
            <Trans
              i18nKey="lp_linuxquestionsorg_pricing_text"
              components={{
                newLine: <br />
              }}
            />
          </Typography>
        </div>
        <SpecialPlansCarousel
          plansType="manjaro_plan"
          showOnly={["basic", "plus", "pro"]}
          options={{
            basic: {
              usageLimit: "unlimited_use",
              disclaimer: { key: "save_n_percent", number: "45" },
              discount: <Discount transKey="manjaro_basic_discount" />
            },
            plus: {
              usageLimit: "unlimited_use",
              disclaimer: { key: "save_n_percent", number: "56" },
              discount: <Discount transKey="manjaro_plus_discount" />
            },
            pro: {
              usageLimit: "unlimited_use",
              disclaimer: { key: "save_n_percent", number: "39" },
              discount: <Discount transKey="manjaro_pro_discount" />
            }
          }}
        />
      </Box>
      <Box pb={8}>
        <Typography
          component="p"
          variant="h5"
          className={classes.regularPricingParagraph}
        >
          <Trans
            i18nKey="manjaro_back_to_regular_pricing"
            components={{
              pageLink: <Link className={classes.greenLink} to="/pricing" />
            }}
          />
        </Typography>
      </Box>
    </>
  );
};

export default LinuxQuestionsOrgLandingPage;
