import {
  android,
  debian,
  kdeNeon,
  kubuntu,
  linuxMint,
  manjaro,
  microsoft,
  ubuntu
} from "assets/img/operating-systems-footer";

export const shellsOSList = [
  {
    name: "Manjaro",
    image: manjaro,
    types: ["KDE 21.0 Ornara", "GNOME 21.0 Ornara", "XFCE 21.0 Ornara"]
  },
  {
    name: "Debian",
    image: debian,
    types: ["Buster GNOME Desktop", "Buster SSH Server (beta)"]
  },
  {
    name: "Ubuntu",
    image: ubuntu,
    types: ["Desktop 20.04 LTS", "Server 20.04 LTS"]
  },
  {
    name: "KDE",
    image: kdeNeon,
    types: ["Neon 20.04 LTS (beta)"]
  },
  {
    name: "Linux Mint",
    image: linuxMint,
    types: ["Cinnamon 20.1 (beta)"]
  },
  {
    name: "Microsoft",
    image: microsoft,
    types: ["Windows 10 Pro (beta) (non-activated)"]
  },
  {
    name: "Kubuntu",
    image: kubuntu,
    types: ["Desktop 20.04 LTS (beta)"]
  },
  {
    name: "Android",
    image: android,
    types: ["X86 (beta)"]
  }
];
