import { blackColor } from "assets/jss/material-kit-react";

const operatingSystemsStyle = theme => ({
  osContainer: {
    width: "68%",
    maxWidth: "1200px",
    margin: "24px auto",
    padding: "0px"
  },
  iconsContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexWrap: "wrap"
  },
  iconContainer: {
    textAlign: "center",
    margin: "4px 4px 5px 4px",
    [theme.breakpoints.down("sm")]: {
      width: "30%"
    },
    // [theme.breakpoints.up("md")]: {
    //   width: "15%"
    // },
    "&.condensed": {
      [theme.breakpoints.up("md")]: {
        width: "8%"
      }
    }
  },
  operatingContainer: {
    textAlign: "center",
    margin: "4px 4px 12px 4px",
    [theme.breakpoints.down("sm")]: {
      width: "30%"
    },
    [theme.breakpoints.up("md")]: {
      width: "15%"
    }
  },
  icon: {
    width: "45px",
    marginBottom: "5px",
    filter: "grayscale(100%)"
  },
  osHeader: {
    fontFamily: "Open Sans, sans-serif",
    fontSize: "24px",
    fontWeight: "900",
    color: blackColor,
    textAlign: "center",
    marginBottom: "15px"
  },
  marginBottom: {
    marginBottom: "20px"
  }
});

export default operatingSystemsStyle;
