import React from "react";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/debian";

import { WrapperContainer } from "components/HOC";

const useStyles = makeStyles(styles);

export default function SectionTwo() {
  const { t } = useTranslation();
  const classes = useStyles();

  const sectionTwoData = [
    {
      title: t("debian_s2_title1"),
      body: t("debian_s2_body1")
    },
    {
      title: t("debian_s2_title2"),
      body: t("debian_s2_body2")
    },
    {
      title: t("debian_s2_title3"),
      body: t("debian_s2_body3")
    },
    {
      title: t("debian_s2_title4"),
      body: t("debian_s2_body4")
    }
  ];

  return (
    <>
      <Box pt={6}>
        <Box pb={4} px={4}>
          <Typography
            component="h2"
            variant="h3"
            align="center"
            className={classes.mainHeader}
          >
            {t("debian_title_section_two")}
          </Typography>
        </Box>
        <WrapperContainer addMaxWidth={true}>
          <Grid container spacing={4} alignItems="center">
            {sectionTwoData.map((data, index) => {
              const { title, body } = data;

              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  key={index}
                  className={classes.marginBottom}
                >
                  <div className={classes.sectionTwoHeaderParent}>
                    <Typography
                      variant="h4"
                      component="h3"
                      className={`${classes.sectionOneHeader} ${classes.sectionOneHeaderPosLeft}`}
                    >
                      {title}
                    </Typography>
                  </div>
                  <div className={classes.greenLine} />
                  <div className={classes.s2BodyContainer}>
                    <Typography
                      variant="body1"
                      className={classes.sectionOneBody}
                      align={"left"}
                    >
                      {body}
                    </Typography>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </WrapperContainer>
      </Box>
    </>
  );
}
