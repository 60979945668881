import React from "react";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import GridItem from "./GridItem";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/cloudmusic";

const useStyles = makeStyles(styles);

export default function SectionThree() {
  const { t } = useTranslation();
  const classes = useStyles();

  const gridItem1 = {
    video: false,
    header: t("cloudmusic_h8"),
    body: t("cloudmusic_p5"),
    gridClass: [classes.gridItem, classes.backgroundDark],
    lineClass: classes.lineOne,
    bodyVariant: "body1",
    headerClass: classes.headers,
    bodyClass: classes.description
  };
  const gridItem2 = {
    video: false,
    header: t("cloudmusic_h9"),
    body: t("cloudmusic_p6"),
    gridClass: [classes.gridItem, classes.backgroundLight],
    lineClass: classes.lineTwo,
    bodyVariant: "body1",
    headerClass: `${classes.headers} ${classes.blackFont}`,
    bodyClass: `${classes.description} ${classes.blackFont}`
  };

  return (
    <div className={classes.secondWrapper}>
      <Grid container alignItems="center">
        <GridItem data={gridItem1} />
        <GridItem data={gridItem2} />
      </Grid>
    </div>
  );
}
