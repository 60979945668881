import {
  blackColor,
  mainGreenColor,
  whiteColor
} from "assets/jss/material-kit-react";
import { shellsMinecraftBG } from "assets/img/minecraft";

const styles = theme => ({
  mainFeaturedPost: {
    position: "relative",
    color: theme.palette.common.white,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: 0,
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${shellsMinecraftBG})`
    },
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${shellsMinecraftBG})`
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${shellsMinecraftBG})`
    }
  },
  headerTextContainer: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "300px",
      margin: "0 auto"
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "550px"
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "750px"
    }
  },
  mainHeader: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: "900",
    color: mainGreenColor,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "42.5984px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "52px"
    }
  },
  subHeader: {
    fontSize: "22px",
    fontFamily: "Open Sans, Roboto",
    color: whiteColor,
    lineHeight: 1.4,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: 1.4
    }
  },
  morePadding: {
    [theme.breakpoints.down("xs")]: {
      padding: "100px 15px 100px 15px"
    },
    [theme.breakpoints.up("sm")]: {
      padding: "175px 45px 175px 45px"
    }
  },
  sectionHeader: {
    fontFamily: "Open Sans, sans-serif",
    color: mainGreenColor,
    fontWeight: "900",
    fontSize: "24px",
    marginBottom: "15px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  sectionBody: {
    fontSize: "16px",
    fontFamily: "Roboto, sans-serif",
    color: blackColor,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  headerContainer: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "350px",
      margin: "0 auto"
    }
  },
  subHeaderContainer: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "350px",
      margin: "0 auto"
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "650px"
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "700px"
    }
  },
  bodyContainer: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "300px",
      margin: "0 auto"
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "400px",
      margin: "0 auto"
    }
  },
  link: {
    color: mainGreenColor,
    fontWeight: "bold",
    "&:hover, &:focus": {
      textDecoration: "underline",
      color: mainGreenColor,
      cursor: "pointer"
    },
    "&:visited": {
      color: mainGreenColor
    }
  },
  linksContainer: {
    maxWidth: "320px",
    margin: "0 auto"
  },
  cardContent: {
    padding: "24px !important"
  },
  card: {
    backgroundColor: "#fafafb",
    borderRadius: "20px",
    transform: "translateY(5px)",
    "-webkit-transform": "translateY(5px)",
    transition: "transform 0.4s",
    "-webkit-transition": "-webkit-transform 0.4s",
    "&:hover": {
      transform: "translateY(-5px)",
      "-webkit-transform": "translateY(-5px)",
      transition: "transform 0.4s",
      "-webkit-transition": "-webkit-transform 0.4s",
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.3)"
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: 0
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "431px"
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "351px"
    }
  },
  sectionOneGridItem: {
    maxWidth: "450px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto"
    }
  }
});

export default styles;
