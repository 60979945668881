import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import { Header } from "components/WhyShells";
import Helmet from "components/instances/instance/Helmet/Helmet";
import CloudTweaksPlansButton from "./CloudTweaksPlansButton";

import { fetchCloudTweaksPlan } from "./../../store/actions/CloudTweaksAction";
import { selectPlan } from "./../../store/actions/PlanAction";

import styles from "../../assets/jss/material-kit-react/views/whyShells";
import { img1 } from "assets/img/whyshells";

const useStyles = makeStyles(styles);

const CloudTweaksLandingPage = ({
  fetchCloudTweaksPlan,
  cloudTweaksPlans,
  selectPlan
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const selectPlanAndJoin = plan => {
    selectPlan(plan);
    history.push("/join");
  };

  useEffect(() => {
    fetchCloudTweaksPlan();
  }, []); // eslint-disable-line

  const detailsOne = {
    header: t("lp_cloudtweaks_title"),
    description: (
      <>
        {t("lp_cloudtweaks_description")}
        <br />
        <br />
        {t("lp_cloudtweaks_description_second")}
        <br />
        <br />
        {t("lp_cloudtweaks_catchprase")}
        {/* <br />
        <br />
        {t("lp_cloudtweaks_description_second")} */}
        <br />
        <br />
        <span style={{ fontSize: ".75em" }}>
          {t("lp_cloudtweaks_annotation")}
        </span>
      </>
    ),
    image: img1,
    addlWclass: `${classes.overflowHidden} ${classes.textAlignLeft}`
  };

  return (
    <>
      <Helmet title="Shells Virtual Desktop - CloudTweaks" />

      <Header
        details={detailsOne}
        largerTextBlock={true}
        prioritizeButton={true}
        specialButton={
          cloudTweaksPlans && (
            <CloudTweaksPlansButton
              basicPlan={
                (cloudTweaksPlans.cloudTweaksPlans &&
                  cloudTweaksPlans.cloudTweaksPlans.basic) ||
                {}
              }
              proPlan={
                (cloudTweaksPlans.cloudTweaksPlans &&
                  cloudTweaksPlans.cloudTweaksPlans.pro) ||
                {}
              }
              onChanged={selectPlanAndJoin}
            />
          )
        }
      />
    </>
  );
};

CloudTweaksLandingPage.serverFetchPlans = (match, store) =>
  store.dispatch(fetchCloudTweaksPlan());

const mapDispatchToProps = dispatch => ({
  fetchCloudTweaksPlan: () => dispatch(fetchCloudTweaksPlan()),
  selectPlan: plan => dispatch(selectPlan(plan))
});

const mapStateToProps = state => ({ cloudTweaksPlans: state.cloudtweaks });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CloudTweaksLandingPage);
