import {
  mainGreenColor,
  whiteColor,
  secondaryGreenColor
} from "assets/jss/material-kit-react";
import { hippoBG } from "assets/img/ubuntu";

const styles = theme => ({
  signUpButton: {
    padding: "6px 24px",
    backgroundColor: mainGreenColor,
    color: whiteColor,
    display: "block",
    borderRadius: "35px",
    textAlign: "center",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover": {
      boxShadow: `0 0 0 2px ${secondaryGreenColor} inset`,
      background: whiteColor,
      color: secondaryGreenColor
    },
    [theme.breakpoints.down("xs")]: {
      width: "250px",
      margin: "0 auto",
      fontSize: "16px",
      padding: "6px 14px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "275px",
      margin: "0 auto",
      fontSize: "16px"
    },
    [theme.breakpoints.up("md")]: {
      width: "350px",
      fontSize: "22px",
      margin: "35px 0 40px 0"
    }
  },
  mainFeaturedPost: {
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${hippoBG})`
    },
    [theme.breakpoints.down("lg")]: {
      backgroundImage: `url(${hippoBG})`
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${hippoBG})`
    }
  },
  whiteFont: {
    color: `${whiteColor} !important`
  },
  ubuntuDevices: {
    width: "100%",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "5%"
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "5%"
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "-6%"
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "4%"
    }
  }
});

export default styles;
