import React from "react";
import { getPrefix } from "@karpeleslab/klbfw";

import BackPage from "../instances/instance/BackPage/BackPage";
import Grid from "@material-ui/core/Grid";
import Helmet from "../instances/instance/Helmet/Helmet";
import Hidden from "@material-ui/core/Hidden";
import SharePane from "./SharePane";
import Style from "style-it";

import classNames from "classnames";
import pageStyle from "assets/scss/modules/page.module.scss";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";
import DisplayRecentArticles from "./DisplayRecentArticles";

import DateUtil from "./../common/data/DateUtil";

export const THUMB_IMG_VARIATION = "format=jpeg&strip&scale_crop=500x500";
export const OG_IMG_VARIATION = "format=jpeg&strip&scale_crop=1200x1200";
export const MAIN_IMG_VARIATION = "format=jpeg&strip&scale_crop=1200x1200";
export const COVER_IMG_VARIATION = "format=jpeg&strip&scale_crop=1325x290";
export const IMG_VARIATIONS = [
  THUMB_IMG_VARIATION,
  OG_IMG_VARIATION,
  MAIN_IMG_VARIATION,
  COVER_IMG_VARIATION
];

const useStyles = makeStyles(styles);
const useComplementaryStyles = makeStyles(theme => ({
  authorAndDate: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    margin: "auto auto 24px auto",
    fontSize: ".8em",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column"
    }
  },

  content: {
    color: "#3C4858",
    padding: "5px",
    [theme.breakpoints.down("md")]: {
      marginRight: "-25px",
      textAlign: "justify"
    }
  },

  title: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.75em"
    }
  }
}));

const DisplayArticle = props => {
  const classes = useStyles();
  const complementaryClasses = useComplementaryStyles();

  const { article, type } = props;
  const cmsKey = {
    blog: "@blog",
    faq: "@faq",
    news: "@news",
    tutorial: "@tutorials"
  };

  const buildBackLink = () => {
    if (type !== "page" && type !== "article") {
      return <BackPage />;
    }
  };
  const buildCreated = () => {
    if (type === "page" || type === "article") return false;

    return (
      <div className={complementaryClasses.authorAndDate}>
        <span
          style={{
            fontSize: ".85rem",
            color: "#3C4858",
            textAlign: "center",
            fontWeight: "bold"
          }}
        >
          By {article.Content_Cms_Author.Name}
        </span>
        <Hidden smDown>
          <span style={{ fontSize: ".85rem", color: "#3C4858" }}>
            &emsp;-&emsp;
          </span>
        </Hidden>
        <span
          style={{
            fontSize: ".85rem",
            color: "#3C4858",
            textAlign: "center"
          }}
        >
          <DateUtil klbDateObj={article.Created} format="MMMM Do YYYY" />
        </span>
      </div>
    );
  };

  return (
    <div
      className={classNames(pageStyle["page--wrapper"], {
        [pageStyle["page-blog"]]: type === "blog",
        "page-blog": type === "blog"
      })}
    >
      <Helmet title={`${article.Title} | Shells official site`}>
        <meta property="og:type" content="article" />
        <meta property="og:title" content={article.Title} />
        {article.Short_Contents && (
          <meta property="og:description" content={article.Short_Contents} />
        )}
        <meta
          property="og:url"
          content={
            "https://www.shells.com" +
            getPrefix() +
            "/" +
            type +
            "/" +
            article.Slug
          }
        />

        <meta property="og:locale" content={article.Language__} />
        <meta property="og:site_name" content="SHELLS" />
        <meta
          property="article:published_time"
          content={<DateUtil klbDateObj={article.Published} format="LLL" />}
        />
        {article.Drive_Item__ && (
          <meta
            property="og:image"
            content={article.Drive_Item.Media_Image[THUMB_IMG_VARIATION]}
          />
        )}
      </Helmet>

      <Grid container className={classes.textCenter}>
        <Grid item xs={12}>
          <div className="banner--wrapper">
            {article.Top_Drive_Item__ && (
              <div className="img--wrapper">
                <img
                  src={article.Top_Drive_Item.Media_Image[THUMB_IMG_VARIATION]}
                  alt="cover"
                />
              </div>
            )}
            {type !== "page" && (
              <div className="title--wrapper">
                <h2
                  className={`${classes.title} ${complementaryClasses.title}`}
                >
                  {article.Title}
                </h2>
              </div>
            )}
          </div>
        </Grid>
        <Grid item container spacing={3} xs={12} sm={12} md={12}>
          {type !== "page" && <SharePane article={article} />}
          <Grid item xs={12} md={type !== "page" ? 7 : 12}>
            {buildCreated()}
            <Style>
              {article.Style}
              <div className={pageStyle["content--wrapper"]}>
                <div
                  className={complementaryClasses.content}
                  dangerouslySetInnerHTML={{ __html: article.Contents }}
                />
              </div>
            </Style>
          </Grid>
          {type !== "page" && (
            <DisplayRecentArticles
              type={type}
              except={article}
              cms={cmsKey[type]}
            />
          )}
        </Grid>
      </Grid>
      {buildBackLink()}
    </div>
  );
};

export default DisplayArticle;
