import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import Helmet from "components/instances/instance/Helmet/Helmet";
import Typography from "@material-ui/core/Typography";
import RedeemCodeForm from "components/RedeemCodeForm/RedeemCodeForm";

import styles from "assets/jss/material-kit-react/views/orderPage.js";
const useStyles = makeStyles(styles);

const RedeemCode = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.genericPageWrapper}>
      <Helmet title={t("helmet_shells_redeem")} />
      <div className={`${classes.main} ${classes.mainRaised}`}>
        <div className={classes.container}>
          <Typography
            variant="h3"
            component="h1"
            align="center"
            style={{ color: "#000" }}
          >
            {t("redeem_code")}
          </Typography>
          <RedeemCodeForm />
        </div>
      </div>
    </div>
  );
};

export default RedeemCode;
