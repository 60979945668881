import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import EmailLayout from "./../../../components/Email/EmailLayout";
import Text from "./../../../components/Email/EmailText";
import InfoBox from "./../../../components/Email/EmailInfoBox";

const Html = () => {
  const { t } = useTranslation();
  return (
    <EmailLayout>
      <table
        border="0"
        cellSpacing="0"
        cellPadding="0"
        width="100%"
        style={{ width: "100%" }}
      >
        <tbody>
          <tr>
            <td>
              <Text size="large" variant="highlight" align="center">
                {t("forgotten_password_recover_title")}
              </Text>
            </td>
          </tr>
          <tr>
            <td>
              <InfoBox>
                <Text align="center">
                  <img
                    src="https://shells.com/img/mail_account_ok.png"
                    width="42px"
                    alt={t("site_name")}
                  />
                </Text>
                <Text align="center" size="medium">
                  {t("user_password_mail_date_notice", {
                    date: moment().format("YYYY-MM-DD HH:mm:ss")
                  })}
                </Text>
                <Text align="center" size="medium">
                  {t("login_notification_date", {
                    year: moment().year(),
                    month: moment().month(),
                    day: moment().day(),
                    time: moment().format("HH:mm:ss")
                  })}
                </Text>
              </InfoBox>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>
              <Text size="medium">
                {t("user_password_mail_updated_notice")}
              </Text>
              <Text size="medium">{t("user_password_mail_regards")}</Text>
              <Text size="medium">{t("user_password_email_footer")}</Text>
            </td>
          </tr>
        </tbody>
      </table>
    </EmailLayout>
  );
};

export default Html;
