import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  logo: {
    width: "70px",
    [theme.breakpoints.down("md")]: {
      width: "45px"
    },
    [theme.breakpoints.down("sm")]: {
      width: "30px"
    }
  }
}));

const list = [
  {
    name: "Debian",
    image: require("./../../assets/img/operating-systems/debian.png")
  },
  {
    name: "KDE",
    image: require("./../../assets/img/operating-systems/kde-neon.png")
  },
  {
    name: "Kubuntu",
    image: require("./../../assets/img/operating-systems/kubuntu.png")
  },
  {
    name: "Linux Mint",
    image: require("./../../assets/img/operating-systems/linux-mint.png")
  },
  {
    name: "Manjaro",
    image: require("./../../assets/img/operating-systems/manjaro.png")
  },
  {
    name: "Ubuntu",
    image: require("./../../assets/img/operating-systems/ubuntu.png")
  }
];

const LinuxDistrosList = () => {
  const classes = useStyles();

  return (
    <Grid container justify="space-around" alignItems="center">
      {list.map(os => (
        <Grid item key={os.name}>
          <img
            src={os.image}
            alt={`logo of ${os.name}`}
            className={classes.logo}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default LinuxDistrosList;
