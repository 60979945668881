import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { list } from "../../store/actions/CmsAction";
import { IMG_VARIATIONS } from "./DisplayArticle";
import Loading from "../common/feedback/loading/Loading";
import Grid from "@material-ui/core/Grid";
import pageStyle from "../../assets/scss/modules/page.module.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { promoImage } from "assets/img/articles";

const RECENT_ARTICLE_LENGTH = 5;

const DisplayRecentArticles = ({ cms, type, except, fetch, list }) => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    fetch(cms).then(() => setLoading(false));
  }, [fetch, cms]);

  return (
    <Grid item xs={12} md={4} lg={3}>
      {loading && <Loading />}
      {!loading && list.length < 1 && (
        <div className={pageStyle["no-article"]}>
          <p>{t("list_no_article")}</p>
        </div>
      )}

      {!loading && list.length > 0 && (
        <ul>
          <h3 className={pageStyle["list-heading"]}>
            {t("recent_articles_heading")}
          </h3>
          {list
            .slice(0, RECENT_ARTICLE_LENGTH)
            .filter(entry => entry.Slug !== except.Slug)
            .map((entry, idx) => (
              <li className={pageStyle["list-item"]} key={idx}>
                <Link
                  to={`/${type}/${entry.Slug}`}
                  className={pageStyle["list-link"]}
                >
                  <span className={pageStyle["list-title"]}>{entry.Title}</span>
                </Link>
              </li>
            ))}
          <div className={pageStyle["list-promoImage"]}>
            <Link to="/pricing">
              <img
                src={promoImage}
                alt={t("promo_image")}
                className={pageStyle["promoImage"]}
              />
            </Link>
          </div>
        </ul>
      )}
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    list: state.cms.cmsList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetch: cms => dispatch(list(cms, { page_no: 1 }, {}, IMG_VARIATIONS))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisplayRecentArticles);
