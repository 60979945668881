import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import { fetchPlans, selectPlan } from "store/actions/PlanAction";
import { addItem, cartFetch } from "store/actions/CartAction";
import { fetchSpecialPlans } from "store/actions/SpecialPlansAction";

import SpecialPlansCarouselItem from "./SpecialPlansCarouselItem";
import Loading from "components/common/feedback/loading/Loading";
import PageModal from "components/PageModal/PageModal";
import Section from "views/LandingPage/Other/Section";
import { isMobileTablet } from "../utils/mobileTablet";

import useSpecialPlansCarouselStyles from "./SpecialPlansCarousel.style";

const SpecialPlansCarousel = ({
  addItem,
  cartFetch,
  selectPlan,
  fetchSpecialPlans,
  specialPlans,
  cartItems,
  os = null,
  plansType = "",
  loading,
  showOnly,
  options
}) => {
  const classes = useSpecialPlansCarouselStyles();
  const [isMobile] = useState(isMobileTablet());
  const [currentActiveIdx, setCurrentActiveIdx] = useState(1);
  const plansWrapperRef = useRef(true);
  const [touchPos, setTouchPos] = useState({ x: 0, y: 0 });
  const [touchTimestamp, setTouchTimestamp] = useState(Date.now());
  const [plansToShow, setPlansToShow] = useState([]);
  const history = useHistory();
  const isNarrow = useMediaQuery("(max-width: 1024px)");

  // With use of Modal for specs
  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const handleOpen = e => {
    e.stopPropagation();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const onTouchEnd = (e, plan = null) => {
    const end = Date.now();
    const { x, y } = touchPos;
    if (!isMobile || !plansWrapperRef || !plansWrapperRef.current) return;

    if (end - touchTimestamp < 100 && plan && !!e.target.closest(".active"))
      return;

    if (
      e.changedTouches[0].clientX < x &&
      (Math.abs(e.changedTouches[0].clientY - y) < 50 ||
        Math.abs(y - e.changedTouches[0].clientY) < 50)
    ) {
      // swipe right
      setCurrentActiveIdx(
        currentActiveIdx < plansToShow.length - 1 ? currentActiveIdx + 1 : 0
      );
    }
    // swipe left
    else if (
      e.changedTouches[0].clientX > x &&
      (Math.abs(e.changedTouches[0].clientY - y) < 50 ||
        Math.abs(y - e.changedTouches[0].clientY) < 50)
    ) {
      setCurrentActiveIdx(
        (currentActiveIdx > 0 ? currentActiveIdx : plansToShow.length) - 1
      );
    }
  };

  const onPlanSelected = planName => {
    selectPlan(specialPlans.specialPlans[planName]);
    addItem(
      os
        ? `${specialPlans.specialPlans[planName].Catalog_Product__},shell_os=${os}`
        : specialPlans.specialPlans[planName].Catalog_Product__
    );
    if (cartItems.length < 1) history.push(os ? `/join?os=${os}` : `/join`);
  };

  useEffect(() => {
    fetchPlans();
    fetchSpecialPlans(plansType);
    cartFetch();
  }, []); // eslint-disable-line

  useEffect(() => {
    window.scrollTo(0, 0);

    if (specialPlans && specialPlans.specialPlans) {
      const finalPlans = [];

      let specialPlansCopy = { ...specialPlans.specialPlans };

      showOnly.forEach(plan => {
        if (specialPlansCopy[plan]) {
          specialPlansCopy[plan] = {
            ...specialPlansCopy[plan],
            ...options[plan]
          };

          // adding monthly price manually if it doesnt exist
          if (options[plan].monthlyPrice) {
            specialPlansCopy[plan]["Price.Monthly"] = {
              value_disp: options[plan].monthlyPrice
            };
          }

          finalPlans.push(specialPlansCopy[plan]);
        }
      });

      setPlansToShow([...finalPlans]);
    }
  }, [specialPlans, showOnly, options]);

  useEffect(() => {
    if (!isMobile || !isNarrow || !plansWrapperRef || !plansWrapperRef.current)
      return;

    let style;
    if (isMobile || isNarrow) {
      style += `-webkit-transform: translate3d(-${(currentActiveIdx + 0.5) *
        ((1 / plansToShow.length) * 100)}%, 0, 0);
      -moz-transform: translate3d(-${(currentActiveIdx + 0.5) *
        ((1 / plansToShow.length) * 100)}%, 0, 0);
        -ms-transform: translate3d(-${(currentActiveIdx + 0.5) *
          ((1 / plansToShow.length) * 100)}%, 0, 0);
          -o-transform: translate3d(-${(currentActiveIdx + 0.5) *
            ((1 / plansToShow.length) * 100)}%, 0, 0);
      transform: translate3d(-${(currentActiveIdx + 0.5) *
        ((1 / plansToShow.length) * 100)}%, 0, 0);`;
    }

    style += `width: ${292 * plansToShow.length}px`;
    plansWrapperRef.current.setAttribute("style", style);
  }, [currentActiveIdx, isMobile, plansToShow.length, specialPlans]); //eslint-disable-line

  useEffect(() => {
    if (
      (!isMobile && !isNarrow) ||
      !plansWrapperRef ||
      !plansWrapperRef.current
    )
      return;

    plansWrapperRef.current.setAttribute(
      "style",
      `width: ${plansToShow.length * 292}px;
       -webkit-transform: translate3d(-${(currentActiveIdx + 0.5) *
         ((1 / plansToShow.length) * 100)}%, 0, 0);
       -moz-transform: translate3d(-${(currentActiveIdx + 0.5) *
         ((1 / plansToShow.length) * 100)}%, 0, 0);
       -ms-transform: translate3d(-${(currentActiveIdx + 0.5) *
         ((1 / plansToShow.length) * 100)}%, 0, 0);
       -o-transform: translate3d(-${(currentActiveIdx + 0.5) *
         ((1 / plansToShow.length) * 100)}%, 0, 0);
       transform: translate3d(-${(currentActiveIdx + 0.5) *
         ((1 / plansToShow.length) * 100)}%, 0, 0);`
    );
  }, [currentActiveIdx, isMobile, isNarrow, plansToShow.length]); //eslint-disable-line

  useEffect(() => {
    if (!plansWrapperRef || !plansWrapperRef.current || isMobile || isNarrow) {
      return;
    } else {
      plansWrapperRef.current.setAttribute(
        "style",
        `width: ${plansToShow.length * 292}px;`
      );
    }
  }, [isMobile, isNarrow, plansToShow.length]); // eslint-disable-line

  if (loading) {
    return <Loading />;
  } else {
    return (
      <Section anchor="pricing" maxWidth={showOnly.length > 3 ? "lg" : "md"}>
        <Box py={4} className={classes.outer}>
          <PageModal
            handleClose={handleClose}
            open={open}
            modalText={modalText}
          />
          {!isMobile && (
            <>
              <IconButton
                className={[classes.arrow, classes.arrowLeft].join(" ")}
                onClick={() =>
                  setCurrentActiveIdx(
                    (currentActiveIdx > 0
                      ? currentActiveIdx
                      : plansToShow.length) - 1
                  )
                }
              >
                <ArrowBackIosIcon className={classes.arrowBack} />
              </IconButton>
              <IconButton
                className={[classes.arrow, classes.arrowRight].join(" ")}
                onClick={() =>
                  setCurrentActiveIdx(
                    currentActiveIdx < plansToShow.length - 1
                      ? currentActiveIdx + 1
                      : 0
                  )
                }
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </>
          )}
          <Box className={classes.inner}>
            <Grid
              container
              spacing={3}
              justify="center"
              alignItems="center"
              className={classes.container}
              ref={plansWrapperRef}
              onTouchStart={e => {
                if (isMobile) {
                  setTouchPos({
                    x: e.touches[0].clientX,
                    y: e.touches[0].clientY
                  });
                }
              }}
              onTouchEnd={e => {
                if (isMobile) {
                  onTouchEnd(e);
                }
              }}
            >
              {plansToShow.map((p, i) => {
                const data = {
                  p,
                  i,
                  handleOpen,
                  setModalText,
                  currentActiveIdx,
                  setCurrentActiveIdx,
                  onTouchEnd,
                  setTouchTimestamp,
                  onPlanSelected,
                  isMobile,
                  plansToShow
                };

                return <SpecialPlansCarouselItem data={data} key={i} />;
              })}
            </Grid>
          </Box>
        </Box>
      </Section>
    );
  }
};

const mapStateToProps = state => ({
  specialPlans: state.specialPlans,
  cartItems: state.cart.cartItem,
  loading: state.specialPlans.loading
});

const mapDispatchToProps = dispatch => {
  return {
    selectPlan: plan => dispatch(selectPlan(plan)),
    fetchSpecialPlans: plansType => dispatch(fetchSpecialPlans(plansType)),
    addItem: plan => dispatch(addItem(plan)),
    cartFetch: () => dispatch(cartFetch())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecialPlansCarousel);
