import React from "react";
import { useTranslation } from "react-i18next";

// components
import Text from "components/landingPage/Text";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

// style
import style from "assets/scss/modules/lpLayout.module.scss";

// img
import img1 from "assets/img/lp/img_repair_001.png";
import img2 from "assets/img/lp/img_repair_002.png";
import img3 from "assets/img/lp/img_repair_003.png";
import img4 from "assets/img/lp/img_repair_004.png";

export default function Repair() {
  const { t } = useTranslation();

  return (
    <>
      <div className={style["lp-content-wrapper"]}>
        <Text
          type="left"
          img={img1}
          title={t("lp_repair_content1_title")}
          text={t("lp_repair_content1_text")}
        />
      </div>
      <div className={style["lp-content-wrapper"]}>
        <Text
          type="right"
          img={img2}
          title={t("lp_repair_content2_title")}
          text={t("lp_repair_content2_text")}
        />
      </div>
      <div className={style["lp-content-wrapper"]}>
        <Text
          type="left"
          img={img3}
          title={t("lp_repair_content3_title")}
          text={t("lp_repair_content3_text")}
        />
      </div>
      <div className={style["lp-content-wrapper"]}>
        <Text
          type="right"
          img={img4}
          title={t("lp_repair_content4_title")}
          text={t("lp_repair_content4_text")}
        />
      </div>
      <div className={`${style["lp-wrapper"]}`}>
        <h2 className={style["lp-table-title"]}>
          {t("lp_repair_comparison_title")}
        </h2>

        <div className={style["lp-table-wrapper"]}>
          <table cellSpacing="0" className={style["lp-table"]}>
            <thead>
              <tr>
                <th className={`${style["lp-table-sticky"]}`}></th>
                <th className={style["lp-table-strong"]}>
                  {t("lp_repair_comparison_th1")}
                </th>
                <th>{t("lp_repair_comparison_th2")}</th>
                <th>{t("lp_repair_comparison_th3")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={`${style["lp-table-sticky"]}`}>
                  {t("lp_repair_comparison_list1")}
                </td>
                <td>
                  <CheckIcon className={style["green"]} />
                </td>
                <td>
                  <ClearIcon />
                </td>
                <td>
                  <ClearIcon />
                </td>
              </tr>
              <tr>
                <td className={`${style["lp-table-sticky"]}`}>
                  {t("lp_repair_comparison_list2")}
                </td>
                <td>
                  <CheckIcon className={style["green"]} />
                </td>
                <td>
                  <ClearIcon />
                </td>
                <td>
                  <ClearIcon />
                </td>
              </tr>
              <tr>
                <td className={`${style["lp-table-sticky"]}`}>
                  {t("lp_repair_comparison_list3")}
                </td>
                <td>
                  <CheckIcon className={style["green"]} />
                </td>
                <td>
                  <ClearIcon />
                </td>
                <td>
                  <ClearIcon />
                </td>
              </tr>
              <tr>
                <td className={`${style["lp-table-sticky"]}`}>
                  {t("lp_repair_comparison_list4")}
                </td>
                <td>
                  <ClearIcon />
                </td>
                <td>
                  <CheckIcon className={style["red"]} />
                </td>
                <td>
                  <CheckIcon className={style["red"]} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
