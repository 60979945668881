import { rest } from "@karpeleslab/klbfw";
import {
  catchRedirectException,
  handleError
} from "../../components/utils/apiErrorHandler";

import {
  CLOUDTWEAKS_PLAN_FETCH,
  CLOUDTWEAKS_PLAN_FETCH_DONE
} from "../reducers/CloudTweaksReducer";

export const fetchCloudTweaksPlan = () => {
  return (dispatch, getState) => {
    dispatch({ type: CLOUDTWEAKS_PLAN_FETCH });

    return rest("Catalog/Product:search", "GET", {
      query: {
        "Description.Type": "cloudtweaks_exclusive"
      }
    })
      .then(catchRedirectException)
      .then(({ data }) => {
        if (!data.data || data.data.length < 1)
          throw new Error("Can't find the product.");

        const res = {};
        data.data.forEach(item => {
          res[item["Description.AuthorCode"]] = item;
        });
        dispatch({
          type: CLOUDTWEAKS_PLAN_FETCH_DONE,
          cloudTweaksPlans: res
        });
        return data.data[0];
      })
      .catch(err => {
        handleError(getState, dispatch, err);
      });
  };
};
