import React from "react";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/lfa";

import { WrapperContainer } from "components/HOC";
import { shellsLaptop } from "assets/img/lfa";

const useStyles = makeStyles(styles);

export default function SectionFive() {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

  let paddingBottom;
  isMobile ? (paddingBottom = 0) : (paddingBottom = 8);

  return (
    <Box
      pt={8}
      py={paddingBottom}
      className={`${classes.bgLightBlue} ${classes.noOverflow}`}
    >
      <WrapperContainer
        addMaxWidth={false}
        addlWclass={classes.s5WrapperPadding}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          className={`${classes.bgDarkBlue} ${classes.s5AddPadding}`}
          style={{ position: "relative" }}
        >
          <img
            src={shellsLaptop}
            alt="Shells Solution"
            className={classes.solutionImage}
          />
          <Hidden smDown>
            <Grid item xs={12} sm={6} md={8}></Grid>
          </Hidden>
          <Grid item xs={12} sm={6} md={4} className={classes.s5GridItem}>
            <Typography
              variant="h5"
              component="h3"
              className={`${classes.headers} ${classes.whiteFont}`}
            >
              {t("lfa_h8")}
            </Typography>
            <div className={classes.s5WhiteLine} />
            <div className={classes.s3BodyContainer}>
              <Typography
                variant="body1"
                className={`${classes.description} ${classes.whiteFont}`}
              >
                {t("lfa_p8")}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </WrapperContainer>
    </Box>
  );
}
