import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

// components
import WorksWith from "./../../views/LandingPage/Other/WorksWith";

// style
import styles from "assets/jss/material-kit-react/components/lpHeroStyle";
const useStyles = makeStyles(styles);

export default function Hero({ title, text, pageName, condensed }) {
  const { t } = useTranslation();
  const classes = useStyles();

  let buttonText, buttonClass, contentClass, gridContainerClass;

  if (
    pageName === "byod" ||
    pageName === "computers" ||
    pageName === "simplified" ||
    pageName === "quickbooks"
  ) {
    buttonClass = classes.smallBusinessJoinButton;
    contentClass = classes.smallBusinessPostContent;
    gridContainerClass = classes.smallBizGridItemContainer;
  } else {
    buttonClass = classes.beautifulJoinButton;
    contentClass = classes.mainFeaturedPostContent;
    gridContainerClass = classes.gridItemContainer;
  }

  switch (pageName) {
    case "byod":
      buttonText = t("lp_byod_button");
      break;
    case "computers":
      buttonText = t("lp_computers_button");
      break;
    case "simplified":
      buttonText = t("lp_simplified_button");
      break;
    case "quickbooks":
      buttonText = t("lp_quickbooks_button");
      break;
    default:
      buttonText = t("new_hp_getshells_now");
  }

  return (
    <>
      <Box>
        <Paper className={classes.mainFeaturedPost}>
          <div className={classes.overlay} />
          <div className={contentClass}>
            <div className={gridContainerClass}>
              <Typography
                component="h1"
                variant="h2"
                className={classes.heroHeader}
              >
                {title}
              </Typography>
              <Box pt={4} className={classes.subHeaderContainer}>
                <Typography
                  component="h2"
                  variant="body1"
                  className={classes.subHeader}
                >
                  {text}
                </Typography>
              </Box>
              <Box pt={4}>
                <Button
                  color="primary"
                  variant="contained"
                  className={buttonClass}
                  component={Link}
                  to={"/pricing"}
                >
                  {buttonText}
                </Button>
                <Box pt={1}>
                  <Typography
                    component="h2"
                    variant="body1"
                    className={classes.moneyBackText}
                  >
                    {t("new_hp_subheader_7day")}
                  </Typography>
                </Box>
              </Box>
            </div>
          </div>
        </Paper>
      </Box>
      <Box py={2}>
        <WorksWith condensed={condensed} />
      </Box>
    </>
  );
}
