import React from "react";
import { useTranslation, Trans } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/giveaway";

const useStyles = makeStyles(styles);

export default function TwitterContestRules() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
        <div className={classes.daasTextContainer}>
          <Typography variant="h3" className={classes.headers} align="center">
            {t("giveaway_twitter_box1_title")}
          </Typography>
        </div>
        <div className={classes.descriptionContainer}>
          <Typography
            variant="body1"
            className={`${classes.description} ${classes.marginBottom}`}
          >
            {t("giveaway_twitter_box1_subtext1")}
          </Typography>
          <Typography
            variant="body1"
            className={`${classes.description} ${classes.marginBottom}`}
          >
            {t("giveaway_twitter_box1_subtext2")}
          </Typography>
          <a
            href="mailto:support@support.shells.com"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            {t("giveaway_twitter_box1_subtext3")}
          </a>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
        <div className={classes.datesTextContainer}>
          <Typography variant="h3" className={classes.headers_first}>
            <Trans
              i18nKey="giveaway_twitter_startdate"
              components={{ blackFont: <span className={classes.blackFont} /> }}
            />
          </Typography>
          <Typography variant="h3" className={classes.headers}>
            <Trans
              i18nKey="giveaway_twitter_enddate"
              components={{ blackFont: <span className={classes.blackFont} /> }}
            />
          </Typography>
        </div>
        <div className={classes.descriptionContainer}>
          <Typography
            variant="body1"
            className={`${classes.description} ${classes.smallMarginBottom}`}
          >
            {t("giveaway_twitter_box2_rules1")}
          </Typography>
          <Typography
            variant="body1"
            className={`${classes.description} ${classes.smallMarginBottom}`}
          >
            {t("giveaway_twitter_box2_rules2")}
          </Typography>
          <Typography
            variant="body1"
            className={`${classes.description} ${classes.smallMarginBottom}`}
          >
            {t("giveaway_twitter_box2_rules3")}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
        <div className={classes.daasTextContainer}>
          <Typography variant="h3" className={classes.headers} align="center">
            {t("giveaway_twitter_box3_title")}
          </Typography>
        </div>
        <div className={classes.descriptionContainer}>
          <Typography
            variant="body1"
            className={`${classes.description} ${classes.smallMarginBottom}`}
          >
            {t("giveaway_twitter_box3_item1")}
          </Typography>
          <Typography
            variant="body1"
            className={`${classes.description} ${classes.smallMarginBottom}`}
          >
            {t("giveaway_twitter_box3_item2")}
          </Typography>
          <Typography
            variant="body1"
            className={`${classes.description} ${classes.smallMarginBottom}`}
          >
            {t("giveaway_twitter_box3_item3")}
          </Typography>
          <Typography
            variant="body1"
            className={`${classes.description} ${classes.smallMarginBottom}`}
          >
            {t("giveaway_twitter_box3_item4")}
          </Typography>
          <Typography
            variant="body1"
            className={`${classes.description} ${classes.smallMarginBottom}`}
          >
            {t("giveaway_twitter_box3_item5")}
          </Typography>
          <Typography
            variant="body1"
            className={`${classes.description} ${classes.smallMarginBottom}`}
          >
            {t("giveaway_twitter_box3_item6")}
          </Typography>
          <Typography
            variant="body1"
            className={`${classes.description} ${classes.smallMarginBottom}`}
          >
            {t("giveaway_twitter_box3_item7")}
          </Typography>
        </div>
      </Grid>
    </>
  );
}
