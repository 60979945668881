import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Get } from "@karpeleslab/klbfw";

const Text = () => {
  const { t } = useTranslation();
  return (
    <textarea
      id="mail-text"
      defaultValue={
        t("user_password_mail_date_notice", {
          date: moment().format("YYYY-MM-DD HH:mm:ss")
        }) +
        "\n\r" +
        t("user_password_mail_updated_notice") +
        "\n\r" +
        t("user_password_mail_login", { username: Get("_user").Email }) +
        "\n\r" +
        t("user_password_mail_regards") +
        "\n\r" +
        t("user_password_email_footer") +
        "\n\r"
      }
    />
  );
};

export default Text;
