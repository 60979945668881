import {
  STRIPE_PAYMENT_COMPLETED,
  STRIPE_PAYMENT_ERROR,
  STRIPE_PAYMENT_FETCH_INFO,
  STRIPE_PAYMENT_FETCH_INFO_DONE,
  STRIPE_PAYMENT_TOKEN_RECEIVED,
  STRIPE_SET_OBJECT,
  STRIPE_SET_PROCESSING,
  STRIPE_SET_STATUS
} from "../reducers/StripePaymentReducer";
import { rest } from "@karpeleslab/klbfw";
import {
  catchRedirectException,
  handleError
} from "../../components/utils/apiErrorHandler";

export const fetchMethodInfo = () => {
  return dispatch => {
    dispatch({ type: STRIPE_PAYMENT_FETCH_INFO });
    return rest("Order/Payment:methodInfo", "POST", { method: "Stripe" })
      .then(catchRedirectException)
      .then(data => {
        dispatch({
          type: STRIPE_PAYMENT_FETCH_INFO_DONE,
          stripeKey: data.data.Fields.cc_token.attributes.key,
          stripeAccount:
            data.data.Fields.cc_token.attributes.options.stripe_account
        });
      });
  };
};

export const generateToken = () => {
  return (dispatch, getState) => {
    if (!getState().stripe.stripe) {
      // you mush call setStripeObject first
      dispatch({ type: STRIPE_PAYMENT_ERROR, error: "Stripe not initialized" });
      handleError(getState, dispatch, new Error("Stripe not initialized"));
      return;
    }

    return getState()
      .stripe.stripe.createToken()
      .then(t => {
        if ("error" in t) {
          dispatch({ type: STRIPE_PAYMENT_ERROR, error: t.error.message });
          handleError(
            getState,
            dispatch,
            new Error("Cannot create stripe token")
          );
        } else {
          dispatch({ type: STRIPE_PAYMENT_TOKEN_RECEIVED, token: t });
          return t;
        }
      })
      .catch(() => {});
  };
};

export const setStripeObject = stripe => {
  return dispatch => {
    return dispatch({ type: STRIPE_SET_OBJECT, stripe: stripe });
  };
};

export const setComplete = () => {
  return dispatch => {
    return dispatch({ type: STRIPE_PAYMENT_COMPLETED });
  };
};

export const setProcessing = processing => {
  return dispatch => {
    return dispatch({ type: STRIPE_SET_PROCESSING, processing: processing });
  };
};

export const setStatus = status => {
  return dispatch => {
    dispatch({ type: STRIPE_SET_STATUS, status: status });
  };
};
