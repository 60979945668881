import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

import Avatar from "@material-ui/core/Avatar";
import Button from "./../CustomButtons/ShellButton";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

const OAuthDialog = ({ app, scopes, onConfirm, onCancel }) => {
  const { t } = useTranslation();
  const [checkedScopes, setCheckedScopes] = useState([]);

  useEffect(() => {
    if (!scopes) return;

    const tmp = {};
    for (const s in scopes)
      tmp[s] = Object.assign(scopes[s], { checked: scopes[s].default });

    setCheckedScopes(tmp);
  }, [scopes]); // eslint-disable-line

  const onScopeStateChanged = (scope, state) => {
    let scopeName = "";
    for (const s in scopes) {
      if (scopes[s].name === scope.name) {
        scopeName = s;
        break;
      }
    }

    if (!scopeName || scopes[scopeName].basic) return;

    setCheckedScopes(s => {
      s[scopeName].checked = state;
      return s;
    });
  };

  return (
    <Grid container direction="column" alignItems="center" spacing={4}>
      <Grid container item direction="column" alignItems="center">
        <Avatar style={{ width: "60px", height: "60px" }}>
          {app.Name.charAt(0).toUpperCase()}
        </Avatar>
        <Typography align="center">{app.Description}</Typography>
      </Grid>
      <Grid item>
        <Typography align="center" variant="h5" component="p">
          {t("oauth_title")}
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          <Trans
            i18nKey="oauth_description"
            values={{ name: app.Name }}
            components={{ bold: <strong /> }}
          />
        </Typography>
        <List>
          {Object.values(checkedScopes).map(scope => (
            <ListItem
              key={scope.name}
              dense
              button
              onClick={onScopeStateChanged(scope, !scope.checked)}
              disabled={scope.basic}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={scope.checked}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": `label-${scope.name}`
                  }}
                />
              </ListItemIcon>
              <ListItemText id={`label-${scope.name}`} primary={scope.name} />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid container item xs={12} justify="space-between">
        <Grid item>
          <Button onClick={onCancel}>{t("oauth_reject")}</Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onConfirm(checkedScopes)}
          >
            {t("oauth_accept")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OAuthDialog;
