/*eslint-disable*/
import React from "react";

// react components for routing our app without refresh
import { HashLink as Link } from "react-router-hash-link";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { useTranslation } from "react-i18next";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

// core components
import Button from "@material-ui/core/Button";

import { logout } from "store/actions/UserActions";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

import { connect } from "react-redux";

const useStyles = makeStyles(styles);

function HeaderLinks({ props, user, logout }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleLogout = () => {
    logout();
  };


  return (
    <List className={classes.list}>
      {user.user &&
      <ListItem className={classes.listItem}>
        <Button
          className={classes.navLink}
          component={Link}
          to="/whyshells"
        >
          {t("menu_whyshells")}
        </Button>
      </ListItem>
      }
      {user.user &&
      <ListItem className={classes.listItem}>
        <Button
          className={classes.navLink}
          component={Link}
          to="/pricing"
        >
          {t("menu_pricing")}
        </Button>
      </ListItem>
      }
      {user.user && 
      <ListItem className={classes.listItem}>
        <Button
          color="inherit"
          className={classes.navLink}
          component={Link}
          to="/download"
        >
          {t("menu_download")}
        </Button>
      </ListItem>
      }
      {user.user &&
      <ListItem className={classes.listItem}>
        <Button
          className={classes.navLink}
          href="mailto:support@support.shells.com"
          target="_blank"
        >
          {t("menu_contact")}
        </Button>
      </ListItem>
      }
      {user.user &&
      <ListItem className={classes.listItem}>
        <Button
          className={classes.navLink}
          component={user.user ? "a" : Link}
          href={process.env.REACT_APP_SHELL_CONSOLE_URL}
          to="/order"
        >
          {t("menu_account")}
        </Button>
      </ListItem>
      }
    </List>
  );
}


const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLinks);
