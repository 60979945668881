import React from "react";
import { Get } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";

const Text = () => {
  const { t } = useTranslation();
  const password = Get("_password");
  const userName = Get("_user").Profile.Display_Name;
  const shellName = Get("_shell").Label;

  return (
    <textarea
      id="mail-text"
      defaultValue={t("shell_mail_default_password_content_text", {
        shellName: shellName,
        userName: userName,
        password: password
      })}
    />
  );
};

export default Text;
