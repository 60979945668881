import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getCurrency, rest } from "@karpeleslab/klbfw";

import Box from "@material-ui/core/Box";
import ComparisonTable from "./../Other/ComparisonTable";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Plan from "./../Other/Plan";
import Section from "./../Other/Section";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

import { fetchPlans, selectPlan } from "store/actions/PlanAction";

const usePlansSectionStyles = makeStyles(theme => ({
  centerText: {
    textAlign: "center"
  },
  img: {
    [theme.breakpoints.down("sm")]: {
      width: "140px"
    }
  },
  negativeBottomMargin: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "-150px"
    }
  },
  noPaddingMobile: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  planWrapper: {
    marginTop: "20px",
    [theme.breakpoints.down("md")]: {
      marginTop: "160px"
    }
  },
  reducePaddingMobile: {
    [theme.breakpoints.down("sm")]: {
      padding: 0
    }
  },
  secondaryText: {
    color: "#82c847"
  },
  thickerText: {
    color: "#000",
    fontSize: "2rem",
    fontFamily: '"Open Sans SemiBold", sans-serif',
    fontWeight: 600
  }
}));

const PlansSection = ({
  fetchPlans,
  selectPlan,
  light,
  minimum,
  standard,
  plus,
  plans,
  ...etc
}) => {
  const { t } = useTranslation();
  const classes = usePlansSectionStyles();
  const history = useHistory();
  const [currencyFormat, setCurrencyFormat] = useState();

  useEffect(() => {
    rest(`Currency/${getCurrency()}`, "GET").then(({ data }) =>
      setCurrencyFormat(data)
    );
  }, []);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  const onPlanSelected = plan => {
    switch (plan) {
      case "lite":
        selectPlan(light);
        break;
      case "basic":
        selectPlan(minimum);
        break;
      case "plus":
        selectPlan(plus);
        break;
      case "pro":
        selectPlan(standard);
        break;
      default:
        break;
    }
    history.push("/join");
  };

  return (
    <Section anchor="pricing" {...etc}>
      <Box px={4} pb={12} className={classes.reducePaddingMobile}>
        <Typography
          variant="h2"
          component="h2"
          className={`${classes.thickerText} ${classes.centerText}`}
        >
          {t("choose_a_plan")}
        </Typography>
        <Container
          maxWidth="md"
          className={`${classes.noPaddingMobile} ${classes.negativeBottomMargin}`}
        >
          <Grid container justify="center" alignItems="center">
            <Grid item xs={4} style={{ textAlign: "right" }}>
              <img
                src={require("assets/img/plans/certificate.png")}
                alt=""
                width="180px"
                className={classes.img}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="h5"
                component="p"
                className={classes.secondaryText}
              >
                {t("risk_free")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Box pt={4} className={classes.reducePaddingMobile}>
          <Grid container spacing={4} alignItems="stretch">
            {plans.map(p => (
              <Grid
                item
                key={p.Catalog_Product__}
                xs={12}
                md={6}
                lg={3}
                className={classes.planWrapper}
              >
                <Plan
                  plan={p}
                  currencyFormat={currencyFormat}
                  onPlanSelected={onPlanSelected}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box pt={12} pb={4}>
          <Typography
            variant="h4"
            component="h4"
            className={`${classes.thickerText} ${classes.centerText}`}
          >
            {t("compare_pricing_plans")}
          </Typography>
        </Box>
        <Box pt={4}>
          <ComparisonTable plans={plans} onPlanSelected={onPlanSelected} />
        </Box>
      </Box>
    </Section>
  );
};

const mapStateToProps = state => {
  return {
    light: state.plans.planLightYear,
    minimum: state.plans.planBasicYear,
    standard: state.plans.planStandardYear,
    plus: state.plans.planPlusYear,
    plans: state.plans.sortedPlans
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectPlan: plan => dispatch(selectPlan(plan)),
    fetchPlans: () => dispatch(fetchPlans())
  };
};

PlansSection.serverfetchPlans = (match, store) => store.dispatch(fetchPlans());

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlansSection);
