import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";

import style from "../../assets/scss/modules/cloudmusic.module.scss";

export default function GridItem({ data }) {
  const { t } = useTranslation();
  const {
    video,
    header,
    body,
    lineClass,
    gridClass,
    headerClass,
    bodyClass,
    bodyVariant,
    interviewLink,
    interviewLinkClass,
    youtubeLink,
    youtubeLinkClass
  } = data;
  let view;

  if (video) {
    view = (
      <iframe
        className={style["youtubevideo"]}
        src="https://www.youtube.com/embed/M1gC-JpGA8M?rel=0"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="DJ Reddy Rell using Shells"
      ></iframe>
    );
  } else {
    view = (
      <>
        <Typography variant="h3" className={headerClass}>
          {youtubeLink ? (
            <Box pb={2}>
              {header}{" "}
              <Link href={youtubeLink} className={youtubeLinkClass}>
                DJ Reddy Rell,
              </Link>
            </Box>
          ) : (
            header
          )}
        </Typography>
        <div className={lineClass}></div>
        <Typography variant={bodyVariant} className={bodyClass}>
          {body}
        </Typography>
        {interviewLink ? (
          <Box pt={2}>
            <Link href={interviewLink} className={interviewLinkClass}>
              {t("cloudmusic_p14")}
            </Link>
          </Box>
        ) : null}
      </>
    );
  }

  return (
    <Grid item xs={12} sm={6} className={gridClass}>
      {view}
    </Grid>
  );
}
