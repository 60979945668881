import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  disclaimerBox: {
    margin: "20px 0",
    backgroundColor: "#fff6c7",
    border: "1px solid #dd4c1e",
    color: "#dd4c1e"
  },

  errorBox: {
    margin: "20px 0",
    backgroundColor: "#fae9e9",
    border: "1px solid #dd1e1e",
    color: "#dd1e1e"
  },

  form: {
    width: "100%",
    maxWidth: "768px",
    margin: "0 auto",
    color: "#000"
  },

  inputField: {
    width: "100%",
    minWidth: "360px",
    margin: "0 auto"
  },

  underlinedText: {
    textDecoration: "underline"
  }
}));

export default useStyles;
