const initState = {
  grantError: null,
  grantResult: null,
  grantScan: null,
  grantScanError: null,
  loadingGrant: false,
  loadingGrantScan: false
};
export const GRANT = "GRANT";
export const GRANT_DONE = "GRANT_DONE";
export const GRANT_ERROR = "GRANT_ERROR";
export const GRANT_SCAN = "GRANT_SCAN";
export const GRANT_SCAN_DONE = "GRANT_SCAN_DONE";
export const GRANT_SCAN_ERROR = "GRANT_SCAN_ERROR";

const oAuthReducer = (state = initState, action) => {
  switch (action.type) {
    case GRANT:
      return {
        ...state,
        loadingGrant: true,
        grantError: null,
        grantResult: null
      };
    case GRANT_DONE:
      return {
        ...state,
        loadingGrant: false,
        grantError: null,
        grantResult: action.grantResult
      };
    case GRANT_ERROR:
      return {
        ...state,
        loadingGrant: false,
        grantError: action.grantError,
        grantResult: null
      };
    case GRANT_SCAN:
      return {
        ...state,
        loadingGrantScan: true,
        grantScan: null,
        grantScanError: null
      };
    case GRANT_SCAN_DONE:
      return {
        ...state,
        loadingGrantScan: false,
        grantScan: action.grantScan,
        grantScanError: null
      };
    case GRANT_SCAN_ERROR:
      return {
        ...state,
        loadingGrantScan: false,
        grantScan: null,
        grantScanError: action.grantScanError
      };
    default:
      return state;
  }
};

export default oAuthReducer;
