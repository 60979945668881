import React from "react";

import Typography from "@material-ui/core/Typography";

import useSpecialPlansCarouselStyles from "./SpecialPlansCarousel.style";

export default function ShellsSpec({
  specName,
  specCount,
  specModalText,
  handleOpen,
  setModalText
}) {
  const classes = useSpecialPlansCarouselStyles();

  return (
    <Typography align="center">
      {specCount}{" "}
      <span
        onClick={e => {
          handleOpen(e);
          setModalText(specModalText);
        }}
        className={classes.specTitle}
      >
        {specName}
      </span>
    </Typography>
  );
}
