import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";

import {
  Header,
  Download,
  SectionOne,
  SectionTwo,
  SectionThree
} from "components/Debian";

export default function Debian() {
  return (
    <>
      <Helmet title="Shells Virtual Desktop - Debian" />
      <Header />
      <Download />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
    </>
  );
}
