import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/material-kit-react/views/manjaro";

const useStyles = makeStyles(styles);

export default function GridPaper({ data }) {
  const { image, header, body } = data;
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} md={6} lg={3} className={classes.gridPaper}>
      <Paper square elevation={0} className={classes.bgLightGray}>
        <img src={image} className={classes.cardImage} alt="" />
        <Typography
          gutterBottom
          variant="h5"
          component="h3"
          className={classes.paperHeader}
        >
          {header}
        </Typography>
        <div className={classes.bodyTextContainer}>
          <Typography
            variant="body1"
            color="textSecondary"
            component="p"
            className={classes.sectionBody}
          >
            {body}
          </Typography>
        </div>
      </Paper>
    </Grid>
  );
}
