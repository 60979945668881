const buildSpecStyle = () => ({
  specImage: {
    textAlign: "center"
  },
  specContainer: {
    color: "#03222D",
    fontWeight: "bold",
    background: "transparent",
    position: "relative"
  },
  specText: {
    marginRight: "8px"
  },
  questionIconContainer: {
    fontSize: "14px",
    marginTop: "-8px",
    position: "absolute",
    top: "50%",
    right: "-15px"
  },
  buildGrid: {
    margin: "auto"
  },
  iconColor: {
    color: "#B0E170"
  },
  buildSpecGridContainer: {
    color: "#03222D",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "12px"
  },
  buildOtherFieldText: {
    color: "#03222D",
    fontWeight: "bold",
    margin: "auto",
    marginBottom: "12px"
  },
  buildOtherFieldDesc: {
    color: "#888888",
    fontWeight: "400"
  },
  buildOtherFieldGridContainer: {
    color: "#03222D",
    fontWeight: "bold"
  },
  marginRight: {
    marginRight: "16px"
  }
});

export default buildSpecStyle;
