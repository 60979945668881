import React from "react";
import { useTranslation } from "react-i18next";
import { HashLink as Link } from "react-router-hash-link";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/daas";

import { WrapperContainer } from "components/HOC";
import { daasDevices } from "assets/img/daas";

const useStyles = makeStyles(styles);

export default function DaasHero() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.mainFeaturedPost}>
      <WrapperContainer addMaxWidth={false} addlWclass={classes.addPadding}>
        <Grid container alignItems="center" justify="center" spacing={2}>
          <Hidden smUp>
            <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
              <img
                alt="Daas devices"
                src={daasDevices}
                className={classes.daasDevicesImage}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h1"
              component="h2"
              className={classes.mainHeader}
            >
              {t("daas_title")}
            </Typography>
            <Box py={6}>
              <Typography
                variant="h1"
                component="h2"
                className={classes.subHeader}
              >
                {t("daas_subheader_one")}
              </Typography>
            </Box>
            <Typography
              variant="h1"
              component="h2"
              className={classes.subHeader}
            >
              {t("daas_subheader_two")}
            </Typography>
            <Button
              variant="contained"
              disableElevation
              className={classes.signUpButton}
              component={Link}
              to="#pricing"
            >
              {t("daas_button")}
            </Button>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
              <img
                alt="DaaS devices"
                src={daasDevices}
                style={{ width: "100%" }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </WrapperContainer>
    </Paper>
  );
}
