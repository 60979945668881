import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
// core components
import { useTranslation } from "react-i18next";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import typostyles from "assets/jss/material-kit-react/components/typographyStyle.js";

import Form from "./Form";
import makeStyles from "@material-ui/core/styles/makeStyles";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

const useStyles = makeStyles(styles);
const useTypoStyles = makeStyles(typostyles);

export default function PasswordForgotten() {
  const { t } = useTranslation();
  const classes = useStyles();
  const typoClasses = useTypoStyles();

  return (
    <div className={classes.genericPageWrapper}>
      <Helmet title={t("password_forgotten")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classNames(classes.container)}>
          <GridContainer spacing={3}>
            <GridItem>
              <div className={typoClasses.titleContainer}>
                <h2 className={typoClasses.titleSection}>
                  {" "}
                  {t("password_forgotten")}{" "}
                </h2>
              </div>
            </GridItem>
            <GridItem>
              <Form />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
