import React, { useState } from "react";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Tooltip from "@material-ui/core/Tooltip";

import { FaQuestionCircle } from "react-icons/fa";

import { withStyles, makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/components/buildSpecStyle";

const useStyles = makeStyles(styles);

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip);

const BuildSpecItem = ({ selected, item }) => {
  const { tooltipTitle, tooltipDesc, build, spec, image, imageAlt, arg } = item;
  const classes = useStyles();

  const [tooltips, setTooltips] = useState({
    cpu: false,
    hdd: false,
    ram: false,
    time: false
  });

  const handleOpenTooltip = tooltip =>
    setTooltips({ ...tooltips, [tooltip]: true });
  const handleClickAway = tooltip =>
    setTooltips({ ...tooltips, [tooltip]: false });

  return (
    <Grid container item xs={12} spacing={3}>
      <React.Fragment>
        <Grid item xs={4} className={classes.specImage}>
          <img src={image} alt={imageAlt} />
        </Grid>
        <Grid
          item
          xs={4}
          className={classes.buildGrid}
          onClick={() => handleOpenTooltip(arg)}
          onMouseEnter={() => handleOpenTooltip(arg)}
          onMouseLeave={() => handleClickAway(arg)}
        >
          <ClickAwayListener onClickAway={() => handleClickAway(arg)}>
            <HtmlTooltip
              disableFocusListener
              open={tooltips["arg"]}
              title={
                <React.Fragment>
                  <Typography color="inherit">{tooltipTitle}</Typography>
                  {tooltipDesc}
                </React.Fragment>
              }
            >
              <div className={classes.specContainer}>
                <span className={classes.specText}>{spec}</span>
                <span className={classes.questionIconContainer}>
                  <FaQuestionCircle className={classes.iconColor} />
                </span>
              </div>
            </HtmlTooltip>
          </ClickAwayListener>
        </Grid>
        <Grid item xs={4} className={classes.buildGrid}>
          {build(selected)}
        </Grid>
      </React.Fragment>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    selected: state.plans.selected
  };
};

export default connect(mapStateToProps)(BuildSpecItem);
