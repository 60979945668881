import React from "react";
import { Get } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import moment from "moment";

import EmailLayout from "./../../../components/Email/EmailLayout";
import Text from "./../../../components/Email/EmailText";
import InfoBox from "./../../../components/Email/EmailInfoBox";

const Html = () => {
  const { t } = useTranslation();
  const recoverUrl = `https://shells.com/reset-password?login=${
    Get("_recover_user").User_Id
  }&password_key=${encodeURIComponent(Get("_user_key"))}`;

  return (
    <EmailLayout>
      <table
        border="0"
        cellSpacing="0"
        cellPadding="0"
        width="100%"
        style={{ width: "100%" }}
      >
        <tbody>
          <tr>
            <td>
              <Text size="medium">{t("user_password_mail_notice")}</Text>
            </td>
          </tr>
          <tr>
            <td>
              <InfoBox>
                <Text align="center" variant="highlight" size="large">
                  <a href={recoverUrl}>{recoverUrl}</a>
                </Text>
              </InfoBox>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>
              <Text size="medium">{t("user_password_mail_regards")}</Text>
              <Text size="medium">{t("user_password_email_footer")}</Text>
              <Text size="medium">
                {t("user_password_mail_date_notice", {
                  date: moment().format("YYYY-MM-DD HH:mm:ss")
                })}
              </Text>
            </td>
          </tr>
        </tbody>
      </table>
    </EmailLayout>
  );
};

export default Html;
