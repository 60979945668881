import React from "react";

import { WrapperContainer } from "components/HOC";
import FadeIn from "./FadeIn";

import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/whyShells";

const useStyles = makeStyles(styles);

export default function SectionFive({ details }) {
  const {
    header,
    descriptionOne,
    descriptionTwo,
    image,
    addlWclass_a,
    addlWclass_b
  } = details;
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 540px)");

  return (
    <>
      <WrapperContainer addMaxWidth={false} addlWclass={addlWclass_a}>
        <div className={`${classes.imageContainer}`}>
          <FadeIn direction={isMobile ? "left" : "right"}>
            <img src={image} alt="" className={classes.leftImage03} />
          </FadeIn>
          <div className={classes.empty}></div>
        </div>
      </WrapperContainer>
      <WrapperContainer addMaxWidth={true} addlWclass={addlWclass_b}>
        <div className={classes.textContainer3}>
          <Typography
            variant="h1"
            className={`${classes.headers} ${classes.fontOrange}`}
          >
            {header}
          </Typography>
          <Typography
            variant="h5"
            className={`${classes.description2} ${classes.marginBottom}`}
          >
            {descriptionOne}
          </Typography>
          <Typography variant="h5" className={classes.description2}>
            {descriptionTwo}
          </Typography>
        </div>
      </WrapperContainer>
    </>
  );
}
