export const bgImageDesktop = require("./shells_header_desktop.png");
export const bgImageTablet = require("./shells_header_tablet.png");
export const bgImageMobile = require("./shells_header_mobile.png");
export const img01 = require("./img01.svg");
export const img02 = require("./img02.svg");
export const img03 = require("./img03.svg");
export const img04 = require("./img04.svg");
export const img05 = require("./img05.svg");
export const img06 = require("./img06.svg");
export const img07 = require("./img07.svg");
export const img08 = require("./img08.svg");
export const img09 = require("./img09.svg");

export const lp_ipad = require("./Shells_Landing_Ipad.png");
export const lp_quickbooks = require("./Shells_Landing_Ipad_quickbooks.png");
export const windows = require("./Microsoft_Badge.svg");
export const apple = require("./App_Store_Badge.svg");
export const google = require("./Google_Play_Badge.svg");
export const linux = require("./Linux_Badge.svg");
export const student = require("./Shells_Students.svg");
export const worker = require("./Shells_Landing_Workers.svg");
export const coder = require("./Shells_Landing_Coders.svg");
export const creator = require("./Shells_Landing_Create.svg");
