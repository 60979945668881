import React from "react";
import { Get } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";

const Text = () => {
  const { t } = useTranslation();
  const userName = Get("_user").Profile.Display_Name;
  const shellName = Get("_shell").Label;
  const productId = Get("_shell").Catalog_Product__;
  const shellId = Get("_shell").Shell__;
  const renewLink = `https://www.shells.com/cart?product=${productId},shell=${shellId}`;

  return (
    <textarea
      id="mail-text"
      defaultValue={t("mail_shell_expired_content_text", {
        shellName: shellName,
        userName: userName,
        renewLink: renewLink
      })}
    />
  );
};

export default Text;
