import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/material-kit-react/views/manjaro";

const useStyles = makeStyles(styles);

export default function GridCard({ data }) {
  const { image, header, body } = data;
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6}>
      <Card square elevation={0} className={classes.gridCard}>
        <img src={image} className={classes.cardImage} alt="" />
        <CardContent className={classes.cardContentOne}>
          <div className={classes.sectionHeaderParent}>
            <Typography
              gutterBottom
              variant="h5"
              component="h3"
              className={`${classes.sectionHeader} ${classes.sectionHeaderPos}`}
            >
              {header}
            </Typography>
          </div>
          <div className={classes.greenLine}></div>
          <Typography
            variant="body1"
            color="textSecondary"
            component="p"
            className={classes.sectionBody}
          >
            {body}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
