import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import HomeVideoDialog from "./HomeVideoDialog";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import {
  geekFlare,
  linuxJournal,
  sourceForge,
  techRadarPro
} from "assets/img/homepage20210414";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/homepage20210414.style.js";

const useStyles = makeStyles(styles);

export default function HPHeader() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openVideoDialog, setOpenVideoDialog] = useState(false);

  const featuredList = [
    {
      image: techRadarPro,
      imageAlt: "TechRadar Pro",
      url: "https://www.techradar.com/best/virtual-desktop-services"
    },
    {
      image: geekFlare,
      imageAlt: "Geekflare",
      url: "https://geekflare.com/virtual-desktop-hosting-platforms/"
    },
    {
      image: sourceForge,
      imageAlt: "Sourceforge",
      url:
        "https://sourceforge.net/articles/qa-with-shells-a-powerful-secure-cloud-desktop-that-you-can-take-anywhere-on-any-device/"
    },
    {
      image: linuxJournal,
      imageAlt: "Linux Journal",
      url:
        "https://www.linuxjournal.com/content/virtual-machine-startup-shells-closes-digital-divide-one-cloud-computer-time"
    }
  ];

  return (
    <Box pb={4}>
      <Paper className={classes.mainFeaturedPost} square elevation={0}>
        <div className={classes.overlay} />
        <div className={classes.mainFeaturedPostContent}>
          <Box className={classes.heroHeaderContainer}>
            <Typography
              component="h1"
              variant="h2"
              className={classes.heroHeader}
            >
              {t("hero_header")}
            </Typography>
          </Box>

          <Box pt={4} className={classes.subHeaderContainer}>
            <Typography
              component="h2"
              variant="body1"
              className={classes.subHeader}
            >
              {t("hero_subheader")}
            </Typography>

            <Box pt={4}>
              <Typography
                component="h2"
                variant="body1"
                className={classes.subHeader}
              >
                {t("hero_subheader_2")}
              </Typography>
            </Box>
          </Box>
          <Box pt={10}>
            <Button
              color="primary"
              variant="contained"
              className={classes.beautifulJoinButton}
              component={Link}
              to={"/pricing"}
            >
              {t("hero_get_button")}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              className={classes.watchVideoButton}
              type="button"
              onClick={() => setOpenVideoDialog(true)}
            >
              {t("hero_video_button")}
            </Button>
          </Box>
        </div>
      </Paper>
      <Box pt={10} style={{ overflow: "hidden" }}>
        <Container maxWidth="lg">
          <Box pb={2}>
            <Typography
              component="h2"
              variant="body1"
              align="center"
              className={`${classes.subHeader} ${classes.blackFont}`}
            >
              {t("hero_featured")}
            </Typography>
          </Box>
          <Grid container spacing={6} justify="center" alignItems="center">
            {featuredList.map((f, i) => (
              <Grid item key={i}>
                <a href={f.url} target="_blank" rel="noopener noreferrer">
                  <img
                    src={f.image}
                    alt={f.imageAlt}
                    className={classes.featured}
                  />
                </a>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <HomeVideoDialog
        open={openVideoDialog}
        onClose={() => setOpenVideoDialog(false)}
      />
    </Box>
  );
}
