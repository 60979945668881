import React from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/material-kit-react/views/security";

const useStyles = makeStyles(styles);

export default function GridCard({ data }) {
  const { header, image, text } = data;
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={4} md={4} lg={4}>
      <Card elevation={0} className={classes.gridCard}>
        <CardMedia
          component="img"
          image={image}
          className={classes.cardImage}
        />
        <CardContent>
          <Typography variant="h5" className={classes.cardHeader}>
            {header}
          </Typography>
          <div className={classes.cardTextContainer}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.cardText}
            >
              {text}
            </Typography>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
}
