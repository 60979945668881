import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";

import Box from "@material-ui/core/Box";

import { VsCodePricingHero } from "components/VsCode";
import DealPricingText from "views/PricingPlans/DealPricingText";
import PlansCarousel from "components/PlansCarousel/PlansCarousel";

export default function VsCode() {
  return (
    <>
      <Helmet title="Shells Virtual Desktop - VS Code" />
      <VsCodePricingHero />
      <Box pb={16} style={{ overflowX: "hidden" }}>
        <DealPricingText os="vscode" />
        <PlansCarousel />
      </Box>
    </>
  );
}
