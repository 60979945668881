import { rest } from "@karpeleslab/klbfw";

import {
  ORDER_CREATED,
  ORDER_CREATED_DONE,
  ORDER_CREATED_ERROR,
  USER_BILLING_DONE,
  USER_BILLING_ERROR,
  USER_LOCATION_DONE,
  USER_LOCATION_ERROE
} from "../reducers/CheckOutReducer";
import { catchRedirectException } from "../../components/utils/apiErrorHandler";

export const createLocation = (firstName, lastName, zip, country) => {
  return dispatch => {
    dispatch({ type: ORDER_CREATED });

    return rest("User/@/Location", "POST", {
      First_Name: firstName,
      Last_Name: lastName,
      Zip: zip,
      Country__: country
    })
      .then(catchRedirectException)
      .then(data => {
        dispatch({
          type: USER_LOCATION_DONE
        });
        return data;
      })
      .catch(data => {
        dispatch({
          type: USER_LOCATION_ERROE,
          error: data.message
        });
        throw data;
      });
  };
};

export const createBilling = location => {
  return dispatch => {
    return rest("User/@:setDefaultLocation", "POST", {
      User_Location__: location,
      type: "Billing"
    })
      .then(catchRedirectException)
      .then(data => {
        dispatch({
          type: USER_BILLING_DONE
        });
        return data;
      })
      .catch(data => {
        dispatch({
          type: USER_BILLING_ERROR,
          error: data.message
        });
        throw data;
      });
  };
};

export const createOrder = () => {
  return dispatch => {
    return rest("Catalog/Cart/@:createOrder", "POST")
      .then(catchRedirectException)
      .then(data => {
        dispatch({
          type: ORDER_CREATED_DONE,
          order: data
        });
        return data;
      })
      .catch(data => {
        dispatch({
          type: ORDER_CREATED_ERROR,
          error: data.message
        });
        throw data;
      });
  };
};
