// techradar special plan

const initState = {
  loading: false,
  techRadarPlans: null
};

export const TECHRADAR_PLAN_FETCH = "TECHRADAR_PLAN_FETCH";
export const TECHRADAR_PLAN_FETCH_DONE = "TECHRADAR_PLAN_FETCH_DONE";

const PlanReducer = (state = initState, action) => {
  switch (action.type) {
    case TECHRADAR_PLAN_FETCH:
      return { ...state, loading: true };
    case TECHRADAR_PLAN_FETCH_DONE:
      return {
        ...state,
        loading: false,
        techRadarPlans: action.techRadarPlans
      };
    default:
      return state;
  }
};

export default PlanReducer;
