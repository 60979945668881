import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { selectPlan } from "store/actions/PlanAction";
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import classNames from "classnames";

import { getShellImage } from "./util";

// style
import style from "../../assets/scss/modules/order.module.scss";
import styles from "assets/jss/material-kit-react/views/orderPage.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

const OrderPlan = ({ plan, selected, selectPlan, nbUnitSelected }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  let servicePeriod;
  let planPriceMonthly = null;

  if (selected) {
    servicePeriod = selected["Basic.ServiceLifetime"];
  }

  if (plan["Price.Monthly"] && plan["Description.AuthorCode"] !== "custom") {
    planPriceMonthly = plan["Price.Monthly"].display;
  } else {
    planPriceMonthly = `$${plan["Price.Monthly"].value_disp * nbUnitSelected}`;
  }

  return (
    <div
      className={classNames(style["order-period-list"], {
        [style["order-period-list-active"]]:
          servicePeriod === plan["Basic.ServiceLifetime"]
      })}
    >
      <Grid container spacing={1}>
        <GridItem
          xs={12}
          onClick={() => {
            selectPlan(plan);
          }}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={[
              selected && selected.Catalog_Product__ === plan.Catalog_Product__
                ? classes.plan
                : classes.planNonSelected,
              plan ? "" : classes.planDisabled
            ].join(" ")}
          >
            <GridItem xs={3} className={style["order-plan-img"]}>
              <img
                src={getShellImage(plan)}
                alt={t(`${plan["Description.AuthorCode"]}_plan`)}
              />
            </GridItem>

            <GridItem xs={5}>
              <h5 className={classes.planName}>{plan["Basic.Name"]}</h5>
            </GridItem>

            <GridItem xs={4}>
              <h5
                className={`${style["price-plan"]} ${style["discount-year"]}`}
              >
                {planPriceMonthly}
                <small>{t("by_month")}</small>
              </h5>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    selected: state.plans.selected,
    nbUnitSelected: state.plans.nbUnitSelected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectPlan: plan => dispatch(selectPlan(plan))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderPlan);
