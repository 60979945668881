import React from "react";
import OSHeader from "./OSHeader";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";

export default function OperatingSystems() {
  return (
    <>
      <OSHeader />
      <SectionOne />
      <SectionTwo />
    </>
  );
}
