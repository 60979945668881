import React from "react";
import { useTranslation } from "react-i18next";
import { Get } from "@karpeleslab/klbfw";

const Text = () => {
  const { t } = useTranslation();
  return (
    <textarea
      id="mail-text"
      defaultValue={
        t("registered_waiting_list_ready_mail_greeting", {
          name: Get("_waitlist").Name
        }) +
        "\n\r" +
        t("registered_waiting_list_ready_mail_content") +
        "\n\nhttps://shells.com/login.html\n\r" +
        t("registered_waiting_list_ready_mail_footer") +
        "\n\r"
      }
    ></textarea>
  );
};

export default Text;
