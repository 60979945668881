import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ShellsButton from "./../../CustomButtons/ShellButton";
import Typography from "@material-ui/core/Typography";

import { selectPlan } from "store/actions/PlanAction";
import { addItem, cartFetch } from "store/actions/CartAction";

import PageModal from "components/PageModal/PageModal";
import Section from "views/LandingPage/Other/Section";
import { isMobileTablet } from "./../../utils/mobileTablet";

import { fetchWindowsPlans } from "store/actions/WindowsAction";

import useWindowsCarouselStyles from "./WindowsCarousel.style";

const WindowsCarousel = ({
  fetchWindowsPlans,
  windowsPlans,
  cartItems,
  cartLoading,
  selectPlan,
  selected,
  addItem,
  cartFetch
}) => {
  const { t } = useTranslation();
  const classes = useWindowsCarouselStyles();
  const [isMobile] = useState(isMobileTablet());
  const [currentActiveIdx, setCurrentActiveIdx] = useState(1);
  const [plans] = useState([
    {
      title: t("new_hp_monthly_title"),
      description: t("new_hp_monthly_detail"),
      disclaimer: t("save_n_percent", { number: "83" }),
      price: "8.95",
      priceMonthly: "8.95",
      usageLimit: t("unlimited_use"),
      plan: "basic",
      processors: 1,
      storage: "40 GB",
      memory: "2 GB",
      discount: (
        <>
          <p>
            <span className={classes.linethrough}>$53.85</span>
            $44.90 for 3 months
          </p>
        </>
      ),
      open: false
    },
    {
      title: t("new_hp_plus_title"),
      description: t("new_hp_plus_detail"),
      disclaimer: t("save_n_percent", { number: "80" }),
      price: "13.95",
      priceMonthly: "13.95",
      usageLimit: t("unlimited_use"),
      plan: "plus",
      processors: 2,
      storage: "80 GB",
      memory: "4 GB",
      discount: (
        <>
          <p>
            <span className={classes.linethrough}>$68.85</span>
            $54.90 for 3 months
          </p>
        </>
      ),
      open: false
    },
    {
      title: t("new_hp_yearly_title"),
      description: t("new_hp_yearly_detail"),
      disclaimer: t("save_n_percent", { number: "76" }),
      price: "24.95",
      priceMonthly: "24.95",
      usageLimit: t("unlimited_use"),
      plan: "pro",
      processors: 4,
      storage: "160 GB",
      memory: "8 GB",
      discount: (
        <>
          <p>
            <span className={classes.linethrough}>$101.85</span>
            $76.90 for 3 months
          </p>
        </>
      ),
      open: false
    }
  ]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const plansWrapperRef = useRef(true);
  const [touchPos, setTouchPos] = useState({ x: 0, y: 0 });
  const [touchTimestamp, setTouchTimestamp] = useState(Date.now());
  const history = useHistory();

  // With use of Modal for specs
  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState("");

  const handleOpen = e => {
    e.stopPropagation();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const specsText = [
    t("comparison_table_modal_processor"),
    t("comparison_table_modal_storage"),
    t("comparison_table_modal_memory")
  ];

  const onTouchEnd = (e, plan = null) => {
    const end = Date.now();
    const { x, y } = touchPos;
    if (!matches || !plansWrapperRef || !plansWrapperRef.current) return;

    if (end - touchTimestamp < 100 && plan && !!e.target.closest(".active"))
      return;

    if (
      e.changedTouches[0].clientX < x &&
      (Math.abs(e.changedTouches[0].clientY - y) < 50 ||
        Math.abs(y - e.changedTouches[0].clientY) < 50)
    ) {
      // swipe right
      setCurrentActiveIdx(
        currentActiveIdx < plans.length - 1 ? currentActiveIdx + 1 : 0
      );
    }
    // swipe left
    else if (
      e.changedTouches[0].clientX > x &&
      (Math.abs(e.changedTouches[0].clientY - y) < 50 ||
        Math.abs(y - e.changedTouches[0].clientY) < 50)
    ) {
      setCurrentActiveIdx(
        (currentActiveIdx > 0 ? currentActiveIdx : plans.length) - 1
      );
    }
  };

  const onPlanSelected = planName => {
    selectPlan(windowsPlans[planName]);
    addItem(
      windowsPlans[planName].products[0].id +
        ",shell_os=windows-server-2019-dc-desktop"
    );
    if (cartItems.length < 1)
      history.push(`/join?os=${windowsPlans[planName]["Meta.shell_os"]}`);
  };

  const returnSpec = (spec, specName, index) => {
    if (specName === "Processor") {
      if (spec > 1) {
        specName = t("comparison_table_title_processors");
      } else {
        specName = t("comparison_table_title_processor");
      }
    }

    return (
      <Typography align="center">
        {spec}{" "}
        <span
          onClick={e => {
            handleOpen(e);
            setModalText(specsText[index]);
          }}
          className={classes.specTitle}
        >
          {specName}
        </span>
      </Typography>
    );
  };

  useEffect(() => {
    fetchWindowsPlans();
    cartFetch();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!matches || !plansWrapperRef || !plansWrapperRef.current) return;

    plansWrapperRef.current.setAttribute(
      "style",
      `-webkit-transform: translate3d(-${(currentActiveIdx + 0.5) *
        33.3}%, 0, 0);
        -moz-transform: translate3d(-${(currentActiveIdx + 0.5) * 33.3}%, 0, 0);
          -ms-transform: translate3d(-${(currentActiveIdx + 0.5) *
            33.3}%, 0, 0);
            -o-transform: translate3d(-${(currentActiveIdx + 0.5) *
              33.3}%, 0, 0);
        transform: translate3d(-${(currentActiveIdx + 0.5) * 33.3}%, 0, 0);
        `
    );
  }, [currentActiveIdx]); //eslint-disable-line

  return (
    <Section anchor="pricing" maxWidth="md">
      <Box py={4} className={classes.outer}>
        <PageModal
          handleClose={handleClose}
          open={open}
          modalText={modalText}
        />
        <IconButton
          className={[classes.arrow, classes.arrowLeft].join(" ")}
          onClick={() =>
            setCurrentActiveIdx(
              (currentActiveIdx > 0 ? currentActiveIdx : plans.length) - 1
            )
          }
        >
          <ArrowBackIosIcon className={classes.arrowBack} />
        </IconButton>
        <IconButton
          className={[classes.arrow, classes.arrowRight].join(" ")}
          onClick={() =>
            setCurrentActiveIdx(
              currentActiveIdx < plans.length - 1 ? currentActiveIdx + 1 : 0
            )
          }
        >
          <ArrowForwardIosIcon />
        </IconButton>
        <Box className={classes.inner}>
          <Grid
            container
            spacing={3}
            justify="center"
            alignItems="center"
            className={classes.container}
            ref={plansWrapperRef}
            onTouchStart={e => {
              if (isMobile) {
                setTouchPos({
                  x: e.touches[0].clientX,
                  y: e.touches[0].clientY
                });
              }
            }}
            onTouchEnd={e => {
              if (isMobile) {
                onTouchEnd(e);
              }
            }}
          >
            {plans.map((p, i) => (
              <Grid
                key={`plan-${i}`}
                item
                xs={4}
                className={[
                  classes.plan,
                  currentActiveIdx === i ? "active" : "",
                  "js-plan"
                ].join(" ")}
                onTouchStart={() => {
                  if (isMobile) {
                    setTouchTimestamp(Date.now());
                  }
                }}
                onTouchEnd={e => {
                  if (isMobile) {
                    onTouchEnd(e, p.plan);
                  }
                }}
                onMouseEnter={() => {
                  if (!isMobile) {
                    setTouchTimestamp(Date.now());
                  }
                }}
                onMouseLeave={e => {
                  if (!isMobile) {
                    onTouchEnd(e, p.plan);
                  }
                }}
              >
                <Box
                  className={classes.planInner}
                  onMouseEnter={() => setCurrentActiveIdx(i)}
                >
                  <Grid
                    container
                    spacing={2}
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item style={{ width: "100%" }}>
                      <Typography
                        variant="h5"
                        component="h4"
                        align="center"
                        paragraph
                        className={classes.title}
                      >
                        {p.title}
                      </Typography>
                      <Box px={2}>
                        {p.disclaimer ? (
                          <Typography
                            align="center"
                            className={`${classes.greenText} ${classes.italic}`}
                          >
                            {p.disclaimer}
                          </Typography>
                        ) : (
                          <Box mb={5} />
                        )}
                        <Typography className={classes.price} align="center">
                          ${p.price.toString().split(".")[0]}
                          <sup>{p.price.toString().split(".")[1]}</sup>
                        </Typography>
                        <Typography
                          align="center"
                          className={classes.monthText}
                        >
                          {`/${t("new_hp_month")}`}
                        </Typography>
                        {p.discount && (
                          <Typography
                            component="div"
                            align="center"
                            className={classes.discountPrice}
                          >
                            {p.discount}
                          </Typography>
                        )}
                        <Box
                          py={3}
                          className={
                            p.plan === "lite"
                              ? classes.descriptionContainerClass
                              : ""
                          }
                        >
                          <Typography align="center" className={classes.italic}>
                            {p.description}
                          </Typography>
                        </Box>
                        <Box pb={1}>
                          {returnSpec(p.processors, "Processor", 0)}
                          {returnSpec(
                            p.storage,
                            t("comparison_table_title_storage"),
                            1
                          )}
                          {returnSpec(
                            p.memory,
                            t("comparison_table_title_memory"),
                            2
                          )}
                        </Box>
                        <Typography
                          align="center"
                          className={`${classes.greenText} ${classes.italic}`}
                        >
                          {p.usageLimit}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item>
                      <ShellsButton
                        variant="contained"
                        color="primary"
                        onClick={() => onPlanSelected(p.plan)}
                        disabled={selected && selected.products && cartLoading}
                      >
                        {t("select_this_plan")}
                      </ShellsButton>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Section>
  );
};

const mapStateToProps = state => ({
  cartItems: state.cart.cartItem,
  cartLoading: state.cart.cartLoading,
  selected: state.plans.selected,
  windowsPlans: state.windows.windowsPlans
});

const mapDispatchToProps = dispatch => {
  return {
    selectPlan: plan => dispatch(selectPlan(plan)),
    addItem: plan => dispatch(addItem(plan)),
    cartFetch: () => dispatch(cartFetch()),
    fetchWindowsPlans: () => dispatch(fetchWindowsPlans())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WindowsCarousel);
