import React, { Component } from "react";
import { Redirect as ReactRedirect } from "react-router-dom";
import { trimPrefix } from "@karpeleslab/klbfw";
import CircularProgress from "@material-ui/core/CircularProgress";

class Redirect extends Component {
  external = false;

  constructor(props) {
    super(props);
    if (props.target.startsWith("http")) this.external = true;
  }
  componentDidMount() {
    if (typeof window === "undefined") return;
    if (this.external) window.location.replace(this.props.target);
  }

  render() {
    if (!this.external) {
      const [, target] = trimPrefix(this.props.target);
      return <ReactRedirect to={target} />;
    }

    return <CircularProgress />;
  }
}

export default Redirect;
