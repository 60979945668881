import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import Typography from "@material-ui/core/Typography";
import PageModal from "../../../components/PageModal/PageModal";

import { makeStyles } from "@material-ui/core/styles";
// import { setTextRange } from "typescript";

const useComparisonTableStyles = makeStyles(theme => ({
  blackTitle: {
    backgroundColor: "#202124",
    color: "#fff",
    fontSize: "1rem"
  },
  borderTop: {
    borderTop: "1px solid #202124",
    position: "relative"
  },
  button: {
    backgroundColor: "transparent",
    border: `2px solid ${theme.palette.text.secondary}`,
    borderRadius: "10px",
    boxShadow: "unset",
    color: theme.palette.text.secondary,
    fontSize: "1em",
    "&:hover": {
      backgroundColor: theme.palette.text.secondary,
      boxShadow: "unset",
      color: "#fff"
    }
  },
  centerText: {
    fontWeight: 400,
    textAlign: "center"
  },
  highlightedText: {
    color: "#4e9d0c"
  },
  noPaddingMobile: {
    [theme.breakpoints.down("sm")]: {
      padding: 0
    }
  },
  recommendedCell: {
    borderLeft: `4px solid ${theme.palette.text.secondary}`,
    borderRight: `4px solid ${theme.palette.text.secondary}`,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "rgba(130, 200, 71, .75)",
      border: "unset",
      color: "#fff",

      "& $highlightedText": {
        color: "#fff"
      }
    },

    "& $button": {
      backgroundColor: theme.palette.text.secondary,
      boxShadow: "unset",
      color: "#fff",
      "&:hover": {
        backgroundColor: "transparent",
        color: theme.palette.text.secondary
      }
    }
  },
  recommendedNotice: {
    backgroundColor: theme.palette.text.secondary,
    color: "#fff",
    position: "absolute",
    top: "calc(100% - 20px)",
    left: "-4px",
    width: "calc(100% + 8px)",
    padding: "10px 0",
    border: `4px solid ${theme.palette.text.secondary}`,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "rgba(130, 200, 71, .75)",
      border: "unset",
      fontSize: ".6rem",
      left: 0,
      top: "100%",
      width: "100%"
    }
  },
  reducePaddingMobile: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2)
    }
  },
  pricingPlanCmp: {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer"
    }
  }
}));

const ComparisonTable = ({ plans, onPlanSelected }) => {
  const { t } = useTranslation();
  const classes = useComparisonTableStyles();
  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const pricingPlansText = [
    t("comparison_table_modal_processor"),
    t("comparison_table_modal_storage"),
    t("comparison_table_modal_memory"),
    t("comparison_table_modal_hours_included")
  ];

  return (
    <Container maxWidth="lg" className={classes.noPaddingMobile}>
      <Grid container>
        <PageModal
          handleClose={handleClose}
          open={open}
          modalText={modalText}
        />
        <Hidden smDown>
          <Grid item md={3}>
            <Box
              py={2}
              className={`${classes.borderTop} ${classes.reducePaddingMobile}`}
            >
              <Typography variant="h6" component="p">
                Components
              </Typography>
            </Box>
          </Grid>
        </Hidden>
        {plans.map(plan => (
          <Grid
            item
            xs={3}
            md={plan["Flags.Recommended"] === "Y" ? 3 : 2}
            key={plan.Catalog_Product__}
            className={
              plan["Flags.Recommended"] === "Y" ? classes.recommendedCell : ""
            }
          >
            <Box
              py={2}
              className={`${classes.borderTop} ${classes.reducePaddingMobile}`}
            >
              <Typography
                variant="h6"
                component="p"
                className={classes.centerText}
              >
                {plan["Basic.Name"]}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Hidden mdUp>
        <Grid container>
          <Grid item xs={12} className={classes.blackTitle}>
            <Box
              p={2}
              onClick={() => {
                handleOpen();
                setModalText(pricingPlansText[0]);
              }}
              className={classes.pricingPlanCmp}
            >
              {t("comparison_table_title_processors")} ⓘ
            </Box>
          </Grid>
        </Grid>
      </Hidden>
      <Grid container>
        <Hidden smDown>
          <Grid item xs={3} md={3}>
            <Box
              py={2}
              className={`${classes.borderTop} ${classes.reducePaddingMobile}`}
            >
              <Typography
                variant="h6"
                component="p"
                onClick={() => {
                  handleOpen();
                  setModalText(pricingPlansText[0]);
                }}
                className={classes.pricingPlanCmp}
              >
                {t("comparison_table_title_processors")} ⓘ
              </Typography>
            </Box>
          </Grid>
        </Hidden>
        {plans.map(plan => (
          <Grid
            item
            xs={3}
            md={plan["Flags.Recommended"] === "Y" ? 3 : 2}
            key={plan.Catalog_Product__}
            className={
              plan["Flags.Recommended"] === "Y" ? classes.recommendedCell : ""
            }
          >
            <Box
              py={2}
              className={`${classes.borderTop} ${classes.reducePaddingMobile}`}
            >
              <Typography
                variant="h6"
                component="p"
                className={`${classes.centerText} ${classes.highlightedText}`}
              >
                {Math.ceil(plan["Shell.Size"] / 4)}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Hidden mdUp>
        <Grid container>
          <Grid item xs={12} className={classes.blackTitle}>
            <Box
              p={2}
              onClick={() => {
                handleOpen();
                setModalText(pricingPlansText[1]);
              }}
              className={classes.pricingPlanCmp}
            >
              {t("comparison_table_title_storage_mobile")} ⓘ
            </Box>
          </Grid>
        </Grid>
      </Hidden>
      <Grid container>
        <Hidden smDown>
          <Grid item md={3}>
            <Box py={2} className={classes.reducePaddingMobile}>
              <Typography
                variant="h6"
                component="p"
                onClick={() => {
                  handleOpen();
                  setModalText(pricingPlansText[1]);
                }}
                className={classes.pricingPlanCmp}
              >
                {t("comparison_table_title_storage")} ⓘ
              </Typography>
            </Box>
          </Grid>
        </Hidden>
        {plans.map(plan => (
          <Grid
            item
            xs={3}
            md={plan["Flags.Recommended"] === "Y" ? 3 : 2}
            key={plan.Catalog_Product__}
            className={
              plan["Flags.Recommended"] === "Y" ? classes.recommendedCell : ""
            }
          >
            <Box py={2} className={classes.reducePaddingMobile}>
              <Typography
                variant="h6"
                component="p"
                className={`${classes.centerText} ${classes.highlightedText}`}
              >
                {plan["Shell.Size"] * 10} <Hidden smDown>GB</Hidden>
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Hidden mdUp>
        <Grid container>
          <Grid item xs={12} className={classes.blackTitle}>
            <Box
              p={2}
              onClick={() => {
                handleOpen();
                setModalText(pricingPlansText[2]);
              }}
              className={classes.pricingPlanCmp}
            >
              {t("comparison_table_title_memory_mobile")} ⓘ
            </Box>
          </Grid>
        </Grid>
      </Hidden>
      <Grid container>
        <Hidden smDown>
          <Grid item md={3}>
            <Box py={2} className={classes.reducePaddingMobile}>
              <Typography
                variant="h6"
                component="p"
                onClick={() => {
                  handleOpen();
                  setModalText(pricingPlansText[2]);
                }}
                className={classes.pricingPlanCmp}
              >
                {t("comparison_table_title_memory")} ⓘ
              </Typography>
            </Box>
          </Grid>
        </Hidden>
        {plans.map(plan => (
          <Grid
            item
            xs={3}
            md={plan["Flags.Recommended"] === "Y" ? 3 : 2}
            key={plan.Catalog_Product__}
            className={
              plan["Flags.Recommended"] === "Y" ? classes.recommendedCell : ""
            }
          >
            <Box py={2} className={classes.reducePaddingMobile}>
              <Typography
                variant="h6"
                component="p"
                className={`${classes.centerText} ${classes.highlightedText}`}
              >
                {Math.ceil((plan["Shell.Size"] * 512) / 1024)}{" "}
                <Hidden smDown>GB</Hidden>
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Hidden mdUp>
        <Grid container>
          <Grid item xs={12} className={classes.blackTitle}>
            <Box
              p={2}
              onClick={() => {
                handleOpen();
                setModalText(pricingPlansText[3]);
              }}
              className={classes.pricingPlanCmp}
            >
              {t("comparison_table_title_hours_included")} ⓘ
            </Box>
          </Grid>
        </Grid>
      </Hidden>
      <Grid container>
        <Hidden smDown>
          <Grid item xs={3} md={3}>
            <Box py={2} className={classes.reducePaddingMobile}>
              <Typography
                variant="h6"
                component="p"
                onClick={() => {
                  handleOpen();
                  setModalText(pricingPlansText[3]);
                }}
                className={classes.pricingPlanCmp}
              >
                {t("comparison_table_title_hours_included")} ⓘ
              </Typography>
            </Box>
          </Grid>
        </Hidden>
        {plans.map(plan => (
          <Grid
            item
            xs={3}
            md={plan["Flags.Recommended"] === "Y" ? 3 : 2}
            key={plan.Catalog_Product__}
            className={
              plan["Flags.Recommended"] === "Y" ? classes.recommendedCell : ""
            }
          >
            <Box py={2} className={classes.reducePaddingMobile}>
              <Typography
                variant="h6"
                component="p"
                className={`${classes.centerText} ${classes.highlightedText}`}
              >
                {plan["Shell.Timer_Allowance"] ? (
                  Math.round(plan["Shell.Timer_Allowance"] / 3600)
                ) : (
                  <>
                    <Hidden smDown>Unlimited</Hidden>
                    <Hidden mdUp>
                      <AllInclusiveIcon />
                    </Hidden>
                  </>
                )}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid container>
        <Hidden smDown>
          <Grid item xs={3} md={3}>
            <Box
              py={2}
              className={`${classes.borderTop} ${classes.reducePaddingMobile}`}
            />
          </Grid>
        </Hidden>
        {plans.map(plan => (
          <Grid
            item
            xs={3}
            md={plan["Flags.Recommended"] === "Y" ? 3 : 2}
            key={plan.Catalog_Product__}
            className={
              plan["Flags.Recommended"] === "Y" ? classes.recommendedCell : ""
            }
          >
            <Box
              px={2}
              py={6}
              className={`${classes.borderTop} ${classes.reducePaddingMobile}`}
            >
              <Button
                color="primary"
                variant="contained"
                size="large"
                fullWidth
                className={classes.button}
                onClick={() => onPlanSelected(plan["Description.AuthorCode"])}
              >
                <Hidden smDown>Get started</Hidden>
                <Hidden mdUp>
                  <ReplyAllIcon />
                </Hidden>
              </Button>
              {plan["Flags.Recommended"] && (
                <Typography
                  variant="h6"
                  component="p"
                  className={`${classes.centerText} ${classes.recommendedNotice}`}
                >
                  Recommended
                </Typography>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ComparisonTable;
