import "./assets/fonts/JanoSansProBold.ttf";
import "./index.css";
import "./assets/scss/cms.scss";
import { rest } from "@karpeleslab/klbfw";

const router = require("./router");

// export renderToString function for server side rendering
global.renderToString = router.renderToString;
global.rest = rest;

// start app if it in the browser
if (typeof window !== "undefined") {
  var WebFont = require("webfontloader");
  WebFont.load({
    google: {
      families: ["Open Sans:600,700,800,900", "PT Sans:400,700"]
    }
  });
  // Start main application here
  router.run();

  // prevent tap to zoom and pinch to zoom on Safari iOS 12+ because it doesn't take the meta into account
  document.addEventListener(
    "touchmove",
    event => {
      if (typeof event.scale !== "undefined" && event.scale !== 1)
        event.preventDefault();
    },
    { capture: false, passive: false }
  );

  let lastTouchEnd = 0;
  document.addEventListener(
    "touchend",
    event => {
      const now = new Date().getTime();
      if (now - lastTouchEnd <= 300) event.preventDefault();
      lastTouchEnd = now;
    },
    { capture: false, passive: false }
  );
}
