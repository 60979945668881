/*eslint-disable*/
import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// material-ui core components
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from 'assets/scss/modules/footer.module.scss';

export default function Footer(props) {
    // const classes = useStyles();
    const { whiteFont } = props;
    const { t } = useTranslation();
    return (
        <footer className={styles['footer']}>
            <div
                className={styles['footer-inner']}
            >
                <div
                    className={styles['footer-nav']}
                >
                    <div
                        className={styles['footer-why']}
                    >
                        <dl
                            className={`${styles['footer-list']} ${styles['footer-list-solutions-for-you']}`}
                        >
                            <dt>{t('footer_nav_why_shells')}</dt>
                            <dd>
                                <ul>
                                    <li>
                                        <Link to="/DaaS">
                                            {t('footer_nav_why_shells_t12')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/linux-cloud-desktop">
                                            {t('footer_nav_why_shells_t11')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/browser-in-the-cloud">
                                            {t('footer_nav_why_shells_t14')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/virtual-box">
                                            {t('footer_nav_why_shells_t15')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/windows-subsystem">
                                            {t('footer_nav_why_shells_t16')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/vmware">
                                            {t('footer_nav_why_shells_t17')}
                                        </Link>
                                    </li>
                                    {/*<li>
                                        <Link to="/">
                                            {t('footer_nav_why_shells_t1')}
                                        </Link>
                                    </li>*/}
                                    <li>
                                        <Link to="/lp/vms">
                                            {t('footer_nav_why_shells_t2')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/lp/ide">
                                            {t('footer_nav_why_shells_t3')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/lp/grade">
                                            {t('footer_nav_why_shells_t4')}
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/lp/editing">
                                            {t('footer_nav_why_shells_t5')}
                                        </Link>
                                    </li> */}
                                    {/* <li>
                                        <Link to="/lp/audio">
                                            {t('footer_nav_why_shells_t6')}
                                        </Link>
                                    </li> */}
                                    {/* <li>
                                        <Link to="/lp/3d">
                                            {t('footer_nav_why_shells_t7')}
                                        </Link>
                                    </li> */}
                                    <li>
                                        <Link to="/lp/irc">
                                            {t('footer_nav_why_shells_t10')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/cloudmusic">
                                            {t('footer_nav_why_shells_t0')}
                                        </Link>
                                    </li>
                                    {/*<li>
                                        <Link to="/">
                                            {t('footer_nav_why_shells_t11')}
                                        </Link>
                                    </li> */}
                                </ul>
                            </dd>
                        </dl>
                        <dl
                            className={`${styles['footer-list']}`}
                        >
                            <dt>{t('footer_nav_business')}</dt>
                            <dd>
                                <ul>
                                    <li>
                                        <Link to="/lp/rif">
                                            {t('footer_nav_why_shells_t9')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/lp/byod">
                                            {t('footer_nav_business_t1')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/lp/simplified">
                                            {t('footer_nav_business_t2')}
                                        </Link>
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                    </div>
                    
                    <div
                        className={styles['footer-second']}
                    >
                        <dl
                            className={styles['footer-list']}
                        >
                            <dt>{t('footer_nav_about')}</dt>
                            <dd>
                                <ul>
                                    <li>
                                        <Link to="/whyshells">
                                            {t('footer_whyshells')}
                                        </Link>
                                    </li>
				    {false &&
                                    <li>
                                        <Link to="/team">
                                            {t('footer_team')}
                                        </Link>
                                    </li>}
                                    <li>
                                        <Link to="/page/careers">
                                            {t('footer_link_careers')}
                                        </Link>
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                        <dl
                            className={styles['footer-list']}
                        >
                            <dt>{t('footer_nav_nav')}</dt>
                            <dd>
                                <ul>
                                    {/*<li>
                                        <Link to="/page/about">
                                            {t('footer_nav_nav_t1')}
                                        </Link>
                                    </li> */}
                                    <li>
                                        <Link to="/os">
                                            {t('footer_link_os')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/download">
                                            {t('footer_link_download')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/page/affiliates">
                                            {t('footer_link_affiliates')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">
                                            {t('footer_link_contact')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/network">
                                            {t('worldwide_network')}
                                        </Link>
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                    </div>
            
                    <div
                        className={styles['footer-legal']}
                    >
                        <dl
                            className={styles['footer-list']}
                        >
                            <dt>{t('footer_nav_com')}</dt>
                            <dd>
                                
                                <ul>
                                    {/*<li>
                                        <Link to="/">
                                            {t('footer_nav_com_t1')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            {t('footer_nav_com_t2')}
                                        </Link>
                                    </li>
                                     */}
                                    <li>
                                        <Link to="/blog">
                                            {t('footer_nav_com_t3')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/tutorial">
                                            {t('footer_nav_com_t4')}
                                        </Link>
                                    </li>
                                    <li>
                                        <a href='mailto:support@support.shells.com'>
                                            {t('footer_link_support')}
                                        </a>
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                        <dl
                            className={styles['footer-list']}
                        >
                            <dt>{t('footer_nav_legal')}</dt>
                            <dd>
                                <ul>
                                    <li>
                                        <Link to="/page/terms">
                                            {t('footer_terms_conditions')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/page/privacy">
                                            {t('footer_privacy_policy')}
                                        </Link>
                                    </li>
                                    {/*<li>
                                        <Link to="">
                                            {t('footer_nav_legal_t1')}
                                        </Link>
                                    </li>*/}
                                </ul>
                            </dd>
                        </dl>
                        <dl
                            className={styles['footer-list']}
                        >
                            <dt>{t('footer_nav_payment_methods')}</dt>
                            <div className={styles['footer-payment-icons-all']}>
                                <dd>
                                    <img src={require("assets/img/payment_methods/mastercard.svg")} alt="Mastercard" className={styles['footer-payment-img-mastercard']} />
                                    <img src={require("assets/img/payment_methods/visa.svg")} alt="Visa" className={styles['footer-payment-img-visa']} />
                                    <img src={require("assets/img/payment_methods/bitpay.svg")} alt="Bitpay" className={styles['footer-payment-img']} />
                                    <img src={require("assets/img/payment_methods/discover.svg")} alt="Discover" className={styles['footer-payment-img-discover']} />
                                    <img src={require("assets/img/payment_methods/amex.svg")} alt="American Express" className={styles['footer-payment-img']} />
                                    <img src={require("assets/img/payment_methods/paypal.svg")} alt="Paypal" className={styles['footer-payment-img-discover']} />
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div
                    className={styles['footer-address']}
                >
                    <div className={styles['footer-social']}>
                        <a href="https://www.facebook.com/shellsdotcom" target="_blank" alt="Facebook Icon" className={styles['footer-icon-color']}>
                            <i className="fab fa-2x fa-facebook" aria-hidden="true"></i> 
                        </a>
                        <a href="https://twitter.com/shellsdotcom" target="_blank" alt="Twitter Icon" className={styles['footer-icon-color']}>
                            <i className="fab fa-2x fa-twitter" aria-hidden="true"></i>
                        </a>
                        <a href="https://instagram.com/shellscom" target="_blank" alt="Instagram Icon" className={styles['footer-icon-color']}>
                            <i className="fab fa-2x fa-instagram" aria-hidden="true"></i>
                        </a>
                        <a href="https://www.reddit.com/r/shellscom/" target="_blank" alt="Instagram Icon" className={styles['footer-icon-color']}>
                            <i className="fab fa-2x fa-reddit-alien" aria-hidden="true"></i>
                        </a>
                        <a href="https://discord.gg/c9KQKj2MUU" target="_blank" alt="Discord Icon" className={styles['footer-icon-color']}>
                            <i className="fab fa-2x fa-discord" aria-hidden="true"></i>                
                        </a>
                        <a href="https://www.producthunt.com/posts/shells" target="_blank" alt="Product Hunt Icon" className={styles['footer-icon-color']}>
                            <i className="fab fa-2x fa-product-hunt" aria-hidden="true"></i>
                        </a>
                    </div>
                    <small className={styles['footer-copy']}>{t('footer_copyright', { year: (new Date()).getFullYear() })}</small>
                </div>

            </div>
        </footer>
    );
}

Footer.propTypes = {
    whiteFont: PropTypes.bool,
};
