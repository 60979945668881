import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";

import VirtualBoxListItem from "./VirtualBoxListItem";
import FadeIn from "components/WhyShells/FadeIn";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/virtualBox";

const useStyles = makeStyles(styles);

export default function VirtualBoxList({ list, text }) {
  const classes = useStyles();

  return (
    <Box pt={6}>
      <div className={classes.subTextContainer}>
        <Typography variant="h3" component="h5" className={classes.subHeader}>
          {text}
        </Typography>
      </div>

      <Box pt={4}>
        <List className={classes.listRoot}>
          {list.map((item, index) => (
            <FadeIn direction="right" key={index}>
              <VirtualBoxListItem item={item} index={index} />
            </FadeIn>
          ))}
        </List>
      </Box>
    </Box>
  );
}
