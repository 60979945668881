import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router";

class LoginRequired extends Component {
  render() {
    const { location, user, children, to } = this.props;
    if (user) return <Fragment> {children}</Fragment>;

    const returnTo = encodeURIComponent(
      location.pathname + location.search + location.hash
    );

    return (
      <Redirect
        from={location.pathname}
        to={to ?? "/login?return_to=" + returnTo}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(LoginRequired));
