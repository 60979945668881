import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";

export default withStyles(() => {
  return {
    root: {
      borderRadius: "0px",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none"
      },
      "&:disabled": {
        border: "1px solid rgba(0, 0, 0, 0.12)"
      }
    },
    containedPrimary: {
      color: "white",
      border: "1px solid #72ba3e",
      background: "#72ba3e",
      "&:hover": {
        background: "#ffffff",
        color: "#72ba3e"
      }
    }
  };
})(Button);
