import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";

import Box from "@material-ui/core/Box";
import { Hero, SectionOne } from "components/Minecraft";
import DealPricingText from "views/PricingPlans/DealPricingText";
import PlansCarousel from "components/PlansCarousel/PlansCarousel";

export default function Minecraft() {
  return (
    <>
      <Helmet title="Shells Virtual Desktop - Minecraft" />
      <Hero />
      <SectionOne />
      <Box pb={16} style={{ overflowX: "hidden" }}>
        <DealPricingText os="vscode" />
        <PlansCarousel />
      </Box>
    </>
  );
}
