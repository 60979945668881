import React from "react";
import { useTranslation, Trans } from "react-i18next";

import Grid from "@material-ui/core/Grid";

import { WrapperContainer } from "components/HOC";
import GridCard from "./GridCard";

import {
  changeImage,
  installAnythingImage,
  availabilityImage,
  greatCommunityImage
} from "assets/img/manjaro";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/manjaro";

const useStyles = makeStyles(styles);

export default function SectionOne() {
  const { t } = useTranslation();
  const classes = useStyles();

  const cardData = [
    {
      image: changeImage,
      header: t("manjaro_h4"),
      body: t("manjaro_p1")
    },
    {
      image: installAnythingImage,
      header: t("manjaro_h5"),
      body: t("manjaro_p2")
    },
    {
      image: availabilityImage,
      header: t("manjaro_h6"),
      body: t("manjaro_p3")
    },
    {
      image: greatCommunityImage,
      header: t("manjaro_h7"),
      body: (
        <Trans i18nKey="manjaro_p4">
          PlaceholderText
          <a
            href="https://www.reddit.com/r/shellscom/"
            className={classes.textLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            PlaceholderText
          </a>
        </Trans>
      )
    }
  ];

  return (
    <WrapperContainer addMaxWidth={true} addlCclass={classes.containerPadding}>
      <Grid container spacing={4}>
        {cardData.map((data, index) => (
          <GridCard data={data} key={index} />
        ))}
      </Grid>
    </WrapperContainer>
  );
}
