import {
  container,
  wideContainer,
  title
} from "assets/jss/material-kit-react.js";

const landingPageStyle = theme => ({
  container: {
    zIndex: "12",
    color: "#000000",
    ...container
  },
  wideContainer: {
    zIndex: "12",
    color: "#000000",
    ...wideContainer
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#04222d",
    textDecoration: "none",
    textTransform: "uppercase",
    fontSize: "32px"
  },
  titlebis: {
    ...title,
    minHeight: "32px",
    color: "#04222d",
    textTransform: "uppercase",
    fontSize: "48px",
    textAlign: "right"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    position: "relative",
    zIndex: "3"
  },
  video: {
    width: "100%",
    height: "100%"
  },
  mainRaised: {
    padding: "0px",
    margin: "0px 0px 0px 0px",
    borderRadius: "0px"
  },
  hero: {
    width: "100vw",
    height: "auto",
    padding: "50px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      marginTop: "0"
    }
  },
  heroInner: {
    width: "100%",
    maxWidth: "1250px",
    margin: "0 auto"
  },
  heroContent: {
    display: "flex",
    flexFlow: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      marginTop: "50px"
    }
  },
  heroTitle: {
    color: "#020202",
    fontSize: "52px",
    fontFamily: "IBM Plex Sans",
    fontWeight: "bold",
    margin: "-13px 12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "24px",
      fontSize: "30px"
    }
  },
  heroDesc: {
    margin: "20px 12px 0 12px",
    fontSize: "16px",
    [theme.breakpoints.down("md")]: {
      margin: "24px"
    },
    [theme.breakpoints.down("sm")]: {
      margin: "92px auto 10px",
      textAlign: "center"
    },
    [theme.breakpoints.down("xs")]: {
      margin: "24px auto 10px"
    }
  },
  heroPic: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "50%",
      maxWidth: "400px",
      margin: "0 auto 24px auto"
    }
  },
  beautifulButton: {
    marginTop: "24px",
    padding: "6px 24px",
    backgroundColor: "#fff",
    boxShadow: "0 0 0 2px #5ba331 inset",
    borderRadius: "35px",
    color: "#5ba331",
    fontSize: "16px",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover": {
      boxShadow: "none",
      background: "linear-gradient(45deg, #5ba331 0%, #75cb42 100%)",
      color: "#fff"
    }
  },
  beautifulJoinButton: {
    width: "315px",
    marginTop: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "6px 24px",
    backgroundColor: "linear-gradient(45deg, #5ba331 0%, #75cb42 100%)",
    borderRadius: "35px",
    color: "#fff",
    fontSize: "22px",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover": {
      boxShadow: "0 0 0 2px #5ba331 inset",
      background: "#fff",
      color: "#5ba331"
    }
  },
  genericPageWrapper: {
    position: "relative",
    height: "auto",
    minHeight: "calc(100vh - 188px)",
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "160px"
    },
    "&::after": {
      content: "''",
      position: "absolute",
      top: "70px",
      left: "50%",
      width: "100%",
      height: "167px",
      transform: "translateX(-50%)"
    }
  },
  imgContact: {
    width: "250px"
  },
  smallLinkWrapper: {
    textAlign: "center",
    width: "100%"
  },
  smallLink: {
    fontSize: "14px",
    color: "#002774",
    textDecoration: "underLine",
    "&:hover": {
      color: "#002774",
      textDecoration: "none",
      opacity: ".4"
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginBottom: "40px"
    }
  },
  margin5: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "40px"
    }
  },
  userFlowDialog: {
    width: "auto",
    minWidth: "130px"
  }
});

export default landingPageStyle;
