import React from "react";
import { useTranslation } from "react-i18next";
import Helmet from "components/instances/instance/Helmet/Helmet";

import Box from "@material-ui/core/Box";

import PlansCarousel from "components/PlansCarousel/PlansCarousel";
import DealPricingText from "views/PricingPlans/DealPricingText";

import { VirtualBoxHero, VirtualBoxList } from "components/VirtualBox";

export default function VirtualBox() {
  const { t } = useTranslation();

  const bulletPoints = [
    t("virtual_box_bullet_1"),
    t("virtual_box_bullet_2"),
    t("virtual_box_bullet_3"),
    t("virtual_box_bullet_4"),
    t("virtual_box_bullet_5"),
    t("virtual_box_bullet_6")
  ];

  return (
    <>
      <Helmet title="Shells Virtual Desktop - VirtualBox" />
      <VirtualBoxHero page="virtualBox" />
      <VirtualBoxList list={bulletPoints} text={t("virtual_box_subtext")} />
      <Box pb={18} style={{ overflowX: "hidden" }}>
        <DealPricingText os="virtualBox" />
        <PlansCarousel />
      </Box>
    </>
  );
}
