import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/edTech";

import { headerImage } from "../../assets/img/edtech";
import { getLocale } from "@karpeleslab/klbfw";

import {
  Header,
  SectionOne,
  SectionTwo,
  SectionThree,
  SectionFour
} from "components/Edtech";

const defaultLng = getLocale();
const useStyles = makeStyles(styles);

export default function EdTech() {
  const { t } = useTranslation();
  const classes = useStyles();

  const headerData = {
    header: t("edtech_h1"),
    description: t("edtech_p1_a"),
    image: headerImage
  };

  return (
    <div
      className={[
        classes.body,
        defaultLng === "ja-JP" ? classes.japanesePage : ""
      ].join(" ")}
    >
      <Helmet title={t("edtech_h1")} />

      {/* Distance learning */}
      <Header details={headerData} />

      {/* Multi Device */}
      <Box my={8}>
        <SectionOne />
      </Box>

      {/* Set up Shells */}
      <SectionTwo />

      {/* Dedicated Shells */}
      <SectionThree />

      {/* Virus resistant */}
      <SectionFour />
    </div>
  );
}
