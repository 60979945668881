import React from "react";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import GridCard from "./GridCard";
import { WrapperContainer } from "components/HOC";

import { image2, image3, image4, image5 } from "assets/img/cloudmusic";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/cloudmusic";

const useStyles = makeStyles(styles);

export default function SectionTwo() {
  const { t } = useTranslation();
  const classes = useStyles();

  const data = [
    {
      header: t("cloudmusic_h4"),
      body: t("cloudmusic_p1"),
      cardContentClass: classes.cardContentOne,
      image: image2,
      benefit: false
    },
    {
      header: t("cloudmusic_h5"),
      body: t("cloudmusic_p2"),
      cardContentClass: classes.cardContentOne,
      image: image3,
      benefit: false
    },
    {
      header: t("cloudmusic_h6"),
      body: t("cloudmusic_p3"),
      cardContentClass: classes.cardContentOne,
      image: image4,
      benefit: false
    },
    {
      header: t("cloudmusic_h7"),
      body: t("cloudmusic_p4"),
      cardContentClass: classes.cardContentOne,
      image: image5,
      benefit: false
    }
  ];

  return (
    <WrapperContainer addlWclass={classes.backgroundLight} addMaxWidth={true}>
      <Grid container spacing={4} justify="center" alignItems="center">
        {data.map((data, i) => (
          <GridCard data={data} key={i} />
        ))}
      </Grid>
    </WrapperContainer>
  );
}
