import {
  mainGreenColor,
  whiteColor,
  secondaryGreenColor
} from "assets/jss/material-kit-react";
import { lttBG, lttBGmobile, lttBGtablet } from "assets/img/ltt";

const styles = theme => ({
  mainFeaturedPost: {
    position: "relative",
    color: theme.palette.common.white,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: 0,
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${lttBGmobile})`
    },
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${lttBGtablet})`
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${lttBG})`
    }
  },
  vsCodeIpadImage: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "45px"
    }
  },
  morePadding: {
    padding: "100px 45px",
    [theme.breakpoints.down("xs")]: {
      padding: "60px 15px 60px 15px"
    }
  },
  mainHeader: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: "900",
    color: whiteColor,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "42.5984px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "52px"
    }
  },
  subHeader: {
    fontSize: "22px",
    fontFamily: "Open Sans, Roboto",
    color: whiteColor,
    lineHeight: 1.4,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: 1.4
    }
  },
  headerTextContainer: {
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "350px",
      textAlign: "center"
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "550px"
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "750px"
    }
  },
  subTextContainer: {
    maxWidth: "750px",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "320px"
    }
  },
  signUpButton: {
    display: "block",
    margin: "40px 0 40px 0",
    backgroundColor: mainGreenColor,
    borderRadius: "35px",
    color: whiteColor,
    fontSize: "22px",
    textAlign: "center",
    padding: "6px 24px",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover, &:focus": {
      boxShadow: `0 0 0 2px ${secondaryGreenColor} inset`,
      background: whiteColor,
      color: secondaryGreenColor
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "auto",
      marginRight: "auto"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "250px",
      padding: "6px 14px"
    },
    [theme.breakpoints.up("md")]: {
      width: "375px"
    }
  }
});

export default styles;
