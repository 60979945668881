const sendGtagEvent = (
  action,
  { event_category = null, event_label = null }
) => {
  if (typeof window === "undefined" || !window.gtag) return;

  window.gtag("event", action, {
    event_category,
    event_label
  });
};

export default sendGtagEvent;
