import React from "react";

import { makeStyles } from "@material-ui/core/styles";

const useRecommendedStyles = makeStyles(theme => {
  const characterRotations = {};
  for (let i = 1; i <= 30; i++)
    characterRotations[`char${i}`] = { transform: `rotate(${i * 5}deg)` };

  return {
    root: {
      color: theme.palette.text.secondary,
      "& span": {
        fontSize: "16px",
        height: "160px",
        left: 0,
        position: "absolute",
        textAlign: "center",
        top: 0,
        transformOrigin: "bottom center",
        width: "14px"
      }
    },
    ...characterRotations
  };
});

const Recommended = ({ text }) => {
  const classes = useRecommendedStyles();

  return (
    <p className={classes.root}>
      {text.split("").map((char, index) => (
        <span key={index} className={classes[`char${index + 1}`]}>
          {char}
        </span>
      ))}
    </p>
  );
};

export default Recommended;
