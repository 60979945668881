import React from "react";
import { Get } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import moment from "moment";

const Text = () => {
  const { t } = useTranslation();
  const userName = Get("_user").Profile.Display_Name;
  const shellName = Get("_shell").Label;
  const productId = Get("_shell").Catalog_Product__;
  const shellId = Get("_shell").Shell__;
  const renewLink = `https://www.shells.com/cart?product=${productId},shell=${shellId}`;
  const now = moment();
  const new_date = Get("_shell").Expires
    ? moment(parseInt(Get("_shell").Expires.unixms))
    : "N/A";
  const days = new_date !== "N/A" ? new_date.diff(now, "days") : "N/A";

  return (
    <textarea
      id="mail-text"
      defaultValue={t("mail_shell_expires_content_text", {
        shellName: shellName,
        userName: userName,
        renewLink: renewLink,
        days: days
      })}
    />
  );
};

export default Text;
