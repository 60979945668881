import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/security";

import { WrapperContainer } from "components/HOC";

const useStyles = makeStyles(styles);

export default function HeaderBG({ data }) {
  const classes = useStyles();
  const {
    mainHeaderTextOne,
    mainHeaderTextTwo,
    subHeaderTextOne,
    subHeaderTextTwo,
    subHeaderClass,
    mainFeaturedClass,
    header,
    top
  } = data;

  let view;
  if (top) {
    view = (
      <Paper className={mainFeaturedClass}>
        <WrapperContainer addMaxWidth={true}>
          <div className={classes.overlayTop} />
          <div className={classes.mainFeaturedPostContent}>
            <Typography variant="h1" className={classes.mainHeaderTop}>
              {mainHeaderTextOne}
            </Typography>
            <Typography variant="h1" className={classes.mainHeader}>
              {mainHeaderTextTwo}
            </Typography>
            <Typography variant="h5" className={subHeaderClass}>
              {subHeaderTextOne}
            </Typography>
            <Typography variant="h5" className={subHeaderClass}>
              {subHeaderTextTwo}
            </Typography>
          </div>
        </WrapperContainer>
      </Paper>
    );
  } else {
    view = (
      <Paper className={mainFeaturedClass}>
        <WrapperContainer addMaxWidth={true}>
          <div className={classes.overlayBottom} />
          <div className={classes.mainFeaturedPostContent}>
            <div className={classes.whiteOutline}>
              <Typography variant="h5" className={classes.bottomHeader}>
                {header}
              </Typography>
              <Typography variant="h5" className={classes.bottomDescription}>
                {subHeaderTextOne}
              </Typography>
              <Typography variant="h5" className={classes.bottomDescription}>
                {subHeaderTextTwo}
              </Typography>
            </div>
          </div>
        </WrapperContainer>
      </Paper>
    );
  }

  return view;
}
