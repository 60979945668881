import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/components/wrapperContainerStyle";

const useStyles = makeStyles(styles);

export default function WrapperContainer({
  children,
  addlWclass,
  addlCclass,
  addMaxWidth
}) {
  const classes = useStyles();

  if (addMaxWidth) {
    return (
      <div className={`${classes.wrapper} ${addlWclass}`}>
        <div className={`${classes.container} ${addlCclass}`}>{children}</div>
      </div>
    );
  } else {
    return <div className={`${classes.wrapper} ${addlWclass}`}>{children}</div>;
  }
}
