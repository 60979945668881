import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";
import { Trans, useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/material-kit-react/views/security";

import {
  oneOfThree,
  linuxGraph,
  linuxOpenSource,
  linuxSecure,
  linuxKernels,
  patchingSecurity
} from "assets/img/security";

import { WrapperContainer } from "components/HOC";
import {
  HeaderBG,
  GridCard,
  ImageAndText,
  StyledBody,
  StyledText
} from "components/Security";
import PlansSection from "../LandingPage/Sections/PlansSection";

const useStyles = makeStyles(styles);

export default function Security() {
  const { t } = useTranslation();
  const classes = useStyles();

  const dataOne = {
    mainHeaderTextOne: t("security_h1"),
    mainHeaderTextTwo: t("security_h2"),
    subHeaderTextOne: t("security_h3"),
    subHeaderClass: classes.subHeader,
    mainFeaturedClass: classes.mainFeaturedPost,
    top: true
  };
  const dataTwo = {
    textOne: (
      <Trans
        i18nKey="security_p1"
        components={{
          bold: <strong />,
          green: <span className={classes.green} />
        }}
      />
    ),
    imageOne: linuxSecure,
    textTwo: (
      <Trans i18nKey="security_p2">
        PlaceholderText
        <span className={classes.boldAndGreen}>PlaceholderText</span>
        PlaceholderText
        <a
          href="https://forum.shells.net"
          className={classes.communityLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          PlaceholderText
        </a>
        .
      </Trans>
    ),
    imageTwo: linuxOpenSource,
    headerOne: t("security_h7"),
    headerTwo: t("security_h8")
  };
  const dataThree = {
    image: oneOfThree,
    header: t("security_h11"),
    text: t("security_p3")
  };
  const dataFour = {
    image: patchingSecurity,
    header: t("security_h12"),
    text: t("security_p4")
  };
  const dataFive = {
    image: linuxKernels,
    header: t("security_h13"),
    text: t("security_p5")
  };
  const dataSix = {
    headerTextOne: t("security_h4"),
    headerTextTwo: t("security_h5"),
    subText: (
      <Trans
        i18nKey="security_h6"
        components={{
          bold: <strong />,
          green: <span className={classes.green} />
        }}
      />
    )
  };
  const dataSeven = {
    imageRight: false,
    image: linuxGraph,
    header: t("security_h10"),
    text: (
      <Trans
        i18nKey="security_p6"
        components={{
          bold: <strong />,
          green: <span className={classes.green} />
        }}
      />
    )
  };
  const dataEight = {
    subHeaderTextOne: (
      <Trans
        i18nKey="security_p7"
        components={{
          bold: <strong />,
          green: <span className={classes.green} />
        }}
      />
    ),
    subHeaderTextTwo: t("security_p8"),
    subHeaderClass: classes.bottomSubHeader,
    mainFeaturedClass: classes.bottomFeaturedPost,
    header: t("security_h9"),
    top: false
  };

  return (
    <div className={classes.body}>
      <Helmet title={t("security_h1")} />
      <HeaderBG data={dataOne} />
      <WrapperContainer
        addMaxWidth={true}
        addlWclass={classes.lightBlueBackground}
      >
        <StyledBody data={dataTwo} />
      </WrapperContainer>
      <WrapperContainer
        addMaxWidth={true}
        addlWclass={`${classes.lightBlueBackground} ${classes.marginTop}`}
      >
        <Grid container spacing={3} justify="center" alignItems="center">
          <GridCard data={dataThree} />
          <GridCard data={dataFour} />
          <GridCard data={dataFive} />
        </Grid>
      </WrapperContainer>
      <WrapperContainer
        addMaxWidth={true}
        addlWclass={classes.darkBlueBackground}
      >
        <StyledText data={dataSix} />
      </WrapperContainer>
      <WrapperContainer
        addMaxWidth={true}
        addlWclass={classes.lightBlueBackground}
      >
        <ImageAndText data={dataSeven} />
      </WrapperContainer>
      <HeaderBG data={dataEight} />
      <div className={classes.marginBottom} />
      <PlansSection maxWidth="xl" />
    </div>
  );
}
