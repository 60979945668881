import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { isMobileTablet } from "components/utils/mobileTablet";
import SharedLinuxPage from "../SharedLinuxPage/SharedLinuxPage";
import { ubuntuDevicesDesktop, ubuntuDevicesMobile } from "assets/img/ubuntu";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/ubuntu";

const useStyles = makeStyles(styles);

export default function UbuntuPricingPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isMobile] = useState(isMobileTablet());

  let image = ubuntuDevicesDesktop;
  if (isMobile) {
    image = ubuntuDevicesMobile;
  }

  const headerDetails = {
    header: t("ubuntu_pricing_header"),
    description: t("ubuntu_pricing_subheader"),
    image,
    heroClass: classes.mainFeaturedPost,
    imageClass: classes.ubuntuDevices,
    whiteFont: classes.whiteFont
  };

  return (
    <SharedLinuxPage
      title={"Shells Virtual Desktop - Ubuntu"}
      headerDetails={headerDetails}
      buttonText={t("ubuntu_pricing_hero_button")}
      buttonUrl="#pricing"
      heroRibbonBool={true}
      showDistrosList={true}
      showDiscountPlans={true}
      specialButtonClass={classes.signUpButton}
    />
  );
}
