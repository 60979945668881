import { USER_LOGOUT } from "./UserReducer";

const initState = {
  cartItem: [],
  cart: null,
  cartLoading: false,
  cartError: false,
  cartAdd: false
};

export const SYNC_CART = "SYNC_CART";
export const SYNC_CART_DONE = "SYNC_CART_DONE";
export const SYNC_CART_ERROR = "SYNC_CART_ERROR";
export const CART_ADD_DONE = "CART_ADD_DONE";

const cartReducer = (state = initState, action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return { ...initState };
    case SYNC_CART:
      return {
        ...state,
        cartLoading: true,
        cartAdd: false
      };
    case SYNC_CART_ERROR:
      return {
        ...state,
        cartLoading: false,
        cartError: action.error,
        cartAdd: false
      };
    case SYNC_CART_DONE:
      return {
        ...state,
        cartLoading: false,
        cartError: null,
        cart: action.cart,
        cartItem: action.item,
        cartAdd: false
      };
    case CART_ADD_DONE:
      return {
        ...state,
        cartLoading: false,
        cartError: null,
        cart: action.cart,
        cartItem: action.item,
        cartAdd: false
      };
    default:
      return state;
  }
};

export default cartReducer;
