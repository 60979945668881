// nodejs library that concatenates classes
import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import { logout } from "store/actions/UserActions";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CartButton from "./CartButton";
import Menu from "@material-ui/icons/Menu";

// actions
import { selectPlan, fetchPlans } from "store/actions/PlanAction";
import { cartFetch } from "store/actions/CartAction";

import styles from "assets/jss/material-kit-react/components/headerStyle.js";
import shellsLogo from "assets/img/shells_header_logo.svg";
import mobileShellsLogo from "assets/img/shellslogo-black.png";

const useStyles = makeStyles(styles);

const Header = ({
  user,
  logout,
  selectPlan,
  cart,
  cartFetch,
  fetchPlans,
  selected,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      if (typeof window === "undefined") return;
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        if (typeof window === "undefined") return;
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });

  React.useEffect(() => {
    cartFetch();
    fetchPlans();
  }, []); // eslint-disable-line

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;

    if (typeof window !== "undefined") {
      const windowsScrollTop = window.pageYOffset;
      if (windowsScrollTop > changeColorOnScroll.height - 400) {
        document.body
          .getElementsByTagName("header")[0]
          .classList.remove(classes[color]);
        document.body
          .getElementsByTagName("header")[0]
          .classList.add(classes[changeColorOnScroll.color]);
      } else {
        document.body
          .getElementsByTagName("header")[0]
          .classList.add(classes[color]);
        document.body
          .getElementsByTagName("header")[0]
          .classList.remove(classes[changeColorOnScroll.color]);
      }
    }
  };
  const handleLogout = () => {
    logout();
  };

  const handleJoin = () => {
    if (cart && cart.cartItem && cart.cartItem.length > 0) {
      const url = !cart.cartItem[0].meta.shell_os
        ? `/join`
        : `/join?os=${cart.cartItem[0].meta.shell_os}`;

      if (selected && selected.products) {
        // this is for windows plans
        selectPlan(selected);
        return history.push(url);
      }

      selectPlan(cart.cartItem[0].data);
      history.push(url);
    } else history.push("/pricing");
  };

  const { color, rightLinks, leftLinks, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });

  const menuItems = (
    <div className={classes.menuItemContainer}>
      <Button
        color="inherit"
        className={classes.navLink}
        component={Link}
        to="/whyshells"
      >
        {t("menu_whyshells")}
      </Button>
      <Button
        color="inherit"
        className={classes.navLink}
        component={Link}
        to="/pricing"
      >
        {t("menu_pricing")}
      </Button>
      {/* <Hidden mdUp> */}
      <Button
        color="inherit"
        className={classes.navLink}
        component={Link}
        to="/download"
      >
        {t("menu_download")}
      </Button>
      {/* </Hidden> */}
    </div>
  );

  const getButtons = user => {
    if (!user) {
      return (
        <>
          <Hidden smDown>
            {location.pathname.indexOf("/join") < 0 &&
              location.pathname.indexOf("/cart") < 0 &&
              location.pathname.indexOf("/login") < 0 &&
              location.pathname.indexOf("/order") < 0 &&
              location.pathname.indexOf("/register") < 0 && <CartButton />}
          </Hidden>
          <Hidden mdUp>
            <Button component={Link} to="/" className={classes.title}>
              <img
                src={mobileShellsLogo}
                alt="Shells Logo"
                className={classes.mobileLogo}
              />
            </Button>
          </Hidden>
          {location.pathname !== "/order" && (
            <Button
              color="primary"
              variant="contained"
              className={[classes.beautifulButton, classes.margin5].join(" ")}
              onClick={handleJoin}
            >
              {t("menu_signup")}
            </Button>
          )}
          <Button
            disabled={location.pathname === "/login"}
            color="primary"
            variant="contained"
            className={[classes.beautifulButton, classes.signUpButton].join(
              " "
            )}
            onClick={() => history.push("/login")}
          >
            {t("menu_signin")}
          </Button>
        </>
      );
    }

    return (
      <List className={classes.list}>
        <Hidden smDown>
          <ListItem className={classes.listItem}>
            {location.pathname.indexOf("/join") < 0 &&
              location.pathname.indexOf("/cart") < 0 &&
              location.pathname.indexOf("/login") < 0 &&
              location.pathname.indexOf("/order") < 0 &&
              location.pathname.indexOf("register") < 0 && <CartButton />}
          </ListItem>
        </Hidden>
        <ListItem className={classes.listItem}>
          <Button
            className={`${classes.navLink} ${classes.logoutBtn}`}
            component={Link}
            onClick={handleLogout}
            to={"/login"}
            endIcon={<ExitToAppIcon />}
          >
            {t("menu_logout")}
          </Button>
        </ListItem>
      </List>
    );
  };

  const brandComponent = (
    <Button component={Link} to="/" className={classes.title}>
      <img src={shellsLogo} alt="Shells Logo" className={classes.imgWhite} />
    </Button>
  );

  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        {leftLinks !== undefined ? brandComponent : null}
        <div className={classes.flex}>
          {leftLinks !== undefined ? (
            <Hidden mdDown implementation="css">
              {leftLinks}
            </Hidden>
          ) : (
            brandComponent
          )}
        </div>
        <Hidden
          smDown
          className={classes.flexifiedMenuItems}
          implementation="css"
        >
          {rightLinks}
        </Hidden>
        {!user && (
          <Hidden smDown implementation="css">
            {menuItems}
          </Hidden>
        )}
        <Hidden smDown implementation="css">
          {getButtons(user)}
        </Hidden>
        <Hidden mdUp>
          <div>
            <CartButton />
            <IconButton aria-label="open drawer" onClick={handleDrawerToggle}>
              <Menu />
            </IconButton>
          </div>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClick={handleDrawerToggle}
        >
          <div className={classes.appResponsive}>
            {leftLinks}
            {rightLinks}
            {getButtons(user)}
            {!user && menuItems}
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
};

const mapDispatchToProps = dispatch => ({
  fetchPlans: () => dispatch(fetchPlans()),
  cartFetch: () => dispatch(cartFetch()),
  selectPlan: plan => dispatch(selectPlan(plan)),
  logout: () => dispatch(logout())
});

const mapStateToProps = state => ({
  cart: state.cart,
  planMinimum: state.plans.planMinimum,
  user: state.user && state.user.user,
  selected: state.plans.selected
});

Header.defaultProp = {
  user: null,
  color: "white"
};

Header.propTypes = {
  user: PropTypes.any,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
