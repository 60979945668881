import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/landingPage";
import Redirect from "../../components/Route/Redirect";

const useStyles = makeStyles(styles);

const ConsoleRedirect = () => {
  const classes = useStyles();

  return (
    <div className={classes.genericPageWrapper}>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div
          className={classes.heroInner}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Redirect target={process.env.REACT_APP_SHELL_CONSOLE_URL} />
        </div>
      </div>
    </div>
  );
};

export default ConsoleRedirect;
