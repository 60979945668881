import {
  img1_linuxCloud,
  img2,
  img3,
  img4,
  img5
} from "../../assets/img/linux-cloud-desktop";

export const sharedLinuxDetails = [
  {
    type: "imageRight",
    header: "lp_linux_cloud_p1_title",
    description: "lp_linux_cloud_p1_desc",
    imgAlt: "multi device support",
    img: img1_linuxCloud,
    imgClass: "multiDeviceImage"
  },
  {
    type: "imageLeft",
    header: "lp_linux_cloud_p2_title",
    description: "lp_linux_cloud_p2_desc",
    imgAlt: "powered by the cloud",
    img: img2,
    imgClass: "cloudImage"
  },
  {
    type: "imageRight",
    header: "lp_linux_cloud_p3_title",
    description: "lp_linux_cloud_p3_desc",
    imgAlt: "safe and secure",
    img: img3,
    imgClass: "secureImage"
  },
  {
    type: "imageLeft",
    header: "lp_linux_cloud_p4_title",
    description: "lp_linux_cloud_p4_desc",
    imgAlt: "plan for any budget",
    img: img4,
    imgClass: "cloudImage"
  },
  {
    type: "imageRight",
    header: "lp_linux_cloud_p5_title",
    description: "lp_linux_cloud_p5_desc",
    imgAlt: "plan for any budget",
    img: img5,
    imgClass: "cloudImage"
  }
];
