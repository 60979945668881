import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    height: "48px",
    padding: "6px 24px",
    backgroundColor: "#72ba3e",
    borderRadius: "35px",
    color: "#fff",
    fontSize: "20px",
    overflow: "hidden",
    cursor: "pointer",
    transition:
      "height .5s cubic-bezier(.17,.67,.16,.99), transform .5s cubic-bezier(.17,.67,.16,.99), background-color .15s linear",
    "&:not(.open):hover": {
      backgroundColor: "#5ba331"
    },
    "&.open": {
      height: "96px",
      transform: "translate3d(0, -33%, 0)"
    },
    "&.open > :first-child": {
      transform: "translate3d(0, -100%, 0)",
      opacity: "0"
    },
    "&.open > :last-child": {
      top: "0",
      opacity: "1"
    },
    "&::before": {
      content: "''",
      position: "absolute",
      top: "calc(50% - 1px)",
      left: "50%",
      width: "0",
      height: "0",
      borderBottom: "1px solid #fff",
      opacity: "0",
      zIndex: "2"
    },
    "&.open::before": {
      width: "100%",
      opacity: "1",
      left: "0",
      transition:
        "left .5s cubic-bezier(.17,.67,.16,.99), width .5s cubic-bezier(.17,.67,.16,.99), opacity .25s linear"
    },
    "@media (max-width: 960px)": {
      fontSize: "16px"
    }
  },
  label: {
    padding: "6px 24px",
    fontWeight: "bold",
    transition:
      "transform .25s cubic-bezier(.17,.67,.16,.99), opacity .25s linear"
  },
  options: {
    position: "absolute",
    top: "100%",
    left: "0",
    width: "100%",
    opacity: "0",
    transition: "top .25s cubic-bezier(.17,.67,.16,.99), opacity .25s linear"
  },
  option: {
    display: "flex",
    width: "100%",
    padding: "12px 24px",
    justifyContent: "space-between",
    alignItems: "center",
    "& > *": {
      height: "100%"
    },
    "&:hover": {
      backgroundColor: "#5ba331"
    }
  }
}));

export default ({ monthlyPrice, yearlyPrice, onChanged }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const cb = () => {
      setOpen(false);
    };
    document.addEventListener("mouseup", cb);

    return () => document.removeEventListener("mouseup", cb);
  }, [setOpen]);

  return (
    <div
      className={`${classes.root} ${open ? "open" : ""}`}
      onClick={() => setOpen(!open)}
    >
      <div className={classes.label}>{t("select_this_plan")}</div>
      <div className={classes.options}>
        <div className={classes.option} onClick={() => onChanged("1m")}>
          <div>
            <strong>{t("monthly")}</strong>
          </div>
          <div>${monthlyPrice}</div>
        </div>
        <div className={classes.option} onClick={() => onChanged("1y")}>
          <div>
            <strong>{t("yearly")}</strong>
          </div>
          <div>${yearlyPrice}</div>
        </div>
      </div>
    </div>
  );
};
