import React from "react";
import Header from "./../Header/Header.js";
import HeaderLinks from "./../Header/HeaderLinks.js";

const dashboardRoutes = [];

export default props => {
  const { ...rest } = props;

  return (
    <Header
      color="transparent"
      routes={dashboardRoutes}
      rightLinks={<HeaderLinks />}
      fixed
      changeColorOnScroll={{
        height: 400,
        color: "white"
      }}
      {...rest}
    />
  );
};
