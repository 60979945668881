import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { selectUnit } from "store/actions/PlanAction";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import BuildSpecItem from "./BuildSpecItem";
import BuildOtherField from "./BuildOtherField";
import { isCustomPlan } from "./util";

// img
import cpuImg from "assets/img/chip.png";
import hddImg from "assets/img/disk.png";
import ramImg from "assets/img/memory.png";
import timeAllowanceImg from "assets/img/hours.png";

import styles from "assets/jss/material-kit-react/components/buildSpecStyle";
const useStyles = makeStyles(styles);

const BuildSpec = ({ selected, plan, selectUnit, nbUnitSelected }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [unitSelected, setUnit] = useState(nbUnitSelected);

  const buildVcpu = selected => {
    if (isCustomPlan(selected)) {
      return Math.ceil(plan.unit_cpu * unitSelected);
    } else {
      return Math.ceil(selected["Shell.Size"] * plan.unit_cpu);
    }
  };

  const buildTimeAllowance = selected => {
    return t("shell_time_allowance", {
      time: parseInt(selected["Shell.Timer_Allowance"]) / 3600
    });
  };

  const buildHdd = selected => {
    if (isCustomPlan(selected)) {
      return `${plan.unit_hdd * unitSelected} GB`;
    } else {
      return `${selected["Shell.Size"] * plan.unit_hdd} GB`;
    }
  };

  const buildRam = selected => {
    if (isCustomPlan(selected)) {
      return `${plan.unit_ram * unitSelected} MB`;
    } else {
      return `${selected["Shell.Size"] * plan.unit_ram} MB`;
    }
  };

  const specItems = [
    {
      tooltipTitle: t("cpu_tooltip"),
      tooltipDesc: t("cpu_tooltip_desc"),
      build: buildVcpu,
      spec: "VCPU",
      image: cpuImg,
      imageAlt: "cpu",
      arg: "cpu"
    },
    {
      tooltipTitle: t("hdd_tooltip"),
      tooltipDesc: t("hdd_tooltip_desc"),
      build: buildHdd,
      spec: "SSD",
      image: hddImg,
      imageAlt: "hdd",
      arg: "hdd"
    },
    {
      tooltipTitle: t("ram_tooltip"),
      tooltipDesc: t("ram_tooltip_desc"),
      build: buildRam,
      spec: "RAM",
      image: ramImg,
      imageAlt: "ram",
      arg: "ram"
    }
  ];

  const timeSpecItem = {
    tooltipTitle: t("time_allowance_tooltip"),
    tooltipDesc: t("time_allowance_tooltip_desc"),
    build: buildTimeAllowance,
    spec: "Time allowance",
    image: timeAllowanceImg,
    imageAlt: "time allowance",
    arg: "time"
  };

  if (!selected) return null;

  return (
    <>
      <Grid container spacing={2} className={classes.buildSpecGridContainer}>
        {specItems.map((item, i) => (
          <BuildSpecItem key={i} item={item} />
        ))}
        {selected["Shell.Timer_Allowance"] && (
          <BuildSpecItem item={timeSpecItem} />
        )}
      </Grid>
      <BuildOtherField setUnit={setUnit} selectUnit={selectUnit} />
    </>
  );
};

const mapStateToProps = state => {
  return {
    selected: state.plans.selected,
    nbUnitSelected: state.plans.nbUnitSelected,
    plan: state.plans
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectUnit: nbUnit => dispatch(selectUnit(nbUnit))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuildSpec);
