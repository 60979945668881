import React from "react";
import { useTranslation } from "react-i18next";
import { HashLink as Link } from "react-router-hash-link";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";

import { WrapperContainer } from "components/HOC";
import { cloudDevices } from "assets/img/browserinthecloud";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/browserInTheCloud";

const useStyles = makeStyles(styles);

export default function BrowserInTheCloudPricingHero() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.mainFeaturedPost}>
      <WrapperContainer addMaxWidth={false} addlWclass={classes.morePadding}>
        <Grid container alignItems="center" justify="center" spacing={2}>
          <Hidden smUp>
            <Grid item xs={12} sm={6}>
              <img
                src={cloudDevices}
                alt="Cloud devices"
                className={classes.cloudDevicesImage}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6}>
            <div className={classes.headerTextContainer}>
              <Typography
                variant="h1"
                component="h2"
                className={classes.mainHeader}
              >
                {t("browser_in_the_cloud_hero")}
              </Typography>
            </div>
            <Box pt={6}>
              <div className={classes.subTextContainer}>
                <Typography
                  variant="h1"
                  component="h2"
                  className={classes.subHeader}
                >
                  {t("browser_in_the_cloud_description")}
                </Typography>
                <Box pt={3}>
                  <Typography
                    variant="h1"
                    component="h2"
                    className={classes.subHeader}
                  >
                    {t("browser_in_the_cloud_phrase")}
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  disableElevation
                  className={classes.signUpButton}
                  component={Link}
                  to="#pricing"
                >
                  {t("browser_in_the_cloud_button")}
                </Button>
              </div>
            </Box>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={6}>
              <img
                src={cloudDevices}
                alt="Cloud devices"
                style={{ width: "100%" }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </WrapperContainer>
    </Paper>
  );
}
