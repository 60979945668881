import { USER_LOGOUT } from "./UserReducer";

const initState = {
  order: null,
  orderLoading: true,
  orderError: false,
  orderProcess: false
};

export const SYNC_ORDER = "SYNC_ORDER";
export const SYNC_ORDER_DONE = "SYNC_ORDER_DONE";
export const SYNC_ORDER_ERROR = "SYNC_CART_ERROR";
export const ORDER_PROCESS = "ORDER_PROCESS";
export const ORDER_PROCESS_DONE = "ORDER_PROCESS_DONE";
export const ORDER_PROCESS_ERROR = "ORDER_PROCESS_ERROR";

const defaultOrderReducer = (state = initState, action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return { ...initState };
    case SYNC_ORDER:
      return {
        ...state,
        orderLoading: true,
        order: null,
        orderError: false,
        orderProcess: false
      };
    case SYNC_ORDER_DONE:
      return {
        ...state,
        orderLoading: false,
        order: action.order,
        orderError: false,
        orderProcess: false
      };
    case SYNC_ORDER_ERROR:
      return {
        ...state,
        orderLoading: false,
        order: null,
        orderError: true,
        orderProcess: false
      };
    case ORDER_PROCESS:
      return {
        ...state,
        orderLoading: false,
        orderError: false,
        orderProcess: true
      };
    case ORDER_PROCESS_DONE:
      return {
        ...state,
        orderLoading: false,
        orderError: false,
        orderProcess: false
      };
    case ORDER_PROCESS_ERROR:
      return {
        ...state,
        orderLoading: false,
        orderError: true,
        orderProcess: false
      };
    default:
      return state;
  }
};

export default defaultOrderReducer;
