const initState = {
  tags: [
    { key: "cfyt-ochxyp-nb5f-auzl-vqzz-inwjp3wu", value: "Commercial" },
    { key: "cfyt-tjedg6-tau5-hpzl-f3wd-jw3bhzwu", value: "Others" }
  ],
  naka: "sppt-f4euoi-eduf-ctda-e3zi-reztqloe",
  sending: false,
  sent: false,
  error: null
};

export const CONTACT_SENDING = "CONTACT_SENDING";
export const CONTACT_SENT = "CONTACT_SENT";
export const CONTACT_ERROR = "CONTACT_ERROR";
export const CONTACT_RESET = "CONTACT_RESET";

const contactReducer = (state = initState, action) => {
  switch (action.type) {
    case CONTACT_SENDING:
      return { ...state, sending: true, error: null };
    case CONTACT_SENT:
      return { ...state, sent: true, sending: false, error: null };
    case CONTACT_ERROR:
      return { ...state, sent: false, sending: false, error: action.error };
    case CONTACT_RESET:
      return { ...state, sent: false, sending: false, error: null };
    default:
      return state;
  }
};

export default contactReducer;
