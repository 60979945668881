import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/homepage20210414.style.js";

const useStyles = makeStyles(styles);

export default function HPTopEnd() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box pt={6}>
      <Paper elevation={0} className={classes.oneDeviceFeaturedPost}>
        <Container maxWidth="lg">
          <div className={`${classes.textContainer} ${classes.alignCenter}`}>
            <Box pb={5}>
              <Typography
                variant="h5"
                className={`${classes.fontOrange} ${classes.tinyHeader}`}
                gutterBottom
              >
                {t("whyshells_h12")}
              </Typography>
              <div className={classes.heroHeaderContainer}>
                <Typography
                  component="h1"
                  variant="h2"
                  className={classes.sectionHeroHeader}
                >
                  {t("top_end_header")}
                </Typography>
              </div>
            </Box>
            <Box pb={3}>
              <Typography
                component="h2"
                variant="body1"
                className={`${classes.subHeader} ${classes.marginBottom}`}
              >
                {t("top_end_desc_one")}
              </Typography>
            </Box>
            <Box pb={8}>
              <Typography
                component="h2"
                variant="body1"
                className={`${classes.subHeader} ${classes.marginBottom}`}
              >
                {t("top_end_desc_two")}
              </Typography>
            </Box>
            <Button
              color="primary"
              variant="contained"
              className={classes.beautifulJoinButton}
              component={Link}
              to={"/pricing"}
            >
              {t("hero_get_button")}
            </Button>
          </div>
        </Container>
      </Paper>
    </Box>
  );
}
