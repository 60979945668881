import React from "react";

import StaticCard from "./StaticCard";

import styles from "../../assets/scss/modules/countdown.module.scss";

export default function FlipDigitsContainer({ unit, digit }) {
  let currentDigit = digit;

  if (currentDigit < 10) {
    currentDigit = `0${currentDigit}`;
  }

  unit = digit === 1 ? unit.slice(0, -1) : unit;

  return (
    <div className={styles["flipDigitsContainer"]}>
      <StaticCard digit={currentDigit} />
      <span className={styles["unitText"]}>{unit}</span>
    </div>
  );
}
