import {
  beigeYellowColor,
  lightPinkColor,
  mainGreenColor,
  whiteColor,
  secondaryGreenColor,
  lightBlueColor,
  blackColor
} from "assets/jss/material-kit-react";

import { blurBg } from "assets/img/edtech";
import {
  bgImageDesktop,
  bgImageTablet,
  bgImageMobile
} from "assets/img/debian";

const edTechStyle = theme => ({
  breakpoints: {
    values: {
      xs: 320,
      sm: 414
    }
  },
  body: {
    boxSizing: "border-box",
    backgroundColor: lightBlueColor
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    padding: "60px 15px"
  },
  container: {
    maxWidth: "1140px",
    margin: "0 auto",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0 15px 0 15px"
    },
    [theme.breakpoints.down("lg")]: {
      padding: "0 20px 0 20px"
    }
  },
  headerContainer: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  headerContainer_a: {
    maxWidth: "620px",
    margin: "0 auto"
  },
  subHeaderContainer: {
    maxWidth: "550px",
    lineHeight: 5
    // margin: "0 auto"
  },
  subHeader: {
    fontSize: "22px",
    fontFamily: "Open Sans",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      lineHeight: 2,
      marginBottom: "45px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      marginBottom: "45px"
    }
  },
  heroHeader: {
    fontFamily: "Open Sans",
    fontWeight: "900",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "42.5984px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "52px"
    }
  },
  // Section One
  sectionOneHeader: {
    fontFamily: "Open Sans",
    color: blackColor,
    fontWeight: "900",
    fontSize: "24px",
    marginBottom: "15px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  sectionOneHeaderPos: {
    position: "absolute",
    bottom: "-10px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      left: "50%",
      ":-webkit-transform": "translateX(-50%)",
      transform: "translateX(-50%)"
    }
  },
  sectionOneBody: {
    fontSize: "16px",
    color: blackColor,
    lineHeight: 2,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  sectionThreeBody: {
    fontSize: "16px",
    color: blackColor,
    lineHeight: 2,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  sectionOneImage: {
    width: "200px",
    margin: "0 auto",
    [theme.breakpoints.up("sm")]: {
      width: "135px"
    },
    [theme.breakpoints.up("md")]: {
      width: "200px"
    }
  },
  s4ImagePosRight: {
    textAlign: "right",
    [theme.breakpoints.down("md")]: {
      textAlign: "center"
    }
  },
  s4ImagePosLeft: {
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      textAlign: "center"
    }
  },
  sectionOneHeaderContainer: {
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      minHeight: "25px"
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "75px"
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "55px"
    }
  },
  sectionOneHeaderParent: {
    position: "relative",
    minHeight: "55px"
  },
  sectionOneHeaderPosRight: {
    position: "absolute",
    bottom: "-15px",
    right: 0,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      left: "50%",
      ":-webkit-transform": "translateX(-50%)",
      transform: "translateX(-50%)"
    }
  },
  sectionOneHeaderPosLeft: {
    position: "absolute",
    bottom: "-15px",
    left: 0,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      left: "50%",
      ":-webkit-transform": "translateX(-50%)",
      transform: "translateX(-50%)"
    }
  },
  bodyContainer: {
    [theme.breakpoints.down("sm")]: {
      minHeight: 0
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "390px"
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "290px"
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "190px"
    }
  },
  description3: {
    fontSize: "16px",
    lineHeight: "1.9",
    fontFamily: "Roboto",
    textAlign: "center"
  },
  blackFont: {
    color: blackColor
  },
  lightBlueFont: {
    color: lightBlueColor
  },
  imageOne: {
    marginBottom: "40px",
    [theme.breakpoints.up("xs")]: {
      width: "230px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "270px"
    }
  },
  mainBackground: {
    backgroundColor: beigeYellowColor
  },
  otherBackground: {
    backgroundColor: lightPinkColor
  },
  cloudImage: {
    // used in LinuxCloudDesktop.js
    [theme.breakpoints.up("xs")]: {
      width: "200px",
      margin: "0 0 25px 0"
    },
    [theme.breakpoints.up("sm")]: {
      width: "250px"
    },
    [theme.breakpoints.up("md")]: {
      margin: "0 25px 0 0"
    }
  },
  secureImage: {
    // used in LinuxCloudDesktop.js
    [theme.breakpoints.up("xs")]: {
      width: "240px",
      margin: "0 0 25px 0"
    },
    [theme.breakpoints.up("sm")]: {
      width: "280px"
    },
    [theme.breakpoints.up("md")]: {
      margin: "0 0 0 0",
      width: "295px"
    }
  },

  multiDeviceImage: {
    // used in LinuxCloudDesktop.js
    marginLeft: "45px",
    [theme.breakpoints.up("xs")]: {
      width: "260px",
      margin: "0 0 25px 0"
    },
    [theme.breakpoints.up("sm")]: {
      width: "300px"
    },
    [theme.breakpoints.up("md")]: {
      margin: "0 0 0 0"
    }
  },
  s1Image: {
    // used in edtech
    width: "200px",
    height: "200px",
    display: "block",
    margin: "0 auto"
  },
  cloudImg: {
    width: "50%"
  },
  safeImage: {
    width: "50%",
    position: "relative",
    transform: "translate(4%, 10%)",
    [theme.breakpoints.down("sm")]: {
      transform: "translate(0,0)"
    }
  },
  youtubeVideo: {
    width: "350px",
    height: "175px",
    display: "block",
    margin: "0 auto",
    marginTop: "40px",
    [theme.breakpoints.up("xs")]: {
      width: "255px",
      height: "165px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "320px",
      height: "195px"
    },
    [theme.breakpoints.up("md")]: {
      width: "450px",
      height: "320px"
    },
    [theme.breakpoints.up("lg")]: {
      width: "640px",
      height: "450px"
    }
  },
  cardImageTablet: {
    margin: "0 auto",
    padding: "15px 0 15px 0",
    [theme.breakpoints.up("xs")]: {
      width: "200px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "215px"
    },
    [theme.breakpoints.up("md")]: {
      width: "230px"
    },
    [theme.breakpoints.up("lg")]: {
      width: "265px"
    }
  },
  cardImageLaptop: {
    margin: "0 auto",
    padding: "15px 0 15px 0",
    [theme.breakpoints.up("xs")]: {
      width: "235px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "245px"
    },
    [theme.breakpoints.up("md")]: {
      width: "265px"
    },
    [theme.breakpoints.up("lg")]: {
      width: "300px"
    }
  },
  // styles for Header
  mainFeaturedPost: {
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    padding: "60px 15px",
    color: theme.palette.common.white,
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${bgImageMobile})`
    },
    [theme.breakpoints.down("lg")]: {
      backgroundImage: `url(${bgImageTablet})`
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${bgImageDesktop})`
    }
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.1)",
    backdropFilter: "blur(4px)"
  },
  mainFeaturedPostContent: {
    textAlign: "center",
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6)
      // paddingRight: 0
    }
  },
  moneyBackText: {
    fontSize: "22px",
    fontFamily: "Open Sans",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  inputAndBtn: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  heroImage: {
    [theme.breakpoints.down("sm")]: {
      // width: "235px"
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "300px"
    },
    [theme.breakpoints.up("md")]: {
      width: "400px"
    },
    [theme.breakpoints.up("lg")]: {
      width: "650px"
    },
    "@media (min-width: 1280px) and (max-width: 1365px)": {
      width: "575px"
    }
  },
  beautifulJoinButton: {
    width: "375px",
    backgroundColor: "linear-gradient(45deg, #5ba331 0%, #75cb42 100%)",
    borderRadius: "35px",
    color: "#fff",
    fontSize: "22px",
    textAlign: "center",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover, &:focus": {
      boxShadow: "0 0 0 2px #5ba331 inset",
      background: "#fff",
      color: "#5ba331"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "250px",
      padding: "6px 14px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      width: "375px"
    }
  },
  signUpButton: {
    margin: "35px 0 40px 0",
    padding: "6px 24px",
    backgroundColor: mainGreenColor,
    color: whiteColor,
    display: "block",
    borderRadius: "35px",
    fontSize: "22px",
    textAlign: "center",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover": {
      boxShadow: `0 0 0 2px ${secondaryGreenColor} inset`,
      background: whiteColor,
      color: secondaryGreenColor
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 auto",
      fontSize: "16px",
      padding: "6px 14px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "315px",
      margin: "0 auto"
    }
  },
  textButtonContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: 0
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 0
    },
    [theme.breakpoints.up("lg")]: {
      padding: 0
    }
  },
  textButtonBg: {
    border: `3px solid ${mainGreenColor}`,
    backgroundColor: "rgba(26, 28, 101, 0.3)",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%"
    },
    [theme.breakpoints.up("md")]: {
      width: "100%"
    }
  },
  zeroMarginLeft: {
    marginLeft: 0,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto"
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "45px"
    }
  },
  marginBottom: {
    marginBottom: "25px"
  },
  s1BodyContainer: {
    [theme.breakpoints.down("sm")]: {
      minHeight: 0
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "292px"
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "225px"
    }
  },
  greenLine_s1_a: {
    border: `3px solid ${mainGreenColor}`,
    borderRadius: "5px",
    margin: "20px auto 20px auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "250px"
    }
  },
  greenLine_s1_b: {
    width: "100%",
    border: `3px solid ${mainGreenColor}`,
    borderRadius: "5px",
    margin: "20px auto 20px auto"
  },
  lightBlueBG: {
    backgroundColor: lightBlueColor
  },
  greenText: {
    color: mainGreenColor
  },
  blackText: {
    color: blackColor
  },
  youtubePost: {
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${blurBg})`
    },
    [theme.breakpoints.down("lg")]: {
      backgroundImage: `url(${blurBg})`
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${blurBg})`
    }
  },
  youtubeContentContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
    }
  },
  videoContainer: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: "65px"
    },
    marginLeft: "75px"
  },
  video: {
    [theme.breakpoints.up("xs")]: {
      width: "252px",
      height: "185px"
    },
    [theme.breakpoints.down("sm")]: {
      width: "300px",
      height: "200px"
    },
    [theme.breakpoints.up("lg")]: {
      width: "560px",
      height: "315px"
    }
  },
  morePadding2: {
    padding: "100px 15px",
    [theme.breakpoints.down("sm")]: {
      padding: "100px 0px"
    }
  },
  featuresPadding: {
    padding: "25px 15px"
  },
  s3TextButtonContainer: {
    maxWidth: "600px",
    margin: "0 auto"
  },
  headerContainer2: {
    textAlign: "center"
  },
  cardImage: {
    margin: "0 auto",
    padding: "15px",
    [theme.breakpoints.up("xs")]: {
      width: "200px"
    }
  },
  gridCard: {
    backgroundColor: lightBlueColor
  },
  cardTextContainer: {
    [theme.breakpoints.down("sm")]: {
      minHeight: 0
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "210px"
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "330px",
      maxWidth: "250px",
      margin: "0 auto"
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "325px",
      margin: "0 auto",
      minHeight: "230px"
    }
  },
  marginBottomMobile: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "35px"
    }
  },
  connectionImage: {
    [theme.breakpoints.down("sm")]: {
      width: "250px",
      margin: "0 auto",
      marginBottom: "40px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "300px"
    },
    [theme.breakpoints.up("md")]: {
      width: "80%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%"
    }
  },
  sectionThreeJoinButton: {
    width: "300px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "6px 24px",
    border: `3px solid ${mainGreenColor}`,
    backgroundColor: "#ffffff",
    borderRadius: "35px",
    color: mainGreenColor,
    fontSize: "22px",
    textAlign: "center",
    // fontWeight: "bold",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover, &:focus": {
      // boxShadow: "0 0 0 2px #5ba331 inset",
      background: mainGreenColor,
      color: "#ffffff"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      width: "250px"
    }
  },
  centerText: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  japanesePage: {
    "& .MuiTypography-alignRight": {
      textAlign: "left"
    }
  }
});

export default edTechStyle;
