import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/whyShells";

const useStyles = makeStyles(styles);

export default function GridItem({ details }) {
  const { t } = useTranslation();
  const { img, header, description, link } = details;
  const classes = useStyles();

  return (
    <Grid item xs={12} md={4} className={classes.iconContainer}>
      <img src={img} alt="for you" className={classes.forImage} />
      <Card className={classes.card}>
        <CardContent className={classes.textContainer1}>
          <Typography
            variant="h3"
            className={`${classes.headers} ${classes.fontOrange}`}
          >
            {header}
          </Typography>
          <div className={classes.gridDescriptionContainer}>
            <Typography
              className={`${classes.description3} ${classes.marginBottom}`}
            >
              {description}
            </Typography>
          </div>
          <Typography className={classes.description3} align="center">
            <Link href={link} className={classes.link}>
              {t("whyshells_for")}
            </Link>
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
