import {
  mainGreenColor,
  secondaryGreenColor,
  whiteColor,
  lightGrayColor,
  orangeColor,
  blackColor,
  bgDarkBlueColor,
  lightBlueColor
} from "assets/jss/material-kit-react";
import { heroImage, shellsIconBg } from "assets/img/lfa";

const lfa = theme => ({
  mainHeader: {
    fontWeight: "900",
    fontFamily: "Open Sans",
    marginBottom: "65px",
    color: whiteColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "42.5984px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "52px"
    }
  },
  mainFeaturedPost: {
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${heroImage})`
    },
    [theme.breakpoints.down("lg")]: {
      backgroundImage: `url(${heroImage})`
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${heroImage})`
    }
  },
  mainFeaturedPostContent: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  textAndButton: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "750px"
    }
  },
  heroDescription: {
    fontSize: "20px",
    lineHeight: "1.6",
    fontFamily: "Roboto",
    color: whiteColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  description: {
    fontSize: "16px",
    lineHeight: "1.6",
    fontFamily: "Roboto, sans-serif",
    color: blackColor
  },
  playButton: {
    backgroundColor: mainGreenColor,
    color: whiteColor,
    fontSize: "42px",
    textAlign: "center",
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    "&:hover": {
      boxShadow: `0 0 0 2px ${secondaryGreenColor} inset`,
      background: whiteColor,
      color: secondaryGreenColor
    }
  },
  signUpButton: {
    margin: "35px 0 40px 0",
    padding: "6px 24px",
    backgroundColor: mainGreenColor,
    color: whiteColor,
    display: "block",
    borderRadius: "35px",
    textAlign: "center",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover": {
      boxShadow: `0 0 0 2px ${secondaryGreenColor} inset`,
      background: whiteColor,
      color: secondaryGreenColor
    },
    [theme.breakpoints.down("sm")]: {
      width: "250px",
      margin: "0 auto",
      fontSize: "16px"
    },
    [theme.breakpoints.up("md")]: {
      width: "315px",
      fontSize: "22px"
    }
  },
  btnCentered: {
    margin: "0 auto"
  },
  addPadding: {
    padding: "120px 15px",
    [theme.breakpoints.down("xs")]: {
      padding: "100px 15px"
    }
  },
  zeroMarginLeft: {
    marginLeft: 0,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto"
    }
  },
  playContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      marginTop: "25px"
    }
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    maxWidth: "650px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "45px auto 45px auto"
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "space-between"
    }
  },
  chaelText: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "10px"
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "15px",
      paddingBottom: ".5px",
      borderBottom: `1px solid ${whiteColor}`
    },
    "&:hover": {
      cursor: "pointer"
    }
  },
  youtubeVideo: {
    display: "block",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      minHeight: "250px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "80%",
      minHeight: "350px"
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "500px"
    }
  },
  marginTop: {
    marginTop: "45px"
  },
  marginBottom: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "25px"
    }
  },
  imgMarginBottom: {
    marginBottom: "25px"
  },
  osContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-evenly"
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between"
    }
  },
  osIcon: {
    [theme.breakpoints.down("xs")]: {
      width: "30%",
      margin: "4px 5px 7px 4px"
    }
  },
  // Section One
  sectionOneMain: {
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    },
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${shellsIconBg})`,
      padding: "10% 0 10% 0"
    },
    [theme.breakpoints.up("md")]: {
      padding: "40% 0 30% 0"
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${shellsIconBg})`,
      position: "relative",
      padding: "30% 0 25% 0"
    }
  },
  allFighters: {
    width: "120%",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: "20%",
      transform: "translateY(-16%)",
      "-webkit-transform": "translateY(-16%)",
      "-moz-transform": "translateY(-16%)",
      "-ms-transform": "translateY(-16%)",
      "-o-transform": "translateY(-16%)"
    },
    [theme.breakpoints.up("lg")]: {
      position: "absolute",
      top: "20%",
      transform: "translateY(-30%)",
      "-webkit-transform": "translateY(-30%)",
      "-moz-transform": "translateY(-30%)",
      "-ms-transform": "translateY(-30%)",
      "-o-transform": "translateY(-30%)"
    }
  },
  fontOrange: {
    color: `${orangeColor} !important`
  },
  tinyHeader: {
    fontSize: "16px",
    fontWeight: "bold"
  },
  sectionOneHeader: {
    fontWeight: "900",
    fontFamily: "Open Sans",
    color: blackColor,
    lineHeight: 1.5,
    fontSize: "32px",
    "z-index": 2
  },
  andImage: {
    "z-index": "-1",
    width: "150px",
    position: "absolute",
    top: "-16px",
    [theme.breakpoints.down("xs")]: {
      left: "50%",
      transform: "translateX(-105%)",
      "-webkit-transform": "translateX(-105%)",
      "-moz-transform": "translateX(-105%)",
      "-ms-transform": "translateX(-105%)",
      "-o-transform": "translateX(-105%)"
    },
    [theme.breakpoints.up("sm")]: {
      left: "50%",
      transform: "translateX(-116%)",
      "-webkit-transform": "translateX(-116%)",
      "-moz-transform": "translateX(-116%)",
      "-ms-transform": "translateX(-116%)",
      "-o-transform": "translateX(-116%)"
    }
  },
  s1Text1div: {
    textAlign: "center",
    marginBottom: "45px",
    "z-index": 110,
    position: "relative"
  },
  greenFont: {
    color: mainGreenColor
  },
  s1Description: {
    fontSize: "20px",
    lineHeight: "1.6",
    fontFamily: "Roboto",
    color: blackColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  s1TextContainer: {
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "45px 0 45px 0"
    },
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      "-webkit-transform": "translate(-50%, -50%)",
      "-moz-transform": "translate(-50%, -50%)",
      "-ms-transform": "translate(-50%, -50%)",
      "-o-transform": "translate(-50%, -50%)",
      "z-index": 100
    }
  },
  text2Bg: {
    maxWidth: "650px",
    borderRadius: "16px",
    backgroundColor: "rgba(255, 255, 255, 0.78)",
    padding: "25px",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "0 auto"
    }
  },
  s1NoOverflow: {
    [theme.breakpoints.down("md")]: {
      overflow: "hidden"
    }
  },
  // Section Two
  bgDarkBlue: {
    backgroundColor: bgDarkBlueColor
  },
  headers: {
    fontWeight: "900",
    fontFamily: "Open Sans, sans-serif",
    marginBottom: "25px",
    lineHeight: 1.5,
    fontSize: "32px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  whiteFont: {
    color: whiteColor
  },
  whiteText: {
    color: lightGrayColor
  },
  whiteLine: {
    border: `2px solid ${whiteColor}`,
    margin: "25px auto 45px auto",
    maxWidth: "250px"
  },
  greenLine: {
    border: `2px solid ${mainGreenColor}`,
    margin: "25px auto 45px auto",
    maxWidth: "250px"
  },
  bodyContainer: {
    maxWidth: "450px",
    margin: "0 auto"
  },
  gridItem: {
    padding: "120px 24px 120px 24px !important",
    [theme.breakpoints.down("xs")]: {
      padding: "100px 24px 100px 24px !important"
    }
  },
  s2Padding: {
    padding: "0px"
  },
  headerContainer: {
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      minHeight: "142px"
    }
  },
  s2Header: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      "-webkit-transform": "translateX(-50%)",
      "-moz-transform": "translateX(-50%)",
      "-ms-transform": "translateX(-50%)",
      "-o-transform": "translateX(-50%)",
      bottom: "-20px"
    }
  },
  // Section 3
  devices: {
    width: "100%",
    margin: "0 auto"
  },
  s3BodyContainer: {
    maxWidth: "450px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
      textAlign: "center"
    }
  },
  s3GreenLine: {
    border: `2px solid ${mainGreenColor}`,
    margin: "25px auto 45px 0",
    maxWidth: "250px",
    [theme.breakpoints.down("sm")]: {
      margin: "25px auto 45px auto"
    }
  },
  // Section 4
  s4BodyContainer: {
    maxWidth: "450px",
    margin: "0 auto",
    textAlign: "center"
  },
  crossPlat: {
    width: "90%",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "20px"
    }
  },
  // Section 5
  solutionImage: {
    [theme.breakpoints.up("md")]: {
      width: "75%",
      position: "absolute",
      left: "-20%",
      top: "50%",
      transform: "translateY(-65%)",
      "-webkit-transform": "translateY(-65%)",
      "-moz-transform": "translateY(-65%)",
      "-ms-transform": "translateY(-65%)",
      "-o-transform": "translateY(-65%)",
      maxWidth: "1500px"
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "10px"
    }
  },
  s5WhiteLine: {
    border: `2px solid ${whiteColor}`,
    margin: "25px auto 45px 0",
    maxWidth: "250px",
    [theme.breakpoints.down("sm")]: {
      margin: "25px auto 45px auto"
    }
  },
  s5GridItem: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 15px 0 15px !important",
      display: "block",
      margin: "0 auto"
    }
  },
  bgLightBlue: {
    backgroundColor: lightBlueColor
  },
  s5AddPadding: {
    [theme.breakpoints.down("xs")]: {
      padding: "0px 0px 60px 0px"
    },
    [theme.breakpoints.up("sm")]: {
      padding: "60px 0px"
    },
    [theme.breakpoints.up("md")]: {
      padding: "35px 15px"
    },
    [theme.breakpoints.up("lg")]: {
      padding: "120px 15px"
    }
  },
  s5WrapperPadding: {
    [theme.breakpoints.down("xs")]: {
      padding: "60px 0px"
    },
    [theme.breakpoints.up("sm")]: {
      padding: "60px 8px"
    }
  },
  noOverflow: {
    [theme.breakpoints.down("xs")]: {
      overflowX: "hidden"
    }
  }
});

export default lfa;
