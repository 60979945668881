import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import FlipDigitsContainer from "./FlipDigitsContainer";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/manjaro";

const useStyles = makeStyles(styles);

export default function Countdown({
  addCountDown = false,
  redirectUrl,
  endMonthAndDay
}) {
  const classes = useStyles();
  const history = useHistory();

  const memoizedCalculateTimeLeft = useCallback(() => {
    let year = new Date().getFullYear();

    const difference = +new Date(`${endMonthAndDay}/${year}`) - +new Date();

    let timeLeft = {};

    if (difference > 0) {
      // converting from milliseconds
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  }, [endMonthAndDay]);

  const [timeLeft, setTimeLeft] = useState(memoizedCalculateTimeLeft());

  useEffect(() => {
    let timer;

    if (addCountDown) {
      timer = setTimeout(() => {
        setTimeLeft(memoizedCalculateTimeLeft());
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, [addCountDown, timeLeft, memoizedCalculateTimeLeft]);

  useEffect(() => {
    const { days, hours, minutes, seconds } = timeLeft;

    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      return history.push(redirectUrl);
    }
  }, [timeLeft, history, redirectUrl]);

  const { days, hours, minutes, seconds } = timeLeft;

  return (
    <div className={classes.countdownContainer}>
      <FlipDigitsContainer unit="days" digit={days} />
      <FlipDigitsContainer unit="hours" digit={hours} />
      <FlipDigitsContainer unit="minutes" digit={minutes} />
      <FlipDigitsContainer unit="seconds" digit={seconds} />
    </div>
  );
}
