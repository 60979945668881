import { rest } from "@karpeleslab/klbfw";
import {
  catchRedirectException,
  handleError
} from "../../components/utils/apiErrorHandler";
import {
  SHELL_CREATE,
  SHELL_CREATE_DONE,
  SHELL_SELECT
} from "../reducers/ShellReducer";

export const createShell = (
  billingId,
  product,
  label,
  size = null,
  _nonce,
  operatingSystemCode = null
) => {
  return (dispatch, getState) => {
    const params = {
      User_Billing__: billingId,
      Catalog_Product__: product,
      Label: label,
      Size: size,
      _nonce
    };
    if (operatingSystemCode) params.Shell_OS__ = operatingSystemCode;

    dispatch({ type: SHELL_CREATE });

    return rest("Shell", "POST", params)
      .then(catchRedirectException)
      .then(d => {
        dispatch({ type: SHELL_CREATE_DONE });
        return d.data;
      })
      .catch(err => {
        handleError(getState, dispatch, err);
      });
  };
};

export const selectShell = shell => {
  return dispatch => {
    dispatch({ type: SHELL_SELECT, shell: shell });
  };
};
