import React from "react";
import { useTranslation, Trans } from "react-i18next";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

import styles from "assets/jss/material-kit-react/views/slickDeals";
import { makeStyles } from "@material-ui/core/styles";

import { windowsDevices } from "assets/img/windows";
import { WrapperContainer } from "components/HOC";
import { slickDealsShellsLogo } from "assets/img/slickdeals";

const useStyles = makeStyles(styles);

export default function SlickDealsPricingHero() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper elevation={0} className={classes.mainFeaturedPost}>
      <WrapperContainer addMaxWidth={false} addlWclass={classes.morePadding}>
        <a href="#pricing" className={classes.mainRibbon}>
          <Trans
            i18nKey="b3g3f_discount"
            components={{ underline: <span /> }}
          />
        </a>
        <Grid container alignItems="center" justify="center" spacing={2}>
          <Hidden smUp>
            <Grid item xs={12} sm={6}>
              <img
                src={windowsDevices}
                alt="Windows devices"
                className={classes.windowsDevices}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6}>
            <div className={classes.headerTextContainer}>
              <img
                src={slickDealsShellsLogo}
                alt="Slickdeals and Shells"
                className={`${classes.shellsLttLogo} ${classes.marginBottom}`}
              />
              <Typography
                variant="h1"
                component="h2"
                className={classes.mainHeader}
              >
                {t("windows_pricing_header")}
              </Typography>
            </div>
            <Box pt={6}>
              <div className={classes.subTextContainer}>
                <Typography
                  variant="h1"
                  component="h2"
                  className={classes.subHeader}
                >
                  {t("slickdeals_subtext")}
                </Typography>
              </div>
            </Box>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={6}>
              <img
                src={windowsDevices}
                alt="Windows Devices"
                style={{ width: "100%" }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </WrapperContainer>
    </Paper>
  );
}
