import {
  COUNTRIES_FETCH,
  COUNTRIES_FETCH_DONE
} from "../reducers/CountriesReducer";
import { rest } from "@karpeleslab/klbfw";
import {
  catchRedirectException,
  handleError
} from "../../components/utils/apiErrorHandler";

export const fetchCountries = () => {
  return (dispatch, getState) => {
    if (getState().countries.countries.length > 0)
      return new Promise(resolve => resolve(getState().countries.countries));

    dispatch({ type: COUNTRIES_FETCH });

    return rest("Country")
      .then(catchRedirectException)
      .then(data => {
        dispatch({ type: COUNTRIES_FETCH_DONE, countries: data.data });
        return data.data;
      })
      .catch(err => {
        handleError(getState, dispatch, err);
      });
  };
};

export const geoLookup = () => {
  return (dispatch, getState) => {
    return rest("ThirdParty/Geoip:lookup")
      .then(data => {
        return data.data;
      })
      .catch(err => {
        handleError(getState, dispatch, err);
      });
  };
};
