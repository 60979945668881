import React from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/minecraft";

const useStyles = makeStyles(styles);

export default function ListItem({ data }) {
  const classes = useStyles();
  const { header, body, href_1, href_1_text, href_2, href_2_text } = data;

  return (
    <Grid item xs={12} sm={6} className={classes.sectionOneGridItem}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Box pb={2}>
            <div className={classes.headerContainer}>
              <Typography
                variant="h5"
                component="h3"
                className={classes.sectionHeader}
              >
                {header}
              </Typography>
            </div>
          </Box>
          <div className={classes.bodyContainer}>
            <Typography
              variant="body1"
              component="p"
              className={classes.sectionBody}
            >
              {body}
            </Typography>

            <Box pt={2} className={classes.linksContainer}>
              <Link
                component="a"
                target="_blank"
                href={href_1}
                className={classes.link}
                rel="noopener noreferrer"
              >
                {href_1_text}
              </Link>
              {href_2 && href_2_text && (
                <Box pt={2}>
                  <Link
                    component="a"
                    target="_blank"
                    href={href_2}
                    className={classes.link}
                    rel="noopener noreferrer"
                  >
                    {href_2_text}
                  </Link>
                </Box>
              )}
            </Box>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
}
