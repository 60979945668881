import React from "react";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import { useTranslation } from "react-i18next";

const PaymentMethodDisplay = ({ method }) => {
  const { t } = useTranslation();
  return (
    <GridContainer>
      <GridItem xs={12}>
        {t("payment_method_name", { name: method.Name })}
      </GridItem>
      <GridItem xs={12}>
        {t("payment_method_expiration", { expiration: method.Expiration })}
      </GridItem>
    </GridContainer>
  );
};

export default PaymentMethodDisplay;
