import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Button from "./../../../components/CustomButtons/ShellLoadingButton";
import Grid from "@material-ui/core/Grid";

import { orderProcess } from "store/actions/DefaultOrderAction";

const loadBitpayScript = (src, onLoad) => {
  const scriptEl = document.createElement("script");
  scriptEl.async = false;
  scriptEl.src = src;
  scriptEl.onload = onLoad;
  document.body.appendChild(scriptEl);
};

const BitpayPaymentWrapper = ({ orderProcess, order }) => {
  const { t } = useTranslation();
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const { orderId } = useParams();

  const setProcess = ({ _javascript, Bitpay_Invoice_Id }) => {
    setLoading(true);

    const callback = () => {
      const final = function(e) {
        if (e.data.status !== "paid") return;

        window.removeEventListener("message", this);
        orderProcess("Bitpay", session, orderId);
      };
      window.addEventListener("message", final);
      setInterval(() => {
        orderProcess("Bitpay", session, orderId);
      }, 60 * 1000);

      window.bitpay.showInvoice(Bitpay_Invoice_Id);
      setTimeout(() => setLoading(false), 3000); // iframe takes a while to actually show up.
    };

    if (window.bitpay) callback();
    else if (_javascript) loadBitpayScript(_javascript, callback);
  };

  useEffect(() => {
    if (order.data && order.data.methods && order.data.methods.Bitpay) {
      setSession(order.data.methods.Bitpay.session);
      setLoading(false);
    }
  }, [order, setSession, setLoading]);

  const init = () => {
    if (!session) return;
    setLoading(true);
    orderProcess("Bitpay", session, orderId)
      .then(res => {
        if (
          res.data.methods &&
          res.data.methods.Bitpay &&
          res.data.methods.Bitpay.doPayment
        )
          setProcess(res.data.methods.Bitpay.fields);
        else {
          // TBD Error
        }
      })
      .catch(() => {
        // TBD Error
      });
  };

  const checkoutHandler = e => {
    if (!window.bitpay) init();
    else setProcess(e);
  };

  return (
    <Grid container justify="center">
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          loading={loading}
          onClick={checkoutHandler}
        >
          {t("checkout_with_bitpay")}
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  order: state.defaultOrder.order,
  orderLoading: state.defaultOrder.orderLoading,
  orderError: state.defaultOrder.orderError,
  orderProcess: state.defaultOrder.orderProcess
});

const mapDispatchToProps = dispatch => ({
  orderProcess: (method, session, orderId) =>
    dispatch(orderProcess(method, session, null, orderId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BitpayPaymentWrapper);
