import React, { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import Helmet from "components/instances/instance/Helmet/Helmet";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/download.js";

import alltogether from "../../assets/img/download/alltogether.png";
import Loading from "../../components/common/feedback/loading/Loading";
import { fetchShellDownloads } from "../../store/actions/SoftwareActions";
import { connect } from "react-redux";
import MobileStoreButton from "./../../components/MobileStoreButton/MobileStoreButton";

const useStyles = makeStyles(styles);

const Download = ({ fetchShellDownloads, shellDownload, loading }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  useLayoutEffect(() => {
    fetchShellDownloads();
  }, [fetchShellDownloads]);

  const hasOS = key => {
    // We only focus on the latest version for now
    if (!shellDownload || shellDownload.Versions.length < 1) return false;
    return key in shellDownload.Versions[0].Download;
  };

  const getUrl = key => {
    return shellDownload.Versions[0].Download[key].Url;
  };

  return (
    <div className={classes.root}>
      <Helmet title={t("download_page_main_header")} />
      <div className={classes.main}>
        <h1 className={classes.mainHeaderOne}>
          {t("download_page_main_header")}
        </h1>
        <h3 className={classes.mainHeaderTwo}>
          {t("download_page_second_header")}
        </h3>
      </div>

      <div>
        <img
          src={alltogether}
          alt="all devices"
          className={classes.allDevicesImage}
        />

        <div style={{ padding: "0px 62px" }}>
          <h3 className={classes.mainHeaderTwo}>
            {t("download_page_body_header")}
          </h3>
        </div>
        {loading && <Loading />}
        {!loading && shellDownload && (
          <>
            <div className={classes.downloadContainer}>
              {hasOS("win64") && (
                <MobileStoreButton store="win64" link={getUrl("win64")} />
              )}
              {hasOS("macos") && (
                <MobileStoreButton store="macos" link={getUrl("macos")} />
              )}
              {hasOS("linux_amd64") && (
                <MobileStoreButton
                  store="linux_amd64"
                  link={getUrl("linux_amd64")}
                />
              )}
            </div>

            <div className={classes.appContainer}>
              {hasOS("ios") && <MobileStoreButton store="ios" />}
              {hasOS("android") && <MobileStoreButton store="android" />}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
Download.serverfetch = (match, store) => store.dispatch(fetchShellDownloads());

const mapStateToProps = state => {
  return {
    shellDownload: state.software.shellDownload,
    loading: state.software.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchShellDownloads: () => dispatch(fetchShellDownloads())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Download);
