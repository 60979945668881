import React from "react";
import { Get } from "@karpeleslab/klbfw";
import { Trans, useTranslation } from "react-i18next";

import EmailLayout from "./../../../components/Email/EmailLayout";
import Text from "./../../../components/Email/EmailText";
import Button from "./../../../components/Email/EmailButton";

const Html = () => {
  const { t } = useTranslation();

  const userName = Get("_user").Profile.Display_Name;
  const shellName = Get("_shell").Label;
  const productId = Get("_shell").Catalog_Product__;
  const shellId = Get("_shell").Shell__;
  const renewLink = `https://www.shells.com/cart?product=${productId},shell=${shellId}`;

  return (
    <EmailLayout>
      <table
        border="0"
        cellSpacing="0"
        cellPadding="0"
        width="100%"
        style={{ width: "100%" }}
      >
        <tbody>
          <tr>
            <td>
              <Text size="medium">
                <Trans i18nKey="mail_shell_expired_content">
                  {{ shellName }}
                  {{ userName }}
                  <br />
                  <a href={renewLink}>sample</a>
                  <a href="mailto:support@support.shells.com">sample</a>
                </Trans>
              </Text>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td align="center">
              <Text size="medium">{t("contact_us_desc")}</Text>
            </td>
          </tr>
          <tr>
            <td align="center">
              <Button href="https://www.shells.com/contact">
                {t("contact_us")}
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </EmailLayout>
  );
};

export default Html;
