import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { useTranslation } from "react-i18next";

// Sections for this page
import FlowLogin from "components/login/Login";

const useStyles = makeStyles(styles);

export default function LandingPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.genericPageWrapper}>
      <Helmet title={t("helmet_shells_login")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div
          className={classes.heroInner}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <FlowLogin />
        </div>
      </div>
    </div>
  );
}
