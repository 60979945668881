import React from "react";
import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import GridCard from "./GridCard";
import { WrapperContainer } from "components/HOC";

import {
  image6,
  image7,
  image8,
  image9,
  image10,
  image11
} from "assets/img/cloudmusic";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/cloudmusic";

const useStyles = makeStyles(styles);

export default function SectionFour() {
  const { t } = useTranslation();
  const classes = useStyles();

  const data = [
    {
      header: t("cloudmusic_h11"),
      body: t("cloudmusic_p7"),
      cardContentClass: classes.cardContentTwo,
      image: image6,
      benefit: true
    },
    {
      header: t("cloudmusic_h12"),
      body: t("cloudmusic_p8"),
      cardContentClass: classes.cardContentTwo,
      image: image7,
      benefit: true
    },
    {
      header: t("cloudmusic_h13"),
      body: t("cloudmusic_p9"),
      cardContentClass: classes.cardContentTwo,
      image: image8,
      benefit: true
    },
    {
      header: t("cloudmusic_h14"),
      body: t("cloudmusic_p10"),
      cardContentClass: classes.cardContentTwo,
      image: image9,
      benefit: true
    },
    {
      header: t("cloudmusic_h15"),
      body: t("cloudmusic_p11"),
      cardContentClass: classes.cardContentTwo,
      image: image10,
      benefit: true
    },
    {
      header: t("cloudmusic_h16"),
      body: t("cloudmusic_p12"),
      cardContentClass: classes.cardContentTwo,
      image: image11,
      benefit: true
    }
  ];

  return (
    <WrapperContainer addMaxWidth={true} addlWclass={classes.backgroundLight}>
      <Typography
        variant="h3"
        className={`${classes.headers} ${classes.blackFont}`}
      >
        {t("cloudmusic_h10")}
      </Typography>

      <Grid container spacing={4} justify="center" alignItems="center">
        {data.map((data, i) => (
          <GridCard data={data} key={i} />
        ))}
      </Grid>
    </WrapperContainer>
  );
}
