import React from "react";
import { useTranslation, Trans } from "react-i18next";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/kde";

import { kdeDevices } from "assets/img/kde";
import { WrapperContainer } from "components/HOC";

const useStyles = makeStyles(styles);

export default function KDEPricingHero({ bannerText }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.mainFeaturedPost}>
      <WrapperContainer addMaxWidth={false} addlWclass={classes.morePadding}>
        <a href="#pricing" className={classes.mainRibbon}>
          <Trans i18nKey={bannerText} components={{ underline: <span /> }} />
        </a>
        <Grid container alignItems="center" justify="center" spacing={2}>
          <Hidden smUp>
            <Grid item xs={12} sm={6}>
              <img
                src={kdeDevices}
                alt="KDE Devices"
                className={classes.kdeDevicesImage}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6}>
            <div style={{ position: "relative" }}>
              <div className={classes.headerTextContainer}>
                <Typography
                  variant="h1"
                  component="h2"
                  align="center"
                  className={classes.mainHeader}
                >
                  {t("kde_pricing_title")}
                </Typography>
              </div>
              <Box pt={6}>
                <div className={classes.subTextContainer}>
                  <Typography
                    variant="h1"
                    component="h2"
                    align="center"
                    className={classes.subHeader}
                  >
                    {t("kde_pricing_description")}
                  </Typography>
                </div>
              </Box>
            </div>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={6}>
              <img
                src={kdeDevices}
                alt="KDE Devices"
                style={{ width: "100%" }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </WrapperContainer>
    </Paper>
  );
}
