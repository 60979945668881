import React from "react";
import { Trans } from "react-i18next";

import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/pricingPlans";

const useStyles = makeStyles(styles);

export default function DealPricingText({ os }) {
  const classes = useStyles();
  let text;
  let containerClass = classes.dealTextContainer;
  let textClass = `${classes.heroDescription} ${classes.addLineHeight}`;

  switch (os) {
    case "ltt":
      text = "ltt_pricing_header";
      break;
    case "manjaro":
      text = "manjaro_pricing_header";
      break;
    case "kde":
      text = "kde_pricing_text";
      break;
    case "linuxmint":
      text = "linux_mint_pricing_header";
      break;
    case "opensuse":
      text = "opensuse_pricing_text";
      break;
    case "windows":
      text = "windows_pricing_text";
      break;
    case "dln":
      text = "dln_pricing_text";
      break;
    case "linux_for_everyone":
      text = "linux_for_everyone_pricing_text";
      break;
    case "slogging":
      text = "slogging_pricing_text";
      break;
    case "vscode":
      text = "vscode_pricing_text";
      containerClass = classes.vsCodeTextContainer;
      break;
    case "ultiuber":
      text = "ultiuber_pricing_text";
      break;
    case "cloud":
      text = "browser_in_the_cloud_pricing_header";
      containerClass = classes.cloudTextContainer;
      break;
    case "daas":
      text = "daas_pricing_header";
      containerClass = classes.daasTextContainer;
      textClass = `${classes.daasPricingHeader} ${classes.addLineHeight}`;
      break;
    case "mxlinux":
      text = "mx_linux_pricing_text";
      break;
    case "virtualBox":
      text = "virtual_box_pricing_text";
      containerClass = classes.virtualBoxTextContainer;
      break;
    case "slickdeals":
      text = "slickdeals_pricing_text";
      containerClass = classes.slickdealsTextContainer;
      break;
    default:
      text = "deal_pricing_text";
  }

  return (
    <div className={containerClass}>
      <Typography
        align="center"
        variant="h3"
        component="h5"
        className={textClass}
      >
        <Trans
          i18nKey={text}
          components={{
            newLine: <br />
          }}
        />
      </Typography>
    </div>
  );
}
