import React from "react";
import { useTranslation } from "react-i18next";

import { WrapperContainer } from "components/HOC";
import { heroText } from "assets/img/minecraft";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
// import Button from "@material-ui/core/Button";
// import { HashLink as Link } from "react-router-hash-link";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/minecraft";

const useStyles = makeStyles(styles);

export default function Hero() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper className={classes.mainFeaturedPost} square elevation={0}>
      <WrapperContainer addMaxWidth={false} addlWclass={classes.morePadding}>
        <div className={classes.overlay} />
        <Grid container justify="center" alignItems="center">
          <Hidden smUp>
            <Grid item xs={12} sm={6}></Grid>
          </Hidden>
          <Grid item xs={12} sm={6}>
            <div className={classes.headerTextContainer}>
              <img src={heroText} alt="" style={{ width: "100%" }} />
            </div>

            <Box pt={6}>
              <div className={classes.subHeaderContainer}>
                <Typography
                  variant="h1"
                  component="h2"
                  className={classes.subHeader}
                >
                  {t("minecraft_hero_subheader_one")}
                </Typography>
              </div>
            </Box>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={6}></Grid>
          </Hidden>
        </Grid>
      </WrapperContainer>
    </Paper>
  );
}
