const wrapperContainerStyle = theme => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    padding: "60px 15px"
  },
  container: {
    maxWidth: "1140px",
    margin: "0 auto",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0 15px 0 15px"
    },
    [theme.breakpoints.down("lg")]: {
      padding: "0 20px 0 20px"
    }
  }
});

export default wrapperContainerStyle;
