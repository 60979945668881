import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Button from "./../../components/CustomButtons/ShellButton";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const OAuthReturn = () => {
  const { t } = useTranslation();

  return (
    <Grid container justify="center">
      <Grid item xs={10} sm={6} md={4}>
        <Box py={6}>
          <Paper elevation={3}>
            <Box p={4}>
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={4}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item>
                    <Typography align="center" variant="h5" component="p">
                      {t("oauth_return_title")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to="/"
                      >
                        {t("menu_home")}
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OAuthReturn;
