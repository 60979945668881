const pageModalStyle = theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    maxWidth: "1140px",
    margin: "0 auto"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 1),
    textAlign: "center",
    borderRadius: "8px",
    [theme.breakpoints.down("lg")]: {
      margin: "0 15px 0 15px"
    }
  },
  text: {
    fontSize: "1.25rem",
    letterSpacing: ".009em"
  }
});

export default pageModalStyle;
