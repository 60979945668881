import React from "react";
import { useTranslation } from "react-i18next";
import { HashLink as Link } from "react-router-hash-link";

import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/lfa";

import { WrapperContainer } from "components/HOC";
import { crossPlatform } from "assets/img/lfa";

const useStyles = makeStyles(styles);

export default function SectionFour() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <WrapperContainer addMaxWidth={false} addlWclass={classes.bgLightBlue}>
      <Grid container spacing={2} alignItems="center" justify="center">
        <Hidden smUp>
          <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
            <img
              src={crossPlatform}
              alt="Cross Platform devices"
              className={classes.crossPlat}
            />
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h5"
            component="h3"
            className={`${classes.headers} ${classes.greenFont}`}
            align="center"
          >
            {t("lfa_h7")}
          </Typography>
          <div className={classes.greenLine} />
          <div className={classes.s4BodyContainer}>
            <Typography
              variant="body1"
              className={classes.description}
              align="center"
            >
              {t("lfa_p7")}
            </Typography>
          </div>
          <Box mt={6}>
            <Button
              variant="contained"
              disableElevation
              className={`${classes.signUpButton} ${classes.btnCentered}`}
              component={Link}
              to="/pricing"
            >
              {t("whyshells_button")}
            </Button>
          </Box>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
            <img
              src={crossPlatform}
              alt="Cross Platform devices"
              className={classes.crossPlat}
            />
          </Grid>
        </Hidden>
      </Grid>
    </WrapperContainer>
  );
}
