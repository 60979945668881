import React from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import SharedLinuxPage from "views/SharedLinuxPage/SharedLinuxPage";

import styles from "../../assets/jss/material-kit-react/views/whyShells";
import { img1 } from "assets/img/whyshells";

const useStyles = makeStyles(styles);

export default function RemoteToolsLandingPage() {
  const { t } = useTranslation();
  const classes = useStyles();

  const details = {
    header: t("lp_remotetools_title"),
    description: (
      <>
        {t("lp_linuxquestionsorg_catchprase")}
        <br />
        {t("lp_linuxquestionsorg_catchprase_second")}
        <br />
        <br />
        <span style={{ fontSize: ".75em" }}>
          {t("lp_techradar_annotation")}
        </span>
      </>
    ),
    image: img1,
    imageClass: `${classes.lpImage} ${classes.marginTop}`,
    addlWclass: `${classes.overflowHidden} ${classes.textAlignLeft}`
  };

  return (
    <>
      <SharedLinuxPage
        title="Shells Virtual Desktop - Remote.Tools"
        headerDetails={details}
        buttonText={t("lp_linuxquestionsorg_checkitout")}
        buttonUrl="#pricing"
        heroRibbonBool={true}
        showDiscountPlans={true}
        specialButtonClass={classes.remoteToolsButton}
      />
      <Box pb={8}>
        <Typography
          component="p"
          variant="h5"
          className={classes.regularPricingParagraph}
        >
          <Trans
            i18nKey="manjaro_back_to_regular_pricing"
            components={{
              pageLink: <Link className={classes.greenLink} to="/pricing" />
            }}
          />
        </Typography>
      </Box>
    </>
  );
}
