import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";

import styles from "../../assets/jss/material-kit-react/components/operatingSystemsStyle";

const useStyles = makeStyles(styles);

export default function OperatingSystem({ data, condensed = false }) {
  const { name, icon, os } = data;
  const classes = useStyles();

  const containerClass = `${
    os ? classes.operatingContainer : classes.iconContainer
  } ${condensed ? "condensed" : ""}`;

  return (
    <div className={containerClass}>
      <Tooltip title={name} placement="top">
        <img src={icon} alt={name} className={classes.icon} />
      </Tooltip>
    </div>
  );
}
