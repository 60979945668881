import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/edTech";

import { WrapperContainer } from "components/HOC";
import {
  virusResistant,
  autoSave,
  professional,
  desktop
} from "assets/img/edtech";

const useStyles = makeStyles(styles);

export default function SectionFour() {
  const { t } = useTranslation();
  const classes = useStyles();

  const sectionFourData = [
    {
      image: virusResistant,
      title: t("edtech_h10"),
      body: t("edtech_p10")
    },
    {
      image: autoSave,
      title: t("edtech_h9"),
      body: t("edtech_p9")
    },
    {
      image: professional,
      title: t("edtech_h11"),
      body: t("edtech_p11")
    },
    {
      image: desktop,
      title: t("edtech_h8"),
      body: t("edtech_p8")
    }
  ];

  return (
    <WrapperContainer addMaxWidth={false} addlWclass={classes.lightBlueBG}>
      <Grid container spacing={3} alignItems="center" justify="center">
        {sectionFourData.map((data, index) => {
          const { image, title, body } = data;

          return (
            <Grid
              item
              xs={12}
              sm={6}
              key={index}
              className={classes.marginBottom}
            >
              <Grid
                container
                alignItems="center"
                justify="center"
                direction={index % 2 === 0 ? "row" : "row-reverse"}
                spacing={2}
                className={classes.gridContainer}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={
                    index % 2 === 0
                      ? classes.s4ImagePosRight
                      : classes.s4ImagePosLeft
                  }
                >
                  <img
                    src={image}
                    alt=""
                    className={
                      index % 2 === 0
                        ? `${classes.sectionOneImage} ${classes.s4ImagePosRight}`
                        : `${classes.sectionOneImage} ${classes.s4ImagePosLeft}`
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.sectionOneHeaderParent}>
                    <Typography
                      variant="h4"
                      component="h3"
                      className={
                        index % 2 === 0
                          ? `${classes.sectionOneHeaderPosRight} ${classes.sectionOneHeader} ${classes.greenText}`
                          : `${classes.sectionOneHeaderPosLeft} ${classes.sectionOneHeader} ${classes.greenText}`
                      }
                      align={index % 2 !== 0 ? "right" : "left"}
                    >
                      {title}
                    </Typography>
                  </div>
                  <div className={classes.greenLine_s1_b} />
                  <div className={classes.bodyContainer}>
                    <Typography
                      variant="body1"
                      className={classes.sectionOneBody}
                      align={index % 2 !== 0 ? "right" : "left"}
                    >
                      {body}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          );
        })}

        <Box pb={4}>
          <Button
            color="primary"
            variant="contained"
            className={classes.sectionThreeJoinButton}
            component={Link}
            to={"/pricing"}
          >
            {t("lp_get_shell_button")}
          </Button>
        </Box>
      </Grid>
    </WrapperContainer>
  );
}
