import React, { useEffect, useState } from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";
import { connect } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import typostyles from "assets/jss/material-kit-react/components/typographyStyle.js";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Countries from "../../components/Countries/Countries";
import { useHistory, useLocation } from "react-router-dom";
import Button from "components/CustomButtons/ShellButton.js";
import { fetchCountries } from "store/actions/CountriesAction";
import FlowLogin from "components/login/Login";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";

// actions
import {
  createBilling,
  createLocation,
  createOrder
} from "../../store/actions/CheckOutAction";
import { addItem } from "store/actions/CartAction";

// component
import NewCartList from "../../components/Cart/NewCartList";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TextField from "@material-ui/core/TextField";
import Loading from "../../components/common/feedback/loading/Loading";

// style
import { Box } from "@material-ui/core";

const useStyles = makeStyles(styles);
const useTypoStyles = makeStyles(typostyles);

const Cart = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const typoClasses = useTypoStyles();
  const history = useHistory();
  const location = useLocation();

  const {
    createLocation,
    createBilling,
    createOrder,
    addItem,
    user,
    fetchCountries,
    countries,
    registering
  } = props;
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [continueEnabled, setContinueEnabled] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [openLoginModal, setOpenLoginModal] = useState(false);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const orderHandller = () => {
    createLocation(firstname, lastname, zip, country)
      .then(data => {
        return createBilling(data.data.User_Location__);
      })
      .then(() => {
        return createOrder();
      })
      .then(data => {
        history.push(`/order/${data.data.Order__}${location.hash}`);
      });
  };

  useEffect(() => {
    if (
      !firstname.trim() ||
      !lastname.trim() ||
      !zip.trim() ||
      !country.trim()
    ) {
      setContinueEnabled(false);
      return;
    }

    setContinueEnabled(true);
  }, [firstname, lastname, zip, country]);

  const addCoupon = () => {
    addItem(`coupon,coupon_code=${coupon}`);
  };

  const buildInputField = () => {
    if (
      props.cart.cartLoading ||
      props.cart.cartItem.length === 0 ||
      countries.length === 0
    ) {
      return (
        <>
          <h2 className={typoClasses.titleSection}>
            {" "}
            {t("checkout_subtitle")}{" "}
          </h2>
          <div
            style={{
              margin: "30px 0 40px 0"
            }}
          >
            <Loading />
          </div>
        </>
      );
    } else {
      return (
        <>
          <h2 className={typoClasses.titleSection}>
            {" "}
            {t("checkout_subtitle")}{" "}
          </h2>
          <GridContainer spacing={1} style={{ margin: "10px" }}>
            <GridItem xs={12} md={6}>
              <TextField
                id="outlined-basic"
                required
                label={t("firstname_lbl")}
                variant="outlined"
                fullWidth
                value={firstname}
                onChange={e => setFirstname(e.target.value)}
                disabled={registering}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                id="outlined-basic"
                required
                label={t("lastname_lbl")}
                variant="outlined"
                fullWidth
                value={lastname}
                onChange={e => setLastname(e.target.value)}
                disabled={registering}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                id="outlined-basic"
                required
                label={t("zip_lbl")}
                variant="outlined"
                fullWidth
                value={zip}
                onChange={e => setZip(e.target.value)}
                disabled={registering}
              />
            </GridItem>

            <GridItem xs={12} md={6}>
              <Countries
                inputProps={{ fullWidth: true, required: true }}
                value={country}
                onChange={e => setCountry(e.target.value)}
                disabled={registering}
                geoLookup
              />
            </GridItem>
            <GridItem xs={12} align="center">
              <Box pt={2} pb={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    orderHandller();
                  }}
                  disabled={!continueEnabled}
                >
                  {t("checkout_payment_btn")}
                </Button>
              </Box>
            </GridItem>
          </GridContainer>
        </>
      );
    }
  };

  const buildCoupon = () => {
    if (props.cart.cartLoading || props.cart.cartItem.length === 0) {
      return null;
    }

    return (
      <>
        <GridContainer
          justify="flex-end"
          alignItems="center"
          spacing={1}
          style={{ margin: "10px 0" }}
        >
          <GridItem xs={12} md={6}>
            <p>{t("cart_coupon")}</p>
          </GridItem>
          <GridItem xs={12} md={5}>
            <TextField
              id="outlined-basic"
              label={t("cart_coupon_lbl")}
              variant="outlined"
              fullWidth
              value={coupon}
              onChange={e => setCoupon(e.target.value)}
              disabled={registering}
            />
          </GridItem>
          <GridItem xs={12} md={1} style={{ textAlign: "center" }}>
            <Button
              color="primary"
              variant="contained"
              disabled={coupon.length === 0}
              onClick={() => {
                addCoupon();
              }}
            >
              {t("cart_coupon_submit")}
            </Button>
          </GridItem>
        </GridContainer>
      </>
    );
  };

  const buildLogin = () => {
    if (props.cart.cartLoading || props.cart.cartItem.length === 0) {
      return null;
    }

    if (user !== null) {
      return (
        <>
          {buildCoupon()}
          {buildInputField()}
        </>
      );
    } else {
      return (
        <>
          <Box py={4} px={2}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Typography>
                {t("create_account_or_login_description")}
              </Typography>
              <Box pt={4}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setOpenLoginModal(true)}
                >
                  {t("create_account_or_login_title")}
                </Button>
              </Box>
            </Grid>
          </Box>
          <Dialog
            open={openLoginModal}
            onClose={() => setOpenLoginModal(false)}
            classes={{
              paper: classes.userFlowDialog
            }}
          >
            <Box py={4} px={2}>
              <FlowLogin onLoggedIn={() => setOpenLoginModal(false)} />
            </Box>
          </Dialog>
        </>
      );
    }
  };

  return (
    <div className={classes.genericPageWrapper}>
      <Helmet title={t("cart_title")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classNames(classes.container)}>
          <h2 className={typoClasses.titleSection}> {t("cart_title")} </h2>
          <NewCartList remove={true} />
          {buildLogin()}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    registering: state.checkout.registering,
    order: state.checkout.order,
    error: state.checkout.error,
    cart: state.cart,
    user: state.user.user,
    countries: state.countries.countries
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createLocation: (firstName, lastName, zip, country) =>
      dispatch(createLocation(firstName, lastName, zip, country)),
    createBilling: location => dispatch(createBilling(location)),
    createOrder: () => dispatch(createOrder()),
    addItem: productId => dispatch(addItem(productId)),
    fetchCountries: () => dispatch(fetchCountries())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cart);
