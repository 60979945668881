import React from "react";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import {
  GiveawayHero,
  TwitterContestRules
  //   RedditContestRules
} from "components/Giveaway";

export default function Giveaway({ social }) {
  let contestRules, textDirection;

  switch (social) {
    case "twitter":
      textDirection = "left";
      contestRules = <TwitterContestRules />;
      break;
    // case "reddit":
    //   textDirection = "right";
    //   contestRules = <RedditContestRules />;
    //   break;
    default:
      break;
  }

  return (
    <>
      <GiveawayHero textOn={textDirection} />
      <Box py={8}>
        <Grid container justify="center">
          {contestRules}
        </Grid>
      </Box>
    </>
  );
}
