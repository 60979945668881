import { USER_LOGOUT } from "./UserReducer";

const initState = {
  loading: false,
  loadingMethod: false,
  updatingMethod: false,
  methodUpdateError: null,
  billings: [],
  selected: null,
  creating: false,
  creationError: null,
  selectedMethod: null,

  updating: false,
  updateError: null
};

export const USER_BILLING_FETCHING = "USER_BILLING_FETCHING";
export const USER_BILLING_FETCH_DONE = "USER_BILLING_FETCH_DONE";
export const USER_BILLING_CREATING = "USER_BILLING_CREATING";
export const USER_BILLING_CREATED = "USER_BILLING_CREATED";
export const USER_BILLING_CREATE_ERROR = "USER_BILLING_CREATE_ERROR";
export const USER_BILLING_FETCH_METHOD = "USER_BILLING_FETCH_METHOD";
export const USER_BILLING_FETCH_METHOD_DONE = "USER_BILLING_FETCH_METHOD_DONE";

export const USER_BILLING_UPDATING_METHOD = "USER_BILLING_UPDATING_METHOD";
export const USER_BILLING_UPDATE_METHOD_ERROR =
  "USER_BILLING_UPDATE_METHOD_ERROR";
export const USER_BILLING_METHOD_UPDATED = "USER_BILLING_METHOD_UPDATED";

export const USER_BILLING_UPDATING = "USER_BILLING_UPDATING";
export const USER_BILLING_UPDATE_ERROR = "USER_BILLING_UPDATE_ERROR";
export const USER_BILLING_UPDATE_DONE = "USER_BILLING_UPDATE_DONE";

const userBillingReducer = (state = initState, action) => {
  let selected = null;

  switch (action.type) {
    case USER_LOGOUT:
      return { ...initState };
    case USER_BILLING_FETCHING:
      return { ...state, loading: true, loadingMethod: true };
    case USER_BILLING_FETCH_METHOD:
      return { ...state, loadingMethod: true };
    case USER_BILLING_FETCH_METHOD_DONE:
      return { ...state, loadingMethod: false, selectedMethod: action.method };
    case USER_BILLING_FETCH_DONE:
      // simplify everything, we only handle 1 user billing at the time => no user billing  management
      if (action.billings.length > 0) selected = action.billings[0];

      return {
        ...state,
        loading: false,
        billings: action.billings,
        selected: selected
      };
    case USER_BILLING_CREATING:
      return { ...state, creating: true, creationError: null };
    case USER_BILLING_CREATE_ERROR:
      return { ...state, creating: false, creationError: action.error };
    case USER_BILLING_CREATED:
      return {
        ...state,
        creating: false,
        selected: action.billing,
        billings: [...state.billings, action.billing]
      };
    case USER_BILLING_UPDATING_METHOD:
      return { ...state, updatingMethod: true, methodUpdateError: null };
    case USER_BILLING_UPDATE_METHOD_ERROR:
      return {
        ...state,
        updatingMethod: false,
        methodUpdateError: action.error
      };
    case USER_BILLING_METHOD_UPDATED:
      return { ...state, updatingMethod: false, selectedMethod: action.method };
    case USER_BILLING_UPDATING:
      return { ...state, updating: true, updateError: null };
    case USER_BILLING_UPDATE_ERROR:
      return { ...state, updating: false, updateError: action.error };
    case USER_BILLING_UPDATE_DONE:
      return { ...state, updating: false, selected: action.billing };
    default:
      return state;
  }
};

export default userBillingReducer;
