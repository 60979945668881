import React from "react";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/daas";

import GridCard from "views/LandingPage/Other/GridCard";

import img1 from "assets/img/lp/img_vms_001.png";
import img2 from "assets/img/lp/img_vms_002.png";
import img3 from "assets/img/lp/img_vms_003.png";
import img4 from "assets/img/lp/img_vms_004.png";
import img5 from "assets/img/lp/img_vms_005.png";
import img6 from "assets/img/lp/img_vms_006.png";

const useStyles = makeStyles(styles);

export default function SectionTwo() {
  const { t } = useTranslation();
  const classes = useStyles();

  const vmsList = [
    {
      title: t("lp_vms_content1_title_v2"),
      text: t("lp_vms_content1_text"),
      image: img1
    },
    {
      title: t("lp_vms_content2_title"),
      text: t("lp_vms_content2_text"),
      image: img2
    },
    {
      title: t("lp_vms_content3_title_v2"),
      text: t("lp_vms_content3_text"),
      image: img3
    },
    {
      title: t("lp_vms_content4_title"),
      text: t("lp_vms_content4_text"),
      image: img4
    },
    {
      title: t("lp_vms_content5_title"),
      text: t("lp_vms_content5_text"),
      image: img5
    },
    {
      title: t("lp_vms_content6_title"),
      text: t("lp_vms_content6_text_v2"), // comment out 3 and 5 from list!
      text2: t("lp_vms_content6_text2"),
      image: img6
    }
  ];

  return (
    <Box pt={8}>
      <Container maxWidth="lg">
        <div className={classes.daasTextContainer}>
          <Typography
            variant="h3"
            className={`${classes.headers} ${classes.addMarginBottom}`}
            align="center"
          >
            {t("daas_section_two_title")}
          </Typography>
        </div>
        <Grid container spacing={4} alignItems="center" justify="center">
          {vmsList.map((data, index) => {
            return <GridCard data={data} key={index} />;
          })}
        </Grid>
      </Container>
    </Box>
  );
}
