import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import { useTranslation } from "react-i18next";

import styles from "assets/jss/material-kit-react/views/orderPage.js";

import Plans from "components/Plans/OrderPlans";
import GridItem from "../../../components/Grid/GridItem";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(styles);

export default function ProductSection({ onPlanSelected }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <GridContainer
      className={classes.section}
      direction="row"
      justify="flex-start"
      alignItems="center"
    >
      <GridContainer justify="flex-start">
        <GridItem xs={12}>
          <div className={classes.titleContainer}>
            <h2 className={classes.titleSection}> {t("order_choose_plan")} </h2>
          </div>
        </GridItem>
      </GridContainer>

      <GridItem xs={12}>
        <Plans onPlanSelected={onPlanSelected} />
      </GridItem>
    </GridContainer>
  );
}
