import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/material-kit-react/views/cloudmusic";

const useStyles = makeStyles(styles);

export default function GridCard({ data }) {
  const { cardContentClass, header, body, image, benefit } = data;
  const classes = useStyles();

  let md = 0;
  if (benefit) {
    md = 4;
  }

  return (
    <Grid item xs={12} sm={6} md={md}>
      <Card className={classes.gridCard} elevation={0}>
        <CardMedia
          component="img"
          image={image}
          className={classes.cardImage}
        />
        <CardContent className={cardContentClass}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className={classes.cardHeader}
          >
            {header}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.cardText}
          >
            {body}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
