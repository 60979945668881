import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/edTech";
import style from "assets/scss/modules/edtech.module.scss";

import { WrapperContainer } from "components/HOC";

const useStyles = makeStyles(styles);

export default function SectionTwo() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper className={classes.youtubePost} square elevation={0}>
      <WrapperContainer addMaxWidth={true} addlWclass={classes.morePadding2}>
        <div className={classes.youtubeContentContainer}>
          <div
            className={`${classes.mainFeaturedPostContent} ${classes.textButtonBg}`}
          >
            <Container maxWidth="lg" className={classes.textButtonContainer}>
              <Box mb={4}>
                <Typography
                  component="h1"
                  variant="h2"
                  className={`${classes.heroHeader} ${classes.lightBlueFont} ${classes.centerText}`}
                >
                  {t("edtech_h6")}
                </Typography>
              </Box>
              <Typography
                component="h2"
                variant="body1"
                className={`${classes.subHeader} ${classes.lightBlueFont} ${classes.centerText}`}
              >
                {t("edtech_p6")}
              </Typography>
              <Button
                variant="contained"
                disableElevation
                className={`${classes.signUpButton} ${classes.zeroMarginLeft}`}
                component={Link}
                to="/pricing"
              >
                {t("whyshells_button")}
              </Button>
            </Container>
          </div>
          <div className={classes.videoContainer}>
            <iframe
              className={style["video"]}
              src="https://www.youtube.com/embed/rWc3s0ydq6E?rel=0"
              frameBorder="0"
              title="Introducing Shells"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </WrapperContainer>
    </Paper>
  );
}
