import React from "react";
import { Get } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";

const Text = () => {
  const { t } = useTranslation();
  return (
    <textarea
      id="mail-text"
      defaultValue={
        Get("_user").Profile.Display_Name +
        "\n\r" +
        t("mail_payment_failed_desc_txt") +
        "\n\r" +
        "Shells\n\rhttps://shells.com/\n\r"
      }
    />
  );
};

export default Text;
