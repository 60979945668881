import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Helmet from "components/instances/instance/Helmet/Helmet";
import NotFound404 from "views/Special/NotFound404";
import { useParams } from "react-router-dom";

// components
import Hero from "components/landingPage/Hero";
import Ide from "./ide";
import Vms from "./vms";
import Audio from "./audio";
import Irc from "./irc";
import Repair from "./repair";
import Grade from "./grade";
import Rif from "./rif";
// import Editing from "./editing";
// import ThreeD from "./3d";
// import PlansSection from "../Sections/PlansSection";
import { AlternativeLayoutHero } from "components/AlternativeLayoutHero";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

// images
import { vsCodeIpad } from "assets/img/vscode";
import { cloudDevices } from "assets/img/browserinthecloud";
import { device1, device3 } from "assets/img/homepage20210414";

import styles from "assets/jss/material-kit-react/views/homepage20210312.style";
const useStyles = makeStyles(styles);

export default function OtherLayout() {
  const { t } = useTranslation();
  const classes = useStyles();
  const params = useParams();
  const [lpMName] = useState([
    "ide",
    "vms",
    "audio",
    "irc",
    "repair",
    "grade",
    "rif",
    "byod",
    "computers",
    "simplified",
    "quickbooks"
    // "3d",
    // "editing",
  ]);
  const [lpBoolean, setLpBoolean] = useState(
    lpMName.indexOf(params.pageName) !== -1
  );

  const buildContent = () => {
    let content;
    let heroSection = (
      <Hero
        title={t(`lp_${params.pageName}_hero_title`)}
        text={t(`lp_${params.pageName}_hero_text`)}
        pageName={params.pageName}
        namesArray={lpMName}
      />
    );

    switch (params.pageName) {
      case "ide":
        heroSection = (
          <AlternativeLayoutHero
            heroText="lp_ide_hero_title"
            subText="lp_ide_hero_text"
            devicesImage={vsCodeIpad}
            imageAlt="Shells and VS Code"
          />
        );
        content = <Ide />;
        break;
      case "vms":
        heroSection = (
          <AlternativeLayoutHero
            heroText="lp_vms_hero_title"
            subText="lp_vms_hero_text"
            devicesImage={cloudDevices}
            imageAlt="Devices using Shells"
          />
        );
        content = <Vms />;
        break;
      case "audio":
        content = <Audio />;
        break;
      case "irc":
        heroSection = (
          <AlternativeLayoutHero
            heroText="lp_irc_hero_title"
            subText="lp_irc_hero_text"
            devicesImage={cloudDevices}
            imageAlt="Devices using Shells"
          />
        );
        content = <Irc />;
        break;
      case "repair":
        content = <Repair />;
        break;
      case "grade":
        heroSection = (
          <AlternativeLayoutHero
            heroText="lp_grade_hero_title"
            subText="lp_grade_hero_text"
            devicesImage={cloudDevices}
            imageAlt="Devices using Shells"
          />
        );
        content = <Grade />;
        break;
      case "rif":
        heroSection = (
          <AlternativeLayoutHero
            heroText="lp_rif_hero_title"
            subText="lp_rif_hero_text"
            devicesImage={device1}
            imageAlt="Devices using Shells"
          />
        );
        content = <Rif />;
        break;
      case "byod":
        heroSection = (
          <AlternativeLayoutHero
            heroText="lp_byod_hero_title"
            subText="lp_byod_hero_text"
            devicesImage={device3}
            imageAlt="Devices using Shells"
          />
        );
        content = <Rif />;
        break;
      case "computers":
      case "simplified":
      case "quickbooks":
        content = <Rif />;
        break;
      // case "3d":
      //   content = <ThreeD />;
      //   break;
      // case "editing":
      //   content = <Editing />;
      //   break;
      default:
        content = null;
    }

    return { content, heroSection };
  };

  useEffect(() => {
    setLpBoolean(lpMName.indexOf(params.pageName) !== -1);
  }, [params.pageName, lpMName]);

  if (!lpBoolean) {
    return <NotFound404 />;
  } else {
    return (
      <>
        <div style={{ overflowX: "hidden" }}>
          <Helmet title={t(`lp_${params.pageName}_title`)}>
            <meta
              name="keywords"
              content={t(`lp_${params.pageName}_keyword`)}
            />
            <meta
              name="description"
              content={t(`lp_${params.pageName}_desc`)}
            />
          </Helmet>

          {buildContent()["heroSection"]}
          {buildContent()["content"]}

          <Box pb={8}>
            <Button
              color="primary"
              variant="contained"
              className={classes.sectionOneJoinButton}
              component={Link}
              to={"/pricing"}
            >
              {t("new_hp_button_two")}
            </Button>
          </Box>
        </div>

        {/* <PlansSection maxWidth="xl" /> */}
      </>
    );
  }
}
