import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "./ShellButton";

const ShellLoadingButton = React.forwardRef(
  ({ loading, children, ...rest }, ref) => {
    if (loading) {
      rest.disabled = true;
      rest.endIcon = <CircularProgress thickness={2} size={16} />;
    }

    return (
      <Button {...rest} ref={ref}>
        {children}
      </Button>
    );
  }
);

export default ShellLoadingButton;
