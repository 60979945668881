import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { Header } from "components/WhyShells";
import SideToSide from "components/WhyShells/SideToSide";
import LinuxDistrosList from "./LinuxDistrosList";
import ShellsButton from "./../../components/CustomButtons/ShellButton";
import PlansCarousel from "./../../components/PlansCarousel/PlansCarousel";
import SpecialPlansCarousel from "../../components/SpecialPlansCarousel/SpecialPlansCarousel";
import { sharedLinuxDetails } from "./sharedLinuxDetails";
import Discount from "views/PricingPlans/Discount";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/edTech";
import additionalStyles from "../../assets/jss/material-kit-react/views/whyShells";

const useStyles = makeStyles(styles);
const useAdditionnalStyles = makeStyles(additionalStyles);

export default function SharedLinuxPage({
  title,
  headerDetails,
  showLinuxDetails,
  buttonText,
  heroRibbonBool,
  showDistrosList,
  showDiscountPlans,
  showNormalPlans,
  specialButtonClass,
  paddingBottom,
  buttonUrl
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const additionnalClasses = useAdditionnalStyles();

  return (
    <>
      <Helmet title={title} />
      <Header
        details={headerDetails}
        heroRibbon={heroRibbonBool ? heroRibbonBool : false}
        specialButton={
          <ShellsButton
            color="primary"
            variant="contained"
            component="a"
            href={buttonUrl}
            className={
              specialButtonClass ? specialButtonClass : classes.signUpButton
            }
          >
            {buttonText}
          </ShellsButton>
        }
      />
      <div id="pricing-plans" style={{ marginTop: "-62px" }} />
      <Box
        pt={8}
        pb={paddingBottom ? paddingBottom : 16}
        px={2}
        style={{ overflowX: "hidden" }}
      >
        {showDistrosList && (
          <>
            <Box pt={8} pb={4}>
              <div className={classes.container}>
                <LinuxDistrosList />
              </div>
            </Box>
            <Typography
              component="h2"
              variant="h4"
              className={additionnalClasses.regularPricingParagraph}
            >
              {t("lp_start_linux_lt_five_minutes")}
            </Typography>
          </>
        )}
        {showDiscountPlans && (
          <Box pb={2}>
            <SpecialPlansCarousel
              plansType="manjaro_plan"
              showOnly={["basic", "plus", "pro"]}
              options={{
                basic: {
                  usageLimit: "unlimited_use",
                  disclaimer: { key: "save_n_percent", number: "45" },
                  discount: <Discount transKey="manjaro_basic_discount" />
                },
                plus: {
                  usageLimit: "unlimited_use",
                  disclaimer: { key: "save_n_percent", number: "56" },
                  discount: <Discount transKey="manjaro_plus_discount" />
                },
                pro: {
                  usageLimit: "unlimited_use",
                  disclaimer: { key: "save_n_percent", number: "39" },
                  discount: <Discount transKey="manjaro_pro_discount" />
                }
              }}
            />
          </Box>
        )}
        {showNormalPlans && <PlansCarousel />}
      </Box>
      {showLinuxDetails &&
        sharedLinuxDetails.map((d, i) => (
          <div className={classes.wrapper} key={i}>
            <SideToSide details={d} typographyComponent="h2" />
          </div>
        ))}
    </>
  );
}
