import React from "react";

// component
import NotFound from "components/NotFound/NotFound";

const NotFound404 = () => {
  return <NotFound />;
};

export default NotFound404;
