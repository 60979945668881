import { combineReducers } from "redux";

import FlowReducer from "./FlowReducer";
import UserReducer from "./UserReducer";
import ToastReducer from "./ToastReducer";
import cmsReducer from "./CmsReducer";
import UserBillingReducer from "./UserBillingReducer";
import StripePaymentReducer from "./StripePaymentReducer";
import ContactReducer from "./ContactReducer";
import PlanReducer from "./PlanReducer";
import CountriesReducer from "./CountriesReducer";
import OrderReducer from "./OrderReducer";
import ShellReducer from "./ShellReducer";
import LocationReducer from "./LocationReducer";
import cartReducer from "./CartReducer";
import CheckOutReducer from "./CheckOutReducer";
import DefaultOrder from "./DefaultOrderReducer";
import SoftwareReducer from "./SoftwareReducer";
import OperatingSystemsReducer from "./OperatingSystemsReducer";
import TechRadarReducer from "./TechRadarReducer";
import CloudTweaksReducer from "./CloudTweaksReducer";
import SpecialPlansReducer from "./SpecialPlansReducer";
import WindowsReducer from "./WindowsReducer";
import OAuthReducer from "./OAuthReducer";

const rootReducer = combineReducers({
  user: UserReducer,
  flow: FlowReducer,
  toast: ToastReducer,
  cms: cmsReducer,
  userBilling: UserBillingReducer,
  stripe: StripePaymentReducer,
  contact: ContactReducer,
  plans: PlanReducer,
  countries: CountriesReducer,
  order: OrderReducer,
  shell: ShellReducer,
  location: LocationReducer,
  cart: cartReducer,
  checkout: CheckOutReducer,
  defaultOrder: DefaultOrder,
  software: SoftwareReducer,
  operatingSystems: OperatingSystemsReducer,
  techradar: TechRadarReducer,
  cloudtweaks: CloudTweaksReducer,
  specialPlans: SpecialPlansReducer,
  windows: WindowsReducer,
  oauth: OAuthReducer
});

export default rootReducer;
