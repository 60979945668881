import React, { useEffect } from "react";
import { rest } from "@karpeleslab/klbfw";
import Helmet from "components/instances/instance/Helmet/Helmet";

import {
  MainHeader,
  SectionOne,
  SectionTwo,
  SectionThree
} from "components/Manjaro";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/manjaro";

const useStyles = makeStyles(styles);

export default function Manjaro() {
  const classes = useStyles();

  useEffect(() => {
    rest("Affiliate:setCode", "POST", { code: "manjaro" });
  }, []);

  return (
    <div className={classes.body}>
      <Helmet title="Shells Virtual Desktop - Manjaro"></Helmet>

      {/* Manjaro */}
      <MainHeader
        bannerText="lp_linuxquestionsorg_ribbon"
        heroText="manjaro_h1"
        subText="manjaro_h2_b"
      />

      {/* Free is Better */}
      <SectionOne />

      {/* The Power */}
      <SectionTwo />

      {/* Artists */}
      <SectionThree />
    </div>
  );
}
