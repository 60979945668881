import {
  mainGreenColor,
  whiteColor,
  blackColor,
  secondaryGreenColor
} from "assets/jss/material-kit-react";
import { lttBG, lttBGmobile, lttBGtablet } from "assets/img/ltt";

const styles = theme => ({
  mainFeaturedPost: {
    position: "relative",
    color: theme.palette.common.white,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: 0,
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${lttBGmobile})`
    },
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${lttBGtablet})`
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${lttBG})`
    }
  },
  lttDevicesImage: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "45px"
    }
  },
  morePadding: {
    padding: "60px 45px",
    [theme.breakpoints.down("xs")]: {
      padding: "60px 15px 60px 15px"
    }
  },
  mainHeader: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: "900",
    color: whiteColor,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "42.5984px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "52px"
    }
  },
  subHeader: {
    fontSize: "22px",
    fontFamily: "Open Sans",
    color: blackColor,
    lineHeight: 1.4,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: 1.4
    }
  },
  headerTextContainer: {
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "300px",
      textAlign: "center"
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "300px"
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "400px"
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "700px"
    }
  },
  subTextContainer: {
    margin: "0 auto",
    padding: "0 15px 0 15px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "320px"
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "600px"
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "650px"
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "720px"
    }
  },
  shellsLttLogo: {
    marginBottom: "10px",
    [theme.breakpoints.down("xs")]: {
      width: "200px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "200px"
    },
    [theme.breakpoints.up("md")]: {
      width: "300px"
    }
  },
  listRoot: {
    width: "100%",
    maxWidth: "540px",
    backgroundColor: theme.palette.background.paper,
    margin: "0 auto"
  },
  listItem: {
    backgroundColor: mainGreenColor,
    borderRadius: "20px",
    margin: "15px 0 15px 0",
    boxShadow: "8px 8px 1px 1px rgba(0, 0, 0, .1)",
    [theme.breakpoints.down("xs")]: {
      borderRadius: 0
    }
  },
  listItemText: {
    fontFamily: "Roboto, sans-serif",
    fontWeight: "900",
    fontSize: "16px"
  },
  checkIconWhite: {
    fontSize: "35px",
    fontWeight: "900",
    color: whiteColor
  },
  checkIcon: {
    fontSize: "35px",
    fontWeight: "900",
    color: blackColor
  },
  listItemWhite: {
    color: whiteColor
  },
  signUpButton: {
    display: "block",
    margin: "40px 0 40px 0",
    backgroundColor: mainGreenColor,
    borderRadius: "35px",
    color: whiteColor,
    fontSize: "22px",
    textAlign: "center",
    padding: "6px 24px",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover, &:focus": {
      boxShadow: `0 0 0 2px ${secondaryGreenColor} inset`,
      background: whiteColor,
      color: secondaryGreenColor
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "auto",
      marginRight: "auto"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "250px",
      padding: "6px 14px"
    },
    [theme.breakpoints.up("md")]: {
      width: "375px"
    }
  }
});

export default styles;
