import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";
import { useTranslation } from "react-i18next";

import Header from "./Header";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import SectionFive from "./SectionFive";

export default function LFA() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t("lfa_title")}>
        <meta name="keywords" content={t("helmet_lfa_keyword")} />
      </Helmet>
      <Header />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
    </>
  );
}
