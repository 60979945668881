import {
  mainGreenColor,
  secondaryGreenColor,
  whiteColor,
  lightGrayColor,
  blackColor
} from "assets/jss/material-kit-react";
import { bgWithLogo, bgWithoutLogo } from "assets/img/manjaro";

const styles = theme => ({
  body: {
    boxSizing: "border-box"
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    padding: "60px 15px"
  },
  container: {
    maxWidth: "1140px",
    margin: "0 auto",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0 15px 0 15px"
    },
    [theme.breakpoints.down("lg")]: {
      padding: "0 20px 0 20px"
    }
  },
  mainFeaturedPost: {
    position: "relative",
    color: theme.palette.common.white,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down("md")]: {
      backgroundImage: `url(${bgWithoutLogo})`
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${bgWithLogo})`
    }
  },
  mainFeaturedPostContent: {
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  pricingMainFeaturedPostContent: {
    textAlign: "left",
    maxWidth: "700px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      maxWidth: "400px",
      margin: "0 auto"
    }
  },
  mainHeader: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: "900",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "42.5984px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "52px"
    }
  },
  pricingMainHeader: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: "900",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "42.5984px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "52px"
    }
  },
  heroSubHeader: {
    fontFamily: "Open Sans, sans-serif",
    color: whiteColor,
    fontWeight: "900",
    fontSize: "24px",
    marginBottom: "15px",
    lineHeight: 1.5,
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px"
    }
  },
  heroSubHeader_nonBold: {
    fontFamily: "Open Sans, sans-serif",
    color: whiteColor,
    fontSize: "24px",
    marginBottom: "15px",
    lineHeight: 1.5,
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px"
    }
  },
  sectionHeader: {
    fontFamily: "Open Sans, sans-serif",
    color: blackColor,
    fontWeight: "900",
    fontSize: "24px",
    marginBottom: "15px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  sectionBody: {
    fontSize: "16px",
    fontFamily: "Roboto, sans-serif",
    color: blackColor,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  subHeader: {
    fontSize: "22px",
    fontFamily: "Open Sans, sans-serif",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  sectionHeaderParent: {
    position: "relative",
    minHeight: "55px",
    maxWidth: "250px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "40px",
      margin: "0 auto"
    }
  },
  sectionHeaderPos: {
    position: "absolute",
    bottom: "-15px",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.down("xs")]: {
      left: "50%",
      ":-webkit-transform": "translateX(-50%)",
      transform: "translateX(-50%)"
    }
  },
  gridCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  bgGreen: {
    backgroundColor: mainGreenColor
  },
  colorGreen: {
    color: mainGreenColor
  },
  whiteLine: {
    border: `2px solid ${whiteColor}`,
    margin: "45px 0 45px 0",
    width: "400px",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      margin: "45px auto 45px auto"
    }
  },
  cardContentOne: {
    minHeight: "240px",
    textAlign: "left",
    maxWidth: "350px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minHeight: 0
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  cardImage: {
    margin: "0 auto",
    padding: "15px 0 15px 0",
    [theme.breakpoints.up("xs")]: {
      width: "200px",
      height: "230px"
    }
  },
  root: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
    }
  },
  content: {
    textAlign: "right",
    width: "100%"
  },
  cardImageContainer: {
    padding: "0 45px 0 45px",
    [theme.breakpoints.down("sm")]: {
      padding: "0"
    }
  },
  icon: {
    display: "block",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "5px",
      width: "50px",
      height: "50px"
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "5px"
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "8px",
      width: "75px",
      height: "75px"
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "10px"
    }
  },
  textAlignLeft: {
    textAlign: "left"
  },
  removePadding: {
    padding: 0
  },
  addlPadding: {
    padding: "150px 45px 50px 45px",
    [theme.breakpoints.down("xs")]: {
      padding: "150px 15px 60px 15px"
    }
  },
  pricingAddlPadding: {
    [theme.breakpoints.down("xs")]: {
      padding: "140px 15px 60px 15px"
    },
    [theme.breakpoints.up("sm")]: {
      padding: "140px 45px 60px 45px"
    },
    [theme.breakpoints.up("lg")]: {
      padding: "140px 45px 50px 45px"
    }
  },
  signUpButton: {
    display: "block",
    margin: "40px 0 40px 0",
    backgroundColor: mainGreenColor,
    borderRadius: "35px",
    color: whiteColor,
    fontSize: "22px",
    textAlign: "center",
    padding: "6px 24px",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover, &:focus": {
      boxShadow: `0 0 0 2px ${secondaryGreenColor} inset`,
      background: whiteColor,
      color: secondaryGreenColor
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "auto",
      marginRight: "auto"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "250px",
      padding: "6px 14px"
    },
    [theme.breakpoints.up("md")]: {
      width: "375px"
    }
  },
  greenLine: {
    width: "225px",
    border: `3px solid ${mainGreenColor}`,
    borderRadius: "5px",
    margin: "20px 0 20px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "20px auto 20px auto"
    }
  },
  headerGreenLine: {
    border: `3px solid ${mainGreenColor}`,
    borderRadius: "5px",
    margin: "45px auto 55px auto",
    width: "300px",
    [theme.breakpoints.down("sm")]: {
      width: "200px"
    }
  },
  paperHeader: {
    color: mainGreenColor,
    fontFamily: "Open Sans, sans-serif",
    fontWeight: "900",
    fontSize: "24px",
    marginBottom: "15px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  marginBottom: {
    marginBottom: "25px"
  },
  whiteColor: {
    color: whiteColor
  },
  bgLightGray: {
    backgroundColor: lightGrayColor
  },
  bodyTextContainer: {
    maxWidth: "300px",
    margin: "0 auto",
    textAlign: "justify"
  },
  powerSection: {
    textAlign: "center",
    backgroundColor: lightGrayColor,
    display: "flex",
    flexDirection: "column"
  },
  gridPaper: {
    [theme.breakpoints.down("md")]: {
      marginBottom: "20px"
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "40px"
    }
  },
  bottomJoinButton: {
    width: "300px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "6px 24px",
    border: `3px solid ${mainGreenColor}`,
    backgroundColor: "#ffffff",
    borderRadius: "35px",
    color: mainGreenColor,
    fontSize: "22px",
    textAlign: "center",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover, &:focus": {
      background: mainGreenColor,
      color: "#ffffff"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      width: "250px"
    }
  },
  containerPadding: {
    [theme.breakpoints.down("lg")]: {
      padding: "0 15px 0 15px"
    }
  },
  textLink: {
    color: mainGreenColor,
    fontWeight: "bold",
    "&:hover, &:focus": {
      textDecoration: "underline",
      color: mainGreenColor
    }
  },
  mainRibbon: {
    position: "absolute",
    top: "30px",
    left: "0",
    width: "100%",
    backgroundColor: mainGreenColor,
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center",
    padding: "12px",
    fontSize: "24px",
    transition: "background-color .15s linear",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px"
    },
    "&:hover": {
      backgroundColor: "#5ba331",
      color: "#fff"
    },
    "& > span": {
      textDecoration: "underline"
    }
  },
  textGridItem: {
    paddingLeft: "45px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px"
    }
  },
  manjaroDevicesImage: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "45px"
    }
  },

  countdownContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "spaceBetween",
    maxWidth: "600px",
    borderRadius: "15px",
    margin: "0 auto",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    top: "130px",
    backgroundColor: mainGreenColor,
    padding: "10px 0px 10px 0px",
    [theme.breakpoints.down("xs")]: {
      borderRadius: 0,
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "650px"
    }
  },
  countdownAddlPadding: {
    [theme.breakpoints.down("xs")]: {
      padding: "275px 15px 60px 15px"
    },
    [theme.breakpoints.up("sm")]: {
      padding: "275px 45px 60px 45px"
    },
    [theme.breakpoints.up("lg")]: {
      padding: "205px 45px 50px 45px"
    }
  },
  gridContainer: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "25px"
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "80px"
    }
  }
});

export default styles;
