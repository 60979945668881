import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import qs from "querystring";

// component
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/Button";
import Loading from "../../components/common/feedback/loading/Loading";

// actions
import { addItem, cartFetch, removeItem } from "store/actions/CartAction";

// style
import styles from "../../assets/scss/modules/cart.module.scss";

const NewCartList = ({ cart, cartFetch, addItem, removeItem }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [initCheck, setInitCheck] = useState(false);

  // when we first load the user's cart, we need to check if there's a product (and/or coupon) in the URL
  // and if it's not already in the cart.
  // if it is, we do nothing, but if it's not, we add it.
  useEffect(() => {
    if (!cart || cart.cartLoading || initCheck) return;

    const query = qs.parse(location.search.replace("?", ""));
    setInitCheck(true);

    if (!query.product) return;

    if (query.product.indexOf("coupon,coupon_code") < 0) addItem(query.product);
    else {
      let preventDouble = false;
      cart.cartItem.forEach(c => {
        if (
          c.data.Internal_Name === "Coupon" &&
          query.product.indexOf(c.data["Meta.coupon_code"]) >= 0
        )
          preventDouble = true;
      });
      if (!preventDouble) addItem(query.product);
    }
  }, [cart]); // eslint-disable-line

  // fetching the cart of the current user's session
  useEffect(() => {
    cartFetch();
  }, [cartFetch]);

  const buildAllPrice = () => {
    if (!cart || cart.cartItem.length < 1) return null;

    return (
      <dl className={styles["cart-total"]}>
        <div>
          <dt>{t("cart_subtotal")}</dt>
          <dd>{cart.cart.total.display}</dd>
        </div>
        <div>
          <dt>{t("cart_tax")}</dt>
          <dd>{cart.cart.total_vat_only.display}</dd>
        </div>
        <div>
          <dt>{t("cart_total")}</dt>
          <dd>{cart.cart.total_vat.display}</dd>
        </div>
      </dl>
    );
  };

  const buildItem = () => {
    if (!cart || cart.cartItem.length < 1) {
      return (
        <div className={styles["cart-nothing"]}>
          <p>{t("cart_no_item")}</p>
        </div>
      );
    } else {
      return cart.cartItem.map((item, idx) => (
        <dl key={`${item}-${idx}`}>
          <div>
            <Hidden smDown>
              <dt>{t("cart_name")}</dt>
            </Hidden>
            <dd>{item.data["Basic.Name"]}</dd>
          </div>
          <div className={styles["cart-mobile-individual-price"]}>
            <Hidden smDown>
              <dt>{t("cart_price")}</dt>
            </Hidden>
            <dd>
              <Hidden mdUp>{t("cart_price")}: &nbsp;</Hidden>
              {item.price.tax.display}
            </dd>
          </div>
          <div>
            <Hidden smDown>
              <dt>{t("cart_actions")}</dt>
            </Hidden>
            <dd>
              <Grid container justify="flex-end">
                <Grid item>
                  <IconButton
                    color="secondary"
                    onClick={() => removeItem(item.key)}
                  >
                    <DeleteForeverIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </dd>
          </div>
        </dl>
      ));
    }
  };

  return (
    <div className={styles["cart-wrapper"]}>
      {cart && !cart.cartLoading ? (
        <>
          <div className={styles["cart-item"]}>{buildItem()}</div>
          {buildAllPrice()}
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  addItem: productId => dispatch(addItem(productId)),
  cartFetch: () => dispatch(cartFetch()),
  removeItem: key => dispatch(removeItem(key))
});

const mapStateToProps = state => ({ cart: state.cart });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCartList);
