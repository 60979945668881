// Header
export const bgImageDesktop = require("./shells_header_desktop.png");
export const bgImageTablet = require("./shells_header_tablet.png");
export const bgImageMobile = require("./shells_header_mobile.png");
export const debianTV = require("./debian_tv.png");

// Section One
export const freedom = require("./Section1_Fingertips.svg");
export const world = require("./Section1_World.svg");
export const stability = require("./Section1_Stability.svg");
export const everywhere = require("./Section1_Everywhere.svg");

// Section Three
export const arrowLeft = require("./Arrow_Left.svg");
export const arrowRight = require("./Arrow_Right.svg");
export const community = require("./Community_Owned.svg");
