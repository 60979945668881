export const img1 = require("./devices.png");
export const img2 = require("./horizontal1.png");
export const img3 = require("./horizontal2.png");
export const img4 = require("./software-secure.png");
export const img5 = require("./upgrade.png");
export const img6 = require("./powerpoint.png");
export const img7 = require("./foryou1.png");
export const img8 = require("./foryou2.png");
export const img9 = require("./education-login.png");
export const img10 = require("./background-wave.svg");
export const img11 = require("./hero-background2-414.svg");
export const img12 = require("./hero-background2-1024.svg");
