import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { contact } from "store/actions/ContactAction";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import Button from "../../components/CustomButtons/ShellButton.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import BoxedText from "../../components/common/feedback/BoxedText";

class Contact extends React.Component {
  initState = {
    name: "",
    subject: "",
    email: "",
    content: "",
    type: "cfyt-tjedg6-tau5-hpzl-f3wd-jw3bhzwu"
  };

  state = this.initState;

  onInputChangeHandler = e => {
    this.setState({ [e.target.getAttribute("name")]: e.target.value });
  };

  submitHandler = e => {
    e.preventDefault();

    this.props
      .contact(
        this.state.email,
        this.state.name,
        this.props.naka,
        this.state.subject,
        this.state.content,
        this.state.type ?? null
      )
      .then(() => this.setState({ ...this.initState }))
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const { t } = this.props;

    if (this.props.sent) {
      return (
        <BoxedText
          disabledGutter
          title={t("contact_message_sent_notif_title")}
          description={t("contact_message_sent_notif")}
        />
      );
    }

    return (
      <form onSubmit={this.submitHandler}>
        <GridContainer spacing={3} direction="column" alignItems="center">
          <GridItem xs={12} md={8}>
            <FormControl required>
              <FormLabel>{t("contact_select_category")}</FormLabel>
              <RadioGroup
                row
                aria-label="type"
                name="type"
                value={this.state.type}
                onChange={this.onInputChangeHandler}
              >
                {this.props.tags.map(t => (
                  <FormControlLabel
                    key={t.key}
                    value={t.key}
                    control={<Radio color="primary" />}
                    label={t.value}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </GridItem>

          <GridItem xs={12} md={8}>
            <TextField
              label={t("contact_name")}
              placeholder={t("contact_name")}
              name="name"
              required
              fullWidth
              value={this.state.name}
              onChange={this.onInputChangeHandler}
              variant="outlined"
              disabled={this.props.sending}
            />
          </GridItem>

          <GridItem xs={12} md={8}>
            <TextField
              label={t("contact_email")}
              placeholder={t("contact_email")}
              name="email"
              type="email"
              fullWidth
              value={this.state.email}
              onChange={this.onInputChangeHandler}
              variant="outlined"
              disabled={this.props.sending}
              required
            />
          </GridItem>

          <GridItem xs={12} md={8}>
            <TextField
              label={t("contact_subject")}
              placeholder={t("contact_subject")}
              name="subject"
              fullWidth
              value={this.state.subject}
              onChange={this.onInputChangeHandler}
              variant="outlined"
              disabled={this.props.sending}
              required
            />
          </GridItem>

          <GridItem xs={12} md={8}>
            <TextField
              label={t("contact_message")}
              placeholder={t("contact_message")}
              name="content"
              fullWidth
              value={this.state.content}
              onChange={this.onInputChangeHandler}
              variant="outlined"
              disabled={this.props.sending}
              required
              multiline
              rows={5}
              rowsMax={Infinity}
            />
          </GridItem>

          <GridItem xs={this.props.sending ? 1 : 4}>
            {this.props.sending && <CircularProgress />}
            {!this.props.sending && (
              <Button
                color="primary"
                variant="contained"
                disabled={this.props.sending}
                type="submit"
                fullWidth
              >
                {t("contact_submit")}
              </Button>
            )}
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    contact: (email, name, to, subject, message, tag, attach = []) =>
      dispatch(contact(email, name, to, subject, message, tag, attach))
  };
};

const mapStateToProps = state => {
  return {
    user: state.user.user,
    tags: state.contact.tags,
    naka: state.contact.naka,
    sending: state.contact.sending,
    sent: state.contact.sent,
    error: state.contact.error
  };
};

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Contact);
