import React from "react";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import PlanButton from "./PlanButton";
import ShellsSpec from "../SpecialPlansCarousel/ShellsSpec";

import usePlansCarouselStyles from "./PlansCarousel.style";

export default function PlansCarouselItem({ data }) {
  const { t } = useTranslation();
  const {
    p,
    i,
    setDuration,
    handleOpen,
    setModalText,
    onTouchEnd,
    setCurrentActiveIdx,
    currentActiveIdx,
    isMobile,
    setTouchTimestamp,
    plansToShow
  } = data;
  const classes = usePlansCarouselStyles();

  return (
    <Grid
      key={`plan-${i}`}
      item
      xs={12 / plansToShow.length}
      className={[
        classes.plan,
        currentActiveIdx === i ? "active" : "",
        "js-plan"
      ].join(" ")}
      onTouchStart={() => {
        if (isMobile) {
          setTouchTimestamp(Date.now());
        }
      }}
      onTouchEnd={e => {
        if (isMobile) {
          onTouchEnd(e, p["Description.AuthorCode"]);
        }
      }}
      onMouseEnter={() => {
        if (!isMobile) {
          setTouchTimestamp(Date.now());
        }
      }}
      onMouseLeave={e => {
        if (!isMobile) {
          onTouchEnd(e, p.plan);
        }
      }}
      // onClick={() => {
      //   if (matches) return;
      //   onPlanSelected(p["Description.AuthorCode"]);
      // }}
    >
      <Box
        className={classes.planInner}
        onMouseEnter={() => {
          setCurrentActiveIdx(i);
        }}
        onClick={() => {
          setCurrentActiveIdx(i);
        }}
      >
        <Grid
          container
          spacing={2}
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item style={{ width: "100%" }}>
            <Typography
              variant="h5"
              component="h4"
              align="center"
              paragraph
              className={classes.title}
            >
              {p["Basic.Family_Name"]}
            </Typography>
            <Box px={2}>
              {p.disclaimer ? (
                <Typography
                  align="center"
                  className={`${classes.greenText} ${classes.italic}`}
                >
                  {t(p.disclaimer)}
                </Typography>
              ) : (
                <Box mb={5} />
              )}
              <Typography className={classes.price} align="center">
                ${p["Price.Monthly"].value_disp.split(".")[0]}
                <sup>{p["Price.Monthly"].value_disp.split(".")[1]}</sup>
              </Typography>
              <Typography align="center" className={classes.monthText}>
                {`/${t("new_hp_month")}`}
              </Typography>
              <Box
                py={3}
                className={
                  p["Description.AuthorCode"] === "lite"
                    ? classes.descriptionContainerClass
                    : ""
                }
              >
                <Typography align="center" className={classes.italic}>
                  {p["Description.Short"]}
                </Typography>
              </Box>
              <Box pb={1}>
                <ShellsSpec
                  specName={
                    Math.ceil(p["Shell.Size"] * 0.25) > 1
                      ? t("comparison_table_title_processors")
                      : t("comparison_table_title_processor")
                  }
                  specCount={Math.ceil(p["Shell.Size"] * 0.25)}
                  specModalText={t("comparison_table_modal_processor")}
                  handleOpen={handleOpen}
                  setModalText={setModalText}
                />
                <ShellsSpec
                  specName={t("comparison_table_title_storage")}
                  specCount={Math.ceil(p["Shell.Size"] * 10) + " GB"}
                  specModalText={t("comparison_table_modal_storage")}
                  handleOpen={handleOpen}
                  setModalText={setModalText}
                />
                <ShellsSpec
                  specName={t("comparison_table_title_memory")}
                  specCount={Math.floor((p["Shell.Size"] * 512) / 1000) + " GB"}
                  specModalText={t("comparison_table_modal_memory")}
                  handleOpen={handleOpen}
                  setModalText={setModalText}
                />
              </Box>
              <Typography
                align="center"
                className={`${classes.greenText} ${classes.italic}`}
              >
                {!p["Shell.Timer_Allowance"]
                  ? t(p.usageLimit)
                  : `${p["Shell.Timer_Allowance"] / 3600} ${t("limited_use")}`}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <PlanButton
              monthlyPrice={p["Monthly"]["Price.Price"].value_disp}
              yearlyPrice={(p["Price.Price"].value_disp / 12).toFixed(2)}
              onChanged={duration => setDuration(i, duration)}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
