import { whiteColor, mainGreenColor } from "assets/jss/material-kit-react";
import { kdeBG } from "assets/img/kde";

const styles = theme => ({
  mainFeaturedPost: {
    position: "relative",
    color: theme.palette.common.white,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: 0,
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${kdeBG})`
    },
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${kdeBG})`
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${kdeBG})`
    }
  },
  mainHeader: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: "900",
    color: whiteColor,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "42.5984px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "52px"
    }
  },
  subHeader: {
    fontSize: "22px",
    fontFamily: "Open Sans, Roboto",
    color: whiteColor,
    lineHeight: 1.4,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: 1.4
    }
  },
  morePadding: {
    padding: "150px 45px",
    [theme.breakpoints.down("xs")]: {
      padding: "150px 15px 60px 15px"
    }
  },
  headerTextContainer: {
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "300px"
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "550px"
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "750px"
    }
  },
  subTextContainer: {
    maxWidth: "750px",
    margin: "0 auto"
  },
  mainRibbon: {
    position: "absolute",
    top: "30px",
    left: "0",
    width: "100%",
    backgroundColor: mainGreenColor,
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center",
    padding: "12px",
    fontSize: "24px",
    transition: "background-color .15s linear",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px"
    },
    "&:hover": {
      backgroundColor: "#5ba331",
      color: "#fff"
    },
    "& > span": {
      textDecoration: "underline"
    }
  },
  kdeDevicesImage: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "45px"
    }
  }
});

export default styles;
