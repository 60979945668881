const initState = {
  loading: false,
  shellDownload: null
};

export const SOFTWARE_FETCH_SHELL = "SOFTWARE_FETCH_SHELL";
export const SOFTWARE_FETCH_SHELL_DONE = "SOFTWARE_FETCH_SHELL_DONE";

const softwareReducer = (state = initState, action) => {
  switch (action.type) {
    case SOFTWARE_FETCH_SHELL:
      return { ...state, loading: true };
    case SOFTWARE_FETCH_SHELL_DONE:
      return { ...state, loading: false, shellDownload: action.shellDownload };
    default:
      return state;
  }
};

export default softwareReducer;
