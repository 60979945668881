import React, { useLayoutEffect } from "react";
import { connect } from "react-redux";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/debian";

import Loading from "../../components/common/feedback/loading/Loading";
import { fetchShellDownloads } from "../../store/actions/SoftwareActions";
import DownloadHOC from "./DownloadHOC";

import { windows, google, apple, linux } from "assets/img/download";

const useStyles = makeStyles(styles);

const DownloadSection = ({ fetchShellDownloads, shellDownload, loading }) => {
  const classes = useStyles();

  useLayoutEffect(() => {
    fetchShellDownloads();
  }, [fetchShellDownloads]);

  const hasOS = key => {
    // Only focused on getting the latest version
    if (!shellDownload || shellDownload.Versions.length < 1) return false;
    return key in shellDownload.Versions[0].Download;
  };

  const getUrl = key => {
    return shellDownload.Versions[0].Download[key].Url;
  };

  return (
    <Box py={4}>
      <Container maxWidth="lg">
        {loading && <Loading />}
        {!loading && shellDownload && (
          <div className={classes.downloadContainer}>
            {hasOS("win64") && (
              <DownloadHOC href={getUrl("win64")}>
                <img
                  src={windows}
                  alt="Get it on Windows 10"
                  className={classes.downloadImage}
                />
              </DownloadHOC>
            )}
            {hasOS("ios") && (
              <DownloadHOC href="https://apps.apple.com/app/id1534972425">
                <img
                  src={apple}
                  alt="Download on the App Store"
                  className={classes.downloadImage}
                />
              </DownloadHOC>
            )}
            {hasOS("android") && (
              <DownloadHOC href="https://play.google.com/store/apps/details?id=com.shells.app">
                <img
                  src={google}
                  alt="Get it on Google Play"
                  className={classes.downloadImage}
                />
              </DownloadHOC>
            )}
            {hasOS("linux_amd64") && (
              <DownloadHOC href={getUrl("linux_amd64")}>
                <img
                  src={linux}
                  alt="Download for Linux"
                  className={classes.finalDownloadImage}
                />
              </DownloadHOC>
            )}
          </div>
        )}
      </Container>
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    shellDownload: state.software.shellDownload,
    loading: state.software.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchShellDownloads: () => dispatch(fetchShellDownloads())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadSection);
