import React, { useState, useEffect } from "react";

// import { useTranslation } 	from 'react-i18next';

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Recommended from "./Recommended";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

const subtleShadow =
  "0 0 2.5rem rgba(0, 0, 0, .05), 0 2rem 2.5rem rgba(0, 0, 0, .05), 0 .3rem .4rem rgba(0, 0, 0, .05)";
const usePlanStyles = makeStyles(theme => ({
  backCloud: {
    left: "8px",
    position: "absolute",
    top: 0,
    transition: "transform .33s cubic-bezier(.17,.67,.16,.99) ",
    width: "calc(100% - 16px)",
    zIndex: 0
  },
  button: {
    backgroundColor: "#fff",
    border: `2px solid ${theme.palette.text.secondary}`,
    borderRadius: "unset",
    boxShadow: "unset",
    color: theme.palette.text.secondary,
    fontSize: "1em",
    "&:hover": {
      backgroundColor: theme.palette.text.secondary,
      boxShadow: "unset",
      color: "#fff"
    }
  },
  discount: {
    backgroundColor: "#fddce0",
    borderRadius: "4px",
    color: theme.palette.error.main,
    fontWeight: 600,
    fontSize: ".8em",
    marginTop: theme.spacing(1)
  },
  discountPrice: {
    paddingTop: theme.spacing(1),

    "& > span": {
      fontWeight: 700
    }
  },
  inner: {
    backgroundColor: "#fff",
    borderRadius: "0 0 26px 26px",
    height: "100%",
    top: "-50px",
    position: "relative",
    zIndex: 2
  },
  line: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
    width: "100%"
  },
  ogPrice: {
    color: theme.palette.error.main,
    fontSize: "1.25em",
    paddingTop: theme.spacing(1),
    textDecoration: "line-through"
  },
  planLogo: {
    marginTop: "-140px",
    width: "240px",
    "@media (min-width: 1280px) and (max-width: 1600px)": {
      marginLeft: "-30px"
    }
  },
  planPlus: {
    "& $backCloud": {
      transform: "translateY(-70%)"
    },
    "& $sun": {
      left: "50%",
      transform: "translate3d(-25%, -75%, 0)"
    },
    "&$wrapper:hover $sun": {
      transform: "translate3d(-25%, -85%, 0)"
    }
  },
  recommended: {
    "& $backCloud": {
      transform: "translateY(-70%)"
    },
    "& $sun": {
      left: "50%",
      transform: "translate3d(-55%, -60%, 0)"
    },
    "&$wrapper:hover $sun": {
      transform: "translate3d(-55%, -70%, 0)"
    }
  },
  recommendedText: {
    position: "absolute",
    height: "230px",
    left: "65%",
    transform: "rotate(-60deg)",
    transformOrigin: "bottom left",
    transition: `top .2s cubic-bezier(.17,.67,.16,.99) .1s,
						   left .2s cubic-bezier(.17,.67,.16,.99) .1s,
						   transform .2s cubic-bezier(.17,.67,.16,.99) .1s`,
    top: "-195px",
    width: "230px",
    zIndex: 2
  },
  secondaryText: {
    color: theme.palette.text.secondary
  },
  smallTitle: {
    textAlign: "center",
    textTransform: "unset",
    fontFamily: '"Open Sans", sans-serif',
    fontWeight: 600
  },
  sun: {
    left: "10px",
    position: "absolute",
    top: 0,
    transition: "transform .15s cubic-bezier(.17,.67,.16,.99) .1s",
    zIndex: 1
  },
  topCloud: {
    position: "absolute",
    left: 0,
    top: 0,
    transform: "translateY(-18%)",
    transition: "transform .15s cubic-bezier(.17,.67,.16,.99)",
    width: "100%",
    zIndex: 1
  },
  withFlame: {
    position: "relative",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

    "&::before": {
      content: '"•"',
      position: "absolute",
      left: 0,
      color: theme.palette.text.secondary,
      transform: "scale(1.5)"
    }
  },
  withFlameWrapper: {},
  wrapper: {
    backgroundColor: "#fff",
    border: "4px solid transparent",
    borderRadius: "30px",
    boxShadow: `0 0 0 0 ${theme.palette.text.secondary} inset, ${subtleShadow}`,
    cursor: "pointer",
    height: "calc(100% - 70px)",
    marginTop: theme.spacing(12),
    position: "relative",

    "&:hover": {
      boxShadow: `0 0 0 4px ${theme.palette.text.secondary} inset, ${subtleShadow}`,
      borderColor: theme.palette.text.secondary,
      transition: "box-shadow .15s linear",

      "& $backCloud": {
        transform: "translateY(-60%)"
      },

      "& $button": {
        backgroundColor: theme.palette.text.secondary,
        boxShadow: "unset",
        color: "#fff"
      },
      "& $recommendedText": {
        top: "-136px",
        left: "51%",
        transform: "rotate(-20deg) scale(1.25)",
        transformOrigin: "bottom left",
        transition: `top .35s cubic-bezier(.17,.67,.16,.99),
								   left .35s cubic-bezier(.17,.67,.16,.99),
								   transform .35s cubic-bezier(.17,.67,.16,.99)`
      },

      "& $sun": {
        transform: "translateY(-40%)"
      },

      "& $topCloud": {
        transform: "scale(.97) translateY(-19%)"
      }
    }
  }
}));

const speedmeters = {
  lite: "speedmeter",
  basic: "speedmeter-1",
  plus: "speedmeter-2",
  pro: "speedmeter-3"
};

const Plan = ({ plan, onPlanSelected, currencyFormat }) => {
  const classes = usePlanStyles();
  // const {t}		= useTranslation();

  // we need the following line to nicely display the "monthly cost" calculated based off of the annual price.
  const [calcMonthlyPrice, setCalcMonthlyPrice] = useState(
    Math.round(plan.variation.Price.value_int / 12) / 100000
  );
  useEffect(() => {
    if (!currencyFormat)
      setCalcMonthlyPrice(p => `${p} ${plan.Price.currency}`);
    else if (currencyFormat.Symbol_Position === "before")
      setCalcMonthlyPrice(p => currencyFormat.Symbol + p);
    else setCalcMonthlyPrice(p => p + currencyFormat.Symbol);
  }, [currencyFormat]); // eslint-disable-line

  const calcSave = (annualPrice, monthlyPrice) => {
    return 100 - Math.round((annualPrice / 12 / monthlyPrice) * 100);
  };

  return (
    <Box
      pt={8}
      px={1}
      className={`${classes.wrapper} ${
        plan["Flags.Recommended"] === "Y" ? classes.recommended : ""
      } `}
    >
      <img
        src={require("assets/img/plans/back-cloud.png")}
        alt=""
        className={classes.backCloud}
      />
      <img
        src={require("assets/img/plans/sunrise.png")}
        alt=""
        className={classes.sun}
      />
      <img
        src={require("assets/img/plans/top-clouds.png")}
        alt=""
        className={classes.topCloud}
      />
      {plan["Flags.Recommended"] === "Y" && (
        <div className={classes.recommendedText}>
          <Recommended text={"Recommended"} />
        </div>
      )}
      <Box px={4} className={classes.inner}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <Grid item>
            <img
              src={require(`assets/img/plans/${
                plan["Description.AuthorCode"]
              }.svg`)}
              alt=""
              className={classes.planLogo}
              width="100%"
              style={{
                transform: ["plus", "pro"].includes(
                  plan["Description.AuthorCode"]
                )
                  ? "translateX(-24px)"
                  : "none"
              }}
            />
          </Grid>
          <Grid
            item
            container
            direction="column"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Box py={1}>
                <Typography
                  variant="h5"
                  component="p"
                  className={classes.secondaryText}
                >
                  {plan["Basic.Name"]}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box py={1}>
                <img
                  src={require(`assets/img/plans/${
                    speedmeters[plan["Description.AuthorCode"]]
                  }.png`)}
                  alt=""
                  width="100%"
                />
              </Box>
            </Grid>
            <hr className={classes.line} />
          </Grid>
          <Grid item>
            <Typography variant="h6" className={classes.smallTitle}>
              {plan["Description.CatchPhrase"]}
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="column"
            justify="space-between"
            alignItems="center"
            style={{ marginTop: "auto" }}
          >
            <hr className={classes.line} />
            <Grid item>
              <Typography variant="h6" className={classes.smallTitle}>
                {plan["Description.AuthorCode"] === "lite"
                  ? "Only"
                  : "Starting at"}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                visibility:
                  plan["Description.AuthorCode"] === "lite"
                    ? "hidden"
                    : "default"
              }}
            >
              <Typography variant="body1" className={classes.ogPrice}>
                &nbsp;
                {plan.Price.value_int !== plan.variation.Price.value_int / 12 &&
                  `${plan.Price.display_short} /mo`}
                &nbsp;
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                className={`${classes.smallTitle} ${classes.discountPrice}`}
              >
                <span>{calcMonthlyPrice}</span> /mo
              </Typography>
            </Grid>
            <Grid item>
              <Box
                p={1}
                className={classes.discount}
                style={{
                  visibility:
                    calcSave(
                      plan.variation.Price.value_int,
                      plan.Price.value_int
                    ) <= 0
                      ? "hidden"
                      : "default"
                }}
              >
                <Typography variant="body2">
                  &nbsp;
                  {plan["Description.AuthorCode"] === "lite"
                    ? ""
                    : `Save ${calcSave(
                        plan.variation.Price.value_int,
                        plan.Price.value_int
                      )}%`}
                </Typography>
              </Box>
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <Box pt={4} pb={2} style={{ width: "100%" }}>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth
                  className={classes.button}
                  onClick={() => onPlanSelected(plan["Description.AuthorCode"])}
                >
                  Get started
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Plan;
