import React from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/edTech";
import additionalStyles from "../../assets/jss/material-kit-react/views/whyShells";

import { img1 } from "assets/img/whyshells";
import SharedLinuxPage from "../SharedLinuxPage/SharedLinuxPage";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const DebianLogo = require("./../../assets/img/operating-systems/Debian.svg");
const FedoraLogo = require("./../../assets/img/operating-systems/Fedora.svg");
const ManjaroLogo = require("./../../assets/img/operating-systems/Manjaro.svg");
const UbuntuLogo = require("./../../assets/img/operating-systems/Ubuntu_Desktop.svg");

const useStyles = makeStyles(styles);
const useAdditionnalStyles = makeStyles(additionalStyles);

const LinuxYouWant = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const additionnalClasses = useAdditionnalStyles();

  const headerDetails = {
    header: t("lp_linux_you_want_hero_header"),
    description: t("lp_linux_you_want_hero_desc"),
    image: img1
  };

  const distrosList = [
    {
      url: "https://www.debian.org/",
      image: DebianLogo,
      imageClass: additionnalClasses.debianColor,
      imageAlt: "Debian"
    },
    {
      url: "https://getfedora.org/",
      image: FedoraLogo,
      imageClass: additionnalClasses.fedoraColor,
      imageAlt: "Fedora"
    },
    {
      url: "https://manjaro.org/",
      image: ManjaroLogo,
      imageClass: additionnalClasses.manjaroColor,
      imageAlt: "Manjaro"
    },
    {
      url: "https://ubuntu.com/",
      image: UbuntuLogo,
      imageClass: additionnalClasses.ubuntuColor,
      imageAlt: "Ubuntu"
    }
  ];

  return (
    <>
      <SharedLinuxPage
        title={t("lp_linux_you_want_title")}
        headerDetails={headerDetails}
        showLinuxDetails={true}
        buttonText={t("get_shell")}
        buttonUrl="/pricing"
        specialButtonClass={additionnalClasses.remoteToolsButton}
        paddingBottom={4}
      />
      <div className={classes.container}>
        <Typography variant="h3" className={additionnalClasses.headers}>
          {t("lp_linux_cloud_distros_list_title")}
        </Typography>
        <Box py={4}>
          <Grid container spacing={3} justify="space-around">
            {distrosList.map((distro, i) => (
              <Grid item key={i}>
                <a target="_blank" href={distro.url} rel="noopener noreferrer">
                  <img
                    src={distro.image}
                    className={distro.imageClass}
                    alt={distro.imageAlt}
                    width="100"
                    height="100"
                  />
                </a>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Typography variant="h3" className={additionnalClasses.headers}>
          {t("lp_linux_cloud_distros_list_outro")}
        </Typography>
      </div>
      <br />
    </>
  );
};

export default LinuxYouWant;
