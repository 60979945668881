import React from "react";
import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/whyShells";
import imageStyles from "../../assets/jss/material-kit-react/views/edTech";

const useStyles = makeStyles(styles);
const useImageStyles = makeStyles(imageStyles);

export default function SideToSide({ details, typographyComponent = "h3" }) {
  const { t } = useTranslation();
  const {
    imgClass,
    img,
    imgAlt,
    header,
    headerClass,
    description,
    descriptionClass,
    type
  } = details;
  const classes = useStyles();
  const imageClasses = useImageStyles();

  let layout;
  if (type === "imageRight") {
    layout = (
      <>
        <Hidden mdUp>
          <img src={img} className={imageClasses[imgClass]} alt={imgAlt} />
        </Hidden>
        <div className={classes.textContainer}>
          <Typography
            variant={typographyComponent}
            className={`${classes.headers} ${headerClass}`}
          >
            {t(header)}
          </Typography>
          <Typography
            variant="body1"
            className={`${classes.description} ${descriptionClass}`}
          >
            {t(description)}
          </Typography>
        </div>
        <Hidden smDown>
          <img src={img} className={imageClasses[imgClass]} alt={imgAlt} />
        </Hidden>
      </>
    );
  } else {
    layout = (
      <>
        <img src={img} className={imageClasses[imgClass]} alt={imgAlt} />
        <div className={classes.textContainer}>
          <Typography
            variant={typographyComponent}
            className={`${classes.headers} ${headerClass}`}
          >
            {t(header)}
          </Typography>
          <Typography
            variant="body1"
            className={`${classes.description} ${descriptionClass}`}
          >
            {t(description)}
          </Typography>
        </div>
      </>
    );
  }

  return (
    <div className={`${classes.container} ${classes.sideBySide}`}>{layout}</div>
  );
}
