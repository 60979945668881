// Hero
// export const heroMobile = require("./heroBGmobile.jpg");
export const heroTablet = require("./heroBGtablet.jpg");
export const heroDesktop = require("./on_any_device-fs8.jpg");

// Featured on
export const geekFlare = require("./Geekflare.jpg");
export const linuxJournal = require("./LinuxJournal.jpg");
export const sourceForge = require("./Sourceforge.jpg");
export const techRadarPro = require("./TechRadarPro.jpg");

// One Device
export const oneDeviceDesktop = require("./the1device_dark3-fs8.jpg");
// export const oneDeviceMobile = require("./the1device_dark3-fs8_414.jpg");
export const oneDeviceTablet = require("./the1device_dark3-fs8_1024.jpg");

// Users
export const students = require("./students.jpg");
export const coders = require("./coders.jpg");
export const workers = require("./workers.jpg");
export const business = require("./businesses.jpg");

// Servers
export const map = require("./shells_world_map.svg");
// export const mapMobile = require("./shells_world_map_414.png");
export const mapTablet = require("./shells_world_map_1024.png");

// Devices
export const device1 = require("./devices1.png");
export const device2 = require("./devices2.png");
export const device3 = require("./devices3.png");
export const device4 = require("./devices4.png");
