import React from "react";
import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

import { WrapperContainer } from "components/HOC";
import { dellImage } from "assets/img/giveaway";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/giveaway";

const useStyles = makeStyles(styles);

export default function GiveawayHero({ textOn }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper
      elevation={0}
      className={textOn === "left" ? classes.twitterHero : classes.redditHero}
    >
      <WrapperContainer addMaxWidth={false} addlWclass={classes.addPadding}>
        <Grid container alignItems="center" justify="center" spacing={2}>
          {textOn === "left" ? (
            <>
              <Hidden smUp>
                <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
                  <img
                    src={dellImage}
                    alt="Dell laptop"
                    className={classes.daasDevicesImage}
                  />
                </Grid>
              </Hidden>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h1"
                  component="h2"
                  className={classes.mainHeader}
                >
                  {t("giveaway_twitter_title_herotext")}
                </Typography>
              </Grid>
              <Hidden xsDown>
                <Grid item xs={12} sm={6}>
                  <img
                    src={dellImage}
                    alt="Dell laptop"
                    className={classes.daasDevicesImage}
                  />
                </Grid>
              </Hidden>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h1"
                  component="h2"
                  className={classes.mainHeader}
                >
                  {t("giveaway_reddit_title_herotext")}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </WrapperContainer>
    </Paper>
  );
}
