import React, { useEffect } from "react";
import { getVat } from "store/actions/OrderAction";
import { fetchCountries } from "store/actions/CountriesAction";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { geoLookup } from "../../store/actions/CountriesAction";
import TextField from "@material-ui/core/TextField";

const Countries = ({
  value,
  onChange,
  fetchCountries,
  inputProps = {},
  countries,
  getVat,
  disabled = false,
  geoLookup = false,
  lookupCountry
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    fetchCountries().then(countries => {
      if (!geoLookup) return;
      lookupCountry().then(lookupResult => {
        const match = lookupResult.country
          ? countries.filter(c => c.Country__ === lookupResult.country.iso_code)
          : [];
        if (match.length < 1) return;
        handleChange({ target: { value: match[0].Country__ } });
      });
    });
    // eslint-disable-next-line
  }, [fetchCountries, geoLookup, lookupCountry]);

  const handleChange = event => {
    getVat(event.target.value);
    onChange(event);
  };

  return (
    <TextField
      variant="outlined"
      label={t("select_country")}
      select
      SelectProps={{ native: true }}
      onChange={handleChange}
      value={value}
      disabled={disabled}
      {...inputProps}
    >
      <option />
      {countries.map(country => (
        <option key={country.Country__} value={country.Country__}>
          {country.Name}
        </option>
      ))}
    </TextField>
  );
};

const mapStateToProps = state => {
  return {
    countries: state.countries.countries
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchCountries: () => dispatch(fetchCountries()),
    lookupCountry: () => dispatch(geoLookup()),
    getVat: country => dispatch(getVat(country))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Countries);
