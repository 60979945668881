import React from "react";
import { useTranslation } from "react-i18next";
import { HashLink as Link } from "react-router-hash-link";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/cloudmusic";

const useStyles = makeStyles(styles);

export default function Header() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper className={classes.mainFeaturedPost}>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.overlay} />
          <div className={classes.mainFeaturedPostContent}>
            <Typography variant="h1" className={classes.mainHeader}>
              {t("cloudmusic_h1")}
            </Typography>
            <Typography variant="h1" className={classes.mainHeader}>
              {t("cloudmusic_h2")}
            </Typography>
            <Typography variant="h5" className={classes.subHeader}>
              {t("cloudmusic_h3")}
            </Typography>
          </div>
          <Button
            variant="contained"
            disableElevation
            className={classes.signUpButton}
            component={Link}
            to="/pricing"
          >
            {t("whyshells_button")}
          </Button>
        </div>
      </div>
    </Paper>
  );
}
