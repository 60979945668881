import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";
import { useTranslation } from "react-i18next";

import {
  Header,
  SectionOne,
  SectionTwo,
  SectionThree,
  SectionFour
} from "components/CloudMusic";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/cloudmusic";

const useStyles = makeStyles(styles);

export default function CloudMusic() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.body}>
      <Helmet title={t("cloudmusic_h2")} />
      <Header />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
    </div>
  );
}
