import React from "react";
import { Redirect as ReactRedirect } from "react-router-dom";
import { useLocation } from "react-router";

const CartRedirect = () => {
  const location = useLocation();
  return <ReactRedirect to={`/cart${location.search}${location.hash}`} />;
};

export default CartRedirect;
