import {
  bgImageDesktop,
  bgImageTablet,
  bgImageMobile
} from "assets/img/debian";
import { blackColor, mainGreenColor } from "assets/jss/material-kit-react";

const styles = theme => ({
  greenFont: {
    color: mainGreenColor
  },
  greenLine: {
    width: "100%",
    border: `3px solid ${mainGreenColor}`,
    borderRadius: "5px",
    margin: "20px 0 20px 0"
  },
  beautifulJoinButton: {
    width: "375px",
    backgroundColor: "linear-gradient(45deg, #5ba331 0%, #75cb42 100%)",
    borderRadius: "35px",
    color: "#fff",
    fontSize: "22px",
    textAlign: "center",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover, &:focus": {
      boxShadow: "0 0 0 2px #5ba331 inset",
      background: "#fff",
      color: "#5ba331"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "250px",
      padding: "6px 14px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      width: "375px"
    }
  },
  heroHeader: {
    fontFamily: "Open Sans",
    fontWeight: "900",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "42.5984px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "52px"
    }
  },
  heroImage: {
    [theme.breakpoints.down("sm")]: {
      width: "235px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "300px"
    },
    [theme.breakpoints.up("md")]: {
      width: "400px"
    },
    [theme.breakpoints.up("lg")]: {
      width: "650px"
    },
    "@media (min-width: 1280px) and (max-width: 1365px)": {
      width: "575px"
    }
  },
  inputAndBtn: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  centeredText: {
    textAlign: "center"
  },
  mainFeaturedPost: {
    position: "relative",
    color: theme.palette.common.white,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    padding: "60px 15px",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${bgImageMobile})`
    },
    [theme.breakpoints.down("lg")]: {
      backgroundImage: `url(${bgImageTablet})`
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${bgImageDesktop})`
    }
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.1)",
    backdropFilter: "blur(4px)"
  },
  mainFeaturedPostContent: {
    textAlign: "center",
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6)
      // paddingRight: 0
    }
  },
  headerContainer: {
    maxWidth: "600px",
    margin: "0 auto"
  },
  subHeaderContainer: {
    maxWidth: "550px",
    lineHeight: 5
    // margin: "0 auto"
  },
  subHeader: {
    fontSize: "22px",
    fontFamily: "Open Sans",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  moneyBackText: {
    fontSize: "22px",
    fontFamily: "Open Sans",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  // Downloads Section
  downloadContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center"
    }
  },
  downloadImage: {
    width: "181px",
    height: "55px",
    margin: "0 15px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "15px"
    }
  },
  finalDownloadImage: {
    width: "181px",
    height: "55px",
    margin: "0 15px"
  },
  // Downloads End
  mainHeader: {
    fontFamily: "Open Sans",
    fontSize: "32px",
    fontWeight: "900",
    textAlign: "center",
    color: blackColor
  },
  sectionOneHeader: {
    fontFamily: "Open Sans",
    color: blackColor,
    fontWeight: "900",
    fontSize: "24px",
    marginBottom: "15px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  sectionOneBody: {
    fontSize: "16px",
    color: blackColor,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  sectionOneImage: {
    width: "200px",
    margin: "0 auto",
    [theme.breakpoints.up("sm")]: {
      width: "135px"
    },
    [theme.breakpoints.up("md")]: {
      width: "200px"
    }
  },
  gridContainer: {
    [theme.breakpoints.up("sm")]: {
      padding: 0,
      minHeight: 0
    },
    [theme.breakpoints.up("md")]: {
      padding: 0,
      minHeight: "250px"
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: 0
    }
  },
  arrowImage: {
    width: "100px",
    [theme.breakpoints.down("xs")]: {
      width: "50px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "50px"
    },
    [theme.breakpoints.up("md")]: {
      width: "100px"
    }
  },
  bodyContainer: {
    [theme.breakpoints.down("sm")]: {
      minHeight: 0
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "145px"
    }
  },
  s2BodyContainer: {
    [theme.breakpoints.down("sm")]: {
      minHeight: 0
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "120px"
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "95px"
    }
  },
  addlMarginTop: {
    marginTop: "52px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0
    }
  },
  sectionOneHeaderParent: {
    position: "relative",
    minHeight: "55px"
  },
  sectionTwoHeaderParent: {
    minHeight: "55px",
    position: "relative",
    width: "100%",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  },
  sectionOneHeaderPosRight: {
    position: "absolute",
    bottom: "-15px",
    right: 0,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      left: "50%",
      ":-webkit-transform": "translateX(-50%)",
      transform: "translateX(-50%)"
    }
  },
  sectionOneHeaderPosLeft: {
    position: "absolute",
    bottom: "-15px",
    left: 0,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      left: "50%",
      ":-webkit-transform": "translateX(-50%)",
      transform: "translateX(-50%)"
    }
  },
  sectionThreeJoinButton: {
    width: "300px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "6px 24px",
    border: `3px solid ${mainGreenColor}`,
    backgroundColor: "#ffffff",
    borderRadius: "35px",
    color: mainGreenColor,
    fontSize: "22px",
    textAlign: "center",
    // fontWeight: "bold",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover, &:focus": {
      // boxShadow: "0 0 0 2px #5ba331 inset",
      background: mainGreenColor,
      color: "#ffffff"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      width: "250px"
    }
  },
  alignLeft: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  marginBottom: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "45px"
    }
  },
  s1bodyMarginBottom1: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: "28px"
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 0
    }
  },
  s3Container: {
    maxWidth: "570px",
    margin: "0 auto"
  }
});

export default styles;
