import React from "react";
import Container from "@material-ui/core/Container";

import {
  createMuiTheme,
  makeStyles,
  ThemeProvider
} from "@material-ui/core/styles";

const sectionTheme = createMuiTheme({
  palette: {
    section: {
      even: "#fff",
      odd: "#e6e6e6"
    },
    text: {
      primary: "#232623",
      secondary: "#82c847"
    }
  }
});

const useSectionStyles = makeStyles(theme => ({
  section: {
    position: "relative",
    color: sectionTheme.palette.text.primary,
    padding: `${theme.spacing(4)}px 0`,
    backgroundColor: sectionTheme.palette.section.even
  },
  anchor: {
    position: "absolute",
    top: "-50px"
  }
}));

const Section = ({ children, maxWidth = "lg", anchor = null, ...etc }) => {
  const classes = useSectionStyles();
  return (
    <ThemeProvider theme={sectionTheme}>
      <section className={classes.section} {...etc}>
        {anchor && <span id={`${anchor}`} className={classes.anchor} />}
        <Container maxWidth={maxWidth}>{children}</Container>
      </section>
    </ThemeProvider>
  );
};

export default Section;
