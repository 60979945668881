import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";

import Box from "@material-ui/core/Box";

import { BrowserInTheCloudPricingHero } from "components/BrowserInTheCloud";
import DealPricingText from "views/PricingPlans/DealPricingText";
import PlansCarousel from "components/PlansCarousel/PlansCarousel";

export default function BrowserInTheCloud() {
  return (
    <>
      <Helmet title="Shells Virtual Desktop - Browser in the Cloud" />
      <BrowserInTheCloudPricingHero />
      <Box pb={16} style={{ overflowX: "hidden" }}>
        <DealPricingText os="cloud" />
        <PlansCarousel />
      </Box>
    </>
  );
}
