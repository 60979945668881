import React, { useState } from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { makeStyles } from "@material-ui/core/styles";

import CarouselItem from "./CarouselItem";
import { isMobileTablet } from "components/utils/mobileTablet";

import styles from "../../assets/jss/material-kit-react/components/carouselStyle";

const useStyles = makeStyles(styles);

export default function Carousel({ data }) {
  const [isMobile] = useState(isMobileTablet());
  const [current, setCurrent] = useState(0);
  const length = data.length;
  const classes = useStyles();
  let dataToRender;

  if (length <= 0) {
    return null;
  } else {
    dataToRender = data.map((item, index) => {
      return (
        <div
          key={index}
          className={
            index === current
              ? `${classes.slide} ${classes.active}`
              : `${classes.slide}`
          }
        >
          {index === current && <CarouselItem data={item} />}
        </div>
      );
    });
  }

  const nextItem = () => {
    const currentValue = current === length - 1 ? 0 : current + 1;
    setCurrent(currentValue);
  };

  const previousItem = () => {
    const currentValue = current === 0 ? length - 1 : current - 1;
    setCurrent(currentValue);
  };

  return (
    <div className={classes.carousel}>
      <ArrowBackIosIcon
        className={classes.leftArrow}
        onClick={() => {
          if (!isMobile) previousItem();
        }}
        onTouchEnd={() => {
          if (isMobile) previousItem();
        }}
      />
      <ArrowForwardIosIcon
        className={classes.rightArrow}
        onClick={() => {
          if (!isMobile) nextItem();
        }}
        onTouchEnd={() => {
          if (isMobile) nextItem();
        }}
      />
      {dataToRender}
    </div>
  );
}
