export const heroImage = require("./hero-bg-blur.png");
export const shellsIconBg = require("./shells_icon_bg.svg");
export const androidTV = require("./androidtv.svg");
export const iOS = require("./apple-ios.svg");
export const firefox = require("./firefox-6.svg");
export const chrome = require("./chrome-7.svg");
export const safari = require("./safari-3.svg");
export const playstation = require("./playstation-6.svg");
export const xbox = require("./xbox-1.svg");
export const oculus = require("./oculus-logo-2.svg");
export const tesla = require("./tesla-motors-1.svg");
export const mmaTeam = require("./mma-team.png");
export const andImage = require("./&.svg");
export const devices = require("./devices.png");
export const crossPlatform = require("./cross-plat.png");
export const shellsLaptop = require("./Shells_Laptop.png");
