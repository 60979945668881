import React from "react";
import { useTranslation, Trans } from "react-i18next";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import { HashLink as Link } from "react-router-hash-link";
import { makeStyles } from "@material-ui/core/styles";

import { WrapperContainer } from "components/HOC";
import { manjaroDevices } from "assets/img/manjaro";
import { Countdown } from "components/Countdown";

import styles from "assets/jss/material-kit-react/views/manjaro";
const useStyles = makeStyles(styles);

export default function MainHeader({
  bannerText,
  heroText,
  subText,
  withButton = true,
  pricingText = false,
  addCountDown = false
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  let url = "/pricing?special=manjaro";
  if (pricingText) {
    url = "#pricing";
  }

  return (
    <Paper className={classes.mainFeaturedPost} square elevation={0}>
      <a href={url} className={classes.mainRibbon}>
        <Trans
          i18nKey={bannerText}
          components={{
            underline: <span />
          }}
        />
      </a>
      {addCountDown && (
        <Countdown
          addCountDown={addCountDown}
          redirectUrl="/pricing?special=manjaro"
          endMonthAndDay="07/27"
        />
      )}
      <WrapperContainer
        addlCclass={`${classes.textAlignLeft} ${classes.removePadding}`}
        addlWclass={
          !addCountDown
            ? classes.pricingAddlPadding
            : classes.countdownAddlPadding
        }
        addMaxWidth={false}
      >
        <div className={classes.overlay} />
        <Grid
          container
          justify="center"
          alignItems="center"
          className={addCountDown && classes.gridContainer}
        >
          <Hidden smUp>
            <Grid item xs={12} sm={6}>
              <img
                src={manjaroDevices}
                alt="Manjaro Devices"
                className={classes.manjaroDevicesImage}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6} className={classes.textGridItem}>
            <div
              className={
                pricingText
                  ? classes.pricingMainFeaturedPostContent
                  : classes.mainFeaturedPostContent
              }
            >
              <Typography
                variant="h1"
                component="h2"
                className={
                  pricingText ? classes.pricingMainHeader : classes.mainHeader
                }
              >
                {t(heroText)}
              </Typography>
              {!pricingText && (
                <Box py={1}>
                  <Typography
                    variant="h1"
                    component="h2"
                    className={classes.heroSubHeader}
                  >
                    {t("manjaro_h2_a")}
                  </Typography>
                </Box>
              )}
              <Box py={1}>
                <Typography
                  variant="h1"
                  component="h2"
                  className={classes.heroSubHeader_nonBold}
                >
                  {t(subText)}
                </Typography>
              </Box>
            </div>
            {withButton && (
              <Button
                variant="contained"
                disableElevation
                className={classes.signUpButton}
                component={Link}
                to="/pricing?special=manjaro"
              >
                {t("manjaro_button")}
              </Button>
            )}
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={6}>
              <img
                src={manjaroDevices}
                alt="Manjaro Devices"
                style={{ width: "100%" }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </WrapperContainer>
    </Paper>
  );
}
