import { rest } from "@karpeleslab/klbfw";

import {
  SYNC_CART,
  SYNC_CART_DONE,
  SYNC_CART_ERROR,
  CART_ADD_DONE
} from "../reducers/CartReducer";
import {
  catchRedirectException,
  handleError
} from "../../components/utils/apiErrorHandler";

export const cartFetch = () => {
  return dispatch => {
    dispatch({ type: SYNC_CART });

    return rest("Catalog/Cart/@", "GET")
      .then(catchRedirectException)
      .then(data => {
        dispatch({
          type: SYNC_CART_DONE,
          item: data.data.products,
          cart: data.data
        });
      })
      .catch(data => {
        dispatch({
          type: SYNC_CART_ERROR,
          error: data.message
        });
        throw data;
      });
  };
};

export const removeItem = key => {
  const query = {
    key: key,
    quantity: 0
  };

  return dispatch => {
    dispatch({ type: SYNC_CART });
    rest("Catalog/Cart/@:setQuantity", "POST", query)
      .then(catchRedirectException)
      .then(data => {
        dispatch({
          type: SYNC_CART_DONE,
          item: data.data.products,
          cart: data.data
        });
      })
      .catch(data => {
        dispatch({
          type: SYNC_CART_ERROR,
          error: data.message
        });
        throw data;
      });
  };
};

export const addItem = productId => {
  const query = {
    request: productId
  };

  return (dispatch, getState) => {
    dispatch({ type: SYNC_CART });
    return rest("Catalog/Cart/@:process", "POST", query)
      .then(catchRedirectException)
      .then(data => {
        dispatch({
          type: CART_ADD_DONE,
          item: data.data.products,
          cart: data.data
        });

        return data;
      })
      .catch(err => {
        dispatch({
          type: SYNC_CART_ERROR,
          error: err.message
        });
        handleError(dispatch, getState, err);
        throw err;
      });
  };
};
