import React from "react";
import { useTranslation } from "react-i18next";
import Helmet from "components/instances/instance/Helmet/Helmet";
import { useHistory } from "react-router-dom";

// style
import style from "assets/scss/modules/notfound.module.scss";
import common from "../../assets/scss/modules/common.module.scss";

// img
import logoBlack from "assets/img/shellslogo-black.png";

const ErrorPage = ({ errorID }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <Helmet title={t("helmet_shells_home")} />
      <div className={style["notfound-wrapper"]}>
        <div className={style["notfound-inner"]}>
          <img src={logoBlack} alt="shells" />
          <h1 className={style["notfound-title"]}>
            <span>{t("error_page_title")}</span>
          </h1>
          <p className={style["notfound-text"]}>{t("error_page_desc")}</p>
          {errorID && (
            <>
              <p className={style["notfound-text"]}>
                {t("error_fatal_error_id")}
              </p>
              <p className={style["notfound-text"]}>{errorID}</p>
            </>
          )}

          <div className={common["btn-warapper"]}>
            <button onClick={goBack}>{t("back_previous_page_btn")}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
