import React from "react";
import { useTranslation, Trans } from "react-i18next";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/material-kit-react/views/edTech";

import {
  img2,
  cloudImage,
  budgetImage,
  safeImage
} from "../../assets/img/edtech";

const useStyles = makeStyles(styles);

export default function SectionOne() {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:540px)");

  const sectionOneData = [
    {
      image: cloudImage,
      header: t("edtech_h3"),
      body: (
        <Trans
          i18nKey="edtech_p3"
          components={{
            green: <span className={classes.greenText} />,
            bold: <strong />
          }}
        />
      )
    },
    {
      image: safeImage,
      header: t("edtech_h4"),
      body: (
        <Trans
          i18nKey="edtech_p4"
          components={{
            green: <span className={classes.greenText} />,
            bold: <strong />
          }}
        />
      )
    },
    {
      image: budgetImage,
      header: t("edtech_h5"),
      body: (
        <Trans
          i18nKey="edtech_p5"
          components={{
            green: <span className={classes.greenText} />,
            bold: <strong />
          }}
        />
      )
    }
  ];

  let textAlign;
  isMobile ? (textAlign = "center") : (textAlign = "left");

  return (
    <>
      <Container maxWidth="sm">
        <Typography
          variant="h4"
          component="h3"
          className={classes.sectionOneHeader}
          align="center"
        >
          {t("edtech_h2")}
        </Typography>
        <div className={classes.greenLine_s1_a} />
        <img src={img2} alt="" className={classes.s1Image} />
        <Box py={2}>
          <Typography
            variant="body1"
            className={classes.sectionOneBody}
            align="center"
          >
            <Trans
              i18nKey="edtech_p2"
              components={{
                green: <span className={classes.greenText} />,
                bold: <strong />
              }}
            />
          </Typography>
        </Box>
      </Container>

      <Box pt={4}>
        <Container maxWidth="lg">
          <Grid container spacing={4} justify="center" alignItems="center">
            {sectionOneData.map((data, i) => {
              const { header, body, image } = data;

              return (
                <Grid item xs={12} sm={4} md={4} key={i}>
                  <Box py={2}>
                    <img src={image} alt="" className={classes.s1Image} />
                  </Box>
                  <div className={classes.sectionOneHeaderContainer}>
                    <Typography
                      variant="h4"
                      component="h3"
                      className={`${classes.sectionOneHeader} ${classes.sectionOneHeaderPos}`}
                      align={textAlign}
                      key={i}
                    >
                      {header}
                    </Typography>
                  </div>
                  <div className={classes.greenLine_s1_b} />
                  <div className={classes.s1BodyContainer}>
                    <Typography
                      variant="body1"
                      className={classes.sectionOneBody}
                      align={textAlign}
                    >
                      {body}
                    </Typography>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Box>
    </>
  );
}
