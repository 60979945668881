import { initFlow } from "../../store/actions/FlowActions";
import { USER_LOGOUT } from "../../store/reducers/UserReducer";
import { error as errorToast, info } from "../../store/actions/ToastAction";

export const catchRedirectException = result => {
  if (!result || !result.result || result.result !== "redirect") return result;
  throw result;
};

export const handleError = (getState, dispatch, error) => {
  if (process.env.NODE_ENV !== "production") {
    console.log(error);
  }

  let reThrow = true;
  let msg = "";

  switch (error.token) {
    case "error_login_required":
      info("error_login_required");
      dispatch(initFlow());
      dispatch({ type: USER_LOGOUT });
      break;
    case "invalid_request_token":
      reThrow = false;
      if (typeof window !== "undefined") window.location.reload();
      break;
    case "login_error_no_login":
      errorToast(error.token);
      break;
    default:
      if (error.exception === "Exception\\Fields") {
        const fieldKeys = Object.keys(error.fields_invalid);

        // We handle only 1 message for now, so take the first one
        msg = error.fields_invalid[fieldKeys[0]].message;
      } else {
        msg = error.error;
      }

      errorToast(msg);
      break;
  }

  if (reThrow) throw error;
};
