import { rest } from "@karpeleslab/klbfw";

import {
  GRANT,
  GRANT_DONE,
  GRANT_ERROR,
  GRANT_SCAN,
  GRANT_SCAN_DONE,
  GRANT_SCAN_ERROR
} from "../reducers/OAuthReducer";
import { catchRedirectException } from "../../components/utils/apiErrorHandler";

export const oauthGrantScan = params => {
  return dispatch => {
    dispatch({ type: GRANT_SCAN });

    return rest("OAuth2:grantScan", "GET", params)
      .then(catchRedirectException)
      .then(grantScan => {
        dispatch({
          type: GRANT_SCAN_DONE,
          grantScan
        });

        return grantScan;
      })
      .catch(grantScanError => {
        dispatch({
          type: GRANT_SCAN_ERROR,
          grantScanError
        });

        return grantScanError;
      });
  };
};

export const oauthGrant = params => {
  return dispatch => {
    dispatch({ type: GRANT });

    return rest("OAuth2:grant", "POST", params)
      .then(grantResult => {
        dispatch({
          type: GRANT_DONE,
          grantResult
        });

        return grantResult;
      })
      .catch(grantError => {
        dispatch({
          type: GRANT_ERROR,
          grantError
        });

        return grantError;
      });
  };
};
