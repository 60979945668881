import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import { img1 } from "assets/img/whyshells";
import SharedLinuxPage from "../SharedLinuxPage/SharedLinuxPage";
import styles from "../../assets/jss/material-kit-react/views/whyShells";

const useStyles = makeStyles(styles);

const LinuxCloudDesktop = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const headerDetails = {
    header: t("lp_linux_cloud_pagetitle"),
    description: t("lp_linux_cloud_catchphrase"),
    image: img1
  };

  return (
    <SharedLinuxPage
      title={t("lp_linux_cloud_pagetitle")}
      headerDetails={headerDetails}
      showLinuxDetails={true}
      showDistrosList={true}
      showNormalPlans={true}
      buttonText={t("lp_linuxquestionsorg_checkitout")}
      buttonUrl="#pricing"
      specialButtonClass={classes.remoteToolsButton}
    />
  );
};

export default LinuxCloudDesktop;
