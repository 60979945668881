import { container, title } from "assets/jss/material-kit-react.js";

const landingPageStyle = {
  container: {
    zIndex: "12",
    color: "#fbfcfe",
    ...container
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#fbfcfe",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    padding: "0px",
    margin: "0px 0px 0px 0px",
    borderRadius: "0px"
  },
  genericPageWrapper: {
    position: "relative",
    paddingTop: "20px",
    height: "auto",
    minHeight: "calc(100vh - 188px)",
    fontFamily: "'PT Sans'",
    fontWeight: 400,
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "160px"
    },
    "&::after": {
      content: "''",
      position: "absolute",
      top: "70px",
      left: "50%",
      width: "100%",
      height: "167px",
      transform: "translateX(-50%)"
    },

    "& h1, & h2, & h3, & h4, & h5, & h6": {
      fontFamily: "'PT Sans'",
      fontWeight: "bold"
    }
  },
  japanesePage: {
    "& p": {
      textAlign: "left"
    }
  }
};

export default landingPageStyle;
