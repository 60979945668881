import React from "react";
import { Get } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";

const Html = () => {
  const { t } = useTranslation();
  return (
    <div id="mail-html">
      <div
        style={{
          color: "#666",
          marginTop: "-6px",
          width: "600px",
          margin: "auto"
        }}
      >
        <div style={{ width: "600px", textAlign: "center", margin: "auto" }}>
          <div style={{ textAlign: "center" }}>
            <img
              src="https://shells.com/img/email_top_shells.png"
              width="134px"
              alt={t("site_name")}
            />
          </div>

          <div style={{ textAlign: "center", width: "600px", margin: "auto" }}>
            <img
              src="https://shells.com/img/shells-email-banner-new.png"
              width="100%"
              alt={t("site_name")}
            />
          </div>
        </div>

        <div
          style={{
            textAlign: "center",
            lineHeight: "1.4rem",
            color: "#989d9f"
          }}
        >
          <div style={{ margin: "auto", textAlign: "left" }}>
            <p
              style={{
                color: "#3C4858",
                margin: "1.75rem 0 0.875rem",
                fontSize: "32px",
                marginTop: "30px",
                minHeight: "32px",
                textAlign: "center",
                fontFamily: '"Roboto Slab", "Times New Roman", serif',
                fontWeight: 700,
                marginBottom: "1rem"
              }}
            >
              <span
                style={{
                  borderBottom: "solid 2px #B0E170",
                  display: "inline-block",
                  height: "38px"
                }}
              >
                {" "}
                {t("registered_waiting_list_mail_greeting", {
                  name: Get("_waitlist").Name
                })}
              </span>
            </p>

            <div
              style={{ textAlign: "center", width: "600px", margin: "auto" }}
            >
              <img
                src="https://shells.com/img/envelop.png"
                width="240px"
                alt={t("site_name")}
              />
            </div>

            <p>
              {t("registered_waiting_list_ready_mail_greeting", {
                name: Get("_waitlist").Name
              })}
            </p>
            <p>{t("registered_waiting_list_ready_mail_content")}</p>
            <a href="https://www.shells.com/login">
              https://www.shells.com/login
            </a>
            <p>{t("mail_best_regards")}</p>

            <p>{t("mail_best_regards")}</p>
            <p style={{ fontWeight: "bold" }}>
              {t("registered_waiting_list_mail_footer")}
            </p>

            <div
              style={{ textAlign: "center", borderTop: "solid 1px #B2B2B2" }}
            >
              <img
                src="https://shells.com/img/email_footer_shells.png"
                width="90px"
                alt={t("site_name")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Html;
