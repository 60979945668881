import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles(() => ({
  videoWrapper: {
    position: "relative",
    backgroundColor: "#000"
  },
  videoProportions: {
    position: "relative",
    width: "100%",
    paddingTop: "56.25%",
    margin: "0 auto"
  },
  videoFrame: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "100%",
    height: "100%",
    transform: "translate3d(-50%, -50%, 0)"
  },
  dialogContentRoot: {
    padding: "4px !important"
  }
}));
const videoUrl = "https://www.youtube.com/embed/rWc3s0ydq6E";

const HomeVideoDialog = ({ open, onClose }) => {
  const videoWrapperEl = useRef(null);
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      onClose={onClose}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogContent className={classes.dialogContentRoot}>
        <Box>
          <div ref={videoWrapperEl} className={classes.videoWrapper}>
            <div className={classes.videoProportions} />
            <iframe
              width="1920"
              height="1080"
              src={`${videoUrl}?autoplay=1`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              autoPlay
              className={classes.videoFrame}
            ></iframe>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default HomeVideoDialog;
