import {
  USER_BILLING_CREATE_ERROR,
  USER_BILLING_CREATED,
  USER_BILLING_CREATING,
  USER_BILLING_FETCH_DONE,
  USER_BILLING_FETCH_METHOD,
  USER_BILLING_FETCH_METHOD_DONE,
  USER_BILLING_FETCHING,
  USER_BILLING_METHOD_UPDATED,
  USER_BILLING_UPDATE_DONE,
  USER_BILLING_UPDATE_ERROR,
  USER_BILLING_UPDATE_METHOD_ERROR,
  USER_BILLING_UPDATING,
  USER_BILLING_UPDATING_METHOD
} from "../reducers/UserBillingReducer";
import { rest } from "@karpeleslab/klbfw";
import { success } from "./ToastAction";
import {
  catchRedirectException,
  handleError
} from "../../components/utils/apiErrorHandler";

export const fetch = () => {
  return dispatch => {
    dispatch({ type: USER_BILLING_FETCHING });

    return rest("User/@/Billing")
      .then(catchRedirectException)
      .then(d => {
        dispatch({ type: USER_BILLING_FETCH_DONE, billings: d.data });
        dispatch(fetchMethod());
      });
  };
};

export const fetchMethod = () => {
  return (dispatch, getState) => {
    const selected = getState().userBilling.selected;
    if (!selected) {
      dispatch({ type: USER_BILLING_FETCH_METHOD_DONE, method: null });
      return;
    }
    const billingId = selected.User_Billing__;

    dispatch({ type: USER_BILLING_FETCH_METHOD });
    return rest("User/Billing/" + billingId + "/Method")
      .then(catchRedirectException)
      .then(d => {
        dispatch({ type: USER_BILLING_FETCH_METHOD_DONE, method: d.data[0] });
      });
  };
};

export const create = (
  locationId,
  stripeToken,
  label = "FanClub Subscription",
  intent = false
) => {
  return (dispatch, getState) => {
    dispatch({ type: USER_BILLING_CREATING });
    let params = {
      Label: label,
      User_Location__: locationId,
      method: "Stripe"
    };

    if (intent) {
      params["stripe_intent"] = stripeToken;
    } else {
      params["cc_token"] = stripeToken;
    }

    return rest("User/@/Billing:create", "POST", params)
      .then(catchRedirectException)
      .then(billing => {
        // Dirty but safe, trigger the refresh
        dispatch({ type: USER_BILLING_CREATED, billing: billing.data });
        dispatch(fetchMethod());
        return billing.data;
      })
      .catch(err => {
        dispatch({ type: USER_BILLING_CREATE_ERROR, error: err.message });
        handleError(getState, dispatch, err);
      });
  };
};

export const updatePaymentMethod = stripeTokenId => {
  return (dispatch, getState) => {
    dispatch({ type: USER_BILLING_UPDATING_METHOD });
    const mId = getState().userBilling.selectedMethod.User_Billing_Method__;

    return rest("User/Billing/Method/" + mId + ":change", "POST", {
      cc_token: stripeTokenId,
      method: "Stripe"
    })
      .then(catchRedirectException)
      .then(data => {
        dispatch({ type: USER_BILLING_METHOD_UPDATED, method: data.data });
      })
      .catch(err => {
        dispatch({
          type: USER_BILLING_UPDATE_METHOD_ERROR,
          error: err.message
        });
        throw err;
      });
  };
};

export const updateBilling = data => {
  return (dispatch, getState) => {
    dispatch({ type: USER_BILLING_UPDATING });

    return rest(
      "User/Billing/" + getState().userBilling.selected.User_Billing__,
      "PATCH",
      data
    )
      .then(catchRedirectException)
      .then(data => {
        dispatch({ type: USER_BILLING_UPDATE_DONE, billing: data.data });
        success("user_billing_update_success");
        return data.data;
      })
      .catch(err => {
        dispatch({ type: USER_BILLING_UPDATE_ERROR, error: err.message });
        throw err;
      });
  };
};
