import React from "react";

import { MainHeader } from "components/Manjaro";
import { KDEPricingHero } from "components/KDE";
import { LinuxMintPricingMainHeader } from "components/LinuxMint";
import { LTTPricingHero } from "components/LTT";
import { OpenSusePricingHero } from "components/OpenSuse";
import { WindowsPricingHero } from "components/Windows";
import { MXLinuxPricingHero } from "components/MxLinux";
import { SlickDealsPricingHero } from "components/SlickDeals";
import PricingPlansRedirect from "./PricingPlansRedirect";
import SpecialPlansCarousel from "components/SpecialPlansCarousel/SpecialPlansCarousel";
import WindowsCarousel from "components/Windows/WindowsCarousel/WindowsCarousel";
import DealPricingText from "./DealPricingText";
import Discount from "./Discount";
import Disclaimer from "./Disclaimer";

export const pricingPlanSections = (
  specialOffer,
  setSpecialOffer,
  pricingPageSections
) => {
  if (specialOffer) {
    pricingPageSections["carousel"] = (
      <SpecialPlansCarousel
        plansType="manjaro_plan"
        showOnly={["basic", "plus", "pro"]}
        options={{
          basic: {
            usageLimit: "unlimited_use",
            disclaimer: { key: "save_n_percent", number: "50" },
            discount: <Discount transKey="manjaro_basic_discount" />
          },
          plus: {
            usageLimit: "unlimited_use",
            disclaimer: { key: "save_n_percent", number: "49" },
            discount: <Discount transKey="manjaro_plus_discount" />
          },
          pro: {
            usageLimit: "unlimited_use",
            disclaimer: { key: "save_n_percent", number: "46" },
            discount: <Discount transKey="manjaro_pro_discount" />
          }
        }}
      />
    );
    pricingPageSections["regularPlansRedirect"] = (
      <PricingPlansRedirect setSpecialOffer={setSpecialOffer} />
    );
    pricingPageSections["dealPricingText"] = <DealPricingText />;
    pricingPageSections["disclaimer"] = null;

    switch (specialOffer) {
      case "linustechtips":
        pricingPageSections["title"] =
          "Shells Virtual Desktop - Linus Tech Tips";
        pricingPageSections["heroSection"] = <LTTPricingHero />;
        pricingPageSections["dealPricingText"] = <DealPricingText os="ltt" />;
        break;
      case "shortcircuit":
        pricingPageSections["title"] = "Shells Virtual Desktop - Short Circuit";
        pricingPageSections["heroSection"] = (
          <LTTPricingHero page="shortCircuit" />
        );
        pricingPageSections["dealPricingText"] = <DealPricingText os="ltt" />;
        break;
      case "WAN":
        pricingPageSections["title"] = "Shells Virtual Desktop - WAN";
        pricingPageSections["heroSection"] = <LTTPricingHero page="WAN" />;
        pricingPageSections["dealPricingText"] = <DealPricingText os="ltt" />;
        break;
      case "destination_linux_network":
        pricingPageSections["title"] =
          "Shells Virtual Desktop - Destination Linux Network";
        pricingPageSections["heroSection"] = <LTTPricingHero page="dln" />;
        pricingPageSections["dealPricingText"] = <DealPricingText os="dln" />;
        break;
      case "linux_for_everyone":
        pricingPageSections["title"] =
          "Shells Virtual Desktop - Linux for Everyone";
        pricingPageSections["heroSection"] = (
          <LTTPricingHero page="linux_for_everyone" />
        );
        pricingPageSections["dealPricingText"] = (
          <DealPricingText os="linux_for_everyone" />
        );
        break;
      case "slogging":
        pricingPageSections["title"] =
          "Shells Virtual Desktop - Slogging Community";
        pricingPageSections["heroSection"] = <LTTPricingHero page="slogging" />;
        pricingPageSections["dealPricingText"] = (
          <DealPricingText os="slogging" />
        );
        break;
      case "manjaro":
        pricingPageSections["title"] = "Shells Virtual Desktop - Manjaro";
        pricingPageSections["heroSection"] = (
          <MainHeader
            withButton={false}
            pricingText={true}
            bannerText="lp_linuxquestionsorg_ribbon"
            heroText="manjaro_pricing_title"
            subText="manjaro_pricing_catchphrase"
          />
        );
        pricingPageSections["carousel"] = (
          <SpecialPlansCarousel
            plansType="manjaro_plan"
            os="manjaro-desktop"
            showOnly={["basic", "plus", "pro"]}
            options={{
              basic: {
                dividePrice: 3,
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_percent", number: "50" },
                discount: <Discount transKey="manjaro_basic_discount" />
              },
              plus: {
                dividePrice: 3,
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_percent", number: "49" },
                discount: <Discount transKey="manjaro_plus_discount" />
              },
              pro: {
                dividePrice: 3,
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_percent", number: "46" },
                discount: <Discount transKey="manjaro_pro_discount" />
              }
            }}
          />
        );
        pricingPageSections["dealPricingText"] = (
          <DealPricingText os="manjaro" />
        );
        break;
      case "manjaro_deal":
        pricingPageSections["title"] = "Shells and Manjaro";
        pricingPageSections["heroSection"] = (
          <MainHeader
            withButton={false}
            pricingText={true}
            bannerText="b3g3f_discount"
            heroText="manjaro_deal_hero"
            subText="manjaro_deal_subtext"
            addCountDown={true}
          />
        );
        pricingPageSections["carousel"] = (
          <SpecialPlansCarousel
            plansType="shells_plan_b3m_g3mf"
            os="manjaro-desktop"
            showOnly={["basic", "plus", "pro"]}
            options={{
              basic: {
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_dollars", number: "41.85" },
                discount: <Discount transKey="b3g3f_text" />,
                monthlyPrice: 9.95
              },
              plus: {
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_dollars", number: "53.85" },
                discount: <Discount transKey="b3g3f_text" />,
                monthlyPrice: 19.95
              },
              pro: {
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_dollars", number: "125.85" },
                discount: <Discount transKey="b3g3f_text" />,
                monthlyPrice: 29.95
              }
            }}
          />
        );
        pricingPageSections["dealPricingText"] = (
          <DealPricingText os="manjaro" />
        );
        pricingPageSections["metaContent"] = "manjaro_deal_description";
        break;
      case "kde":
        pricingPageSections["title"] = "Shells Virtual Desktop - KDE";
        pricingPageSections["heroSection"] = (
          <KDEPricingHero bannerText="lp_linuxquestionsorg_ribbon" />
        );
        pricingPageSections["carousel"] = (
          <SpecialPlansCarousel
            plansType="manjaro_plan"
            os="ubuntu-focal-kde-neon-desktop"
            showOnly={["basic", "plus", "pro"]}
            options={{
              basic: {
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_percent", number: "50" },
                discount: <Discount transKey="manjaro_basic_discount" />
              },
              plus: {
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_percent", number: "49" },
                discount: <Discount transKey="manjaro_plus_discount" />
              },
              pro: {
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_percent", number: "46" },
                discount: <Discount transKey="manjaro_pro_discount" />
              }
            }}
          />
        );
        pricingPageSections["dealPricingText"] = <DealPricingText os="kde" />;
        break;
      case "kde_conference":
        pricingPageSections["title"] =
          "Shells Virtual Desktop - KDE Conference";
        pricingPageSections["heroSection"] = (
          <KDEPricingHero bannerText="b1g1f_discount" />
        );
        pricingPageSections["carousel"] = (
          <SpecialPlansCarousel
            plansType="shells_b1m_g1mf"
            os="ubuntu-focal-kde-neon-desktop"
            showOnly={["basic", "plus", "pro"]}
            options={{
              basic: {
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_percent", number: "50" },
                discount: <Discount transKey="b1g1f_text" />
              },
              plus: {
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_percent", number: "50" },
                discount: <Discount transKey="b1g1f_text" />
              },
              pro: {
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_percent", number: "50" },
                discount: <Discount transKey="b1g1f_text" />
              }
            }}
          />
        );
        break;
      case "linux_mint":
        pricingPageSections["title"] = "Shells Virtual Desktop - Linux Mint";
        pricingPageSections["heroSection"] = <LinuxMintPricingMainHeader />;
        pricingPageSections["carousel"] = (
          <SpecialPlansCarousel
            plansType="manjaro_plan"
            os="ubuntu-focal-mint-cinnamon-desktop"
            showOnly={["basic", "plus", "pro"]}
            options={{
              basic: {
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_percent", number: "50" },
                discount: <Discount transKey="manjaro_basic_discount" />
              },
              plus: {
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_percent", number: "49" },
                discount: <Discount transKey="manjaro_plus_discount" />
              },
              pro: {
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_percent", number: "46" },
                discount: <Discount transKey="manjaro_pro_discount" />
              }
            }}
          />
        );
        pricingPageSections["dealPricingText"] = (
          <DealPricingText os="linuxmint" />
        );
        break;
      case "opensuse":
        pricingPageSections["title"] = "Shells Virtual Desktop - openSUSE";
        pricingPageSections["heroSection"] = (
          <OpenSusePricingHero bannerText="lp_linuxquestionsorg_ribbon" />
        );
        pricingPageSections["carousel"] = (
          <SpecialPlansCarousel
            plansType="manjaro_plan"
            os="opensuse-leap-gnome-desktop"
            showOnly={["basic", "plus", "pro"]}
            options={{
              basic: {
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_percent", number: "50" },
                discount: <Discount transKey="manjaro_basic_discount" />
              },
              plus: {
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_percent", number: "49" },
                discount: <Discount transKey="manjaro_plus_discount" />
              },
              pro: {
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_percent", number: "46" },
                discount: <Discount transKey="manjaro_pro_discount" />
              }
            }}
          />
        );
        pricingPageSections["dealPricingText"] = (
          <DealPricingText os="opensuse" />
        );
        break;
      case "opensuse_conference":
        pricingPageSections["title"] =
          "Shells Virtual Desktop - openSUSE Conference";
        pricingPageSections["heroSection"] = (
          <OpenSusePricingHero bannerText="b1g1f_discount" />
        );
        pricingPageSections["carousel"] = (
          <SpecialPlansCarousel
            plansType="shells_b1m_g1mf"
            os="opensuse-leap-gnome-desktop"
            showOnly={["basic", "plus", "pro"]}
            options={{
              basic: {
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_percent", number: "50" },
                discount: <Discount transKey="b1g1f_text" />
              },
              plus: {
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_percent", number: "50" },
                discount: <Discount transKey="b1g1f_text" />
              },
              pro: {
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_percent", number: "50" },
                discount: <Discount transKey="b1g1f_text" />
              }
            }}
          />
        );
        break;
      case "mx_linux":
        pricingPageSections["title"] = "Shells Virtual Desktop - MX Linux";
        pricingPageSections["heroSection"] = <MXLinuxPricingHero />;
        pricingPageSections["carousel"] = (
          <SpecialPlansCarousel
            plansType="manjaro_plan"
            os="debian-buster-mx-linux-desktop"
            showOnly={["basic", "plus", "pro"]}
            options={{
              basic: {
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_percent", number: "50" },
                discount: <Discount transKey="manjaro_basic_discount" />
              },
              plus: {
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_percent", number: "49" },
                discount: <Discount transKey="manjaro_plus_discount" />
              },
              pro: {
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_percent", number: "46" },
                discount: <Discount transKey="manjaro_pro_discount" />
              }
            }}
          />
        );
        pricingPageSections["dealPricingText"] = (
          <DealPricingText os="mxlinux" />
        );
        break;
      case "windows":
        pricingPageSections["title"] = "Shells Virtual Desktop - Windows";
        pricingPageSections["heroSection"] = <WindowsPricingHero />;
        pricingPageSections["dealPricingText"] = (
          <DealPricingText os="windows" />
        );
        pricingPageSections["carousel"] = <WindowsCarousel />;
        break;
      case "ultiuber":
        pricingPageSections["title"] = "Shells Virtual Desktop - Ultiuber";
        pricingPageSections["heroSection"] = <LTTPricingHero page="ultiuber" />;
        pricingPageSections["dealPricingText"] = (
          <DealPricingText os="ultiuber" />
        );
        break;
      case "linuxlads":
        pricingPageSections["title"] = "Shells Virtual Desktop - Linux Lads";
        pricingPageSections["heroSection"] = (
          <LTTPricingHero page="linuxlads" />
        );
        pricingPageSections["dealPricingText"] = (
          <DealPricingText os="linux_for_everyone" />
        );
        break;
      case "slickdeals":
        pricingPageSections["title"] = "Shells Virtual Desktop - Slickdeals";
        pricingPageSections["heroSection"] = <SlickDealsPricingHero />;
        pricingPageSections["dealPricingText"] = (
          <DealPricingText os="slickdeals" />
        );
        pricingPageSections["carousel"] = (
          <SpecialPlansCarousel
            plansType="slickdeals_plans"
            showOnly={["plus", "pro"]}
            os="windows-server-2019-dc-desktop"
            options={{
              plus: {
                monthlyPrice: 13.95,
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_dollars", number: "95.85" },
                discount: <Discount transKey="b3g3f_text" />
              },
              pro: {
                monthlyPrice: 24.95,
                usageLimit: "unlimited_use",
                disclaimer: { key: "save_n_dollars", number: "128.85" },
                discount: <Discount transKey="b3g3f_text" />
              }
            }}
          />
        );
        pricingPageSections["disclaimer"] = (
          <Disclaimer transKey="slickdeals_disclaimer" />
        );
        break;
      case "mike_dominick_show":
        pricingPageSections["title"] =
          "Shells Virtual Desktop - Mike Dominick Show";
        pricingPageSections["heroSection"] = (
          <LTTPricingHero page="mike_dominick_show" />
        );
        pricingPageSections["dealPricingText"] = <DealPricingText os="dln" />;
        break;
      case "gardinerbryant":
        pricingPageSections["title"] =
          "Shells Virtual Desktop - Gardiner Bryant";
        pricingPageSections["heroSection"] = (
          <LTTPricingHero page="gardinerbryant" />
        );
        pricingPageSections["dealPricingText"] = (
          <DealPricingText os="gardinerbryant" />
        );
        break;
      default:
        break;
    }
  }

  return pricingPageSections;
};
