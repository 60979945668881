import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

import { WrapperContainer } from "components/HOC";
import VideoModal from "components/PageModal/VideoModal";
import OS from "./OS";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/lfa";

const useStyles = makeStyles(styles);

export default function Header() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const video = (
    <iframe
      src="https://www.youtube.com/embed/nvrAL951Lys?rel=0"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen="allowfullscreen"
      className={classes.youtubeVideo}
      title="A word from Chael Sonnen"
    ></iframe>
  );

  return (
    <Paper className={classes.mainFeaturedPost} square elevation={0}>
      <VideoModal open={open} handleClose={handleClose} video={video} />
      <div className={classes.mainFeaturedPostContent}>
        <WrapperContainer addMaxWidth={false} addlWclass={classes.addPadding}>
          <Container maxWidth="lg">
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={10}>
                <div className={classes.textAndButton}>
                  <Typography variant="h1" className={classes.mainHeader}>
                    {t("lfa_h1")}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h3"
                    className={classes.heroDescription}
                  >
                    {t("lfa_p1")}
                  </Typography>
                  <div className={classes.buttonsContainer}>
                    <Button
                      variant="contained"
                      disableElevation
                      className={`${classes.signUpButton} ${classes.zeroMarginLeft}`}
                      component={Link}
                      to="/pricing"
                    >
                      {t("whyshells_button")}
                    </Button>
                    <div className={classes.playContainer}>
                      <Button
                        variant="contained"
                        disableElevation
                        className={classes.playButton}
                        onClick={() => handleOpen()}
                      >
                        <PlayArrowIcon />
                      </Button>
                      <Typography
                        variant="h5"
                        component="h3"
                        className={`${classes.heroDescription} ${classes.chaelText}`}
                        onClick={() => handleOpen()}
                      >
                        {t("lfa_h2")}
                      </Typography>
                    </div>
                  </div>
                  <Typography
                    variant="h5"
                    component="h3"
                    className={`${classes.heroDescription} ${classes.marginBottom}`}
                  >
                    {t("lfa_p9")}
                  </Typography>
                  <OS />
                </div>
              </Grid>
              <Hidden smDown>
                <Grid item md={2}></Grid>
              </Hidden>
            </Grid>
          </Container>
        </WrapperContainer>
      </div>
    </Paper>
  );
}
