import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import { WrapperContainer } from "components/HOC";
import { Carousel } from "components/Carousel";

import {
  kritaLogo,
  kdenLiveLogo,
  ardourLogo,
  libreLogo,
  thunderbirdLogo,
  firefoxLogo
} from "assets/img/manjaro";

import styles from "../../assets/jss/material-kit-react/views/manjaro";

const useStyles = makeStyles(styles);

export default function SectionThree() {
  const { t } = useTranslation();
  const classes = useStyles();

  const carouselData = [
    { image: kritaLogo, name: "Krita", purpose: t("manjaro_p13") },
    { image: kdenLiveLogo, name: "Kdenlive", purpose: t("manjaro_p14") },
    { image: ardourLogo, name: "Ardour", purpose: t("manjaro_p15") },
    { image: libreLogo, name: "Libre Office", purpose: t("manjaro_p16") },
    { image: thunderbirdLogo, name: "Thunderbird", purpose: t("manjaro_p17") },
    { image: firefoxLogo, name: "Firefox", purpose: t("manjaro_p18") }
  ];

  return (
    <WrapperContainer addMaxWidth={true}>
      <Typography variant="h5" component="h3" className={classes.sectionHeader}>
        {t("manjaro_h17")}
      </Typography>

      <Carousel data={carouselData} />

      <Box pt={8}>
        <Button
          color="primary"
          variant="contained"
          className={classes.bottomJoinButton}
          component={Link}
          to="/pricing?special=manjaro"
        >
          {t("lp_get_shell_button")}
        </Button>
      </Box>
    </WrapperContainer>
  );
}
