import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
// core components
import styles from "assets/jss/material-kit-react/views/page.js";
// Sections for this page
import CmsList from "components/cms/List";
import Container from "@material-ui/core/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(styles);

export default function List(props) {
  const classes = useStyles();
  const { type } = props;
  return (
    <div className={classes.genericPageWrapper}>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <Container maxWidth="xl">
          <CmsList type={type} />
        </Container>
      </div>
    </div>
  );
}
