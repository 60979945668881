import {
  mainGreenColor,
  blackColor,
  whiteColor,
  lightGrayColor
} from "assets/jss/material-kit-react";

const styles = theme => ({
  gridCardHeight_vms: {
    // for vms
    [theme.breakpoints.down("xs")]: {
      minHeight: 0
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "586px"
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "403px"
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "356px"
    }
  },
  gridCardHeight_ide: {
    // for ide
    [theme.breakpoints.down("xs")]: {
      minHeight: 0
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "570px"
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "380px"
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "340px"
    }
  },
  greenLine: {
    width: "225px",
    border: `3px solid ${mainGreenColor}`,
    borderRadius: "5px",
    margin: "20px 0 20px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "20px auto 20px auto"
    }
  },
  gridCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    }
  },
  cardContentOne: {
    minHeight: "160px",
    textAlign: "left",
    maxWidth: "350px"
  },
  cardImage: {
    margin: "0 auto",
    padding: "15px 0 15px 0",
    [theme.breakpoints.up("xs")]: {
      width: "200px"
    }
  },
  headerContainer: {
    position: "relative",
    minHeight: "70px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "40px",
      marginTop: "10px"
    }
  },
  sectionHeaderPos: {
    position: "absolute",
    left: 0,
    bottom: "-12px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      bottom: "unset"
    }
  },
  sectionHeader: {
    fontFamily: "Open Sans, sans-serif",
    color: blackColor,
    fontWeight: "900",
    fontSize: "24px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  sectionBody: {
    fontSize: "16px",
    fontFamily: "Roboto, sans-serif",
    color: blackColor,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  bodyContainer: {
    minHeight: "100px",
    [theme.breakpoints.down("sm")]: {
      minHeight: 0
    }
  },
  checkIcon: {
    fontSize: "35px",
    fontWeight: "900",
    color: mainGreenColor
  },
  xIcon: {
    fontSize: "35px",
    fontWeight: "900",
    color: "red"
  },
  tableHeader: {
    fontSize: "16px",
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 900,
    borderBottom: "none",
    textAlign: "center",
    backgroundColor: lightGrayColor,
    height: "75px",
    whiteSpace: "nowrap",
    wordBreak: "keep-all"
  },
  tableCellTitle: {
    fontSize: "16px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 900,
    color: blackColor,
    borderBottom: "none",
    height: "75px",
    width: "auto",
    minWidth: "140px"
  },
  tableCell: {
    fontSize: "16px",
    fontFamily: "Roboto, sans-serif",
    borderBottom: "none",
    height: "75px"
  },
  geekbenchScore: {
    fontSize: "16px",
    fontFamily: "Roboto, sans-serif",
    "&:hover, &:focus": {
      textDecoration: "underline"
    }
  },
  sticky: {
    position: "sticky",
    left: 0,
    background: whiteColor
  },
  stickyHeader: {
    position: "sticky",
    left: 0,
    background: lightGrayColor
  },
  shellsIcon: {
    width: "150px"
  },
  codeanywhereIcon: {
    width: "50px",
    marginRight: "7px"
  },
  icon: {
    fontSize: "40px",
    marginRight: "7px"
  },
  iconDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  posRelative: {
    position: "relative"
  }
});

export default styles;
