import React from "react";
import { useTranslation } from "react-i18next";
import { HashLink as Link } from "react-router-hash-link";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";

import { WrapperContainer } from "components/HOC";
import { vsCodeIpad } from "assets/img/vscode";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/vscode";

const useStyles = makeStyles(styles);

export default function VsCodePricingHero() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.mainFeaturedPost}>
      <WrapperContainer addMaxWidth={false} addlWclass={classes.morePadding}>
        <Grid container alignItems="center" justify="center" spacing={2}>
          <Hidden smUp>
            <Grid item xs={12} sm={6}>
              <img
                src={vsCodeIpad}
                alt="iPad using VS code with Shells"
                className={classes.vsCodeIpadImage}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6}>
            <div className={classes.headerTextContainer}>
              <Typography
                variant="h1"
                component="h2"
                className={classes.mainHeader}
              >
                {t("vscode_pricing_header")}
              </Typography>
            </div>
            <Box pt={6}>
              <div className={classes.subTextContainer}>
                <Typography
                  variant="h1"
                  component="h2"
                  className={classes.subHeader}
                >
                  {t("vscode_pricing_description")}
                </Typography>
                <Button
                  variant="contained"
                  disableElevation
                  className={classes.signUpButton}
                  component={Link}
                  to="#pricing"
                >
                  {t("vscode_button")}
                </Button>
              </div>
            </Box>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={6}>
              <img
                src={vsCodeIpad}
                alt="iPad using VS code with Shells"
                style={{ width: "100%" }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </WrapperContainer>
    </Paper>
  );
}
