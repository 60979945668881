import React from "react";
import { Get } from "@karpeleslab/klbfw";
import { Trans, useTranslation } from "react-i18next";

import EmailLayout from "./../../../components/Email/EmailLayout";
import Text from "./../../../components/Email/EmailText";
import Button from "./../../../components/Email/EmailButton";

const Html = () => {
  const { t } = useTranslation();

  const password = Get("_password");
  const userName = Get("_user").Profile.Display_Name;
  const shellName = Get("_shell").Label;

  return (
    <EmailLayout>
      <table
        border="0"
        cellSpacing="0"
        cellPadding="0"
        width="100%"
        style={{ width: "100%" }}
      >
        <tbody>
          <tr>
            <td>
              <Text size="medium">
                <Trans i18nKey="shell_mail_default_password_content">
                  {{ shellName }}
                  {{ userName }}
                  {{ password }}
                  <br />
                  <a href={process.env.REACT_APP_SHELL_CONSOLE_URL}>sample</a>
                  <a href="https://www.shells.com/l/en-US/tutorial/Changing-your-Operating-System-Password-and-Admin-Password-on-your-Shell%E2%84%A2">
                    sample
                  </a>
                  <a href="https://support.shells.net">sample</a>
                  <a href="https://www.shells.com/tutorial">sample</a>
                </Trans>
              </Text>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td align="center">
              <Text size="medium">{t("contact_us_desc")}</Text>
            </td>
          </tr>
          <tr>
            <td align="center">
              <Button href="https://www.shells.com/contact">
                {t("contact_us")}
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </EmailLayout>
  );
};

export default Html;
