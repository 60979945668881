import React from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/pricingPlans";

const useStyles = makeStyles(styles);

export default function PricingPlansRedirect({ setSpecialOffer }) {
  const classes = useStyles();

  return (
    <Box className={classes.regularPlansRedirectContainer}>
      <Typography
        component="p"
        variant="h5"
        className={classes.regularPricingParagraph}
      >
        <Trans
          i18nKey="manjaro_back_to_regular_pricing"
          components={{
            pageLink: (
              <Link
                className={classes.greenLink}
                to="/pricing"
                onClick={() => setSpecialOffer(null)}
              />
            )
          }}
        />
      </Typography>
    </Box>
  );
}
