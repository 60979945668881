import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as NumericInput from "react-numeric-input";
import { isCustomPlan } from "./util";

import Grid from "@material-ui/core/Grid";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/components/buildSpecStyle";

const useStyles = makeStyles(styles);

const BuildOtherField = ({ selected, nbUnitSelected, setUnit, selectUnit }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const onChangeHandler = event => {
    setUnit(event);
    selectUnit(event);
  };

  const getMinUnitsValue = plans => {
    if (!plans) {
      return 1;
    }

    return isCustomPlan(selected) ? 8 : 1;
  };

  if (isCustomPlan(selected)) {
    return (
      <center>
        <p className={classes.buildOtherFieldText}>{t("custom_shell_title")}</p>
        <Grid
          container
          spacing={1}
          className={classes.buildOtherFieldGridContainer}
          justify="center"
        >
          <p>
            <span className={classes.marginRight}>
              {t("order_choose_number_units")}
            </span>
            <NumericInput
              min={getMinUnitsValue(selected)}
              max={1024}
              defaultValue={nbUnitSelected}
              onChange={onChangeHandler}
              strict
            />
          </p>
          <p className={classes.buildOtherFieldDesc}>
            {t("order_choose_number_units_desc")}
          </p>
        </Grid>
      </center>
    );
  }

  return (
    <center>
      <p className={classes.buildOtherFieldText}>{selected["Basic.Name"]}</p>
      <Grid
        container
        spacing={1}
        className={classes.buildOtherFieldGridContainer}
      >
        <p className={classes.buildOtherFieldDesc}>
          {selected["Description.CatchPhrase"]}
        </p>
      </Grid>
    </center>
  );
};

const mapStateToProps = state => {
  return {
    nbUnitSelected: state.plans.nbUnitSelected,
    selected: state.plans.selected,
    plans: state.plans.plans
  };
};

export default connect(mapStateToProps)(BuildOtherField);
