import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/material-kit-react/views/security";

const useStyles = makeStyles(styles);

export default function StyledBody({ data }) {
  const { headerOne, textOne, imageOne, headerTwo, textTwo, imageTwo } = data;
  const classes = useStyles();

  return (
    <>
      <div className={classes.flexContainer}>
        <div className={`${classes.textContainer} ${classes.mRightmBottom}`}>
          <div className={classes.greenLineTop}></div>
          <Hidden only={["md", "lg", "xl"]}>
            <img src={imageOne} alt="" className={classes.imageOne} />
          </Hidden>
          <Typography variant="h1" className={classes.header}>
            {headerOne}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {textOne}
          </Typography>
        </div>
        <Hidden smDown>
          <img src={imageOne} alt="" className={classes.imageOne} />
        </Hidden>
      </div>

      <div className={classes.flexContainer}>
        <img src={imageTwo} alt="" className={classes.imageOne} />
        <div
          className={`${classes.textContainer} ${classes.marginLeft} ${classes.flexEnd}`}
        >
          <Typography variant="h1" className={classes.header}>
            {headerTwo}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {textTwo}
          </Typography>
          <div className={classes.greenLineBottom}></div>
        </div>
      </div>
    </>
  );
}
