import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const ButtonBase = withStyles(theme => ({
  root: {
    borderRadius: "10px",
    display: "inline-block",
    margin: `${theme.spacing(2)}px 12px`,
    background: `transparent url('${require("assets/img/stores.png")}') no-repeat scroll`,
    width: "181px",
    height: "55px",
    color: "#fff",
    backgroundSize: "cover",

    "&:hover": {
      backgroundColor: "transparent"
    },

    [theme.breakpoints.down("sm")]: {
      // margin: "48px 0",
      transform: "scale(.9)"
    }
  }
}))(Button);

const useStyles = makeStyles(() => ({
  ios: {
    top: "0px",
    backgroundPosition: "0 25%"
  },
  win64: {
    top: "-1px",
    backgroundPosition: "0 100%"
  },
  linux_amd64: {
    top: "-1px",
    backgroundPosition: "0 75%"
  },
  macos: {
    top: "-1px",
    backgroundPosition: "0 50%"
  },
  android: {
    backgroundPosition: "top center"
  }
}));

const links = {
  ios: "https://apps.apple.com/app/id1534972425",
  android: "https://play.google.com/store/apps/details?id=com.shells.app"
};

const MobileStoreButton = ({ store, link = null }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ButtonBase
      component="a"
      className={classes[store]}
      href={link || links[store]}
      target="_blank"
      title={t(`download_${store}`)}
    />
  );
};

export default MobileStoreButton;
