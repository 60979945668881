import { rest, upload } from "@karpeleslab/klbfw";
import {
  FLOW_DONE,
  FLOW_ERROR,
  FLOW_FETCH,
  FLOW_UPLOADING,
  FLOW_UPLOADING_DONE,
  FLOW_INIT
} from "../reducers/FlowReducer";
import { handleError } from "../../components/utils/apiErrorHandler";

export const flow = data => {
  return (dispatch, getState) => {
    dispatch({ type: FLOW_FETCH });

    return rest("User:flow", "POST", {
      ...data,
      session: getState().flow.session
    })
      .then(d => {
        dispatch({ type: FLOW_DONE, data: d.data });
      })
      .catch(err => {
        dispatch({ type: FLOW_ERROR, error: err.message });
        handleError(getState, dispatch, err);
      });
  };
};

export const initFlow = () => {
  return dispatch => {
    dispatch({ type: FLOW_INIT });
  };
};

export const uploadFiles = () => {
  return dispatch => {
    dispatch({ type: FLOW_UPLOADING });

    upload.onprogress = d => {
      if (d.queue.length !== 0) return;
      let allComplete = true;

      d.running.forEach(value => {
        if (value.status !== "complete") allComplete = false;
        if (allComplete) dispatch({ type: FLOW_UPLOADING_DONE });
      });
    };

    let c = 0;
    //    for (const vf in files) {
    //        if(files[vf].file) {
    //            c++;
    //            upload.append(files[vf].target, files[vf].file, files[vf].param);
    //            upload.run();
    //        }
    //   }

    if (c === 0) dispatch({ type: FLOW_UPLOADING_DONE });
  };
};
