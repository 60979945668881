import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { selectPlan, fetchPlans } from "store/actions/PlanAction";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/pricingPlans";

const useStyles = makeStyles(styles);

const BuildYourOwnShellLink = ({ fetchPlans, fetchedPlans, selectPlan }) => {
  const classes = useStyles();
  const [customPlan, setCustomPlan] = useState(null);

  useEffect(() => {
    fetchPlans();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!fetchedPlans) return;

    const planCustom = fetchedPlans.find(
      p => p["Description.AuthorCode"] === "custom"
    );
    if (planCustom) setCustomPlan(planCustom);
  }, [fetchedPlans]);

  return (
    customPlan && (
      <Box className={classes.regularPlansRedirectContainer}>
        <Typography
          component="p"
          variant="h5"
          className={classes.buildYourShellParagraph}
        >
          <Trans
            i18nKey="build_your_own_shell_link"
            components={{
              pageLink: (
                <Link
                  className={classes.greenLink}
                  to="/join"
                  onClick={() => selectPlan(customPlan)}
                />
              )
            }}
          />
        </Typography>
      </Box>
    )
  );
};

const mapStateToProps = state => {
  return {
    fetchedPlans: state.plans.plans
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectPlan: plan => dispatch(selectPlan(plan)),
    fetchPlans: () => dispatch(fetchPlans())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuildYourOwnShellLink);
