import React from "react";
import { useParams } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/landingPage20210323";

const useStyles = makeStyles(styles);

export default function GridCard({ data }) {
  const { image, title, text, text2 } = data;
  const classes = useStyles();
  const { pageName } = useParams();

  let gridClass;

  if (!pageName) {
    gridClass = classes.gridCardHeight_vms;
  }

  switch (pageName) {
    case "ide":
      gridClass = classes.gridCardHeight_ide;
      break;
    case "vms":
      gridClass = classes.gridCardHeight_vms;
      break;
    default:
      gridClass = classes.gridCardHeight_ide;
  }

  return (
    <Grid item xs={12} sm={6} className={gridClass}>
      <Card square elevation={0} className={classes.gridCard}>
        <img src={image} className={classes.cardImage} alt="" />
        <CardContent className={classes.cardContentOne}>
          <div className={classes.headerContainer}>
            <Typography
              gutterBottom
              variant="h5"
              component="h3"
              className={`${classes.sectionHeader} ${classes.sectionHeaderPos}`}
            >
              {title}
            </Typography>
          </div>
          <div className={classes.greenLine}></div>
          <div className={classes.bodyContainer}>
            <Typography
              variant="body1"
              color="textSecondary"
              component="p"
              className={classes.sectionBody}
            >
              {text}
            </Typography>

            {text2 && (
              <Box py={1}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  component="p"
                  className={classes.sectionBody}
                >
                  {text2}
                </Typography>
              </Box>
            )}
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
}
