import React from "react";
import { Get } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import DateUtil from "./../../../components/common/data/DateUtil";

import EmailLayout from "./../../../components/Email/EmailLayout";
import Text from "./../../../components/Email/EmailText";
import Button from "./../../../components/Email/EmailButton";
import InfoBox from "./../../../components/Email/EmailInfoBox";

const Html = () => {
  const { t } = useTranslation();

  const Payment_Class = () => {
    if (Get("_order").Payment_Class !== "") {
      return (
        <tr>
          <td width="20%" nowrap>
            <Text size="medium">{t("payment_method")}</Text>
          </td>
          <td>
            <Text size="medium">: {Get("_order").Payment_Method}</Text>
          </td>
        </tr>
      );
    }
  };

  const Shipping_User_Location = () => {
    if (Get("_order").Shipping_User_Location) {
      return (
        <td valign="top">
          <Text variant="highlight" size="large">
            {t("shipping_address")}
          </Text>
          <Text size="medium">
            {Get("_order").Shipping_User_Location.Display[0]}{" "}
            {Get("_order").Shipping_User_Location.Display[1]}
          </Text>
        </td>
      );
    } else {
      return (
        <td valign="top">
          <Text variant="highlight" size="large">
            {t("shipping_address")}
          </Text>
          <Text size="medium">
            {Get("_order").Billing_User_Location.Display[0]}{" "}
            {Get("_order").Billing_User_Location.Display[1]}
          </Text>
        </td>
      );
    }
  };

  const quantity = line => {
    if (line.Quantity) {
      return line.Quantity;
    } else {
      return "1";
    }
  };

  const items = () => {
    return Get("_order").Items.map((line, idx) => {
      return (
        <tr key={`order_item_${idx}`}>
          <td className="prod_name">
            <Text>{line.Catalog_Product["Basic.Name"]}</Text>
          </td>
          <td className="unit_price" align="right">
            <Text>{line.Price.display}</Text>
          </td>
          <td className="quantity" align="center">
            <Text>{quantity(line)}</Text>
          </td>
          <td className="prod_total" align="right">
            <Text>{line.Full_Price.display}</Text>
          </td>
        </tr>
      );
    });
  };

  return (
    <EmailLayout>
      <table
        border="0"
        cellSpacing="0"
        cellPadding="0"
        width="100%"
        style={{ width: "100%" }}
      >
        <tbody>
          <tr>
            <td>
              <Text size="medium">
                {Get("_order").Billing_User_Location.Last_Name}{" "}
                {Get("_order").Billing_User_Location.First_Name}
              </Text>
              <Text size="medium">{t("order_thanks")}</Text>
            </td>
          </tr>
          <tr>
            <td>
              <InfoBox>
                <table
                  cellSpacing="0"
                  cellPadding="0"
                  border="0"
                  align="center"
                  width="100%"
                >
                  <tr>
                    <td
                      bgColor="#72BA3E"
                      style={{ color: "#FFFFFF", fontWeight: 500 }}
                    >
                      <Text size="large">
                        <b style={{ color: "#FFFFFF" }}>{t("order_number")}</b>{" "}
                        #{Get("_order").Invoice_Number}
                      </Text>
                    </td>
                  </tr>
                </table>
              </InfoBox>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>
              <table
                cellSpacing="0"
                cellPadding="0"
                border="0"
                align="center"
                width="100%"
                style={{ lineHeight: "1.4rem" }}
              >
                <tr>
                  <td width="20%" nowrap>
                    <Text size="medium">{t("order_date")}</Text>
                  </td>
                  <td>
                    <Text size="medium">
                      : <DateUtil klbDateObj={Get("_order").Created} />
                    </Text>
                  </td>
                </tr>
                {Get("_order").Invoice_Date && (
                  <tr>
                    <td width="20%" nowrap>
                      <Text size="medium">{t("payment_date")}</Text>
                    </td>
                    <td>
                      <Text size="medium">
                        : <DateUtil klbDateObj={Get("_order").Invoice_Date} />
                      </Text>
                    </td>
                  </tr>
                )}
                {Payment_Class()}
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <hr size="2" color="#72BA3E" />
            </td>
          </tr>
          <tr>
            <td>
              <table
                cellSpacing="0"
                cellPadding="5"
                border="0"
                align="center"
                width="100%"
              >
                <tr>
                  <td valign="top">
                    <Text variant="highlight" size="large">
                      {t("invoice")}
                    </Text>
                    <Text size="medium">
                      {Get("_order").Billing_User_Location.Display[0]}{" "}
                      {Get("_order").Billing_User_Location.Display[1]}
                    </Text>
                  </td>
                  {Shipping_User_Location()}
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <hr size="2" color="#72BA3E" />
            </td>
          </tr>
          <tr>
            <td>
              <Text variant="highlight" size="large">
                {t("order_details")}
              </Text>
              <table
                cellSpacing="0"
                cellPadding="5"
                border="0"
                align="center"
                width="100%"
                style={{ borderCollapse: "collapse" }}
              >
                <tr>
                  <th bgcolor="#72BA3E">
                    <Text variant="inverted" size="medium">
                      {t("product_name")}
                    </Text>
                  </th>
                  <th bgcolor="#72BA3E">
                    <Text variant="inverted" size="medium">
                      {t("price")}
                    </Text>
                  </th>
                  <th bgcolor="#72BA3E">
                    <Text variant="inverted" size="medium">
                      {t("quantity")}
                    </Text>
                  </th>
                  <th bgcolor="#72BA3E">
                    <Text variant="inverted" size="medium">
                      {t("sub_total")}
                    </Text>
                  </th>
                </tr>
                {items()}
                <tr>
                  <td
                    className="subtotal_h"
                    colSpan="3"
                    align="right"
                    bgcolor="#72BA3E"
                  >
                    <Text variant="inverted" size="medium">
                      {t("sub_total")}
                    </Text>
                  </td>
                  <td className="subtotal" align="right">
                    <Text size="medium">{Get("_order").Total.display}</Text>
                  </td>
                </tr>
                <tr>
                  <td
                    className="shippingfee_h"
                    colSpan="3"
                    align="right"
                    bgcolor="#72BA3E"
                  >
                    <Text variant="inverted" size="medium">
                      {t("discount")}
                    </Text>
                  </td>
                  <td className="shippingfee" align="right">
                    <Text size="medium">
                      {Get("_order").Subtotals.discount.display}
                    </Text>
                  </td>
                </tr>
                <tr>
                  <td
                    className="shippingfee_h"
                    colSpan="3"
                    align="right"
                    bgcolor="#72BA3E"
                  >
                    <Text variant="inverted" size="medium">
                      {t("shipping_fee")}
                    </Text>
                  </td>
                  <td className="shippingfee" align="right">
                    <Text size="medium">
                      {Get("_order").Subtotals.shipping.display}
                    </Text>
                  </td>
                </tr>
                <tr>
                  <td
                    className="tax_h"
                    colSpan="3"
                    align="right"
                    bgcolor="#72BA3E"
                  >
                    <Text variant="inverted" size="medium">
                      {t("vat")}
                    </Text>
                  </td>
                  <td className="total" align="right">
                    <Text size="medium">
                      {Get("_order").Vat_Amount.display}
                    </Text>
                  </td>
                </tr>
                <tr className="noborder">
                  <td
                    className="total_h"
                    colSpan="3"
                    align="right"
                    bgcolor="#72BA3E"
                  >
                    <Text variant="inverted" size="medium">
                      <b>{t("total")}</b>
                    </Text>
                  </td>
                  <td className="total" align="right">
                    <Text size="medium">
                      <b>{Get("_order").Total_Vat.display}</b>
                    </Text>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <hr size="2" color="#B0E170" />
            </td>
          </tr>
          <tr>
            <td valign="top">
              <Text variant="highlight" size="large">
                {t("vat_legal_number_title")}
              </Text>
              <Text size="medium">
                {t("vat_legal_number_eu", { value: "EU372033958" })}
              </Text>
            </td>
          </tr>
          <tr>
            <td align="center">
              <Text size="medium">{t("contact_us_desc")}</Text>
            </td>
          </tr>
          <tr>
            <td align="center">
              <Button href="https://www.shells.com/contact">
                {t("contact_us")}
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </EmailLayout>
  );
};

export default Html;
