const initState = {
  loading: false,
  vat: null
};

export const VAT_FETCH = "VAT_FETCH";
export const VAT_FETCH_DONE = "VAT_FETCH_DONE";

const orderReducer = (state = initState, action) => {
  switch (action.type) {
    case VAT_FETCH:
      return { ...state, loading: true };
    case VAT_FETCH_DONE:
      return { ...state, loading: false, vat: action.vat };
    default:
      return state;
  }
};

export default orderReducer;
