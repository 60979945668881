import React from "react";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/material-kit-react/views/security";

const useStyles = makeStyles(styles);

export default function ImageAndText({ data }) {
  const classes = useStyles();
  const { image, header, text, imageRight } = data;

  let view;
  if (imageRight) {
    view = (
      <>
        <div className={`${classes.textContainer} ${classes.mRightmBottom}`}>
          <Typography variant="h1" className={classes.header}>
            {header}
          </Typography>
          <Typography variant="body1" className={classes.imageText}>
            {text}
          </Typography>
        </div>
        <img src={image} alt="" className={classes.imageOne} />
      </>
    );
  } else {
    view = (
      <>
        <img
          src={image}
          alt=""
          className={`${classes.imageOne} ${classes.mRightmBottom}`}
        />
        <div className={`${classes.textContainer}`}>
          <Typography variant="h1" className={classes.header}>
            {header}
          </Typography>
          <Typography variant="body1" className={classes.imageText}>
            {text}
          </Typography>
        </div>
      </>
    );
  }
  return <div className={classes.sideBySide}>{view}</div>;
}
