import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectPlan } from "store/actions/PlanAction";
import { connect } from "react-redux";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import classNames from "classnames";

import styles from "assets/jss/material-kit-react/views/orderPage.js";
import { makeStyles } from "@material-ui/core/styles";

import style from "../../assets/scss/modules/order.module.scss";

const useStyles = makeStyles(styles);

const ToggleOrder = ({ plans, selectPlan, selected, variant }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [period, setPeriod] = useState("Yearly");

  useEffect(() => {
    let servicePeriod;
    if (selected) {
      servicePeriod = selected["Basic.ServiceLifetime"];
    }

    switch (servicePeriod) {
      case "1y":
        setPeriod("Yearly");
        break;
      case "1m":
        setPeriod("Monthly");
        break;
      default:
        setPeriod("Yearly");
        break;
    }
  }, [selected]);

  const changePeriod = name => {
    const serviceLifetime = name === "Yearly" ? "1y" : "1m";

    const lifeTimeFiltered = plans.filter(
      p => p["Basic.ServiceLifetime"] === serviceLifetime
    );

    const filtered = lifeTimeFiltered.filter(
      p => p["Description.AuthorCode"] === selected["Description.AuthorCode"]
    );

    let newSelected = filtered.length > 0 ? filtered[0] : lifeTimeFiltered[0];

    selectPlan(newSelected);
  };

  const handlePeriod = (event, name) => {
    if (name !== null) {
      changePeriod(name);
    }
  };

  if (variant === "toggle") {
    return (
      <div className={style["toggle-button-container"]}>
        <ToggleButtonGroup
          value={period}
          exclusive
          aria-label="Set period"
          onChange={handlePeriod}
        >
          <ToggleButton
            value="Yearly"
            aria-label="Yearly"
            className={`${classes.toggleColor} ${classes.root}`}
          >
            {t("order_period_year")}
          </ToggleButton>
          <ToggleButton
            value="Monthly"
            aria-label="Monthly"
            className={`${classes.toggleColor} ${classes.root}`}
          >
            {t("order_period_month")}
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    );
  } else {
    return (
      <ul className={style["order-period"]}>
        <li
          className={classNames(style["order-period-item"], {
            [style["order-period-item-active"]]: period === "Yearly"
          })}
          onClick={() => {
            changePeriod("Yearly");
          }}
        >
          {t("order_period_year")}
        </li>
        <li
          className={classNames(style["order-period-item"], {
            [style["order-period-item-active"]]: period === "Monthly"
          })}
          onClick={() => {
            changePeriod("Monthly");
          }}
        >
          {t("order_period_month")}
        </li>
      </ul>
    );
  }
};

const mapStateToProps = state => {
  return {
    plans: state.plans.plans,
    // custom: state.plans.planCustom,
    selected: state.plans.selected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectPlan: plan => dispatch(selectPlan(plan))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToggleOrder);
