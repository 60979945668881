import React from "react";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/material-kit-react/views/security";

const useStyles = makeStyles(styles);

export default function StyledText({ data }) {
  const { headerTextOne, headerTextTwo, subText } = data;
  const classes = useStyles();

  return (
    <>
      <Typography variant="h1" className={classes.mainHeader}>
        {headerTextOne}
      </Typography>
      <Typography variant="h1" className={classes.mainHeader}>
        {headerTextTwo}
      </Typography>
      <div className={classes.greenLine}></div>

      <div className={classes.descriptionContainer}>
        <Typography
          variant="h5"
          className={`${classes.description} ${classes.whiteFont}`}
        >
          {subText}
        </Typography>
      </div>
    </>
  );
}
