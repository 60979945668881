import {
  primaryColor,
  container,
  title
} from "assets/jss/material-kit-react.js";

const orderPageStyle = theme => ({
  container: {
    zIndex: "12",
    color: "#fbfcfe",
    ...container
  },
  main: {
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    padding: " 0 0 32px 0",
    margin: "0px",
    borderRadius: "0px"
  },
  titleStep: {
    color: primaryColor,
    marginLeft: "12px",
    marginTop: "-20px"
  },
  plans: {
    marginBottom: "18px"
  },
  plan: {
    background: "#B0E170",
    cursor: "pointer",
    padding: "16px",
    border: "1px solid #B0E170",
    color: "#000000",
    minHeight: "105px"
  },
  planNonSelected: {
    background: "white",
    cursor: "pointer",
    padding: "16px",
    border: "1px solid #B0E170",
    color: "#000000",
    minHeight: "105px"
  },
  planName: {
    color: primaryColor
  },
  planDisabled: {
    filter: "grayscale(100%)",
    "&:hover": {
      cursor: "not-allowed"
    }
  },
  price: {
    color: primaryColor
  },
  infoPlan: {
    color: primaryColor
  },
  paymentMethod: {
    cursor: "pointer",
    border: "2px solid #afe170",
    color: "#000000"
  },
  titleFeatures: {
    color: primaryColor
  },
  titleFeature: {
    color: primaryColor
  },
  descFeature: {
    color: primaryColor
  },
  titleContainer: {
    maxWidth: "330px",
    margin: "auto",
    marginBottom: "32px"
  },
  titleSection: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "left",
    borderBottom: "solid 2px #72BA3E",
    fontSize: "16px",
    width: "maxContent",
    [theme.breakpoints.down("md")]: {
      marginLeft: "24px"
    }
  },
  genericPageWrapper: {
    position: "relative",
    height: "auto",
    minHeight: "calc(100vh - 188px)",
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "160px"
    },
    "&::after": {
      content: "''",
      position: "absolute",
      top: "70px",
      left: "50%",
      width: "100%",
      height: "167px",
      transform: "translateX(-50%)"
    }
  },
  toggleColor: {
    "&.Mui-selected": {
      backgroundColor: "#B0E170",
      color: "#3C4858",
      "&:focus": {
        backgroundColor: "#B0E170",
        color: "#3C4858"
      }
    }
  },
  root: {
    color: "#3C4858"
  },
  specsHeader: {
    color: "#3C4858",
    fontWeight: "bold"
  },
  specsGridContainer: {
    background: "#F4F4F4",
    padding: "22px",
    minHeight: "480px"
  },

  // styling for Cart.js
  cartColor: {
    color: "#888888"
  },
  cartNumbers: {
    color: "#000000",
    fontWeight: "bold"
  },
  cartText: {
    fontSize: "12px"
  },

  // styling for Billing.js
  billingColor: {
    color: "#888888"
  },
  billingContainer: {
    border: "solid 2px #B0E170",
    padding: "24px 12px",
    marginBottom: "12px"
  },
  stripeContainer: {
    margin: "auto",
    border: "solid 1px #B0E170",
    padding: "12px"
  },
  serviceTermsText: {
    fontSize: "11px",
    color: "#888888"
  },
  orderButtonGridItem: {
    textAlign: "right",
    paddingRight: "0"
  },
  billingMargin: {
    margin: "12px"
  },
  selectedGridItem: {
    paddingTop: "12px",
    paddingBottom: "12px",
    color: "#888888"
  },
  userFlowDialog: {
    width: "auto",
    minWidth: "130px"
  }
});

export default orderPageStyle;
