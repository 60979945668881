export const androidTV = require("./android-tv.png");
export const iOS = require("./ios.png");
export const android = require("./android.png");
export const firefox = require("./firefox.png");
export const chrome = require("./chrome.png");
export const safari = require("./safari.png");
export const ps = require("./ps.png");
export const xbox = require("./xbox.png");
export const oculus = require("./oculus.png");
export const tesla = require("./tesla.png");
