import React from "react";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import GridItem from "./GridItem";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/cloudmusic";

const useStyles = makeStyles(styles);

export default function SectonOne() {
  const { t } = useTranslation();
  const classes = useStyles();

  const gridItem1 = {
    video: true,
    gridClass: classes.youtubeContainer
  };
  const gridItem2 = {
    video: false,
    header: t("cloudmusic_h17"),
    body: t("cloudmusic_p13"),
    youtubeLink:
      "https://www.youtube.com/playlist?list=PLqDYDprBLTXKFV1Zk6HbbYzyg7VWed3oD",
    youtubeLinkClass: classes.headerLinkClass,
    interviewLink:
      "https://www.shells.com/b/update-header-image/l/en-US/blog/Music-with-the-Cloud-Interview-with-DJ-Reddy-Rell",
    interviewLinkClass: classes.linkClass,
    headerClass: classes.bottomHeader,
    bodyClass: classes.bottomSubHeader,
    bodyVariant: "h5"
  };

  return (
    <Paper className={classes.secondFeaturedPost}>
      <div className={classes.wrapper}>
        <div className={classes.overlay} />
        <div className={`${classes.sectionOnePostContent}`}>
          <Grid container spacing={6} justify="center" alignItems="center">
            <GridItem data={gridItem1} />
            <GridItem data={gridItem2} />
          </Grid>
        </div>
      </div>
    </Paper>
  );
}
