import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";
import { useTranslation } from "react-i18next";

import {
  Header,
  SectionOne,
  SectionTwo,
  SectionFive,
  SectionSix,
  ImageAndText
} from "components/WhyShells";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/whyShells";

import {
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9
} from "assets/img/whyshells";

const useStyles = makeStyles(styles);

export default function WhyShells() {
  const { t } = useTranslation();
  const classes = useStyles();

  const detailsOne = {
    header: t("whyshells_h1"),
    description: t("whyshells_p1"),
    image: img1,
    imageClass: classes.image1,
    addlWclass: `${classes.overflowHidden} ${classes.textAlignLeft}`
  };
  const detailsTwo = {
    headerOne: t("whyshells_h12"),
    headerTwo: t("whyshells_h2"),
    subHeader: t("whyshells_p2"),
    addlWclass: classes.lightBlueBG
  };
  const detailsThree = {
    headerOne: t("whyshells_h3"),
    descriptionOne: t("whyshells_p3"),
    imageOne: img2,
    headerTwo: t("whyshells_h4"),
    descriptionTwo: t("whyshells_p4"),
    imageTwo: img3,
    addlWclass: `${classes.darkBlueBG} ${classes.overflowHidden}`
  };
  const detailsFour = {
    header_a: t("whyshells_h5a"),
    header_b: t("whyshells_h5b"),
    description: t("whyshells_p5"),
    image: img4,
    addlWclass: classes.lightBlueBG,
    imageLeft: true,
    addButton: true,
    fontColor: classes.fontOrange,
    textContainerClass: classes.textContainer3,
    lineClass: classes.orangeLine
  };
  const detailsFive = {
    header_a: t("whyshells_h6a"),
    header_b: t("whyshells_h6b"),
    description: t("whyshells_p6"),
    image: img5,
    imageLeft: false,
    addButton: false,
    addlWclass: `${classes.darkBlueBG} ${classes.overflowHidden}`,
    fontColor: classes.whiteFont,
    textContainerClass: classes.textContainer1,
    lineClass: classes.whiteLine
  };
  const detailsSix = {
    header: t("whyshells_h7"),
    descriptionOne: t("whyshells_p7_one"),
    descriptionTwo: t("whyshells_p7_two"),
    image: img6,
    addlWclass_a: `${classes.lightBlueBG} ${classes.paddingBottom} ${classes.overflowHidden}`,
    addlWclass_b: classes.lightBlueBG
  };

  const card_a = {
    img: img7,
    header: t("whyshells_h9"),
    description: t("whyshells_p9"),
    link: "/lp/ide"
  };
  const card_b = {
    img: img8,
    header: t("whyshells_h10"),
    description: t("whyshells_p10"),
    link: "/lp/audio"
  };
  const card_c = {
    img: img9,
    header: t("whyshells_h11"),
    description: t("whyshells_p11"),
    link: "/edtech"
  };

  const detailsSeven = {
    header: t("whyshells_h8"),
    addlWclass: `${classes.lightBlueBG} ${classes.block}`,
    card_a,
    card_b,
    card_c
  };

  return (
    <>
      <Helmet title={t("whyshells_h1")} />

      {/* Why Shells */}
      <Header details={detailsOne} />

      {/* The Future Is Us */}
      <SectionOne details={detailsTwo} />

      {/* Work on your projects */}
      <SectionTwo details={detailsThree} />

      {/* Stay secure */}
      <ImageAndText details={detailsFour} />

      {/* Upgrades are easy */}
      <ImageAndText details={detailsFive} />

      {/* Top end technology */}
      <SectionFive details={detailsSix} />

      {/* See what Shells can do for you */}
      <SectionSix details={detailsSeven} />
    </>
  );
}
