import React from "react";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/pricingPlans";
const useStyles = makeStyles(styles);

export default function PricingPlansHeroSection() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Box py={4}>
        <Typography
          variant="h1"
          component="h1"
          align="center"
          className={classes.heroTitle}
        >
          {t("pricing_page_title")}
        </Typography>
        <Box py={2} className={classes.heroBox}>
          <Typography
            variant="h2"
            component="h2"
            align="center"
            color="primary"
            className={classes.heroCatchphrase}
          >
            {t("pricing_page_hero_catchphrase")}
          </Typography>
        </Box>
        <Box py={1} className={classes.descriptionBox}>
          <Typography
            variant="h3"
            component="h3"
            className={classes.heroDescription}
            align="center"
          >
            {t("pricing_page_hero_description")}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
