import React from "react";
import { useTranslation, Trans } from "react-i18next";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

import { WrapperContainer } from "components/HOC";
import {
  lttDevices,
  lttShellsLogo,
  shortCircuitShellsLogo
} from "assets/img/ltt";
import gardinerBryantShellsLogo from "assets/img/gardinerbryant/gardiner-bryant-shells.png";
import { dlnShellsLogo } from "assets/img/destination-linux-network";
import { linuxForEveryoneShellsLogo } from "assets/img/linux-for-everyone";
import { sloggingShellsLogo } from "assets/img/slogging";
import { ultiuberDevices, ultiuberShellsLogo } from "assets/img/ultiuber";
import { linuxLadsShellsLogo } from "assets/img/linuxlads";
import { shellsShowLogo } from "assets/img/mike-dominick-show";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/ltt";

const useStyles = makeStyles(styles);

// This component is used for Linus Tech Tips, Short Circuit, WAN (All under LTT brand)
// Also used for Destination Linux Network, Linux For Everyone, Slogging, Ultiuber, Linux Lads, Mike Dominick Show
export default function LTTPricingHero({ page }) {
  const { t } = useTranslation();
  const classes = useStyles();

  let heroText = "ltt_pricing_title";
  let descriptionText = "ltt_pricing_description";
  let devicesImage = (
    <img
      src={lttShellsLogo}
      alt="Linus Tech Tips and Shells logo"
      className={classes.shellsLttLogo}
    />
  );
  let imageAlt = "Linus Tech Tips with Shells";
  let imageSrc = lttDevices;
  let bannerText = "lp_linuxquestionsorg_ribbon";

  switch (page) {
    case "shortCircuit":
      descriptionText = "sc_pricing_description";
      devicesImage = (
        <img
          src={shortCircuitShellsLogo}
          alt="Short Circuit and Shells logo"
          className={classes.shellsLttLogo}
        />
      );
      imageAlt = "Short Circuit with Shells";
      break;
    case "WAN":
      descriptionText = "wan_pricing_description";
      imageAlt = "WAN with Shells";
      break;
    case "dln":
      devicesImage = (
        <img
          src={dlnShellsLogo}
          alt="Destination Linux Network and Shells logo"
          className={`${classes.shellsLttLogo} ${classes.marginLeft}`}
        />
      );
      descriptionText = "dln_pricing_description";
      imageAlt = "Destination Linux Network with Shells";
      break;
    case "linux_for_everyone":
      devicesImage = (
        <img
          src={linuxForEveryoneShellsLogo}
          alt="Linux for Everyone and Shells logo"
          className={classes.linuxForEveryoneLogo}
        />
      );
      descriptionText = "linux_for_everyone_pricing_description";
      imageAlt = "Linux for Everyone with Shells";
      break;
    case "slogging":
      devicesImage = (
        <img
          src={sloggingShellsLogo}
          alt="Slogging and Shells logo"
          className={`${classes.shellsLttLogo} ${classes.margins}`}
        />
      );
      descriptionText = "slogging_pricing_description";
      imageAlt = "Slogging Community with Shells";
      break;
    case "ultiuber":
      devicesImage = (
        <img
          src={ultiuberShellsLogo}
          alt="Ultiuber and Shells logo"
          className={`${classes.shellsLttLogo} ${classes.marginBottom}`}
        />
      );
      imageSrc = ultiuberDevices;
      imageAlt = "Ultiuber with Shells";
      heroText = "ultiuber_pricing_header";
      descriptionText = "ultiuber_pricing_description";
      break;
    case "linuxlads":
      devicesImage = (
        <img
          src={linuxLadsShellsLogo}
          alt="Linux Lads and Shells"
          className={`${classes.shellsLttLogo} ${classes.marginBottom}`}
        />
      );
      imageAlt = "Linux Lads and Shells";
      descriptionText = "linux_lads_subtext";
      break;
    case "mike_dominick_show":
      devicesImage = (
        <img
          src={shellsShowLogo}
          alt="The Mike Dominick Show and Shells"
          className={`${classes.shellsLttLogo} ${classes.marginBottom}`}
        />
      );
      imageAlt = "The Mike Dominick Show and Shells";
      descriptionText = "mike_dominick_show_pricing_description";
      break;
    case "gardinerbryant":
      devicesImage = (
        <img
          src={gardinerBryantShellsLogo}
          alt="Gardiner Bryant and Shells"
          className={`${classes.shellsLttLogo} ${classes.marginBottom}`}
        />
      );
      imageAlt = "Gardiner Bryant and Shells";
      heroText = "gardiner_bryant_pricing_header";
      descriptionText = "gardiner_bryant_subtext";
      break;
    default:
      break;
  }

  return (
    <Paper elevation={0} className={classes.mainFeaturedPost}>
      <WrapperContainer addMaxWidth={false} addlWclass={classes.morePadding}>
        <a href="#pricing" className={classes.mainRibbon}>
          <Trans i18nKey={bannerText} components={{ underline: <span /> }} />
        </a>
        <Grid container alignItems="center" justify="center" spacing={2}>
          <Hidden smUp>
            <Grid item xs={12} sm={6}>
              <img
                src={imageSrc}
                alt={imageAlt}
                className={classes.lttDevicesImage}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6}>
            <div className={classes.headerTextContainer}>
              {devicesImage}
              <Typography
                variant="h1"
                component="h2"
                className={classes.mainHeader}
              >
                {t(heroText)}
              </Typography>
            </div>
            <Box pt={6}>
              <div className={classes.subTextContainer}>
                <Typography
                  variant="h1"
                  component="h2"
                  className={classes.subHeader}
                >
                  {t(descriptionText)}
                </Typography>
              </div>
            </Box>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={6}>
              <img src={imageSrc} alt={imageAlt} style={{ width: "100%" }} />
            </Grid>
          </Hidden>
        </Grid>
      </WrapperContainer>
    </Paper>
  );
}
