import {
  USER_CHANGE,
  USER_FORGET_PWD,
  USER_INFO_CHANGE_ERROR,
  USER_LOGOUT,
  USER_PASSWORD_RESET_DONE,
  USER_PASSWORD_RESET_ERROR,
  USER_PASSWORD_RESETING,
  USER_REGISTER,
  USER_REGISTER_DONE,
  USER_UPDATE
} from "../reducers/UserReducer";

import { initFlow } from "./FlowActions.js";
import { rest } from "@karpeleslab/klbfw";
import {
  catchRedirectException,
  handleError
} from "../../components/utils/apiErrorHandler";

export const changeUser = user => {
  return dispatch => {
    dispatch({ type: USER_CHANGE, user: user });
  };
};

export const logout = () => {
  return (dispatch, getState) => {
    return rest("User/@:logout", "POST")
      .then(catchRedirectException)
      .then(dispatch(initFlow()), dispatch({ type: USER_LOGOUT }))
      .catch(err => {
        handleError(getState, dispatch, err);
      });
  };
};

export const forgetPassword = email => {
  return (dispatch, getState) => {
    dispatch({ type: USER_FORGET_PWD, email: email });
    return rest("User:forgot_password", "POST", { login: email })
      .then(catchRedirectException)
      .catch(err => {
        handleError(getState, dispatch, err);
      });
  };
};

export const resetPassword = (p1, p2, login, key) => {
  return (dispatch, getState) => {
    dispatch({ type: USER_PASSWORD_RESETING });

    const p = {
      login: login,
      key: key,
      p1: p1,
      p2: p2
    };

    return rest("User:recover_password", "POST", p)
      .then(catchRedirectException)
      .then(() => {
        dispatch({ type: USER_PASSWORD_RESET_DONE });
      })
      .catch(e => {
        dispatch({ type: USER_PASSWORD_RESET_ERROR, error: e.message });
        handleError(getState, dispatch, e);
      });
  };
};

export const updateUser = () => {
  return (dispatch, getState) => {
    return rest("User:get", "GET")
      .then(catchRedirectException)
      .then(data => {
        dispatch({ type: USER_UPDATE, data: data.data });
      })
      .catch(err => {
        dispatch({ type: USER_INFO_CHANGE_ERROR, error: err.message });
        handleError(getState, dispatch, err);
      });
  };
};

export const registerAndLog = (
  email,
  password,
  tosAccept,
  displayName = null
) => {
  return (dispatch, getState) => {
    dispatch({ type: USER_REGISTER });

    const data = {
      Email: email,
      Password: password,
      client_id: "WEB",
      i_accept_the_terms_of_service: tosAccept,
      displayName: displayName
    };

    return rest("User:register", "POST", data)
      .then(catchRedirectException)
      .then(() =>
        rest("User:login", "POST", { login: email, password: password })
      )
      .then(d => {
        dispatch({ type: USER_REGISTER_DONE, user: d.data.user });
        return d.data.user;
      })
      .catch(err => {
        dispatch({ type: USER_REGISTER_DONE });
        handleError(getState, dispatch, err);
      });
  };
};
