import { lightGrayColor } from "assets/jss/material-kit-react";

const downloadPageStyle = theme => ({
  root: {
    margin: "0",
    boxSizing: "borderBox",
    textAlign: "center"
  },
  main: {
    backgroundColor: lightGrayColor,
    padding: "25px 36px",
    marginBottom: "25px"
  },
  mainHeaderOne: {
    fontSize: "52px",
    fontWeight: "900",
    color: "#1d1d1f",
    letterSpacing: ".009em",
    lineHeight: 1.3,
    fontFamily: "Open Sans, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "42.5984px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "52px"
    }
  },
  mainHeaderTwo: {
    fontSize: "22px",
    fontFamily: "Open Sans, sans-serif",
    lineHeight: "1.6",
    color: "#1d1d1f",
    letterSpacing: ".009em",
    marginTop: "40px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  allDevicesImage: {
    width: "300px",
    [theme.breakpoints.up("md")]: {
      width: "500px"
    }
  },
  badgeImage: {
    cursor: "pointer",
    width: "175px",
    marginRight: "15px",
    [theme.breakpoints.down("xs")]: {
      margin: "0 0 15px 0"
    }
  },
  bodyHeader: {
    marginBottom: "25px"
  },
  appContainer: {
    margin: "25px 0 25px 0",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: "0 0 25px 0"
    }
  },
  downloadContainer: {
    marginTop: "25px",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    }
  }
});

export default downloadPageStyle;
