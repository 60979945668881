import React, { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { rest } from "@karpeleslab/klbfw";

import Box from "@material-ui/core/Box";
import Helmet from "components/instances/instance/Helmet/Helmet";
import PlansCarousel from "./../../components/PlansCarousel/PlansCarousel";
import PricingPlansHeroSection from "./PricingPlansHeroSection";
import BuildYourOwnShellLink from "./BuildYourOwnShellLink";
import { pricingPlanSections } from "./util";
import sendGtagEvent from "./../../components/utils/sendGtagEvent";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/pricingPlans";

const useStyles = makeStyles(styles);

const PricingPlans = ({ withLitePlan }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();

  const [specialOffer, setSpecialOffer] = useState(null);

  useLayoutEffect(() => {
    const params = new URLSearchParams(location.search);

    switch (params.get("special")) {
      case "manjaro":
        rest("Affiliate:setCode", "POST", { code: "manjaro" });
        setSpecialOffer("manjaro");
        break;
      case "manjaro_deal":
        setSpecialOffer("manjaro_deal");
        break;
      case "kde":
        rest("Affiliate:setCode", "POST", { code: "kdeorg" });
        setSpecialOffer("kde");
        break;
      case "kde_conference":
        setSpecialOffer("kde_conference");
        break;
      case "linux_mint":
        rest("Affiliate:setCode", "POST", { code: "linuxmint" });
        setSpecialOffer("linux_mint");
        break;
      case "linustechtips":
      case "shortcircuit":
      case "WAN":
        rest("Affiliate:setCode", "POST", { code: "linustechtips" });
        setSpecialOffer(params.get("special"));
        break;
      case "opensuse":
        rest("Affiliate:setCode", "POST", { code: "opensuse" });
        setSpecialOffer("opensuse");
        break;
      case "opensuse_conference":
        setSpecialOffer("opensuse_conference");
        break;
      case "windows":
        setSpecialOffer("windows");
        break;
      case "destination_linux_network":
        setSpecialOffer("destination_linux_network");
        break;
      case "linux_for_everyone":
        setSpecialOffer("linux_for_everyone");
        break;
      case "slogging":
        setSpecialOffer("slogging");
        break;
      case "mx_linux":
        rest("Affiliate:setCode", "POST", { code: "MXLinux" });
        setSpecialOffer("mx_linux");
        break;
      case "ultiuber":
        rest("Affiliate:setCode", "POST", { code: "f49A2k" });
        setSpecialOffer("ultiuber");
        break;
      case "linuxlads":
        setSpecialOffer("linuxlads");
        break;
      case "slickdeals":
        setSpecialOffer("slickdeals");
        break;
      case "mike_dominick_show":
        setSpecialOffer("mike_dominick_show");
        break;
      case "gardinerbryant":
        rest("Affiliate:setCode", "POST", { code: "gardiner" });
        setSpecialOffer("gardinerbryant");
        break;
      default:
        sendGtagEvent(
          `view_pricing_${withLitePlan ? "regular" : "without_lite"}`,
          {
            event_category: "ab_testing_pricing",
            event_label: `View page 'Pricing', version ${
              withLitePlan
                ? "A (with Lite plan included)"
                : "B (without Lite plan)"
            }`
          }
        );
        break;
    }
  }, [location]); // eslint-disable-line

  const pricingPageSections = {
    title: "pricing_page_title",
    heroSection: <PricingPlansHeroSection />,
    carousel: !withLitePlan ? (
      <PlansCarousel
        showOnly={["basic", "plus", "pro"]}
        options={{
          basic: {
            disclaimer: "as_low_as",
            usageLimit: "unlimited_use"
          },
          plus: {
            disclaimer: "as_low_as",
            usageLimit: "unlimited_use"
          },
          pro: {
            disclaimer: "as_low_as",
            usageLimit: "unlimited_use"
          }
        }}
      />
    ) : (
      <PlansCarousel
        showOnly={["lite", "basic", "plus", "pro"]}
        options={{
          lite: {
            disclaimer: null,
            usageLimit: null
          },
          basic: {
            disclaimer: "as_low_as",
            usageLimit: "unlimited_use"
          },
          plus: {
            disclaimer: "as_low_as",
            usageLimit: "unlimited_use"
          },
          pro: {
            disclaimer: "as_low_as",
            usageLimit: "unlimited_use"
          }
        }}
      />
    ),
    regularPlansRedirect: <BuildYourOwnShellLink />,
    dealPricingText: null,
    disclaimer: null,
    metaContent: "pricing_page_hero_catchphrase"
  };

  const {
    title,
    heroSection,
    carousel,
    regularPlansRedirect,
    dealPricingText,
    disclaimer,
    metaContent
  } = pricingPlanSections(specialOffer, setSpecialOffer, pricingPageSections);

  return (
    <>
      <Helmet title={t(title)}>
        <meta name="description" content={t(metaContent)} />
      </Helmet>
      {heroSection}
      {dealPricingText}
      <Box pb={16} className={classes.overflowHidden}>
        {carousel}
      </Box>
      {regularPlansRedirect}
      {disclaimer}
    </>
  );
};

export default PricingPlans;
