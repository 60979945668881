import { rest } from "@karpeleslab/klbfw";

import {
  ORDER_PROCESS,
  ORDER_PROCESS_DONE,
  ORDER_PROCESS_ERROR,
  SYNC_ORDER,
  SYNC_ORDER_DONE,
  SYNC_ORDER_ERROR
} from "../reducers/DefaultOrderReducer";
import { handleError } from "../../components/utils/apiErrorHandler";

export const orderFetch = slug => dispatch => {
  dispatch({ type: SYNC_ORDER });
  return rest("Order/" + slug.orderId + ":process", "GET")
    .then(data => {
      dispatch({ type: SYNC_ORDER_DONE, order: data });
      return data.data;
    })
    .catch(data => {
      dispatch({
        type: SYNC_ORDER_ERROR,
        error: data.message
      });
      handleError(null, null, data);
      throw data;
    });
};

export const orderFreeProcess = (method, session, orderId, data = {}) => {
  return dispatch => {
    dispatch({ type: ORDER_PROCESS });

    return rest(`Order/${orderId}:process`, "POST", {
      method: method,
      session: session,
      ...data
    })
      .then(data => {
        dispatch({ type: ORDER_PROCESS_DONE });
        return data;
      })
      .catch(data => {
        dispatch({
          type: ORDER_PROCESS_ERROR,
          error: data.message
        });
        handleError(null, null, data);
        throw data;
      });
  };
};

export const orderProcess = (
  method,
  session,
  cc_token = null,
  orderId,
  params = {}
) => {
  return dispatch => {
    dispatch({ type: ORDER_PROCESS });
    const args = { method, session };

    if (cc_token && cc_token.token) args.cc_token = cc_token.token.id;

    return rest(`Order/${orderId}:process`, "POST", {
      ...args,
      ...params
    })
      .then(data => {
        dispatch({ type: ORDER_PROCESS_DONE });
        return data;
      })
      .catch(data => {
        dispatch({
          type: ORDER_PROCESS_ERROR,
          error: data.message
        });
        handleError(null, null, data);
        throw data;
      });
  };
};
