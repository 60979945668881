import { applyMiddleware, createStore as reduxCreateStore } from "redux";
import rootReducer from "./reducers/rootReducer";
import thunk from "redux-thunk";

export default function createStore(state) {
  return reduxCreateStore(rootReducer, state, applyMiddleware(thunk));
}

export let store = null;
export function getStore() {
  return store;
}
export function setAsCurrentStore(s) {
  store = s;
  if (process.env.NODE_ENV !== "production" && typeof window !== "undefined") {
    window.store = store;
  }
}
