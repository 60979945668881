import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCookie } from "@karpeleslab/klbfw";
// component
import CartListOrder from "../../components/Cart/CartListOrder";
import Helmet from "../../components/instances/instance/Helmet/Helmet";
import PaymentTabs from "./PaymentTabs";
import { error, error as errorToast } from "../../store/actions/ToastAction";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "./../../components/CustomButtons/Button";
// actions
import {
  orderFetch,
  orderFreeProcess,
  orderProcess
} from "store/actions/DefaultOrderAction";
import sendGtagEvent from "./../../components/utils/sendGtagEvent";
// style
import classNames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import typostyles from "assets/jss/material-kit-react/components/typographyStyle.js";

const useStyles = makeStyles(styles);
const useTypoStyles = makeStyles(typostyles);

const Order = props => {
  const classes = useStyles();
  const history = useHistory();
  const slug = useParams();
  const { t } = useTranslation();
  const typoClasses = useTypoStyles();
  const [shellId, setShellId] = useState(); // If the order contained a shell id it will be stored here (the first one found)

  const [continueEnabled, setContinueEnabled] = useState(false);
  const {
    orderFetch,
    orderProcess,
    orderFreeProcess,
    stripeStatus,
    order,
    processingOrder
  } = props;
  const pricingTestCookie = getCookie("pricing_test");

  useEffect(() => {
    orderFetch(slug).then(order => {
      const items = order.order.Items;
      for (let i = 0; i < items.length; i++) {
        if (items[i].Catalog_Product["Meta.shell"]) {
          setShellId(items[i].Catalog_Product["Meta.shell"]);
          return;
        }
      }
    });
  }, [slug]);// eslint-disable-line
  useEffect(() => {
    if (!stripeStatus.complete) {
      setContinueEnabled(false);
      return;
    }

    setContinueEnabled(true);
  }, [stripeStatus]);

  const [done, setDone] = useState(false);
  useEffect(() => {
    if (
      !order ||
      !order.order ||
      !order.order.data ||
      order.order.data.order_payable
    ) {
      setDone(false);
      return;
    }
    if (
      order.order.data.order &&
      ["pending-paid", "completed", "cancelled", "forcedcollection"].includes(
        order.order.data.order.Status
      )
    )
      setDone(true);
  }, [processingOrder]); // eslint-disable-line

  const errorNotObject = error => {
    const responseType = typeof error;

    if (responseType !== "object") errorToast(t("order_error_not_json"));
  };

  const submitFreeHandler = (m = "Free", data = {}) => {
    const method = order.order.data.methods[m].method;
    const session = order.order.data.methods[m].session;
    const orderId = order.order.data.order.Order__;

    orderFreeProcess(method, session, orderId, data)
      .then(() => history.push("/order_completed_item"))
      .catch(errorNotObject);
  };

  const submitStripeHandler = async (
    stripe,
    stripeElements,
    clientSecret = null
  ) => {
    if (!stripe || !stripeElements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const location = order.order.data.order.Billing_User_Location;
    const user = order.order.data.order.User;
    const orderId = order.order.data.order.Order__;
    const method = order.order.data.methods.Stripe.method;
    const session = order.order.data.methods.Stripe.session;

    setContinueEnabled(false);

    if (clientSecret) {
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            // we are being redirected to this page
            break;
          case "processing":
            error(t("payment_processing"));
            setContinueEnabled(false);
            return;
          case "requires_payment_method":
            error(t("requires_payment_method"));
            setContinueEnabled(false);
            return;
          default:
            error(t("unexpected_error"));
            setContinueEnabled(false);
            return;
        }
      });
    } else {
      const result = await stripe.confirmPayment({
        elements: stripeElements,
        redirect: "if_required",
        confirmParams: {
          //setup_future_usage: ccRemember ? 'off_session' : undefined,
          payment_method_data: {
            billing_details: {
              name: `${location.First_Name} ${location.Last_Name}`,
              email: user.Email,
              address: {
                country: location.Country__,
                postal_code: location.Zip,
                state: location.Province ?? "",
                city: location.City ?? "",
                line1: location.Address ?? "",
                line2: location.Address2 ?? ""
              }
            }
          }, // Make sure to change this to your payment completion page
          return_url: `https://${window.location.host}/order/${orderId}`
        }
      });

      if (result.error) {
        if (
          result.error.type === "card_error" ||
          result.error.type === "validation_error"
        ) {
          errorToast(result.error.message);
        } else {
          errorToast(t("unexpected_error"));
        }

        setContinueEnabled(true);
        return;
      }
    }
    setContinueEnabled(true);

    orderProcess(method, session, null, orderId, { stripe_intent: 1 })
      .then(() => {
        const items = order.order.Items;
        if (
          items &&
          items.length >= 1 &&
          items[0].Catalog_Product &&
          items[0].Catalog_Product["Description.Type"] === "shells_plan"
        ) {
          const fromWhichVersion =
            pricingTestCookie === "pricing_alt" ? "without_lite" : "regular";
          const labelDetails =
            pricingTestCookie === "pricing_alt"
              ? "they were shown the Pricing version B (without Lite plan) page."
              : "they were shown the Pricing version A (with Lite plan included) page.";
          if (items.length === 1) {
            sendGtagEvent(
              `checkout_plan_${fromWhichVersion}__${
                items[0].Catalog_Product["Description.AuthorCode"]
              }_${items[0].Catalog_Product["Basic.ServiceLifetime"]}`,
              {
                event_category: "ab_testing_pricing",
                event_label: `Visitor completed the checkout process for ${
                  items[0].Catalog_Product["Description.AuthorCode"]
                } plan (${
                  items[0].Catalog_Product["Basic.ServiceLifetime"]
                }); ${labelDetails}`
              }
            );
          } else {
            sendGtagEvent(`checkout_plan_${fromWhichVersion}__multiple`, {
              event_category: "ab_testing_pricing",
              event_label: `Visitor completed the checkout process for multiple plans; ${labelDetails}`
            });
          }
        }
      })
      .then(() => history.push("/order_completed_item"))
      .catch(errorNotObject);
  };

  return (
    <div className={classes.genericPageWrapper}>
      <Helmet title={t("order_title")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classNames(classes.container)}>
          {done ? (
            <>
              <Typography
                variant="h2"
                component="h2"
                className={typoClasses.titleSection}
              >
                {t("order_completed_title")}
              </Typography>
              <Typography> {t("order_completed_p")}</Typography>
              <Grid container spacing={4} justify="center" alignItems="center">
                {order.order.data.order &&
                  order.order.data.order.Invoice_Status === "completed" &&
                  order.order.data.order.Invoice_Url && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        target="_blank"
                        href={order.order.data.order.Invoice_Url}
                      >
                        {t("order_download_invoice_pdf")}
                      </Button>
                    </Grid>
                  )}
                {order.order.data.order && shellId && (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      target="_blank"
                      href={`https://view.shells.com/?id=${shellId}`}
                    >
                      {t("order_go_to_shell")}
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    component=""
                    variant="contained"
                    href={process.env.REACT_APP_SHELL_CONSOLE_URL}
                  >
                    {t("go_to_my_account")}
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <h2 className={typoClasses.titleSection}>{t("order_title")}</h2>
              <CartListOrder />
              {order &&
                order.order &&
                order.order.data &&
                order.order.data.order.Status !== "completed" && (
                  <PaymentTabs
                    methods={order.order.data.methods}
                    methodsOrder={order.order.data.methods_order}
                    submitStripeHandler={submitStripeHandler}
                    submitFreeHandler={submitFreeHandler}
                    continueEnabled={continueEnabled}
                    order={order}
                  />
                )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  order: state.defaultOrder,
  processingOrder: state.defaultOrder.orderProcess,
  stripeStatus: state.stripe.status
});

const mapDispatchToProps = dispatch => ({
  orderFetch: slug => dispatch(orderFetch(slug)),
  orderFreeProcess: (method, session, orderId, data = {}) =>
    dispatch(orderFreeProcess(method, session, orderId, data)),
  orderProcess: (method, session, cc_token, orderId, params = {}) =>
    dispatch(orderProcess(method, session, cc_token, orderId, params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Order));
