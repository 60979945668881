import React, { useState } from "react";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Button from "../../components/CustomButtons/ShellLoadingButton";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { addItem } from "store/actions/CartAction";

import useStyles from "./RedeemCodeForm.style";

const RedeemCodeForm = ({ addItem }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [code, setCode] = useState("");
  const [showError, setShowError] = useState(false);
  const [processing, setProcessing] = useState(false);

  const submitCode = e => {
    e.preventDefault();
    setProcessing(true);
    setShowError(false);
    addItem(`coupon:${code}`)
      .then(() => {
        history.push("/cart");
      })
      .catch(() => {
        setProcessing(false);
        setShowError(true);
      });
  };

  return (
    <Box py={4} component="form" onSubmit={submitCode} className={classes.form}>
      <Grid
        container
        spacing={4}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="body1" component="p" style={{ color: "#000" }}>
            {t("redeem_code_description")}
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            id="redeem-code"
            label={t("redeem_code")}
            required
            variant="outlined"
            className={classes.inputField}
            value={code}
            onChange={e => setCode(e.target.value)}
            disabled={processing}
          />
        </Grid>
        <Grid item>
          {showError ? (
            <Box p={2} className={classes.errorBox}>
              <Trans
                i18nKey="redeem_code_invalid"
                components={{
                  contactLink: <Link to="/contact" />
                }}
              />
            </Box>
          ) : (
            <Box p={2} className={classes.disclaimerBox}>
              <Trans
                i18nKey="redeem_code_disclaimer"
                components={{
                  underline: <span className={classes.underlinedText} />
                }}
              />
            </Box>
          )}
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            type="submit"
            loading={processing}
          >
            {t("submit")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapDispatchToProps = dispatch => ({
  addItem: productId => dispatch(addItem(productId))
});

export default connect(
  null,
  mapDispatchToProps
)(RedeemCodeForm);
