const initState = {
  loading: false,
  countries: []
};

export const COUNTRIES_FETCH = "COUNTRIES_FETCH";
export const COUNTRIES_FETCH_DONE = "COUNTRIES_FETCH_DONE";

const CountriesReducer = (state = initState, action) => {
  switch (action.type) {
    case COUNTRIES_FETCH:
      return { ...state, loading: true };
    case COUNTRIES_FETCH_DONE:
      return { ...state, loading: false, countries: action.countries };
    default:
      return state;
  }
};

export default CountriesReducer;
