const initState = {
  loading: true,
  selected: null,
  unit_cpu: 0.25,
  unit_ram: 512,
  unit_hdd: 10,
  nbUnitSelected: 8,
  plans: [],
  carouselPlans: {}
};

export const PLANS_FETCH = "PLANS_FETCH";
export const PLANS_FETCH_DONE = "PLANS_FETCH_DONE";

export const PLAN_SELECT = "PLAN_SELECT";
export const PLAN_UNIT_SELECT = "PLAN_UNIT_SELECT";

const generateCarouselPlans = plans => {
  const yearly = {};
  const monthly = {};
  const result = [];

  plans.forEach(currentPlan => {
    // think of currentPlan as a memory location because JS objects work as references
    const isYearly = currentPlan["Basic.ServiceLifetime"] === "1y";
    const planType = currentPlan["Description.AuthorCode"];

    if (planType === "custom") return;

    if (isYearly) {
      // if its an yearly plan, push to result array
      result.push(currentPlan);

      // should have only ONE planType for the yearly period
      if (!(planType in yearly)) {
        // if the planType is NOT in the yearly object, put in the yearly object
        yearly[planType] = currentPlan;

        if (planType in monthly) {
          // if the planType is also in the monthly object, update the plan object to include the monthly data
          currentPlan["Monthly"] = monthly[planType];
        }
      }
    } else {
      // for monthly plans
      if (planType in yearly) {
        // if the monthly planType is also in the yearly object, link them both
        yearly[planType]["Monthly"] = currentPlan;
      } else {
        // if not, add the data to the monthly object
        monthly[planType] = currentPlan;
      }
    }
  });

  return yearly;
};

const PlanReducer = (state = initState, action) => {
  switch (action.type) {
    case PLANS_FETCH:
      return { ...state, loading: true };
    case PLANS_FETCH_DONE:
      return {
        ...state,
        loading: false,
        plans: action.plans,
        carouselPlans: generateCarouselPlans(action.plans)
      };
    case PLAN_SELECT:
      return { ...state, selected: action.plan };
    case PLAN_UNIT_SELECT:
      return { ...state, nbUnitSelected: action.nbUnitSelected };
    default:
      return state;
  }
};

export default PlanReducer;
