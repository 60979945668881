import React from "react";
import moment from "moment";

const DateUtil = ({ klbDateObj, format = "YYYY-MM-DD HH:mm:ss" }) => {
  let unixTime;

  if (!klbDateObj || typeof klbDateObj !== "object") return "N/A";
  if (klbDateObj && klbDateObj.unixms) unixTime = parseInt(klbDateObj.unixms);
  else if (klbDateObj && klbDateObj.unix)
    unixTime = parseInt(klbDateObj.unix) * 1000;

  return <>{unixTime ? moment(unixTime).format(format) : "N/A"}</>;
};

export default DateUtil;
