import { USER_LOGOUT } from "./UserReducer";

const initState = {
  creating: false,
  selected: null
};

export const SHELL_CREATE = "SHELL_CREATE";
export const SHELL_CREATE_DONE = "SHELL_CREATE_DONE";

export const SHELL_SELECT = "SHELL_SELECT";

const shellReducer = (state = initState, action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return { ...initState };
    case SHELL_CREATE:
      return { ...state, creating: true };
    case SHELL_CREATE_DONE:
      return { ...state, creating: false };
    case SHELL_SELECT:
      return { ...state, selected: action.shell };

    default:
      return state;
  }
};

export default shellReducer;
