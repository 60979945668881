import React from "react";
import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/debian";

import { WrapperContainer } from "components/HOC";
import { freedom, world, stability, everywhere } from "assets/img/debian";

const useStyles = makeStyles(styles);

export default function SectionOne() {
  const { t } = useTranslation();
  const classes = useStyles();

  const sectionOneData = [
    {
      img: freedom,
      title: t("debian_s1_title1"),
      body: t("debian_s1_body1")
    },
    {
      img: world,
      title: t("debian_s1_title2"),
      body: t("debian_s1_body2")
    },
    {
      img: stability,
      title: t("debian_s1_title3"),
      body: t("debian_s1_body3")
    },
    {
      img: everywhere,
      title: t("debian_s1_title4"),
      body: t("debian_s1_body4")
    }
  ];

  return (
    <WrapperContainer addMaxWidth={true}>
      <Grid container alignItems="center" justify="center" spacing={4}>
        {sectionOneData.map((data, index) => {
          const { img, title, body } = data;

          return (
            <Grid
              item
              xs={12}
              sm={6}
              key={index}
              className={classes.marginBottom}
            >
              <Grid
                container
                alignItems="center"
                justify="center"
                direction={index % 2 === 0 ? "row" : "row-reverse"}
                spacing={2}
                className={classes.gridContainer}
              >
                <Grid item xs={12} sm={6}>
                  <img src={img} alt="" className={classes.sectionOneImage} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.sectionOneHeaderParent}>
                    <Typography
                      variant="h4"
                      component="h3"
                      className={
                        index % 2 === 0
                          ? `${classes.sectionOneHeaderPosRight} ${classes.sectionOneHeader}`
                          : `${classes.sectionOneHeaderPosLeft} ${classes.sectionOneHeader}`
                      }
                      align={index % 2 !== 0 ? "left" : "right"}
                    >
                      {title}
                    </Typography>
                  </div>
                  <div className={classes.greenLine} />
                  <div className={classes.bodyContainer}>
                    <Typography
                      variant="body1"
                      className={classes.sectionOneBody}
                      align={index % 2 !== 0 ? "left" : "right"}
                    >
                      {body}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </WrapperContainer>
  );
}
