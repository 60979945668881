const articleCardStyle = theme => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  go: {
    marginLeft: "auto",
    color: "#fff"
  },
  headerRoot: {
    height: "auto",
    minHeight: "135px",
    [theme.breakpoints.up("md")]: {
      minHeight: "125px"
    }
  },
  icon: {
    padding: "5px 10px 5px 10px"
  },
  media: {
    height: 0,
    paddingTop: "88.25%"
  },
  subheader: {
    fontSize: ".8rem"
  },
  title: {
    fontSize: "1.15rem"
  },
  cardContent: {
    [theme.breakpoints.up("sm")]: {
      minHeight: "150px"
    }
  },
  cardActionArea: {
    "&:hover": {
      color: "#000"
    }
  }
});

export default articleCardStyle;
