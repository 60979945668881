import React from "react";
import { Get } from "@karpeleslab/klbfw";

const Text = () => {
  return (
    <textarea
      id="mail-text"
      defaultValue={`Greetings ${Get("_user").Profile.Display_Name}, \n\r \n\r 
        The code to validate your Shell's reinstall is: ${Get(
          "_code"
        )}. \n\r \n\r
        Please enter that code in the setup wizard to complete the reinstallation.`}
    />
  );
};

export default Text;
