import React from "react";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/whyShells";

const useStyles = makeStyles(styles);

export default function TopToBottom({ details }) {
  const {
    header,
    description,
    addlDescription,
    img,
    imgAlt,
    imgClass
  } = details;
  const classes = useStyles();

  let addlText;
  if (addlDescription) {
    addlText = (
      <>
        <br />
        <br />
        <Typography variant="p" className={classes.description}>
          {addlDescription}
        </Typography>
      </>
    );
  } else {
    addlText = null;
  }

  return (
    <div className={classes.container}>
      <Typography variant="h3" className={classes.headers}>
        {header}
      </Typography>

      <img src={img} alt={imgAlt} className={imgClass} />

      <Typography variant="p" className={classes.description}>
        {description}
      </Typography>
      {addlText}
    </div>
  );
}
