import { flushGet, Get } from "@karpeleslab/klbfw";

const initState = () => {
  return {
    complete: false,
    email: null,
    fields: [],
    message: [],
    req: [],
    session: null,
    loading: true,
    error: null,
    user: null,
    Redirect: null,
    url: null,
    uploading: false,
    initial: true,
    uploadingDone: false
  };
};

export const FLOW_FETCH = "FLOW_FETCH";
export const FLOW_DONE = "FLOW_DONE";
export const FLOW_ERROR = "FLOW_ERROR";
export const FLOW_UPLOADING = "FLOW_UPLOADING";
export const FLOW_UPLOADING_DONE = "FLOW_UPLOADING_DONE";
export const FLOW_INIT = "FLOW_INIT";

const flowReducer = (state = initState(), action) => {
  let s;
  switch (action.type) {
    case FLOW_FETCH:
      return { ...state, loading: true, error: null };
    case FLOW_DONE:
      return { ...state, loading: false, ...action.data, error: null };
    case FLOW_ERROR:
      return { ...state, loading: false, error: action.error };
    case FLOW_UPLOADING:
      return { ...state, uploading: true };
    case FLOW_UPLOADING_DONE:
      return { ...state, uploading: false, uploadingDone: true };
    case FLOW_INIT:
      s = { ...initState() };
      s.session = Get("session") ?? null;
      flushGet();
      return s;
    default:
      return state;
  }
};

export default flowReducer;
