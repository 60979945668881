import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { WrapperContainer } from "components/HOC";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/os";

const useStyles = makeStyles(styles);

export default function SectionTwo() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <WrapperContainer addMaxWidth={true} addlWclass={classes.wrapperClassS2}>
      <Box pb={6} className={classes.heroTextContainer}>
        <Typography variant="h3" className={classes.subHeader}>
          {t("os_bottom_msg")}
        </Typography>
      </Box>

      <Button
        color="primary"
        variant="contained"
        className={classes.beautifulJoinButton}
        component={Link}
        to={"/pricing"}
      >
        {t("os_get_button")}
      </Button>
      <Box pt={1}>
        <Typography
          component="h2"
          variant="body1"
          className={classes.moneyBackText}
        >
          {t("new_hp_subheader_7day")}
        </Typography>
      </Box>
    </WrapperContainer>
  );
}
