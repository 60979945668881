import React from "react";

import { WrapperContainer } from "components/HOC";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/material-kit-react/views/whyShells";

const useStyles = makeStyles(styles);

export default function SectionOne({ details }) {
  const { headerOne, headerTwo, subHeader, addlWclass } = details;
  const classes = useStyles();

  return (
    <WrapperContainer addMaxWidth={true} addlWclass={addlWclass}>
      <div className={classes.textContainer2}>
        <Typography
          variant="h5"
          className={`${classes.fontOrange} ${classes.tinyHeader}`}
          gutterBottom
        >
          {headerOne}
        </Typography>
        <Typography variant="h3" className={classes.sectionOneHeader}>
          {headerTwo}
        </Typography>
        <Typography variant="h5" className={classes.description}>
          {subHeader}
        </Typography>
      </div>
    </WrapperContainer>
  );
}
