import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation, Trans } from "react-i18next";

import { WrapperContainer } from "components/HOC";

import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/material-kit-react/views/whyShells";

const useStyles = makeStyles(styles);

export default function Header({
  details,
  largerTextBlock = false,
  prioritizeButton = false,
  specialButton = null,
  heroRibbon = false
}) {
  const { t } = useTranslation();
  const {
    header,
    description,
    image,
    imageClass,
    addlWclass,
    heroClass,
    whiteFont,
    show7Day
  } = details;
  const classes = useStyles();

  return (
    <Paper
      className={heroClass ? heroClass : classes.mainFeaturedPost}
      square
      elevation={0}
    >
      <div className={classes.overlay} />
      {heroRibbon && (
        <a href="#pricing-plans" className={classes.mainRibbon}>
          <Trans
            i18nKey="lp_linuxquestionsorg_ribbon"
            components={{ underline: <span /> }}
          />
        </a>
      )}
      <WrapperContainer addlWclass={addlWclass} addMaxWidth={true}>
        <div className={classes.contentContainer}>
          <div
            className={
              largerTextBlock
                ? classes.largerTextAndButton
                : classes.textAndButton
            }
          >
            <div className={classes.mainFeaturedPostContent}>
              <div className={classes.heroHeaderContainer}>
                <Typography
                  variant="h1"
                  className={
                    whiteFont
                      ? `${classes.mainHeader} ${whiteFont}`
                      : classes.mainHeader
                  }
                >
                  {header}
                </Typography>
              </div>
              <Typography
                variant="h5"
                className={
                  whiteFont
                    ? `${classes.description} ${whiteFont}`
                    : classes.description
                }
              >
                {description}
              </Typography>
            </div>
            {!prioritizeButton && (
              <Hidden mdUp>
                <img
                  src={image}
                  alt=""
                  className={imageClass ? imageClass : classes.image1}
                />
                <div className={classes.marginBottom} />
              </Hidden>
            )}
            {specialButton || (
              <Button
                variant="contained"
                disableElevation
                className={`${classes.signUpButton} ${classes.zeroMarginLeft}`}
                component={Link}
                to="/pricing"
              >
                {t("whyshells_button")}
              </Button>
            )}
            {show7Day && (
              <Typography
                component="h2"
                variant="body1"
                className={classes.moneyBackText}
              >
                {t("new_hp_subheader_7day")}
              </Typography>
            )}
          </div>
          {prioritizeButton ? (
            <>
              <div className={classes.marginBottom} />
              <img
                src={image}
                alt=""
                className={imageClass ? imageClass : classes.image1}
              />
            </>
          ) : (
            <Hidden smDown>
              <img
                src={image}
                alt=""
                className={imageClass ? imageClass : classes.image1}
              />
            </Hidden>
          )}
        </div>
      </WrapperContainer>
    </Paper>
  );
}
