import {
  mainGreenColor,
  secondaryGreenColor,
  whiteColor,
  lightBlueColor,
  bgDarkBlueColor,
  orangeColor,
  blackColor
} from "assets/jss/material-kit-react";
import { img10, img11, img12 } from "assets/img/whyshells";

const whyShellsStyle = theme => ({
  breakpoints: {
    values: {
      xs: 320,
      sm: 414
    }
  },
  mainHeader: {
    fontWeight: "900",
    fontFamily: "Open Sans",
    marginBottom: "65px",
    color: "#042E0F",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "42.5984px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "52px"
    }
  },
  mainFeaturedPost: {
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${img11})`
    },
    [theme.breakpoints.down("lg")]: {
      backgroundImage: `url(${img12})`
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${img10})`
    }
  },
  mainFeaturedPostContent: {
    textAlign: "left",
    marginBottom: "45px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  contentContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      alignItems: "center",
      width: "100vw"
    }
  },
  // used in side to side component
  stsHeaders: {
    fontWeight: "bold",
    fontFamily: "IBM Plex Sans",
    marginBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "26px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "28px"
    }
  },
  // used in side to side component
  stsDescription: {
    fontSize: "20px",
    lineHeight: "1.6",
    fontFamily: "IBM Plex Sans",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  headers: {
    fontWeight: "900",
    fontFamily: "Open Sans",
    marginBottom: "25px",
    color: "#042E0F",
    lineHeight: 1.5,
    fontSize: "24px"
  },
  sectionOneHeader: {
    fontWeight: "900",
    fontFamily: "Open Sans",
    marginBottom: "25px",
    color: "#042E0F",
    lineHeight: 1.5,
    fontSize: "32px"
  },
  headerNextLine: {
    fontWeight: "900",
    fontFamily: "Open Sans",
    color: "#042E0F",
    lineHeight: 1.5,
    fontSize: "24px"
  },
  headerContainer: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "300px",
      margin: "0 auto"
    }
  },
  headerContainer2: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "300px",
      margin: "0 auto",
      textAlign: "center"
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "300px",
      margin: "0 auto",
      textAlign: "left",
      marginLeft: 0
    }
  },
  heroHeaderContainer: {
    minWidth: "430px",
    [theme.breakpoints.down("sm")]: {
      minWidth: 0
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "430px"
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "365px"
    }
  },
  fontOrange: {
    color: `${orangeColor} !important`
  },
  tinyHeader: {
    fontSize: "16px",
    fontWeight: "bold"
  },
  description: {
    fontSize: "20px",
    lineHeight: "1.6",
    fontFamily: "Roboto",
    color: "#042E0F",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  description2: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    },
    fontSize: "16px",
    lineHeight: "1.6",
    fontFamily: "Roboto",
    textAlign: "justify",
    color: "#042E0F"
  },
  description3: {
    fontSize: "16px",
    lineHeight: "1.6",
    fontFamily: "Roboto",
    color: "#042E0F"
  },
  descriptionContainer: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "350px",
      margin: "0 auto"
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "375px",
      margin: "0 auto"
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "450px"
    }
  },
  gridDescriptionContainer: {
    [theme.breakpoints.down("sm")]: {
      minHeight: 0
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "85px"
    }
  },
  link: {
    color: mainGreenColor,
    "&:hover": {
      textDecoration: "underline",
      color: mainGreenColor
    }
  },
  signUpButton: {
    margin: "35px 0 40px 0",
    padding: "6px 24px",
    backgroundColor: mainGreenColor,
    color: whiteColor,
    display: "block",
    borderRadius: "35px",
    fontSize: "22px",
    textAlign: "center",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover": {
      boxShadow: `0 0 0 2px ${secondaryGreenColor} inset`,
      background: whiteColor,
      color: secondaryGreenColor
    },
    [theme.breakpoints.down("sm")]: {
      width: "250px",
      margin: "0 auto",
      fontSize: "16px",
      padding: "6px 14px"
    },
    [theme.breakpoints.up("md")]: {
      width: "315px",
      margin: "0 auto"
    }
  },
  moreButtonStyle: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "75px"
  },
  image: {
    display: "block",
    margin: "0 auto",
    marginBottom: "25px",
    [theme.breakpoints.up("xs")]: {
      width: "230px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "235px"
    },
    [theme.breakpoints.up("md")]: {
      width: "290px"
    }
  },
  secureImage: {
    display: "block",
    margin: "0 auto",
    marginBottom: "25px",
    [theme.breakpoints.up("xs")]: {
      width: "245px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "260px"
    },
    [theme.breakpoints.up("md")]: {
      width: "345px"
    }
  },
  horizontalFirst: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "25px"
    }
  },
  image1: {
    [theme.breakpoints.down("sm")]: {
      width: "175%"
    },
    [theme.breakpoints.up("md")]: {
      width: "120%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      marginLeft: "45px"
    }
  },
  newHPleftImage: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "25px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%"
      // marginLeft: "-195px",
      // marginRight: "45px"
    },
    [theme.breakpoints.up("md")]: {
      width: "70%",
      marginLeft: "-325px",
      marginRight: "45px"
    },
    "& img": {
      width: "100%"
    }
  },
  leftImage: {
    [theme.breakpoints.down("sm")]: {
      width: "150%",
      marginLeft: "-220px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%",
      marginLeft: "-5px"
      // marginRight: "45px"
    },
    [theme.breakpoints.up("md")]: {
      width: "70%",
      marginLeft: "-325px"
    },
    "& img": {
      width: "100%"
    }
  },
  newHPleftImage02: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%"
      // marginLeft: "-195px",
      // marginRight: "45px"
    },
    [theme.breakpoints.up("md")]: {
      width: "75%",
      marginLeft: "-365px"
    },
    [theme.breakpoints.up("lg")]: {
      // marginLeft: "-550px"
    },
    "& img": {
      width: "100%"
    }
  },
  leftImage02: {
    [theme.breakpoints.down("sm")]: {
      // width: "150%",
      marginLeft: "-225px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%",
      marginLeft: "-5px",
      marginRight: "45px"
    },
    [theme.breakpoints.up("md")]: {
      width: "75%",
      marginLeft: "-365px"
    },
    [theme.breakpoints.up("lg")]: {
      // marginLeft: "-550px"
    },
    "& img": {
      width: "100%"
    }
  },
  leftImage03: {
    [theme.breakpoints.down("sm")]: {
      width: "150%",
      marginLeft: "-10px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%"
    },
    [theme.breakpoints.up("md")]: {
      width: "80%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "150%"
    }
  },
  newHPhorizontalImage: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: "25px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%",
      marginBottom: "25px",
      // marginRight: "-195px",
      marginLeft: "45px"
    },
    // [theme.breakpoints.up("sm")]: {
    //   width: "70%",
    //   marginLeft: "45px"
    // },
    [theme.breakpoints.up("md")]: {
      marginRight: "-150px",
      marginBottom: "0px"
    },
    [theme.breakpoints.up("lg")]: {
      width: "70%"
    },
    "& img": {
      width: "100%"
    }
  },
  horizontalImage: {
    [theme.breakpoints.down("sm")]: {
      width: "150%",
      marginRight: "-195px",
      marginLeft: "40px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%",
      marginLeft: "220px"
    },
    [theme.breakpoints.up("md")]: {
      marginRight: "-150px",
      marginLeft: "60px"
    },
    [theme.breakpoints.up("lg")]: {
      width: "70%"
    },
    "& img": {
      width: "100%"
    }
  },
  horizontalImage02: {
    marginLeft: "45px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "45px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%",
      marginLeft: "245px"
    },
    [theme.breakpoints.up("md")]: {
      width: "80%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "150%"
    }
  },
  newHPhorizontalImage03: {
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "50px"
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "105px"
    },
    "& img": {
      width: "100%"
    }
  },
  horizontalImage03: {
    marginLeft: "45px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "45px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "50%",
      marginLeft: "385px",
      marginRight: "-75px"
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "85px"
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "105px"
    },
    "& img": {
      width: "100%"
    }
  },
  horizontalSecond: {
    marginRight: "25px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 25px 0"
    }
  },
  forImage: {
    marginBottom: "20px",
    [theme.breakpoints.up("xs")]: {
      width: "230px",
      height: "150px",
      marginLeft: "auto",
      marginRight: "auto"
    },
    [theme.breakpoints.up("md")]: {
      width: "290px",
      height: "210px",
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  card: {
    borderRadius: "20px"
  },
  container: {
    maxWidth: "1140px",
    margin: "0 auto",
    textAlign: "center"
    // [theme.breakpoints.down("sm")]: {
    //   padding: "0 15px 0 15px"
    // },
    // [theme.breakpoints.down("lg")]: {
    //   padding: "0 20px 0 20px"
    // }
  },
  iconContainer: {
    maxWidth: "450px",
    textAlign: "center",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "25px"
    }
  },
  sideBySide: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  // used in SideToSide component, which is used in various places
  textContainer: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  textContainer1: {
    textAlign: "center"
  },
  textContainer2: {
    maxWidth: "700px"
  },
  textContainer3: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  inverted: {
    listStyleType: "none",
    textAlign: "left",
    padding: "24px 12px",
    width: "33vw",
    maxWidth: "100%",
    minWidth: "320px"
  },
  lightBlueBG: {
    backgroundColor: lightBlueColor
  },
  darkBlueBG: {
    backgroundColor: bgDarkBlueColor
  },
  blackBG: {
    backgroundColor: "#0f131b"
  },
  whiteFont: {
    color: whiteColor
  },
  blackFont: {
    color: blackColor
  },
  orangeLine: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      marginRight: "auto"
    },
    border: `2px solid ${orangeColor}`,
    width: "100px",
    margin: "40px 0px 45px 0px"
  },
  whiteLine: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      marginRight: "auto"
    },
    border: `2px solid ${whiteColor}`,
    width: "100px",
    margin: "40px 0px 45px 0px"
  },
  marginBottom: {
    marginBottom: "25px"
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "inline-block"
    }
  },
  empty: {
    width: "300px"
  },
  paddingBottom: {
    paddingBottom: "0 !important"
  },
  block: {
    display: "block"
  },
  marginTop: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "25px"
    }
  },
  extraButtonStyle: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "75px"
  },
  sectionSixHeaderContainer: {
    textAlign: "center",
    marginBottom: "85px"
  },
  overflowHidden: {
    overflowX: "hidden"
  },
  textAndButton: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: "360px"
    }
  },
  largerTextAndButton: {
    [theme.breakpoints.up("md")]: {
      minWidth: "50%"
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "30%",
      maxWidth: "750px"
    }
  },
  zeroMarginLeft: {
    marginLeft: 0,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto"
    }
  },
  mainRibbon: {
    position: "relative",
    zIndex: "3",
    display: "block",
    marginTop: "15px",
    marginBottom: "-30px",
    width: "100vw",
    backgroundColor: mainGreenColor,
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center",
    padding: "12px",
    fontSize: "24px",
    transition: "background-color .15s linear",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px"
    },
    "&:hover": {
      backgroundColor: "#5ba331",
      color: "#fff"
    },
    "& > span": {
      textDecoration: "underline"
    }
  },

  // image styling for Remote Tools and Linux Questions LP
  lpImage: {
    [theme.breakpoints.down("xs")]: {
      width: "175%"
      // marginLeft: "240px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "175%"
      // marginLeft: "475px"
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      marginLeft: 0
    }
  },
  lqWrapper: {
    display: "block"
  },
  // Styling for Remote Tools Landing page
  regularPricingParagraph: {
    textAlign: "center",
    color: blackColor,
    fontSize: "22px",
    fontFamily: "Roboto, sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: 1.4
    }
  },
  greenLink: {
    color: mainGreenColor,
    "&:hover": {
      textDecoration: "underline",
      color: mainGreenColor
    }
  },
  remoteToolsButton: {
    margin: "35px 0 40px 0",
    padding: "6px 24px",
    backgroundColor: mainGreenColor,
    color: whiteColor,
    display: "block",
    borderRadius: "35px",
    fontSize: "22px",
    textAlign: "center",
    "& .MuiButton-label": {
      textTransform: "none"
    },
    "&:hover": {
      boxShadow: `0 0 0 2px ${secondaryGreenColor} inset`,
      background: whiteColor,
      color: secondaryGreenColor
    },
    [theme.breakpoints.down("sm")]: {
      width: "200px",
      margin: "0 auto",
      fontSize: "16px",
      padding: "6px 14px"
    },
    [theme.breakpoints.up("md")]: {
      width: "315px"
    }
  },

  // Used for Street landing page
  moneyBackText: {
    fontSize: "22px",
    fontFamily: "Open Sans",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      textAlign: "center"
    }
  },

  // for tech radar exclusive
  heroDescription: {
    fontSize: "22px",
    fontFamily: "Open Sans, Roboto",
    color: blackColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: 1.4
    }
  },
  dealTextContainer: {
    maxWidth: "575px",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
      maxWidth: "320px"
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "36px"
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "64px"
    }
  },
  addLineHeight: {
    lineHeight: 1.4
  }
});

export default whyShellsStyle;
