import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import pageModalStyle from "../../assets/jss/material-kit-react/components/pageModalStyle";

const useStyles = makeStyles(pageModalStyle);

export default function PageModal({ modalText, handleClose, open }) {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      disableScrollLock={true}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <p className={classes.text}>{modalText}</p>
        </div>
      </Fade>
    </Modal>
  );
}
