import React, { Component, useEffect } from "react";
import { flow, initFlow, uploadFiles } from "../../store/actions/FlowActions";
import { connect } from "react-redux";
import Redirect from "../Route/Redirect";
import { withRouter } from "react-router-dom";
import { changeUser } from "../../store/actions/UserActions";
import { Get, getPrefix } from "@karpeleslab/klbfw";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import {
  FormTitle,
  LoginForm,
  OAuth2Wrapper,
  OauthBtn,
  UserInfoWrapper
} from "./styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
//import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Loading from "../common/feedback/loading/Loading";
import { withTranslation, Trans } from "react-i18next";
import Button from "components/CustomButtons/ShellButton";
import ScrollToTop from "../Route/ScrollToTop";
import { Hidden, Typography } from "@material-ui/core";
import { error } from "../../store/actions/ToastAction";

// this is required due to how this component is build
// (spoiler alert: it's not that great)
const OnCompleteComponent = ({ onComplete }) => {
  useEffect(() => {
    onComplete();
  }, []); // eslint-disable-line
  return <></>;
};

class Login extends Component {
  data = {
    form: {},
    files: []
  };

  submitHandle = e => {
    e.preventDefault();
    const cpy = { ...this.data.form };
    this.data.form = {};
    this.props.flow(cpy).catch(() => {});
  };

  isRequired = field => {
    return this.props.req.includes(field);
  };

  onInputChange = e => {
    if (e.target.getAttribute("type") === "checkbox")
      this.data.form[e.target.getAttribute("name")] = e.target.checked;
    else this.data.form[e.target.getAttribute("name")] = e.target.value;
  };

  onImageChange = e => {
    if (e.target.files.length === 0) {
      return;
    }
    this.data.files[e.target.getAttribute("name")].file = e.target.files[0];
  };

  setAvatarImage = (name, image) => {
    this.data.files[name].file = image;
  };

  oauthBtnClickHandle = id => {
    this.props.flow({ oauth2: id }).catch(() => {});
  };

  buildOauthBtns = () => {
    return this.props.fields
      .filter(field => field.type === "oauth2")
      .map(field => {
        return (
          <OauthBtn
            key={field.id}
            button
            onClick={() => {
              this.oauthBtnClickHandle(field.id);
            }}
            alt={field.info.Name}
            className={field.info.Token_Name}
            backgroundColor={field.button["background-color"]}
            logo={field.button["logo"]}
          />
        );
      });
  };

  buildForm = () => {
    let checkbox;

    return this.props.fields
      .filter(field => field.type !== "oauth2")
      .map((field, idx) => {
        const fieldId = (field.name ?? 0) + idx;
        switch (field.type) {
          case "text":
          case "password":
          case "email":
          case "phone":
            return (
              <TextField
                variant="outlined"
                margin="normal"
                required={this.isRequired(field.name)}
                fullWidth
                name={field.name}
                label={field.label}
                type={field.type}
                id={fieldId}
                key={fieldId}
                onChange={this.onInputChange}
              />
            );
          case "label":
            if (field.link)
              return (
                <a href={field.link} key={fieldId}>
                  {field.label}
                </a>
              );

            // trick to identify the separation label between normal and oauth2 login
            // We look at the next element to display (if available) and check if it's a oauth btn
            var isOauthSeparationLabel =
              this.props.fields.length > idx + 1 &&
              this.props.fields[idx + 1].type === "oauth2";

            if (field.style === "error") {
              error(field.label, false);
            }

            // Hide error (only display them on snackbar) if on mobile
            return (
              <Hidden xsDown={field.style === "error"}>
                <Typography
                  variant="inherit"
                  paragraph
                  align={isOauthSeparationLabel ? "center" : "left"}
                  key={fieldId}
                  color={field.style === "error" ? "secondary" : "initial"}
                >
                  {field.label}
                </Typography>
              </Hidden>
            );
          case "checkbox":
            checkbox = (
              <Checkbox
                value="remember"
                color="primary"
                name={field.name}
                required={this.isRequired(field.name)}
                id={fieldId}
                onChange={this.onInputChange}
              />
            );

            var label = field.label;
            if (field.link) {
              /* eslint-disable */
              label = (
                <Trans
                  i18nKey={field.name}
                  components={{
                    "external-link": (
                      <a
                        href={field.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    )
                  }}
                />
              );
              /* eslint-enable */
            }

            return (
              <FormControlLabel
                key={fieldId}
                control={checkbox}
                label={label}
              />
            );
          default:
            return <div />;
        }
      });
  };

  buildUserSection = () => {
    if (this.props.user) {
      return (
        <UserInfoWrapper>
          <strong>{this.props.user.Profile.Display_Name}</strong>
        </UserInfoWrapper>
      );
    } else if (this.props.email) {
      return (
        <UserInfoWrapper>
          <strong>{this.props.email}</strong>
        </UserInfoWrapper>
      );
    }

    return "";
  };

  handleComplete = () => {
    if (typeof this.props.onLoggedIn === "function") {
      this.props.logUser(this.props.user);
      return <OnCompleteComponent onComplete={this.props.onLoggedIn} />;
    }
    // The upload has already been launched
    if (this.props.uploading) return <CircularProgress />;

    // The upload has been completed and we have a redirect => do it
    const redir =
      this.props.Redirect === `${getPrefix()}/`
        ? process.env.REACT_APP_SHELL_CONSOLE_URL
        : this.props.Redirect;

    if (this.props.uploadingDone && this.props.Redirect) {
      this.props.logUser(this.props.user);
      return <Redirect target={redir} />;
    }

    // we didn't launched any upload (if we did the first if of this function would be true)
    // We have some files so upload them.
    if (!this.props.uploadingDone && Object.keys(this.data.files).length > 0) {
      this.props.uploadFiles(this.data.files);
    } else if (this.props.Redirect) {
      // We don't have any files so just check if we need to redirect
      this.props.logUser(this.props.user);
      return <Redirect target={redir} />;
    } else if (this.props.url) {
      // We need to redirect to page outside the website
      this.props.logUser(this.props.user);
      return <Redirect target={this.props.url} />;
    }

    // At this point, the login process is complete, we don't have any file and any redirect instruction, well we are kind of stuck ....
    // This case should not happen, don't really know what to do except to display an error

    return <div>Something went wrong</div>;
  };

  // transform the query string to an object e.g ?foo=bar&var=value become {foo:"bar",var:"value"}
  parseQuery = queryString => {
    let query = {};
    if (queryString === "") return query;
    let pairs = (queryString[0] === "?"
      ? queryString.substr(1)
      : queryString
    ).split("&");
    for (let i = 0; i < pairs.length; i++) {
      let pair = pairs[i].split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
  };

  handleBack = () => {
    this.props.initFlow();
    this.props
      .flow(this.parseQuery(this.props.location.search.trim()))
      .catch(() => {});
  };

  render() {
    if (this.props.loading)
      return (
        <LoginForm>
          <Loading size={60} />
        </LoginForm>
      );

    const errors = this.props.error ? <div>Error {this.props.error}</div> : "";

    if (this.props.complete) {
      return this.handleComplete();
    }

    if (this.props.Redirect) {
      return <Redirect target={this.props.Redirect} />;
    }

    if (this.props.url) {
      return <Redirect target={this.props.url} />;
    }

    const userSection = this.buildUserSection();

    return (
      <LoginForm onSubmit={this.submitHandle}>
        <ScrollToTop />
        <FormTitle>{this.props.message}</FormTitle>
        {userSection}
        {errors}
        {this.buildForm()}
        <OAuth2Wrapper>{this.buildOauthBtns()}</OAuth2Wrapper>

        {this.buildButtons()}
      </LoginForm>
    );
  }

  buildButtons = () => {
    const { t } = this.props;
    const Next = (
      <Button
        type="submit"
        fullWidth={this.props.initial}
        variant="contained"
        color="primary"
      >
        {t("next_btn")}
      </Button>
    );

    if (!this.props.initial && this.props.fields.length > 0) {
      return (
        <Grid
          container
          direction="row-reverse"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item>{Next}</Grid>
          <Grid item>
            <Button variant="contained" onClick={() => this.handleBack()}>
              {t("back_btn")}
            </Button>
          </Grid>
        </Grid>
      );
    } else if (!this.props.initial && this.props.fields.length < 1) {
      return;
    } else {
      return Next;
    }
  };

  componentDidMount() {
    const params = this.parseQuery(this.props.location.search.trim());
    this.props.initFlow();
    this.props.flow(params).catch(() => {});
  }
}

const mapStateToProps = state => {
  return {
    complete: state.flow.complete,
    email: state.flow.complete,
    fields: state.flow.fields,
    message: state.flow.message,
    req: state.flow.req,
    loading: state.flow.loading,
    error: state.flow.error,
    user: state.flow.user,
    initial: state.flow.initial,
    Redirect: state.flow.Redirect,
    url: state.flow.url,
    uploading: state.flow.uploading,
    uploadingDone: state.flow.uploadingDone
  };
};

const mapDispatchToProps = dispatch => {
  return {
    flow: data => dispatch(flow(data)),
    uploadFiles: files => dispatch(uploadFiles(files)),
    logUser: user => dispatch(changeUser(user)),
    initFlow: () => dispatch(initFlow())
  };
};

Login.serverFetch = (match, store) => store.dispatch(flow({ ...Get() }));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(Login)));
