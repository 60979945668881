import React, { useLayoutEffect, useState, useRef } from "react";
import Helmet from "../instances/instance/Helmet/Helmet";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";

import ArticleCard from "./ArticleCard";
import Paginations from "../Pagination/Pagination.js";

import { list } from "../../store/actions/CmsAction";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";
import { IMG_VARIATIONS } from "./DisplayArticle";
import style from "../../assets/scss/modules/list.module.scss";
import bannerBlog from "assets/img/banners/blog-banner-v4.jpg";
import bannerTutorial from "assets/img/banners/tuto-banner-v2.jpg";
import Loading from "../common/feedback/loading/Loading";

const useStyles = makeStyles(styles);

const CmsList = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { list } = props;
  const { type, paging, cmsList } = props;
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const inputRef = useRef();
  const cmsKey = {
    blog: "@blog",
    faq: "@faq",
    news: "@news",
    tutorial: "@tutorials"
  };

  useLayoutEffect(() => {
    inputRef.current.focus();

    setLoading(true);
    list(cmsKey[type], { ...paging, page_no: 1 }, {}, IMG_VARIATIONS).finally(
      () => setLoading(false)
    );
    // eslint-disable-next-line
	}, []);

  const changePaginate = num => {
    const pagiset = {
      page_no: num
    };

    setLoading(true);
    window.scrollTo(0, 0);

    list(cmsKey[type], pagiset, {}, IMG_VARIATIONS).finally(() => {
      setLoading(false);
    });
  };

  const buildPaginate = () => {
    if (cmsList.length === 0) return null;

    const { page_no, page_max } = paging;
    const prevActive = page_no <= 1;
    const nextActive = page_no < page_max;

    let prevButn = [];
    let nextButn = [];

    let delta = 2,
      previous = 1,
      pagiArray = [
        {
          text: previous,
          onClick: () => changePaginate(1),
          active: page_no === 1
        }
      ];

    for (let i = page_no - delta; i <= page_no + delta; i++) {
      if (i <= 1 || i >= page_max) continue;
      if (i - previous > 1) pagiArray.push({ text: "...", disabled: true });

      pagiArray.push({
        text: i,
        onClick: () => {
          changePaginate(i);
        },
        active: i === page_no
      });
      previous = i;
    }

    if (page_max - previous > 1) {
      pagiArray.push({ text: "...", disabled: true });
    }

    if (page_max > 1) {
      pagiArray.push({
        text: page_max,
        onClick: () => {
          changePaginate(page_max);
        },
        active: page_max === page_no
      });
    }

    if (prevActive) {
      prevButn.push({
        text: "<",
        disabled: true
      });
    } else {
      prevButn.push({
        text: "<",
        onClick: () => {
          changePaginate(page_no - 1);
        }
      });
    }

    if (!nextActive) {
      nextButn.push({
        text: ">",
        disabled: true
      });
    } else {
      nextButn.push({
        text: ">",
        onClick: () => {
          changePaginate(page_no + 1);
        }
      });
    }

    const pagiArrayTotal = loading
      ? []
      : [...prevButn, ...pagiArray, ...nextButn];
    return <Paginations pages={pagiArrayTotal} color="info" />;
  };

  const buildBanner = () => {
    const banner = {
      blog: bannerBlog,
      tutorial: bannerTutorial
    };
    if (type === "blog" || type === "tutorial") {
      return <img src={banner[type]} alt={t(`title_${type}`)} width="100%" />;
    } else {
      return (
        <h1 align="center" className={classes.title}>
          {t(`title_${type}`)}
        </h1>
      );
    }
  };

  const buildList = () => {
    if (cmsList.length === 0 && !loading) {
      return (
        <div className={style["no-article"]}>
          <p>{t("list_no_article")}</p>
        </div>
      );
    } else if (loading) {
      return (
        <div style={{ width: "100%", margin: "auto" }}>
          <Loading />
        </div>
      );
    } else {
      return cmsList.map((item, idx) => {
        return (
          <Grid key={idx} item xs={12} sm={6} md={4} xl={3}>
            <ArticleCard article={item} type={type} />
          </Grid>
        );
      });
    }
  };

  const buildSearch = () => {
    const onSubmit = e => {
      e.preventDefault();
      setLoading(true);
      list(
        cmsKey[type],
        { page_no: 1 },
        { all: searchValue },
        IMG_VARIATIONS
      ).finally(() => {
        setLoading(false);
        setSearchValue("");
      });
    };

    if (cmsKey[type] === "@blog" || cmsKey[type] === "@tutorials") {
      return (
        <form onSubmit={onSubmit} className={classes.searchForm}>
          <Input
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            className={classes.searchInput}
            inputRef={inputRef}
            required
          />
          <Button
            type="submit"
            variant="contained"
            className={classes.searchButton}
            disableElevation
          >
            {t("blog_tutorial_search")}
          </Button>
        </form>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Helmet title={`${t(`title_${type}`)} | Shells official site`} />
      <Grid container className={classes.textCenter}>
        <Grid item xs={12}>
          {buildBanner()}
        </Grid>
        <Grid item xs={12} className={classes.searchContainer}>
          {buildSearch()}
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={4}
          style={{ marginTop: "24px", marginLeft: "-7px" }}
        >
          {buildList()}
        </Grid>
        <Grid
          item
          container
          justify="center"
          className={classes.paginationContainer}
        >
          <Grid item className={style["paginate-wrapper"]}>
            {buildPaginate()}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = state => {
  return {
    cmsList: state.cms.cmsList,
    cmsLoading: state.cms.cmsLoading,
    cmsError: state.cms.cmsError,
    paging: state.cms.paging
  };
};

const mapDispatchToProps = dispatch => {
  return {
    list: (cms, paging, filters, IMG_VARIATIONS) =>
      dispatch(list(cms, paging, filters, IMG_VARIATIONS))
  };
};

CmsList.serverFetchBlogs = (match, store) =>
  store.dispatch(list("@blog", { page_no: 1 }, {}, IMG_VARIATIONS));
CmsList.serverFetchFaq = (match, store) =>
  store.dispatch(list("@faq", { page_no: 1 }, {}, IMG_VARIATIONS));
CmsList.serverFetchNews = (match, store) =>
  store.dispatch(list("@news", { page_no: 1 }, {}, IMG_VARIATIONS));
CmsList.serverFetchTutorials = (match, store) =>
  store.dispatch(list("@tutorials", { page_no: 1 }, {}, IMG_VARIATIONS));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CmsList);
