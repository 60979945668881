import React from "react";
import { Trans } from "react-i18next";

import useSpecialPlansCarouselStyles from "../../components/SpecialPlansCarousel/SpecialPlansCarousel.style";

export default function Discount({ transKey }) {
  const classes = useSpecialPlansCarouselStyles();

  if (transKey) {
    return (
      <Trans
        i18nKey={transKey}
        components={{
          newLine: <br />,
          dealText: <p className={classes.dealText} />,
          monthFreeText: <span className={classes.monthFreeText} />,
          lineThrough: <span className={classes.linethrough} />,
          discountParent: <p />
        }}
      />
    );
  } else {
    return null;
  }
}
