import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "./../CustomButtons/ShellButton";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import ShareIcon from "@material-ui/icons/Share";
import Typography from "@material-ui/core/Typography";

import styles from "assets/jss/material-kit-react/components/articleCardstyle";
import { makeStyles } from "@material-ui/core/styles";
import { THUMB_IMG_VARIATION } from "./DisplayArticle";
import DefaultImage from "../../assets/img/shells_icon_white.svg";

import DateUtil from "./../common/data/DateUtil";

const useStyles = makeStyles(styles);

const ArticleCard = ({ article, type }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const trimContents = (contents, length) => {
    contents = contents.slice(0, length);
    let lastIdx = contents.lastIndexOf(" ");
    contents = contents.substring(0, lastIdx);

    return contents;
  };

  const getCurrentUrl = () =>
    typeof window === "undefined" ? "" : window.location.href;
  const SHARE_PARAMS = [
    {
      url: "https://www.facebook.com/sharer.php?u=",
      title: "Share On Facebook",
      params: "",
      text: article.Title,
      sharedUrl: getCurrentUrl(),
      generateText: () => {
        return "";
      },
      generateSharedUrl: () => {
        return getCurrentUrl();
      },
      iconClass: "fab fa-lg fa-facebook",
      iconColor: "#3B5998",
      key: "facebook"
    },
    {
      url: "https://twitter.com/intent/tweet?",
      title: "Share On Twitter",
      params: "",
      text: article.Title,
      sharedUrl: getCurrentUrl(),
      hashTags: article.keywords,
      suggestedAccounts: [],
      generateText: () => {
        if (article.Title) {
          return `text=${article.Title.split(" ").join("%20")}`;
        } else return false;
      },
      generateSharedUrl: () => {
        return `%0D%0A${getCurrentUrl()}`;
      },
      iconClass: "fab fa-lg fa-twitter",
      iconColor: "#00ACEE",
      key: "twitter"
    },
    {
      url: "mailto:?",
      title: "Share via Email",
      params: "",
      text: article.Title,
      sharedUrl: getCurrentUrl(),
      sendTo: "",
      generateText: () => {
        return `subject=${article.Title}`;
      },
      generateSharedUrl: () => {
        return `&body=${getCurrentUrl()}`;
      },
      iconClass: "fa fa-lg fa-envelope",
      iconColor: "#C0C0C0",
      key: "email"
    }
  ];
  const buildModal = (platform, w = 400, h = 500, resizable = "no") => {
    const queryUrl = platform.url;
    const queryText = platform.generateText();
    const sharedUrl = platform.generateSharedUrl();
    const query = `${queryUrl}${queryText}${sharedUrl}`;
    const options = `width=${w},height=${h},resizable=${resizable}`;

    window.open(query, platform.title, options).focus();
  };
  const handleShare = key =>
    buildModal(SHARE_PARAMS.find(el => el.key === key));

  return (
    <Card component={Paper} className={classes.root}>
      <CardActionArea
        component={Link}
        to={`/${type}/${article.Slug}`}
        className={classes.cardActionArea}
      >
        <CardHeader
          title={article.Title}
          subheader={
            <DateUtil klbDateObj={article.Created} format="MMMM Do YYYY" />
          }
          classes={{
            root: classes.headerRoot,
            title: classes.title,
            subheader: classes.subheader
          }}
        />
        <CardMedia
          className={classes.media}
          image={
            article.Drive_Item && article.Drive_Item.Media_Image !== undefined
              ? article.Drive_Item.Media_Image[THUMB_IMG_VARIATION]
              : DefaultImage
          }
          alt={article.Title}
        />
        {article.Short_Contents.length !== 0 && (
          <CardContent className={classes.cardContent}>
            <Typography variant="body2" color="textSecondary" component="p">
              {`${trimContents(article.Short_Contents, 200)}`
                ? `${trimContents(article.Short_Contents, 200)}...`
                : null}
            </Typography>
          </CardContent>
        )}
        <CardActions disableSpacing>
          <IconButton aria-label="share" onClick={handleClick}>
            <ShareIcon />
          </IconButton>
          <Menu
            component={Paper}
            getContentAnchorEl={null}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
          >
            {SHARE_PARAMS.map(s => (
              <MenuItem key={s.key} onClick={() => handleShare(s.key)}>
                <ListItemIcon>
                  <i
                    className={`${s.iconClass} ${classes.icon}`}
                    aria-hidden="true"
                    style={{ color: s.iconColor }}
                  />
                </ListItemIcon>
                <ListItemText primary={t(`share_${s.key}`)} />
              </MenuItem>
            ))}
          </Menu>
          <Button
            component={Link}
            color="primary"
            variant="contained"
            className={classes.go}
            to={`/${type}/${article.Slug}`}
          >
            {t("read_more")}
          </Button>
        </CardActions>
      </CardActionArea>
    </Card>
  );
};

export default ArticleCard;
